import React, { useEffect } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import SearchUser from "../../helperComponents/searchUser";
import SearchUserAdvanced from "../../helperComponents/searchUserAdvanced";
import SelectDomainArea from "../../helperComponents/selectDomainArea";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { Factory } from "../../../helpers/factories";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterElements: {
    margin: theme.spacing(2),
    width: "90%",
  },
}));

export default function AssessmetnsFilter({
  toastOptions,
  setToastOptions,
  queryString,
  setQueryString,
  ...props
}) {
  const classes = useStyles();

  //current user from app context
  const { currentUser } = useAppContext();

  const [user, setUser] = React.useState(Factory.createUser());

  const [userQueryOptions, setUserQueryOptions] = React.useState({});

  //dates
  const [selectedFromDate, setSelectedFromDate] = React.useState(
    moment().subtract(30, "days")
  );
  const [selectedToDate, setSelectedToDate] = React.useState(moment());

  // assessment type - review or observation
  const [assessmentType, setAssessmentType] = React.useState("");
  const [domainArea, setDomainArea] = React.useState(
    Factory.createDomainArea()
  );

  //pre-load domain/araes
  useEffect(() => {
    //set initial query string
    setQueryString({
      student: user._id ? user._id : "",
      domainArea: domainArea._id ? domainArea._id : "",
      type: assessmentType ? assessmentType : "",
      from: selectedFromDate ? selectedFromDate : "",
      to: selectedFromDate ? selectedToDate : "",
    });
  }, [domainArea, user]);

  //handle From date change
  const handleFromDateChange = (date) => {
    if (date === "Invalid Date" || date === null) {
    } else {
      setSelectedFromDate(date);
      setQueryString({
        ...queryString,
        from: moment(date),
      });
    }
  };

  //handle To date change
  const handleToDateChange = (date) => {
    if (date === "Invalid Date" || date === null) {
    } else {
      setSelectedToDate(date);
      setQueryString({
        ...queryString,
        to: moment(date),
      });
    }
  };

  //handle assessment Type  change
  const handleTypeChange = (e) => {
    setAssessmentType(e.target.value);
    if (e.target.value === "all") {
      setQueryString({
        ...queryString,
        type: "",
      });
    } else {
      setQueryString({
        ...queryString,
        type: e.target.value,
      });
    }
  };

  return (
    <Grid container justify="space-between" spacing={3}>
      {["teacher", "areaCoordinator", "domainHead", "headOfSchool"].includes(
        currentUser.role
      ) && (
        <Grid item xs={12} sm={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              {/* <SearchUser
              user={user}
              setUser={setUser}
              role={"student"}
              style={{ display: "flex" }}
            /> */}
              <SearchUserAdvanced
                user={user}
                setUser={setUser}
                userQueryOptions={userQueryOptions}
                setUserQueryOptions={setUserQueryOptions}
                style={{ display: "flex" }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <Grid container justify="space-between" spacing={3}>
          <Grid item xs={12} sm={3}>
            <SelectDomainArea
              domainArea={domainArea}
              setDomainArea={setDomainArea}
              showAllOption={true}
              orgId={currentUser.organisation.id}
              type={""}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl
              className={classes.formControl}
              variant="outlined"
              style={{ width: "90%" }}
            >
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                size="small"
                value={assessmentType}
                onChange={handleTypeChange}
                label="Type"
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"review"}>Review</MenuItem>
                <MenuItem value={"observation"}>Observation</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={3} justify="space-between">
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    className={classes.filterElements}
                    margin="normal"
                    id="date-picker-dialog-from"
                    label="From"
                    format="dd/MM/yyyy"
                    value={selectedFromDate}
                    onChange={handleFromDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    margin="normal"
                    className={classes.filterElements}
                    id="date-picker-dialog-t0"
                    label="To"
                    format="dd/MM/yyyy"
                    value={selectedToDate}
                    onChange={handleToDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
