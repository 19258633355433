/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const AssessmentSummaryService = {
  getAssessmentSummariesByOrgId,
  addAssessmentSummaryByOrgId,
};

function getAssessmentSummariesByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_assessment_summary_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function addAssessmentSummaryByOrgId(orgId, assessmentSummary) {
  const requestOptions = RequestHelper.getPostRequestOptions(assessmentSummary);

  var api_url = config.api_assessment_summary_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.assessmentSummary;
    });
}

// function updateNotificationByOrgIdAndNotificationId(
//   orgId,
//   notificationId,
//   notification
// ) {
//   const requestOptions = RequestHelper.getPutRequestOptions(notification);

//   var api_url = config.api_notifications_by_orgId_and_notificationId;
//   api_url = RequestHelper.replaceParams(api_url, {
//     orgId: orgId,
//     notificationId: notificationId,
//   });
//   //return Promise.reject(new Error());
//   return fetch(api_url, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       return data.notification;
//     });
// }

// function deleteNotificationsByOrgIdAndNotificationId(orgId, notificationId) {
//   const requestOptions = RequestHelper.getDeleteRequestOptions();

//   var api_url = config.api_notifications_by_orgId_and_notificationId;
//   api_url = RequestHelper.replaceParams(api_url, {
//     orgId: orgId,
//     notificationId: notificationId,
//   });

//   //return Promise.reject(new Error());
//   return fetch(api_url, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       return data._id;
//     });
// }
