import React, { useEffect } from "react";

import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterElements: {
    margin: theme.spacing(2),
    width: "90%",
  },
}));

export default function LeResourcesFilter({
  toastOptions,
  setToastOptions,
  queryString,
  setQueryString,
  ...props
}) {
  const classes = useStyles();

  const [keywords, setKeywords] = React.useState("");
  const [orgName, setOrgName] = React.useState(""); // State for organization name
  const [timer, setTimer] = React.useState(null);
  const [keywordsBeingTyped, setKeywordsBeingTyped] = React.useState(false);
  const [orgNameBeingTyped, setOrgNameBeingTyped] = React.useState(false); // State for tracking organization name typing

  useEffect(() => {
    if (!keywordsBeingTyped && !orgNameBeingTyped) {
      // Check both keyword and org name typing status
      setQueryString({
        keywords: keywords,
        orgName: orgName, // Include orgName in the query string
      });
    }
  }, [keywordsBeingTyped, orgNameBeingTyped]);

  const handleKeywordsChange = (e) => {
    setKeywords(e.target.value);
    setKeywordsBeingTyped(true);
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      setKeywordsBeingTyped(false);
    }, 500);
    setTimer(newTimer);
  };

  const handleOrgNameChange = (e) => {
    setOrgName(e.target.value);
    setOrgNameBeingTyped(true);
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      setOrgNameBeingTyped(false);
    }, 500);
    setTimer(newTimer);
  };

  return (
    <Grid container justify="space-between" spacing={3}>
      <Grid
        item
        xs={12}
        sm={6}
        style={{ backgroundColor: "white", borderRadius: "5px" }}
      >
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "90%" }}
        >
          <TextField
            margin="dense"
            id="search-tags"
            name="search-tags"
            label="Search Tags"
            type="text"
            value={keywords}
            placeholder="Enter keywords to search learning resources"
            onChange={handleKeywordsChange}
            fullWidth
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={{ backgroundColor: "white", borderRadius: "5px" }}
      >
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "90%" }}
        >
          <TextField
            margin="dense"
            id="search-org"
            name="search-org"
            label="Search Organization"
            type="text"
            value={orgName}
            placeholder="Enter organization name to filter by organization"
            onChange={handleOrgNameChange}
            fullWidth
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
