import { BrowserRouter as Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import Login from "./components/login/login";
import Landing from "./components/landing/landing";
import Paperbase from "./components/dashboard/paperbase";
// import { useAppContext } from "./libs/contextLib";
import AuthenticatedRoutes from "./components/routes/AuthenticatedRoutes";
import HybridRoutes from "./components/routes/HybridRoutes";
import UnauthenticatedRoutes from "./components/routes/UnauthenticatedRoutes";

export default function Routes() {
  // const { isAuthenticated, userHasAuthenticated } = useAppContext();

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <HybridRoutes
          exact
          path="/"
          unauthComp={Landing}
          authComp={Paperbase}
        />
        <UnauthenticatedRoutes exact path="/login">
          <Login />
        </UnauthenticatedRoutes>
        <AuthenticatedRoutes exact path="/users">
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
            "headOfAdmin",
          ]}
          path="/absentees"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes exact path="/resetPassword">
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={["schoolAdmin"]}
          path="/resetUserPassword"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={["schoolAdmin"]}
          path="/bulkUpdateStudent"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes exact path="/myDetails">
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes exact path="/roadmap">
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes exact path="/domainRoadmap">
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes exact path="/fiveAreasRoadmap">
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes exact path="/groupRoadmap">
          <Paperbase />
        </AuthenticatedRoutes>
        {/* <AuthenticatedRoutes exact path="/roadmap/:roadmapNodeId">
          <Paperbase />
        </AuthenticatedRoutes> */}
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
            "headOfAdmin",
          ]}
          path="/roadmaps"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "student",
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
            "staff",
          ]}
          path="/schoolRoadmap"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
          ]}
          path="/mentees"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
          ]}
          path="/lessonPlans"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
          ]}
          path="/studentProfile"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
          ]}
          path="/teacherProfile"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "student",
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
          ]}
          path="/roadmapTracker"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
            "staff",
            "headOfAdmin",
          ]}
          path="/mentormentees"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes exact path="/announcements">
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            // "student",
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
          ]}
          path="/assessments"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
          ]}
          path="/fitnessTest"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
          ]}
          path="/assessmentSummaries"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
            "staff",
            "headOfAdmin",
          ]}
          path="/appraisals"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
          ]}
          path="/assessmentsSnapshot"
        >
          <Paperbase />
        </AuthenticatedRoutes>

        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
          ]}
          path="/reports"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "student",
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
          ]}
          path="/portraits"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes exact path="/timetable">
          <Paperbase />
        </AuthenticatedRoutes>

        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
          ]}
          path="/manageTimeTable"
        >
          <Paperbase />
        </AuthenticatedRoutes>

        <AuthenticatedRoutes
          exact
          allowedRoles={["bbAdmin"]}
          path="/affiliations"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={["bbAdmin"]}
          path="/addEditOrganisation"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={["bbAdmin"]}
          path="/organisations"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={["bbAdmin"]}
          path="/resetAdminPasswords"
        >
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes exact path="/learningResources">
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes exact path="/manageLearningResources">
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "student",
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
            "staff",
            "headOfAdmin",
          ]}
          path="/bbChatbot"
        >
          {" "}
          <Paperbase />
        </AuthenticatedRoutes>
        <AuthenticatedRoutes exact path="/emergency">
          <Paperbase />
        </AuthenticatedRoutes>

        {/* New code added */}
        <AuthenticatedRoutes
          exact
          allowedRoles={[
            "student",
            "teacher",
            "domainHead",
            "areaCoordinator",
            "headOfSchool",
            "schoolAdmin",
            "staff",
            "headOfAdmin",
          ]}
          path="/reviewMain"
        >
          <Paperbase />
        </AuthenticatedRoutes>

        {/* <Route path="/login">
          <Login />
        </Route> */}
        {/* <AuthenticatedRoutes
          exact
          path="/"
          unauthComp={Landing}
          authComp={Paperbase}
        ></AuthenticatedRoutes> */}
      </Switch>
    </ThemeProvider>
  );
}
