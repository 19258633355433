import React from "react";
import { ResponsiveContainer } from "recharts";
import Skeleton from "@material-ui/lab/Skeleton";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //overflow: "auto",
  },
  blobParent: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  blobNeutral: {
    background: "rgba(255, 187, 40, 1)",
    borderRadius: "50%",
    margin: "10px",
    height: "40px",
    width: "40px",
    boxShadow: "0 0 0 0 rgba(255, 187, 40, 1)",
    transform: "scale(1)",
    animation: "$pulseNeutral 4s infinite",
  },
  blobGood: {
    background: "rgba(0, 196, 159, 1)",
    borderRadius: "50%",
    margin: "10px",
    height: "40px",
    width: "40px",
    boxShadow: "0 0 0 0 rgba(0, 196, 159, 1)",
    transform: "scale(1)",
    animation: "$pulseGood 4s infinite",
  },
  blobBad: {
    background: "rgba(255, 0, 0, 63)",
    borderRadius: "50%",
    margin: "10px",
    height: "40px",
    width: "40px",
    boxShadow: "0 0 0 0 rgba(255, 0, 0, 1)",
    transform: "scale(1)",
    animation: "$pulseBad 4s infinite",
  },
  "@keyframes pulseGood": {
    "0%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(0, 196, 159, 0.7)",
    },
    "70%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 10px rgba(0, 196, 159, 0)",
    },
    "100%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(0, 196, 159, 0)",
    },
  },
  "@keyframes pulseNeutral": {
    "0%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(255, 187, 40, 0.7)",
    },
    "70%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 10px rgba(255, 187, 40, 0)",
    },
    "100%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(255, 187, 40, 0)",
    },
  },
  "@keyframes pulseBad": {
    "0%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(255, 0, 0, 0.7)",
    },
    "70%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 10px rgba(255, 0, 0, 0)",
    },
    "100%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(255, 0, 0, 0)",
    },
  },
}));

export default function LearnerPulse({ assessmentPieData, roadmapPieData }) {
  const classes = useStyles();

  // console.log(assessmentPieData);
  // console.log(roadmapPieData);

  let assessmentScore = 0;
  let sumAssessmentScore = 0;
  let roadmapScore = 0;
  let sumRoadmapScore = 0;

  assessmentPieData.map((entry, index) => {
    let temp =
      entry["name"] === "mainly negative"
        ? -1 * entry["value"]
        : entry["name"] === "mainly positive"
        ? entry["value"]
        : 0;
    sumAssessmentScore = sumAssessmentScore + entry["value"];
    assessmentScore = assessmentScore + temp;
  });
  assessmentScore = assessmentScore / sumAssessmentScore;

  if (roadmapPieData.lengh > 0) {
    let pos = roadmapPieData[0] ? roadmapPieData[0]["value"] : 0;
    let neg = roadmapPieData[1] ? roadmapPieData[1]["value"] : 0;
    roadmapScore = (pos - neg) / (pos + neg);
  }
  roadmapPieData.map((entry, index) => {
    let temp =
      entry["name"] === "incomplete" ? -1 * entry["value"] : entry["value"];
    sumRoadmapScore = sumRoadmapScore + entry["value"];
    roadmapScore = roadmapScore + temp;
  });
  roadmapScore = roadmapScore / sumRoadmapScore;

  const pulseScore = 0.6 * assessmentScore + 0.4 * roadmapScore;
  // console.log("assessmentScore", assessmentScore);
  // console.log("roadmapScore", roadmapScore);
  // console.log("pulseScore", pulseScore);
  return (
    <ResponsiveContainer width="100%" height={60}>
      <div className={classes.blobParent}>
        {(() => {
          if (assessmentPieData.length == 0 && roadmapPieData.length == 0)
            return <Skeleton variant="circle" width={40} height={40} />;
          if (pulseScore > 0.1) {
            return <div className={classes.blobGood}></div>;
          } else if (pulseScore < -0.1) {
            return <div className={classes.blobBad}></div>;
          } else {
            return <div className={classes.blobNeutral}></div>;
          }
        })()}
        {/* {pulseScore ? (
          <div className={classes.blob}></div>
        ) : (
          <Skeleton variant="circle" width={40} height={40} />
        )} */}
      </div>
    </ResponsiveContainer>
  );
}
