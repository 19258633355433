import { useAppContext } from "../../../libs/contextLib";
import { RoadmapService } from "../../../services/roadmap";
import React, { Fragment, useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Box, Button, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
    padding: theme.spacing(1.5), // Slightly smaller padding
    marginBottom: theme.spacing(2), // Slightly smaller margin
  },
  alertContainer: {
    borderRadius: "9px",
    minHeight: "80px", // Adjust this value as needed
    backgroundColor: "##dedfe0",
    border: "1.2px dashed #c77a22",
    padding: theme.spacing(1.5),
  },

  icon: {
    color: "#cc7431",
    fontSize: "24px",
    marginRight: "8px",
    verticalAlign: "middle", // Align icon with text
  },
  roundedBadge: {
    borderRadius: "4px",
    display: "flex",
    alignItems: "center", // Aligns the icon and title vertically
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: "15px",
    fontWeight: 500,
    fontFamily: "'Arial', sans-serif",
    color: "#9c2121", // Title color
    display: "inline", // Ensures it stays on the same line as the icon
  },
  message: {
    fontFamily: "'Arial', sans-serif",
    fontSize: "14px",
    color: "#333333",
    fontWeight: 600, // Semi-bold
    marginTop: theme.spacing(0.5), // Adjust the value for padding between title and message
  },
  snackbar: {
    marginTop: theme.spacing(2),
  },
}));

function RedFlags(props) {
  const classes = useStyles();
  const location = useLocation();
  const { currentUser } = useAppContext();
  const [roadmapNodes, setRoadmapNodes] = useState([]);
  // console.log("road", roadmapNodes);
  const [showAlert, setShowAlert] = useState(false);
  const [roadmapChildNodes, setRoadmapChildNodes] = React.useState([]);
  const [expireGoal, setExpireGoal] = useState([]);
  const [inactiveRoadmaps, setInactiveRoadmaps] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  //console.log("roadmapChildNode", roadmapChildNodes);
  const [message, setMessage] = useState("");

  const [selectedFromDate, setSelectedFromDate] = useState(
    moment().subtract(3, "years")
  );
  const [selectedToDate, setSelectedToDate] = useState(moment());

  const [queryString, setQueryString] = React.useState({
    user: currentUser.id,
    domainArea: "",
    role: "",
    from: selectedFromDate.valueOf(), // Convert to timestamp
    to: selectedToDate.valueOf(), // Convert to timestamp
  });
  //fetch roadmap and its childnode
  const onLoad = useCallback(async () => {
    setLoading(true);
    try {
      // Fetch roadmap nodes by organisation ID and query string
      const roadmapNodesData = await RoadmapService.getRoadmapNodesByOrgId(
        currentUser.organisation.id,
        queryString
      );

      setRoadmapNodes(roadmapNodesData);
      setError(false);

      // Check if there is at least one roadmap node to fetch child nodes
      if (roadmapNodesData.length > 0) {
        const roadmapChildNodesData = await Promise.all(
          roadmapNodesData.map(async (roadmapNode) => {
            const childNodes =
              await RoadmapService.getRoadmapChildNodeByOrgIdAndRoadmapNodeId(
                currentUser.organisation.id,
                roadmapNode._id
              );

            return {
              roadmapNodeId: roadmapNode._id,
              goal: roadmapNode.goal,
              childNodes: childNodes,
            };
          })
        );

        setRoadmapChildNodes(roadmapChildNodesData);
        // Check for inactive roadmaps
      }
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
      setIsDataLoading(false);
    }
  }, [currentUser.organisation.id, queryString]);

  const checkRoadmapStatuses = () => {
    const now = moment();
    const thresholdDate = now.subtract(7, "days");

    const expiredGoals = [];
    const inactiveRoadmaps = [];
    const expiringSoonRoadmaps = [];

    roadmapNodes.forEach((node) => {
      let daysLeft = moment(node.completionDate).diff(moment(), "days");

      const childNodes =
        roadmapChildNodes.find(
          (childNode) => childNode.roadmapNodeId === node._id
        )?.childNodes || [];

      // Check if all indicators of success are fully completed
      const isMainNodeFullyCompleted = node.indicatorsOfSuccess?.every(
        (indicator) => indicator.indicatorProgress === 100
      );

      // Skip this roadmap if it's fully completed
      if (isMainNodeFullyCompleted) {
        return;
      }

      if (daysLeft <= 0) {
        expiredGoals.push({
          goal: node.goal,
        });
        // Skip further checks if the roadmap is expired
        return;
      }

      // Check if the roadmap is expiring soon (less than 6 days)
      if (daysLeft <= 5) {
        expiringSoonRoadmaps.push({
          goal: node.goal,
          daysLeft: daysLeft,
        });
      }

      const allLastUpdatedDates = childNodes.map((childNode) =>
        moment(childNode.lastUpdated)
      );

      if (allLastUpdatedDates.length > 0) {
        const mostRecentUpdateDate = allLastUpdatedDates.reduce(
          (mostRecent, date) => (date.isAfter(mostRecent) ? date : mostRecent),
          moment("2024-01-01") // Keep this same
        );

        const isRoadmapInactive = mostRecentUpdateDate.isBefore(thresholdDate);

        if (isRoadmapInactive) {
          inactiveRoadmaps.push({
            goal: node.goal,
            mostRecentUpdateDate: mostRecentUpdateDate.format("MMMM D, YYYY"),
          });
        }
      }
    });

    let message = "";

    if (expiredGoals.length > 0) {
      setExpireGoal(expiredGoals);
      message = "Some of your roadmaps have expired.";
    }

    if (expiringSoonRoadmaps.length > 0) {
      if (message) {
        message += " Additionally, some of your roadmaps are expiring soon.";
      } else {
        message = "Some of your roadmaps are expiring soon.";
      }
    }

    if (inactiveRoadmaps.length > 0) {
      if (message) {
        message +=
          " Additionally, some roadmaps have been inactive for a while. Please update them.";
      } else {
        message =
          "Some of your roadmaps have been inactive for a while. Please update them.";
      }
    }

    if (message) {
      setShowAlert(true);
      setMessage(message);
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    if (roadmapNodes.length > 0 && roadmapChildNodes.length > 0) {
      checkRoadmapStatuses();
    }
  }, [roadmapNodes, roadmapChildNodes]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Fragment>
      {showAlert && (
        <Grid container className={classes.root}>
          <Grid
            item
            xs={12}
            sm={8}
            md={12}
            container
            direction="column"
            className={classes.alertContainer}
          >
            <Grid item>
              <div className={classes.roundedBadge}>
                <WarningIcon className={classes.icon} />
                <Typography className={classes.title}>Alert!</Typography>
              </div>
            </Grid>
            <Grid item>
              <Typography className={classes.message}>
                Attention! {message}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className={classes.snackbar}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity="error" elevation={2}>
          Attention! Please Update Your Roadmaps.
        </MuiAlert>
      </Snackbar>
    </Fragment>
  );
}

export default RedFlags;
