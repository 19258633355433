import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import Chip from "@material-ui/core/Chip";

import Roles from "../../../helpers/roles";

const useStyles = makeStyles((theme) => ({
  dataGridRoot: {
    width: "100%",
    display: "flex",
    //padding: theme.spacing(4),
    // marginBottom: "20px",
  },
  dataGrid: {
    flexGrow: 1,
    // height: "600px",
    // minHeight: "400px",
    backgroundColor: "white !important",
  },
  whiteBackground: {
    backgroundColor: "white !important",
  },
  moreVertIcon: {
    cursor: "pointer",
    // color: //theme.palette.mutedGray.main,
  },
}));

function AbsenteesDataGrid({
  setUser,
  user,
  absentees,
  setOpType,
  setSelectedRowIndex,
  setIsDialogOpen,
  isDataLoading,
  handleOpenDialog,
  ...props
}) {
  const classes = useStyles();

  // const [anchorEl, setAnchorEl] = React.useState({});

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    // { field: "firstName", headerName: "First Name", width: 120 },
    // { field: "lastName", headerName: "Last Name", width: 130 },
    {
      field: "student",
      headerName: "Student",
      width: 250,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", marginRight: "10px" }}>
              <Avatar
                src={params.row.absentee.student.picURL}
                alt={params.row.absentee.student.firstName}
                className={classes.avatarBackground}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2">
                {params.row.absentee.student.firstName +
                  " " +
                  params.row.absentee.student.lastName}
              </Typography>
              <Typography
                variant="caption"
                style={{ textTransform: "capitalize" }}
              >
                {`Grade ${params.row.absentee.grade}  ${
                  params.row.absentee.student.groupSection != null
                    ? ", Sec " + params.row.absentee.student.groupSection
                    : " "
                } `}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 130,
      renderCell: (params) => {
        let a = moment();
        let b = moment(params.row.absentee.date).format("DD/MM/YYYY");
        if (a.subtract(0, "days").format("DD/MM/YYYY") == b) {
          return <Chip size="small" label="Today" color="secondary" />;
        } else if (a.subtract(1, "days").format("DD/MM/YYYY") == b) {
          return <Chip size="small" label="Yesterday" color="primary" />;
        } else {
          return (
            <Typography variant="body2">
              {moment(params.row.absentee.date).format("DD/MM/YYYY")}
            </Typography>
          );
        }
      },
    },
    {
      field: "grade",
      headerName: "Grade",
      width: 70,
      renderCell: (params) => {
        return (
          <Typography variant="body2">{params.row.absentee.grade}</Typography>
        );
      },
    },
    {
      field: "domain",
      headerName: "Domain",
      width: 130,
      renderCell: (params) => {
        return (
          <Typography variant="body2">
            {params.row.absentee.domainArea.displayName}
          </Typography>
        );
      },
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 320,
      renderCell: (params) => {
        return (
          <div
            style={{
              overflow: "scroll",
              whiteSpace: "pre-wrap",
              height: "80%",
            }}
          >
            <Typography variant="body2">
              {params.row.absentee.reason}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "teacher",
      headerName: "Teacher",
      width: 150,
      renderCell: (params) => {
        return (
          <Typography variant="capt">
            {params.row.absentee.teacher.firstName +
              " " +
              params.row.absentee.teacher.lastName}
          </Typography>
        );
      },
    },

    // ...(currentUser.role == "schoolAdmin"
    //   ? [
    //       {
    //         field: "",
    //         headerName: "",
    //         disableClickEventBubbling: true,
    //         renderCell: (params) => {
    //           const onClick = () => {
    //             setUser(params.row);
    //             handleOpenDialog();
    //             //return alert(JSON.stringify(thisRow, null, 4));
    //           };

    //           return (
    //             <div>
    //               <Tooltip title="Edit">
    //                 <IconButton aria-label="edit" onClick={onClick}>
    //                   <EditIcon />
    //                 </IconButton>
    //               </Tooltip>
    //               <Tooltip title="Delete">
    //                 <IconButton aria-label="delete" onClick={onClick}>
    //                   <DeleteIcon />
    //                 </IconButton>
    //               </Tooltip>
    //             </div>
    //           );
    //         },
    //       },
    //     ]
    //   : []),
  ];

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleClick = (index, event) => {
  //   console.log(index);
  //   setAnchorEl({ [index]: event.currentTarget });
  // };

  return (
    <div className={classes.dataGridRoot}>
      <div className={classes.dataGrid}>
        <DataGrid
          className={classes.whiteBackground}
          rows={absentees}
          columns={columns}
          pageSize={10}
          pagination
          //rowCount={props.rowCount}
          //paginationMode="server"
          //onPageChange={handleDataGridPageChange}
          autoHeight={true}
          loading={isDataLoading}
        />
      </div>
    </div>
  );
}

export default AbsenteesDataGrid;
