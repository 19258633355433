import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { TimeTableService } from "../../../services/timeTable";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import AddTimeTableDialog from "./addTimeTableDialog";
import AlertDialog from "../../helperComponents/alertDialog";
import Toast from "../../helperComponents/toast";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import EditTimeTable from "./editTimeTable";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dataGridRoot: {
    height: "100vh",
    width: "100%",
  },
  dataGrid: {
    backgroundColor: "white",
  },
  selectStatus: {
    fontSize: "0.875rem",
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//define component

function ManageTimeTable(props) {
  const classes = useStyles();
  const { currentUser } = useAppContext();

  //active timeTable
  const [activeTimeTable, setActiveTimeTable] = React.useState({
    _id: "",
    title: "",
    startDate: moment().format("DD/MM/yyyy"),
    endDate: moment().format("DD/MM/yyyy"),
    weeks: [],
    status: "",
    organisation: "",
    createdBy: "",
  });

  //loaded timeTables
  const [timeTables, setTimeTables] = React.useState([]);

  //current row selected
  const [selectedRowIndex, setSelectedRowIndex] = React.useState("");

  //operation type Save or Edit
  const [opType, setOpType] = React.useState("");

  //data is loading
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  //publish timeTable
  const [publishTimeTable, setPublishTimeTable] = React.useState(false);
  const [publishStatus, setPublishStatus] = React.useState("");

  //timeTable dialog
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  //edit timetable dialog
  const [openEditTimeTableDialog, setOpenEditTimeTableDialog] =
    React.useState(false);

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 500,
  });

  //alert dialog
  const [alertDialogOptions, setAlertDialogOptions] = React.useState({
    open: false,
    title: "",
    message:
      "Delete this timetable? All learning experiences will also be deleted!",
    cancelMessage: "Cancel",
    confirmMessage: "OK",
  });

  //rows for datagrid

  let rows = [];

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "_id", headerName: "_id", width: 120, hide: "true" },
    { field: "title", headerName: "Title", width: 130 },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 150,
      renderCell: (params) => {
        return moment(params.row.startDate).format("DD/MM/YYYY");
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 150,
      renderCell: (params) => {
        return moment(params.row.endDate).format("DD/MM/YYYY");
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const changeStatus = (event) => {
          setActiveTimeTable(params.row);
          setSelectedRowIndex(params.row.id);
          setPublishTimeTable(true);
          setPublishStatus(event.target.value);
        };
        return (
          <FormControl className={classes.formControl}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Select
              className={classes.selectStatus}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={params.row.status}
              onChange={changeStatus}
              label="Status"
            >
              <MenuItem value="published">Published</MenuItem>
              <MenuItem value="unpublished">Unpublished</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 120,
      renderCell: (params) => {
        return moment(params.row.dateCreated).format("DD/MM/YYYY");
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
      renderCell: (params) => {
        return (
          params.row.createdBy.firstName + " " + params.row.createdBy.lastName
        );
      },
    },

    {
      field: "",
      headerName: "",
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = (op) => {
          setActiveTimeTable(params.row);
          setSelectedRowIndex(params.row.id);
          op === "edit"
            ? handleOpenEditTimeTable()
            : handleConfirmDeleteTimeTable();
          //return alert(JSON.stringify(thisRow, null, 4));
        };

        return (
          <div>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => {
                  return onClick("edit");
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  return onClick("delete");
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  //pre-load verify token
  useEffect(() => {
    if (props.startFetchData) {
      getTimeTablesByOrgId();
    }

    async function getTimeTablesByOrgId() {
      try {
        setTimeTables(
          await TimeTableService.getTimeTablesByOrgId(
            currentUser.organisation.id
          )
        );
        setIsDataLoading(false);
      } catch (err) {
        setErrorLoadingPage(true);
      }
    }
  }, [props.startFetchData]);

  function loadRows(timeTables) {
    let temp = [];
    timeTables.forEach((timeTable, index) => {
      temp.push({
        id: index,
        _id: timeTable._id,
        dateCreated: timeTable.dateCreated,
        organisation: timeTable.organisation,
        title: timeTable.title,
        startDate: timeTable.startDate,
        endDate: timeTable.endDate,
        noWeeks: timeTable.noWeeks,
        noSlotsPerDay: timeTable.noSlotsPerDay,
        slotNames: timeTable.slotNames,
        timePeriods: timeTable.timePeriods,
        cod: timeTable.cod,
        tods: timeTable.tods,
        createdBy: timeTable.createdBy,
        status: timeTable.status,
      });
    });
    return temp;
  }

  function handleCreateTimeTable() {
    setActiveTimeTable({
      _id: "",
      title: "",
      body: "",
      status: "",
      organisation: "",
      createdBy: "",
    });
    setOpType("add");
    setIsDialogOpen(true);
  }

  function handleCloseDialog() {
    setIsDialogOpen(false);
  }

  function handleOpenAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: true });
  }

  function handleCloseAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
  }

  function handleConfirmDeleteTimeTable() {
    setOpType("delete");
    handleOpenAlertDialog();
  }

  //delete timetable

  async function handleProceedDeleteTimeTable() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
    let temp = [...timeTables];
    temp.splice(selectedRowIndex, 1);
    try {
      await TimeTableService.deleteTimeTableByOrgIdAndTimeTableId(
        currentUser.organisation.id,
        activeTimeTable._id
      );
      setTimeTables(temp);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "TimeTable Deleted",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error deleting timeTable",
      });
    }
  }

  //publish timetable, using useeffect because of delay
  //useEffect to load timetable
  useEffect(() => {
    if (publishTimeTable) {
      handlePublishTimeTable();
      setPublishTimeTable(false);
    }
  }, [publishTimeTable]);

  async function handlePublishTimeTable() {
    try {
      //publish
      if (publishStatus === "published") {
        await TimeTableService.updateTimeTableByOrgIdAndTimeTableIdPublish(
          currentUser.organisation.id,
          activeTimeTable._id
        );
        //temp for updating datagrid
        let temp = [...timeTables];
        temp.forEach((timeTable, i) => {
          i === selectedRowIndex
            ? (timeTable.status = "published")
            : (timeTable.status = "unpublished");
        });
        setTimeTables(temp);
      }
      //unpublish
      else {
        await TimeTableService.updateTimeTableByOrgIdAndTimeTableIdUnpublish(
          currentUser.organisation.id,
          activeTimeTable._id
        );
        //temp for updating datagrid
        let temp = [...timeTables];
        temp.forEach((timeTable, i) => {
          i === selectedRowIndex
            ? (timeTable.status = "unpublished")
            : (timeTable.status = timeTable.status);
        });
        setTimeTables(temp);
      }
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Timetable updated",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error updating timetable",
      });
    }
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  //handle edit timetable dialog
  const handleOpenEditTimeTable = () => {
    setOpenEditTimeTableDialog(true);
  };

  const handleCloseEditTimeTableDialog = () => {
    //edit announcement in announcements to update table
    let temp = [...timeTables];

    temp[selectedRowIndex] = activeTimeTable;
    setTimeTables(temp);
    setOpenEditTimeTableDialog(false);
  };

  //data grid
  function TimeTableDataGrid(props) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.dataGridRoot}>
          <DataGrid
            className={classes.dataGrid}
            rows={rows}
            columns={columns}
            pageSize={5}
            autoHeight={true}
            loading={isDataLoading}
            {...props}
          />
        </Grid>
      </Grid>
    );
  }

  //assessment
  function timeTableComponent() {
    return (
      <Fragment>
        <Dialog
          fullScreen
          // fullWidth={fullWidth}
          // maxWidth={maxWidth}
          open={openEditTimeTableDialog}
          onClose={handleCloseEditTimeTableDialog}
          TransitionComponent={Transition}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">Edit Timetable</DialogTitle>
          <DialogContent>
            <EditTimeTable
              activeTimeTable={activeTimeTable}
              setActiveTimeTable={setActiveTimeTable}
              timeTables={timeTables}
              setTimeTables={timeTables}
              toastOptions={toastOptions}
              setToastOptions={setToastOptions}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseEditTimeTableDialog}
              variant="outlined"
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Toast
          toastOptions={toastOptions}
          handleCloseToast={handleCloseToast}
        />
        <AlertDialog
          alertDialogOptions={alertDialogOptions}
          handleClose={handleCloseAlertDialog}
          handleConfirm={handleProceedDeleteTimeTable}
        />
        <div className={classes.root}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
              <Typography variant="h4" className={classes.textCenter}>
                TimeTables
              </Typography>
            </Grid> */}
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCreateTimeTable}
              >
                Add TimeTable
              </Button>
            </Grid>
            <Grid item xs={12}>
              <AddTimeTableDialog
                isDialogOpen={isDialogOpen}
                activeTimeTable={activeTimeTable}
                setActiveTimeTable={setActiveTimeTable}
                timeTables={timeTables}
                setTimeTables={setTimeTables}
                selectedRowIndex={selectedRowIndex}
                setSelectedRowIndex={setSelectedRowIndex}
                handleCloseDialog={handleCloseDialog}
                toastOptions={toastOptions}
                setToastOptions={setToastOptions}
              />
            </Grid>
            {TimeTableDataGrid()}
          </Grid>
        </div>
      </Fragment>
    );
  }

  if (!errorLoadingPage) {
    rows = loadRows(timeTables);
    return timeTableComponent();
  } else {
    return <ErrorLoadingPage />;
  }

  // if (announcementsLoaded) {
  //   rows = loadRows(timeTables);
  //   return announcementComponent({ loading: isDataLoading });
  // } else if (isDataLoading & !errorLoadingPage) {
  //   return announcementComponent({ loading: isDataLoading });
  // } else {
  //   return <ErrorLoadingPage />;
  // }
}

export default ManageTimeTable;
