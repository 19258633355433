/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";
import { saveAs } from "file-saver";

import {
  handleResponse,
  handleResponseFileDownload,
} from "../helpers/handleResponse";

export const PortraitService = {
  getPortraits,
  addPortrait,
  deletePortraitById,
  updatePortraitById,
  downloadPortrait,
};

function getPortraits(options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url =
    config.api_portraits_by_orgId + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.portraits;
    });
}

function addPortrait(portrait) {
  const requestOptions = RequestHelper.getPostRequestOptionsFormData(portrait);

  var api_url = config.api_portraits_by_orgId;
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.portrait;
    });
}

function updatePortraitById(portraitId, portrait) {
  const requestOptions = RequestHelper.getPutRequestOptionsFormData(portrait);

  var api_url = config.api_portraits_by_orgId_and_portraitId;
  api_url = RequestHelper.replaceParams(api_url, {
    portraitId: portraitId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.portrait;
    });
}

function deletePortraitById(orgId, portraitId) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_portraits_by_orgId_and_portraitId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    portraitId: portraitId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data._id;
    });
}

function downloadPortrait(options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptionsFileDownload("blob");

  var api_url = config.api_portraits_download_google_cloud_storage_file;

  api_url = api_url + RequestHelper.formatQueryString(options);
  let fileName = (() => {
    var s = options.docURL.split("/");
    return s[s.length - 1];
  })();

  return fetch(api_url, requestOptions)
    .then(handleResponseFileDownload)
    .then((response) => response.blob())
    .then((blob) => {
      saveAs(blob, fileName + ".pdf");
      return URL.createObjectURL(blob);
    });
}
