import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { AnnouncementService } from "../../../services/announcement";
import { useAppContext } from "../../../libs/contextLib";
import Moment from "react-moment";
import Divider from "@material-ui/core/Divider";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import Loader from "../../helperComponents/loader";
import { Utils } from "../../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  textCenter: {
    textAlign: "center",
  },
  announcementWrapper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
  },
  announcement: {
    padding: theme.spacing(4),
    // backgroundColor: "white",
    borderRadius: "5px",
    marginBottom: theme.spacing(5),
  },
  announcementTitle: {
    marginBottom: theme.spacing(0),
  },
  announcementDate: {
    marginBottom: theme.spacing(4),
  },
  announcementBody: {
    marginBottom: theme.spacing(0),
  },
  announceDivider: {
    backgroundColor: "#dadce0",
    marginTop: theme.spacing(4),
  },
}));

function Announcements(props) {
  const classes = useStyles();
  const { currentUser } = useAppContext();
  const [showLoader, setShowLoader] = React.useState(true);
  const [announcements, setAnnouncements] = React.useState([]);
  const [announcementsLoaded, setAnnouncementsLoaded] = React.useState("false");

  //pre-load verify token
  useEffect(() => {
    if (props.startFetchData) {
      onLoad();
    }
  }, [props.startFetchData]);

  async function onLoad() {
    try {
      setAnnouncements(
        await AnnouncementService.getAnnouncementsByOrgId(
          currentUser.organisation.id
        )
      );
      setShowLoader(false);
      //return announcementComponent();
    } catch (err) {
      // console.log(err);
    }
  }

  if (announcementsLoaded) {
    return announcementComponent();
  } else {
    return <ErrorLoadingPage />;
  }

  function announcementComponent() {
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.textCenter}>
              Announcements
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Loader visible={showLoader ? "true" : "false"} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display:
                announcementsLoaded && announcements.length === 0
                  ? "block"
                  : "none",
            }}
          >
            <Typography variant="body1" className={classes.textCenter}>
              There are no announcements.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display:
                announcementsLoaded && announcements.length > 0
                  ? "block"
                  : "none",
            }}
          >
            <div className={classes.announcementWrapper}>
              {announcements.map((announcement, annIndex) => (
                <div className={classes.announcement} key={annIndex}>
                  <div className={classes.announcementTitle}>
                    <Typography variant="h5">{announcement.title}</Typography>
                  </div>
                  <div className={classes.announcementDate}>
                    <Typography variant="caption">
                      <Moment format="MMMM DD YYYY">
                        {announcement.dateCreated}
                      </Moment>
                    </Typography>
                    <Typography variant="caption">
                      {" By " +
                        announcement.createdBy.firstName +
                        " " +
                        announcement.createdBy.lastName}
                    </Typography>
                  </div>
                  <div className={classes.announcementBody}>
                    <div>
                      <Typography variant="body2">
                        {announcement.body
                          .split("\n")
                          .map(function (item, bodyIndex) {
                            return (
                              <span key={annIndex + bodyIndex}>
                                {Utils.urlify(item)}
                                <br />
                              </span>
                            );
                          })}
                        {/* {announcement.body} */}
                      </Typography>
                    </div>
                  </div>
                  <Divider className={classes.announceDivider} />
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Announcements;
