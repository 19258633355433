import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { AttendanceService } from "../../../services/attendance";
import Grid from "@material-ui/core/Grid";
import { Factory } from "../../../helpers/factories";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import AbsenteesDataGrid from "./absenteesDataGrid";
import AbsenteesFilter from "./absenteesFilter";
import Toast from "../../helperComponents/toast";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "none",
  },
  dataGridRoot: {
    width: "100%",
  },
  dataGrid: {
    backgroundColor: "white",
  },
  button: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatarBackground: {
    //color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: theme.palette.primary.main, //deepOrange[500],
  },
}));

export default function Absentees(props) {
  const classes = useStyles();
  const { currentUser } = useAppContext();

  //current selected user
  const [user, setUser] = React.useState(Factory.createUser());

  //list of absentees
  const [absentees, setAbsentees] = React.useState([]);

  const [queryString, setQueryString] = React.useState({});

  const [isLoading, setIsLoading] = React.useState(true);

  const [selectedRowIndex, setSelectedRowIndex] = React.useState("");
  const [opType, setOpType] = React.useState("");
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 6000,
  });

  //pre-load verify token
  useEffect(() => {
    if (props.startFetchData) {
      onLoad();
    }
  }, [props.startFetchData, queryString]);

  async function onLoad() {
    try {
      const absentees = await AttendanceService.getAttendanceByOrgId(
        currentUser.organisation.id,
        queryString
      );
      setAbsentees(loadRows(absentees));
      setIsLoading(false);
    } catch (err) {
      setErrorLoadingPage(true);
    }
  }

  function loadRows(absentees) {
    let temp = [];
    absentees.forEach((absentee, index, anns) => {
      temp.push({
        id: index,
        absentee: absentee,
      });
    });
    return temp;
  }

  function handleCloseDialog() {
    setIsDialogOpen(false);
  }

  function handleOpenDialog() {
    setIsDialogOpen(true);
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  if (errorLoadingPage) {
    return (
      <ErrorLoadingPage message="There was a problem loading the page. Try refreshing the page." />
    );
  } else {
    return (
      <div className={classes.root}>
        <Toast
          toastOptions={toastOptions}
          handleCloseToast={handleCloseToast}
        />
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <Typography variant="h4" className={classes.textCenter}>
              Students
            </Typography>
          </Grid> */}
          <Grid itex xs={12}>
            <AbsenteesFilter
              user={user}
              setUser={setUser}
              opType={opType}
              setOpType={setOpType}
              setIsDialogOpen={setIsDialogOpen}
              queryString={queryString}
              setQueryString={setQueryString}
            />
          </Grid>
          <Grid item xs={12}>
            <AbsenteesDataGrid
              setUser={setUser}
              user={user}
              absentees={absentees}
              setOpType={setOpType}
              setSelectedRowIndex={selectedRowIndex}
              setIsDialogOpen={setIsDialogOpen}
              isDataLoading={isLoading}
              handleOpenDialog={handleOpenDialog}
            />
          </Grid>
          {/* {AnnouncementDataGrid({ loading: isDataLoading })} */}
        </Grid>
      </div>
    );
  }
}
