/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const LearningExperienceService = {
  addLearningExperience,
  addLessonPlanToLearningExperience,
  getLearningExperiencesByOrgIdAndTimeTableId,
  updateLearningExperienceByOrgIdAndTimeTableIdAndLearningExperienceId,
  deleteLearningExperienceByLearningExperienceId,
  removeLessonPlanFromLearningExperience,
};

function getLearningExperiencesByOrgIdAndTimeTableId(orgId, timeTableId) {
  const requestOptions = RequestHelper.getGetRequestOptions();
  var api_url = config.api_learning_experiences_by_orgId_and_timeTableId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    timeTableId: timeTableId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.learningExperiences;
    });
}

function addLearningExperience(orgId, timeTableId, data) {
  const requestOptions = RequestHelper.getPostRequestOptions(data);

  var api_url = config.api_learning_experiences_by_orgId_and_timeTableId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    timeTableId: timeTableId,
  });

  // + RequestHelper.formatQueryString(options);
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.learningExperience;
    });
}

function updateLearningExperienceByOrgIdAndTimeTableIdAndLearningExperienceId(
  orgId,
  timeTableId,
  learningExperienceId,
  data,
  options = {}
) {
  const requestOptions = RequestHelper.getPutRequestOptions(data);

  var api_url =
    config.api_learning_experiences_by_orgId_and_timeTableId_and_learningExperienceId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    timeTableId: timeTableId,
    learningExperienceId: learningExperienceId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.learningExperience;
    });
}

function deleteLearningExperienceByLearningExperienceId(
  orgId,
  timeTableId,
  learningExperienceId
) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url =
    config.api_learning_experiences_by_orgId_and_timeTableId_and_learningExperienceId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    timeTableId: timeTableId,
    learningExperienceId: learningExperienceId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function addLessonPlanToLearningExperience(
  orgId,
  timeTableId,
  learningExperienceId,
  lessonId
) {
  const requestOptions = RequestHelper.getPostRequestOptions({
    lesson: lessonId,
  });

  var api_url =
    config.api_learning_experiences_by_orgId_and_timeTableId_and_learningExperienceId_lessonPlan;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    timeTableId: timeTableId,
    learningExperienceId: learningExperienceId,
  });

  // + RequestHelper.formatQueryString(options);
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.learningExperience;
    });
}

function removeLessonPlanFromLearningExperience(
  orgId,
  timeTableId,
  learningExperienceId
) {
  const requestOptions = RequestHelper.getDeleteRequestOptions({});

  var api_url =
    config.api_learning_experiences_by_orgId_and_timeTableId_and_learningExperienceId_lessonPlan;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    timeTableId: timeTableId,
    learningExperienceId: learningExperienceId,
  });

  // + RequestHelper.formatQueryString(options);
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.learningExperience;
    });
}
