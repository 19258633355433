/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const AppraisalService = {
  getAppraisalsByOrgId,
  getCollatedAppraisalsByOrgId,
  addAppraisalsByOrgId,
  updateAppraisalsByOrgIdAndAppraisalId,
  deleteAppraisalsByOrgIdAndAppraisalId,
};

function getAppraisalsByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_appraisals_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.appraisals;
    });
}

function getCollatedAppraisalsByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_appraisals_collated_by_org_id;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.appraisals;
    });
}

function addAppraisalsByOrgId(orgId, appraisal) {
  const requestOptions = RequestHelper.getPostRequestOptions(appraisal);

  var api_url = config.api_appraisals_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.appraisal;
    });
}

function updateAppraisalsByOrgIdAndAppraisalId(orgId, appraisalId, appraisal) {
  const requestOptions = RequestHelper.getPutRequestOptions(appraisal);

  var api_url = config.api_appraisals_by_orgId_and_appraisalId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    appraisalId: appraisalId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.appraisal;
    });
}

function deleteAppraisalsByOrgIdAndAppraisalId(orgId, appraisalId) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_appraisals_by_orgId_and_appraisalId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    appraisalId: appraisalId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
