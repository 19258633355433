/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const TimeTableService = {
  getTimeTablesByOrgId,
  addTimeTableByOrgId,
  deleteTimeTableByOrgIdAndTimeTableId,
  updateTimeTableByOrgIdAndTimeTableId,
  updateTimeTableByOrgIdAndTimeTableIdPublish,
  updateTimeTableByOrgIdAndTimeTableIdUnpublish,
  updateTimeTableByOrgIdAndTimeTableIdInsertLesson,
  uploadTimeTableFile,
};

function getTimeTablesByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_timeTables_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.timeTables;
    });
}

function addTimeTableByOrgId(orgId, timeTable) {
  const requestOptions = RequestHelper.getPostRequestOptions(timeTable);

  var api_url = config.api_timeTables_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.timeTable;
    });
}

function updateTimeTableByOrgIdAndTimeTableId(orgId, timeTableId, timeTable) {
  const requestOptions = RequestHelper.getPutRequestOptions(timeTable);

  var api_url = config.api_timeTables_by_orgId_and_timeTableId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    timeTableId: timeTableId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.timeTable;
    });
}

function updateTimeTableByOrgIdAndTimeTableIdPublish(orgId, timeTableId) {
  const requestOptions = RequestHelper.getPutRequestOptions();

  var api_url = config.api_timeTables_by_orgId_and_timeTableId_publish;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    timeTableId: timeTableId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.timeTable;
    });
}

function updateTimeTableByOrgIdAndTimeTableIdUnpublish(orgId, timeTableId) {
  const requestOptions = RequestHelper.getPutRequestOptions();

  var api_url = config.api_timeTables_by_orgId_and_timeTableId_unpublish;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    timeTableId: timeTableId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.timeTable;
    });
}

function deleteTimeTableByOrgIdAndTimeTableId(orgId, timeTableId) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_timeTables_by_orgId_and_timeTableId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    timeTableId: timeTableId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.timeTables;
    });
}

function updateTimeTableByOrgIdAndTimeTableIdInsertLesson(
  orgId,
  timeTableId,
  lesson,
  options = {}
) {
  const requestOptions = RequestHelper.getPutRequestOptions(lesson);
  var api_url = config.api_timeTables_by_orgId_and_timeTableId_insertLesson;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
      timeTableId: timeTableId,
    }) + RequestHelper.formatQueryString(options);
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.lesson;
    });
}

function uploadTimeTableFile(orgId, timeTableId, timeTableFile, options = {}) {
  const requestOptions =
    RequestHelper.getPostRequestOptionsFileUpload(timeTableFile);

  // console.log("timetable service", timeTableFile);

  var api_url = config.api_timeTables_by_orgId_and_timeTableId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
      timeTableId: timeTableId,
    }) + RequestHelper.formatQueryString(options);
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
