/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const FiveAreasRoadmapService = {
  addFiveAreasRoadmapByOrgId,
  getFiveAreasRoadmapByOrgId,
  updateFiveAreasRoadmapById,
  deleteFiveAreasRoadmapById,
};

function addFiveAreasRoadmapByOrgId(orgId, fiveAreasRoadmap) {
  const requestOptions = RequestHelper.getPostRequestOptions(fiveAreasRoadmap);

  var api_url = config.api_fiveAreasRoadmap_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.fiveAreasRoadmap;
    });
}

function getFiveAreasRoadmapByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_fiveAreasRoadmap_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return {
        fiveAreasRoadmaps: data.fiveAreasRoadmaps,
        page: data.page,
        hasMore: data.hasMore,
      };
    });
}

function updateFiveAreasRoadmapById(
  orgId,
  fiveAreasRoadmapId,
  fiveAreasRoadmap
) {
  const requestOptions = RequestHelper.getPutRequestOptions(fiveAreasRoadmap);

  var api_url = config.api_fiveAreasRoadmap_by_orgId_and_fiveAreasRoadmapId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    fiveAreasRoadmapId: fiveAreasRoadmapId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.fiveAreasRoadmap;
    });
}

// delete school roadmap ; all child nodes will also be deleted

function deleteFiveAreasRoadmapById(orgId, fiveAreasRoadmapId) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_fiveAreasRoadmap_by_orgId_and_fiveAreasRoadmapId;

  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    fiveAreasRoadmapId: fiveAreasRoadmapId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data._id;
    });
}
