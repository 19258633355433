import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { OrganisationService } from "../../../services/organisation";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import AlertDialog from "../../helperComponents/alertDialog";
import Toast from "../../helperComponents/toast";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dataGridRoot: {
    height: "100vh",
    width: "100%",
  },
  dataGrid: {
    backgroundColor: "white",
  },
}));

function Organisations(props) {
  const classes = useStyles();
  const { currentUser } = useAppContext();

  //active organisation
  const [organisation, setOrganisation] = React.useState({
    _id: "",
    title: "",
    body: "",
    status: "",
    organisation: "",
    createdBy: "",
  });

  //loaded organisations
  const [organisations, setOrganisations] = React.useState([]);

  //current row selected
  const [selectedRowIndex, setSelectedRowIndex] = React.useState("");

  //operation type Save or Edit
  const [opType, setOpType] = React.useState("");

  //data is loading
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  //organisation dialog
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 6000,
  });

  //alert dialog
  const [alertDialogOptions, setAlertDialogOptions] = React.useState({
    open: false,
    title: "",
    message: "Delete this organisation?",
    cancelMessage: "Cancel",
    confirmMessage: "OK",
  });

  //rows for datagrid

  let rows = [];

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "_id", headerName: "_id", width: 120, hide: "true" },
    { field: "name", headerName: "Name", width: 200 },
    { field: "prefix", headerName: "Prefix", width: 120 },
    { field: "yearJoined", headerName: "Year Joined", width: 130 },
    { field: "type", headerName: "Type", width: 120 },
    { field: "accountStatus", headerName: "Status", width: 120 },
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 200,
      renderCell: (params) => {
        return moment(params.row.dateCreated).format("DD-MM-yyyy");
      },
    },
    {
      field: "",
      headerName: "",
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = (op) => {
          setOrganisation(params.row);
          setSelectedRowIndex(params.row.id);
          op === "edit"
            ? handleOpenDialog()
            : handleConfirmDeleteOrganisation();
          //return alert(JSON.stringify(thisRow, null, 4));
        };

        return (
          <div>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => {
                  return onClick("edit");
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  return onClick("delete");
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  //pre-load verify token
  useEffect(() => {
    if (props.startFetchData) {
      onLoad();
    }
  }, [props.startFetchData]);

  async function onLoad() {
    try {
      const organisations = await OrganisationService.getOrganisations();
      setOrganisations(organisations);
      setIsDataLoading(false);
    } catch (err) {
      setErrorLoadingPage(true);
    }
  }

  function loadRows(orgs) {
    let temp = [];
    orgs.forEach((organisation, index, orgs) => {
      organisation["id"] = index;
      temp.push(organisation);
    });
    return temp;
  }

  function handleCreateOrganisation() {
    setOrganisation({
      _id: "",
      title: "",
      body: "",
      status: "",
      organisation: "",
      createdBy: "",
    });
    setOpType("add");
    setIsDialogOpen(true);
  }

  function handleCloseDialog() {
    setIsDialogOpen(false);
  }

  const handleOpenDialog = () => {
    setOpType("edit");
    setIsDialogOpen(true);
  };

  function handleOpenAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: true });
  }

  function handleCloseAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
  }

  function handleConfirmDeleteOrganisation() {
    handleOpenAlertDialog();
  }

  async function handleProceedDeleteOrganisation() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
    let temp = [...organisations];
    temp.splice(selectedRowIndex, 1);
    try {
      await OrganisationService.deleteOrganisationsByOrgIdAndOrganisationId(
        currentUser.organisation.id,
        organisation._id
      );
      setOrganisations(temp);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Organisation Deleted",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error deleting organisation",
      });
    }
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  //data grid
  function OrganisationDataGrid(props) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.dataGridRoot}>
          <DataGrid
            className={classes.dataGrid}
            rows={rows}
            columns={columns}
            pageSize={10}
            autoHeight={true}
            loading={isDataLoading}
            {...props}
          />
        </Grid>
      </Grid>
    );
  }

  //assessment
  function OrganisationComponent(props) {
    return (
      <Fragment>
        <Toast
          toastOptions={toastOptions}
          handleCloseToast={handleCloseToast}
        />
        <AlertDialog
          alertDialogOptions={alertDialogOptions}
          handleClose={handleCloseAlertDialog}
          handleConfirm={handleProceedDeleteOrganisation}
        />
        <div className={classes.root}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
              <Typography variant="h4" className={classes.textCenter}>
                Organisations
              </Typography>
            </Grid> */}
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCreateOrganisation}
              >
                Add Organisation
              </Button>
            </Grid>
            <Grid item xs={12}>
              {/* <OrganisationDialog
                isDialogOpen={isDialogOpen}
                organisation={organisation}
                setOrganisation={setOrganisation}
                organisations={organisations}
                setOrganisations={setOrganisations}
                selectedRowIndex={selectedRowIndex}
                setSelectedRowIndex={setSelectedRowIndex}
                handleCloseDialog={handleCloseDialog}
                opType={opType}
                setOpType={setOpType}
                toastOptions={toastOptions}
                setToastOptions={setToastOptions}
              /> */}
            </Grid>
            {OrganisationDataGrid()}
          </Grid>
        </div>
      </Fragment>
    );
  }

  if (!errorLoadingPage) {
    rows = loadRows(organisations);
    return OrganisationComponent();
  } else {
    return <ErrorLoadingPage />;
  }

  // if (organisationsLoaded) {
  //   rows = loadRows(organisations);
  //   return organisationComponent({ loading: isDataLoading });
  // } else if (isDataLoading & !errorLoadingPage) {
  //   return organisationComponent({ loading: isDataLoading });
  // } else {
  //   return <ErrorLoadingPage />;
  // }
}

export default Organisations;
