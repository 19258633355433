import React, { Fragment } from "react";

import moment from "moment";

import { useAppContext } from "../../../libs/contextLib";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import SearchUserAdvanced from "../../helperComponents/searchUserAdvanced";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";

// import SearchUser from "../../helperComponents/searchUser";
import { FitnessTestService } from "../../../services/fitnessTest";

import { Factory } from "../../../helpers/factories";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { Utils } from "../../../helpers/utils";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";

import Toast from "../../helperComponents/toast";
import Slide from "@material-ui/core/Slide";

import { makeStyles } from "@material-ui/core/styles";

//placeholder image

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  formComment: {
    marginBottom: "16px",
  },
  paper: {
    padding: "20px",
    marginBottom: "40px",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FitnessTestDialog({
  activeFitnessTest,
  setActiveFitnessTest,
  fitnessTests,
  setFitnessTests,
  isDialogOpen,
  handleCloseDialog,
  selectedRowIndex,
  setSelectedRowIndex,
  opType,
  setOpType,
  ...props
}) {
  const classes = useStyles();

  const { currentUser } = useAppContext();

  //fitnessTest state - there is an activeFitnessTest state but this one is to deal with rendering issues
  const [fitnessTest, setFitnessTest] = React.useState(
    Factory.createFitnessTest()
  );

  //console.log("fitnessTest", fitnessTest);

  // For BMI calculation
  const [height, setHeight] = React.useState("");
  const [weight, setWeight] = React.useState("");
  const [bmi, setBmi] = React.useState(null);

  React.useEffect(() => {
    if (height && weight) {
      const heightInMeters = height / 100;
      const calculatedBmi = (
        weight /
        (heightInMeters * heightInMeters)
      ).toFixed(2);
      let bmiRemarks = "";

      if (calculatedBmi < 18.5) {
        bmiRemarks = "Underweight";
      } else if (calculatedBmi >= 18.5 && calculatedBmi < 24.9) {
        bmiRemarks = "Normal";
      } else if (calculatedBmi >= 24.9 && calculatedBmi < 29.9) {
        bmiRemarks = "Overweight";
      } else {
        bmiRemarks = "Obesity";
      }

      setBmi(calculatedBmi);
      setFitnessTest((prev) => ({
        ...prev,
        bmi: calculatedBmi,
        bmiRemarks: bmiRemarks,
      }));
    } else {
      setBmi(null);
      setFitnessTest((prev) => ({
        ...prev,
        bmi: "",
        bmiRemarks: "",
      }));
    }
  }, [height, weight]);

  const [user, setUser] = React.useState(null);

  const [userQueryOptions, setUserQueryOptions] = React.useState({});

  //fitnessTest states
  const [yearRange, setYearRange] = React.useState(
    Utils.getYearRange(
      parseInt(moment().subtract(10, "years").format("YYYY")),
      parseInt(moment().format("YYYY"))
    )
  );

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 2000,
  });

  /*
   * Helpers
   */

  React.useEffect(() => {
    setFitnessTest(activeFitnessTest);
    if (opType === "edit" || opType === "view") {
      // setFitnessTest(activeFitnessTest);
      setUser(activeFitnessTest.student);
      setHeight(activeFitnessTest.height); // Initialize height
      setWeight(activeFitnessTest.weight); // Initialize weight
    } else {
      setUser(null);
      setFitnessTest(Factory.createFitnessTest);
      let tempEvents = [
        { name: "40m Sprint", result: 0, unit: "second" },
        { name: "Beep Test", result: 0, unit: "level" },
        { name: "10 x 5m Agility Test", result: 0, unit: "second" },
        { name: "Curl Up", result: 0, unit: "reps" },
        { name: "Ruler Drop Test", result: 0, unit: "cm" },
        { name: "Broad Jump", result: 0, unit: "metres" },
        { name: "Backsaver Sit and Reach", result: 0, unit: "cm" },
        { name: "Standing Stork Test", result: 0, unit: "reps" },
        { name: "Push-Ups", result: 0, unit: "reps" },
        { name: "Alternate-Hand Wall-Toss Test", result: 0, unit: "reps" },
      ];
      setFitnessTest({ ...fitnessTest, events: tempEvents, comments: "None" });
    }
  }, [activeFitnessTest]);

  //form is valid for saving

  const isFormValid = () => {
    function checkEventsEmpty() {
      let hasEmptyField = false;
      try {
        fitnessTest.events.forEach((event) => {
          if (event.name === "" || event.result === "" || event.unit === "") {
            throw new Error("Event field empty");
          }
        });
      } catch (e) {
        hasEmptyField = true;
      }

      if (hasEmptyField) return true;
      return false;
    }

    return (
      user &&
      user._id &&
      fitnessTest &&
      fitnessTest.learningCycle &&
      fitnessTest.year &&
      fitnessTest.gender &&
      fitnessTest.comments &&
      fitnessTest.events.length != 0 &&
      !checkEventsEmpty()
    );
  };

  /*
   * Handlers
   */

  /*
   * Add/Update Fitness Test
   */

  const handleSaveFitnessTest = async (addNew = false) => {
    //prepare to save
    let temp = { ...fitnessTest, height, weight };

    //check whether it's a update or a new addition
    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      //check save or edit
      if (opType === "add") {
        temp["student"] = user._id;
        temp["createdBy"] = currentUser.id;
        temp["organisation"] = currentUser.organisation.id;

        const savedFitnessTest =
          await FitnessTestService.addFitnessTestsByOrgId(
            currentUser.organisation.id,
            temp
          );

        //add fitnessTest to fitnessTests to update table

        setActiveFitnessTest(savedFitnessTest);
        let updatedFitnessTestList = [...fitnessTests];
        updatedFitnessTestList.unshift(savedFitnessTest);
        setFitnessTests(updatedFitnessTestList);
        setSelectedRowIndex(0);
      } else {
        //edit fitnessTest in fitnessTests to update table
        temp["student"] = user._id;
        temp["createdBy"] = currentUser.id;
        temp["organisation"] = currentUser.organisation.id;

        const updatedFitnessTest =
          await FitnessTestService.updateFitnessTestsByOrgIdAndFitnessTestId(
            currentUser.organisation.id,
            activeFitnessTest._id,
            temp
          );
        let updatedFitnessTestList = [...fitnessTests];
        updatedFitnessTest["id"] = selectedRowIndex;
        updatedFitnessTestList[selectedRowIndex] = updatedFitnessTest;
        setFitnessTests(updatedFitnessTestList);
      }

      if (addNew) {
        setOpType("add");
        setUser(null);
      } else {
        handleCloseDialog();
      }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Fitness Test Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  };

  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  const handleAddEvent = (e) => {
    let temp = fitnessTest.events;
    temp.push({ name: "", result: "", unit: "" });
    setFitnessTest({ ...fitnessTest, events: temp });
  };

  const handleEventDetailsChange = (e, index) => {
    let temp = fitnessTest.events;
    temp[index][e.target.name] = e.target.value;
    setFitnessTest({ ...fitnessTest, events: temp });
  };

  //return component

  return (
    <Dialog
      fullScreen
      open={isDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      {/* toast for messages */}
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />

      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {opType === "add" ? "Add Fitness Test" : "Edit Fitness Test"}
          </Typography>
        </Toolbar>
      </AppBar>

      {/* student/fitnessTest details */}
      <div
        style={{
          height: "100vh",
          backgroundColor: "#eaeff1",
        }}
      >
        <Grid
          container
          spacing={3}
          style={{
            justifyContent: "center",
            backgroundColor: "#eaeff1",
          }}
        >
          <Grid item xs={8} sm={10}>
            <Paper className={classes.paper} style={{ marginTop: "20px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Fitness Test
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SearchUserAdvanced
                    user={user}
                    setUser={setUser}
                    userQueryOptions={userQueryOptions}
                    setUserQueryOptions={setUserQueryOptions}
                    style={{ display: "flex" }}
                    isDisabled={
                      fitnessTest.createdBy &&
                      fitnessTest.createdBy._id != currentUser.id
                    }
                  />
                  {/* <SearchUser
                    user={activeFitnessTest.user}
                    setUser={setUser}
                    //   role={"student"}
                    style={{ display: "flex" }}
                    isDisabled={
                      fitnessTest.createdBy &&
                      fitnessTest.createdBy._id != currentUser.id
                    }
                  /> */}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    style={{ width: "90%" }}
                    disabled={
                      fitnessTest.createdBy &&
                      fitnessTest.createdBy._id != currentUser.id
                    }
                  >
                    <InputLabel id="gender-select-label">Gender</InputLabel>
                    <Select
                      labelId="gender-select-label"
                      id="gender-select-fitnessTest-gender"
                      size="small"
                      value={fitnessTest.gender}
                      onChange={(e) => {
                        setFitnessTest({
                          ...fitnessTest,
                          gender: e.target.value,
                        });
                      }}
                      label="Type"
                    >
                      <MenuItem value={"male"}>Male</MenuItem>
                      <MenuItem value={"female"}>Female </MenuItem>
                      <MenuItem value={"unspecified"}>Unspecified</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ width: "90%" }}
                  >
                    <InputLabel id="type-description">Grade</InputLabel>
                    <Select
                      labelId="grade"
                      id="grade"
                      name="grade"
                      disabled={
                        fitnessTest.createdBy &&
                        fitnessTest.createdBy._id != currentUser.id
                      }
                      value={fitnessTest.grade}
                      onChange={(e) => {
                        setFitnessTest({
                          ...fitnessTest,
                          grade: e.target.value,
                        });
                      }}
                      label="Grade"
                    >
                      <MenuItem value={"0"}>PP</MenuItem>
                      <MenuItem value={"1"}>1</MenuItem>
                      <MenuItem value={"2"}>2</MenuItem>
                      <MenuItem value={"3"}>3</MenuItem>
                      <MenuItem value={"4"}>4</MenuItem>
                      <MenuItem value={"5"}>5</MenuItem>
                      <MenuItem value={"6"}>6</MenuItem>
                      <MenuItem value={"7"}>7</MenuItem>
                      <MenuItem value={"8"}>8</MenuItem>
                      <MenuItem value={"9"}>9</MenuItem>
                      <MenuItem value={"10"}>10</MenuItem>
                      <MenuItem value={"11"}>11</MenuItem>
                      <MenuItem value={"12"}>12</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    style={{ width: "90%" }}
                    disabled={
                      fitnessTest.createdBy &&
                      fitnessTest.createdBy._id != currentUser.id
                    }
                  >
                    <InputLabel id="learningCycle-select-label">
                      Learning Cycle
                    </InputLabel>
                    <Select
                      labelId="learningCycle-select-label"
                      id="learningCycle-select-type"
                      size="small"
                      value={fitnessTest.learningCycle}
                      onChange={(e) => {
                        setFitnessTest({
                          ...fitnessTest,
                          learningCycle: e.target.value,
                        });
                      }}
                      label="Learning Cycle"
                    >
                      <MenuItem value={"1"}>1</MenuItem>
                      <MenuItem value={"2"}>2 </MenuItem>
                      <MenuItem value={"3"}>3</MenuItem>
                      <MenuItem value={"4"}>4 </MenuItem>
                      <MenuItem value={"5"}>5</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    style={{ width: "90%" }}
                    disabled={
                      fitnessTest.createdBy &&
                      fitnessTest.createdBy._id != currentUser.id
                    }
                  >
                    <InputLabel id="reportYear-select-label">Year</InputLabel>
                    <Select
                      labelId="reportYear-select-label"
                      id="reportYear-select-fitnessTest"
                      size="small"
                      value={fitnessTest.year}
                      onChange={(e) => {
                        setFitnessTest({
                          ...fitnessTest,
                          year: e.target.value,
                        });
                      }}
                      label="Year"
                    >
                      {yearRange.map((year) => {
                        return (
                          <MenuItem value={year} key={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Events
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                {fitnessTest.events.length == 0 ? (
                  <Grid item xs={12}>
                    There are no events recorded.
                  </Grid>
                ) : (
                  fitnessTest.events.map((event, index) => {
                    return (
                      <Fragment key={"fragment" + index + "name"}>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          key={"event" + index + "name"}
                        >
                          <FormControl
                            className={classes.formControl}
                            variant="outlined"
                            style={{ width: "90%" }}
                            // disabled={
                            //   fitnessTest.createdBy &&
                            //   fitnessTest.createdBy._id != currentUser.id
                            // }
                          >
                            <InputLabel id="event-select-label">
                              Event
                            </InputLabel>
                            <Select
                              name="name"
                              labelId="event-select-label"
                              id="event-select-fitnessTest-gender"
                              size="small"
                              value={event.name}
                              onChange={(e) => {
                                handleEventDetailsChange(e, index);
                              }}
                              label="Event"
                            >
                              <MenuItem value={"40m Sprint"}>
                                40m Sprint
                              </MenuItem>
                              <MenuItem value={"30m Sprint"}>
                                30m Sprint
                              </MenuItem>
                              <MenuItem value={"Beep Test"}>Beep Test</MenuItem>
                              <MenuItem value={"10 x 5m Agility Test"}>
                                10 x 5m Agility Test
                              </MenuItem>
                              <MenuItem value={"Curl Up"}>Curl Up</MenuItem>
                              <MenuItem value={"Ruler Drop Test"}>
                                Ruler Drop Test
                              </MenuItem>
                              <MenuItem value={"Broad Jump"}>
                                Broad Jump
                              </MenuItem>
                              <MenuItem value={"Backsaver Sit and Reach"}>
                                Backsaver Sit and Reach{" "}
                              </MenuItem>
                              <MenuItem value={"Standing Stork Test"}>
                                Standing Stork Test
                              </MenuItem>
                              <MenuItem value={"Push-Ups"}>Push-Ups</MenuItem>
                              <MenuItem value={"Alternate-Hand Wall-Toss Test"}>
                                Alternate-Hand Wall-Toss Test
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          key={"event" + index + "result"}
                        >
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            style={{ width: "90%" }}
                          >
                            <TextField
                              id="result"
                              name="result"
                              label="Result"
                              //   type="number"
                              value={event.result}
                              //   disabled={
                              //     fitnessTest.createdBy &&
                              //     fitnessTest.createdBy._id != currentUser.id
                              //   }
                              variant="outlined"
                              placeholder="Seconds, Reps, Level"
                              onChange={(e) => {
                                handleEventDetailsChange(e, index);
                              }}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          key={"event" + index + "unit"}
                        >
                          <FormControl
                            className={classes.formControl}
                            variant="outlined"
                            style={{ width: "90%" }}
                            // disabled={
                            //   fitnessTest.createdBy &&
                            //   fitnessTest.createdBy._id != currentUser.id
                            // }
                          >
                            <InputLabel id="unit-select-label">Unit</InputLabel>
                            <Select
                              labelId="unit-select-label"
                              id="unit-select-fitnessTest-gender"
                              size="small"
                              value={event.unit}
                              name="unit"
                              onChange={(e) => {
                                handleEventDetailsChange(e, index);
                              }}
                              label="Unit"
                            >
                              <MenuItem value={"second"}>seconds</MenuItem>
                              <MenuItem value={"reps"}>reps</MenuItem>
                              <MenuItem value={"metres"}>metres</MenuItem>
                              <MenuItem value={"cm"}>cm</MenuItem>
                              <MenuItem value={"level"}>level</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {fitnessTest.createdBy._id === currentUser.id ? (
                            <Tooltip title="Delete Event">
                              <IconButton
                                aria-label="Delete Event"
                                color="primary"
                                // disabled={fieldDisabled()}
                                onClick={() => {
                                  let temp = fitnessTest.events;
                                  temp.splice(index, 1);
                                  setFitnessTest({
                                    ...fitnessTest,
                                    events: temp,
                                  });
                                }}
                              >
                                <DeleteIcon fontSize="large" />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Grid>
                      </Fragment>
                    );
                  })
                )}
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Body Measurements
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="height"
                    name="height"
                    label="Height (cm)"
                    type="number"
                    variant="outlined"
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="weight"
                    name="weight"
                    label="Weight (kg)"
                    type="number"
                    variant="outlined"
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    id="bmi"
                    name="bmi"
                    label="BMI"
                    variant="outlined"
                    value={fitnessTest.bmi || ""}
                    onChange={(e) =>
                      setFitnessTest({
                        ...fitnessTest,
                        bmi: e.target.value,
                      })
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="bmi-remarks"
                    name="bmiRemarks"
                    label="BMI Remarks"
                    variant="outlined"
                    value={fitnessTest.bmiRemarks || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {opType === "add" ||
                  fitnessTest.createdBy._id === currentUser.id ? (
                    <Tooltip title="Add Event">
                      <IconButton
                        aria-label="Add Event"
                        color="primary"
                        // disabled={fieldDisabled()}
                        onClick={() => {
                          handleAddEvent();
                        }}
                      >
                        <AddCircleOutlineIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      multiline
                      className={classes.formComment}
                      rows={3}
                      margin="dense"
                      id="comments"
                      name="comments"
                      label="Comments"
                      type="text"
                      variant="filled"
                      // value={childAppraisals.mentorReport.comment || ""}
                      value={fitnessTest.comments}
                      onChange={(e) => {
                        setFitnessTest({
                          ...fitnessTest,
                          comments: e.target.value,
                        });
                      }}
                      disabled={
                        fitnessTest.createdBy &&
                        fitnessTest.createdBy._id != currentUser.id
                      }
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
            {/* save close */}
            {
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "40px",
                  }}
                >
                  <div style={{ padding: "10px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleCloseDialog}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={!isFormValid()}
                      onClick={() => {
                        handleSaveFitnessTest(false);
                      }}
                    >
                      Save and Close
                    </Button>
                  </div>
                  {opType === "add" ||
                  (fitnessTest.createdBy &&
                    fitnessTest.createdBy._id === currentUser.id) ? (
                    <div style={{ padding: "10px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!isFormValid()}
                        onClick={() => {
                          handleSaveFitnessTest(true);
                        }}
                      >
                        Save and Add New
                      </Button>
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            }
            {/* end save close */}
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}
