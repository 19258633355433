import React, { useState } from "react";
import { useAppContext } from "../../libs/contextLib";
import { UserService } from "../../services/user";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Factory } from "../../helpers/factories";
import Roles from "../../helpers/roles";

export default function SearchAdmins({
  label = "Search Usernames",
  user,
  setUser,
  role,
  ...props
}) {
  const { currentUser } = useAppContext();

  //autocomplete suggestions
  const [searchSettings, setSearchSettings] = useState({
    userName: "",
    suggestions: [],
  });

  //autcomplete loading
  const [loading, setLoading] = useState(true);

  //handle key change
  const handleChange = (e) => {
    //query options
    let queryOptions = {
      name: e.target.value,
      // role: role,
    };
    if (role) {
      queryOptions.role = role;
    }
    //call api
    (async () => {
      try {
        const filteredUsers = await UserService.getUserAdmins(queryOptions);

        setSearchSettings({
          ...searchSettings,
          suggestions: filteredUsers,
        });

        setLoading(false);
      } catch (err) {
        setSearchSettings({
          ...searchSettings,
          suggestions: [],
        });
      }
    })();
  };

  //return component

  return (
    <Autocomplete
      id="asynchronous-demo"
      //size="small"
      style={{ width: "90%", margin: "16px" }}
      loading={loading}
      freeSolo
      defaultValue={() => {
        return user ? user : Factory.createUser();
      }}
      onChange={(e, v) => {
        if (v != null) {
          setUser(v);
        } else {
          setUser(Factory.createUser());
        }
      }}
      getOptionSelected={(option) => {
        // return option.firstName === value.firstName;
        return option.username;
      }}
      getOptionLabel={(option) => {
        // return option.firstName ? option.firstName + " " + option.lastName : "";
        return option.username;
      }}
      renderOption={(option) => {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", marginRight: "10px   " }}>
              <Avatar src={option.picURL} alt={option.firstName} />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2">
                {option.firstName + " " + option.lastName}
              </Typography>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                {option.username}
              </Typography>
              <Typography
                variant="caption"
                style={{ textTransform: "capitalize" }}
              >
                {option.role === "student" && `${option.username}, `}
                {Roles[option.role].displayName}{" "}
                {option.role === "student" && `, Grade ${option.grade}`}
              </Typography>
              <Typography variant="caption">{option.email}</Typography>
              {/* <img src={option.picUrl} alt="user image" /> */}
            </div>
          </div>
        );
      }}
      options={searchSettings.suggestions}
      //   loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          onChange={handleChange}
        />
      )}
    />
  );
}
