import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  moreVertIcon: {
    cursor: "pointer",
    // color: //theme.palette.mutedGray.main,
  },
}));

function UserMenu({
  user,
  setUser,
  users,
  setOpType,
  rowIndex,
  setIsDialogOpen,
  ...props
}) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState({});

  // React.useEffect(() => {
  //   //open dialog if user selected
  //   if (user._id != "") {
  //     // setOpType("edit");
  //     setIsDialogOpen(true);
  //   }
  // }, [user]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const handleOpenUserDialog = () => {
    setAnchorEl(null);
    setIsDialogOpen(true);
    setOpType("edit");
    setUser(users[rowIndex].user);
  };

  return (
    <div style={{ display: "flex" }}>
      <Tooltip title="More">
        <IconButton
          aria-label="edit"
          onClick={(e) => handleClick(rowIndex, e)}
          id={rowIndex + "-button"}
        >
          <MoreVertIcon className={classes.moreVertIcon} />
        </IconButton>
      </Tooltip>
      <Menu
        id={rowIndex + "-menu"}
        anchorEl={anchorEl && anchorEl[rowIndex]}
        keepMounted
        open={Boolean(anchorEl && anchorEl[rowIndex])}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenUserDialog}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <LockOpenIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Reset Password" />
        </MenuItem> */}
      </Menu>
    </div>
  );
}

export default UserMenu;
