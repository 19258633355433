import React, { useState } from "react";
import { useAppContext } from "../../libs/contextLib";
import { AffiliationService } from "../../services/affiliation";
import Typography from "@material-ui/core/Typography";
//import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Factory } from "../../helpers/factories";

export default function SearchAffiliations({
  label = "Search School",
  affiliation,
  setAffiliation,
  queryString,
  setQueryString,
  isDisabled,
  isReadOnly,
  ...props
}) {
  //const { currentUser } = useAppContext();

  //autocomplete suggestions
  const [searchSettings, setSearchSettings] = useState({
    schoolName: "",
    suggestions: [],
  });

  //autcomplete loading
  const [loading, setLoading] = useState(true);

  //handle key change
  const handleChange = (e) => {
    //query options
    let queryOptions = {
      schoolName: e.target.value,
    };
    //call api
    (async () => {
      try {
        const filteredSchools = await AffiliationService.getAffiliations(
          queryOptions
        );

        setSearchSettings({
          ...searchSettings,
          suggestions: filteredSchools,
        });
        setLoading(false);
      } catch (err) {
        setSearchSettings({
          ...searchSettings,
          suggestions: [],
        });
      }
    })();
  };

  //return component

  return (
    <Autocomplete
      id="asynchronous-demo"
      //size="small"
      disabled={isDisabled}
      style={{ width: "90%", margin: "16px" }}
      loading={loading}
      freeSolo
      defaultValue={() => {
        return affiliation ? affiliation : Factory.createAffiliation();
      }}
      onChange={(e, v) => {
        if (v != null) {
          setAffiliation(v);
          setQueryString({ id: v._id });
        } else {
          setQueryString({});
          setAffiliation(Factory.createAffiliation());
        }
      }}
      getOptionSelected={(option, value) => {
        // console.log("OPTION", option);
        // console.log("VALUE", value);
        return option.schoolName === value.schoolName;
      }}
      getOptionLabel={(option) => {
        return option.schoolName;
      }}
      renderOption={(option) => {
        // console.log("opton", option);
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2">{option.schoolName}</Typography>
              <Typography variant="caption">
                {option.schoolType + ", " + option.country}
              </Typography>
              {/* <img src={option.picUrl} alt="user image" /> */}
            </div>
          </div>
        );
      }}
      options={searchSettings.suggestions}
      //   loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{ ...params.InputProps, readOnly: isReadOnly }}
          onChange={handleChange}
        />
      )}
    />
  );
}
