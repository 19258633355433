//replaces params, takes url, and dictionary of params and paramVal

export const RequestHelper = {
  getGetRequestOptions,
  getPostRequestOptions,
  getPostRequestOptionsFileUpload,
  getPostRequestOptionsFormData,
  getPutRequestOptions,
  getPutRequestOptionsFormData,
  getDeleteRequestOptions,
  getGetRequestOptionsFileDownload,
  replaceParams,
  formatQueryString,
};

function checkAuthTokenPresent() {
  try {
    return JSON.parse(localStorage.getItem("currentUser"))["auth-token"];
  } catch (err) {
    return "";
  }
}

function getGetRequestOptions() {
  let requestOptions = {
    method: "GET",
    // credentials: "include", //important: for setting cookies
    headers: {
      "Content-Type": "application/json",
    },
  };

  const authToken = checkAuthTokenPresent();
  if (authToken) {
    requestOptions["headers"]["auth-token"] = authToken;
  }

  return requestOptions;
}

function getPostRequestOptions(data) {
  let requestOptions = {
    method: "POST",
    // credentials: "include", //important: for setting cookies
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  const authToken = checkAuthTokenPresent();
  if (authToken) {
    requestOptions["headers"]["auth-token"] = authToken;
  }

  return requestOptions;
}

function getPostRequestOptionsFileUpload(data) {
  // console.log("DATAAAA", data);
  let requestOptions = {
    method: "POST",
    // credentials: "include", //important: for setting cookies
    headers: {
      // "Content-Type": "multipart/form-data",
    },
    body: data,
  };

  const authToken = checkAuthTokenPresent();
  if (authToken) {
    requestOptions["headers"]["auth-token"] = authToken;
  }

  return requestOptions;
}

function getPostRequestOptionsFormData(data) {
  // console.log("DATAAAA", data);
  let requestOptions = {
    method: "POST",
    // credentials: "include", //important: for setting cookies
    headers: {
      // `"Content-Type": "multipart/form-data",
    },
    body: data,
  };

  const authToken = checkAuthTokenPresent();
  if (authToken) {
    requestOptions["headers"]["auth-token"] = authToken;
  }

  return requestOptions;
}

function getPutRequestOptions(data = {}) {
  let requestOptions = {
    method: "PUT",
    // credentials: "include", //important: for setting cookies
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  const authToken = checkAuthTokenPresent();
  if (authToken) {
    requestOptions["headers"]["auth-token"] = authToken;
  }

  return requestOptions;
}

function getPutRequestOptionsFormData(data = {}) {
  let requestOptions = {
    method: "PUT",
    // credentials: "include", //important: for setting cookies
    headers: {
      // "Content-Type": "application/json",
    },
    body: data,
  };

  const authToken = checkAuthTokenPresent();
  if (authToken) {
    requestOptions["headers"]["auth-token"] = authToken;
  }

  return requestOptions;
}

function getDeleteRequestOptions(data = {}) {
  let requestOptions = {
    method: "DELETE",
    credentials: "include", //important: for setting cookies
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  const authToken = checkAuthTokenPresent();
  if (authToken) {
    requestOptions["headers"]["auth-token"] = authToken;
  }

  return requestOptions;
}

function getGetRequestOptionsFileDownload(responseType) {
  console.log("response thpe", responseType);
  let requestOptions = {
    method: "GET",
    // credentials: "include", //important: for setting cookies
    headers: {
      // "Content-Type": "application/json",
    },
    responseType: responseType,
  };

  const authToken = checkAuthTokenPresent();
  if (authToken) {
    requestOptions["headers"]["auth-token"] = authToken;
  }

  return requestOptions;
}

function replaceParams(url, params) {
  var newUrl = url;
  for (var key of Object.keys(params)) {
    newUrl = newUrl.replace(key, params[key]);
  }
  return newUrl;
}

function formatQueryString(options) {
  var queryString = "?";
  for (var key of Object.keys(options)) {
    queryString += key + "=" + options[key] + "&";
  }
  return queryString;
}
