import React from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import DialogTitle from "@material-ui/core/DialogTitle";
import SelectDomainArea from "../../helperComponents/selectDomainArea";

export default function ChooseDomainDialog({
  currentUser,
  isOpenChooseDomainDialog,
  setIsOpenChooseDomainDialog,
  handleCloseAddDomainAreaReport,
  domainArea,
  setDomainArea,
  handleCompleteAddDomainAreaReport,
  type,
}) {
  return (
    <Dialog
      open={isOpenChooseDomainDialog}
      onClose={handleCloseAddDomainAreaReport}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Choose Area/Domain"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <SelectDomainArea
            domainArea={domainArea}
            setDomainArea={setDomainArea}
            showAllOption={false}
            orgId={currentUser.organisation.id}
            style={{ display: "flex" }}
            type={type}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCompleteAddDomainAreaReport} color="primary">
          Add
        </Button>
        <Button
          onClick={() => {
            setIsOpenChooseDomainDialog(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
