import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import SelectSPW from "../../helperComponents/selectSPW";
import SearchTag from "../../helperComponents/searchTags";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import SelectRole from "../../helperComponents/selectRole";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import SelectDomainArea from "../../helperComponents/selectDomainArea";
import { Factory } from "../../../helpers/factories";

import { RoadmapService } from "../../../services/roadmap";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
}));

function AddEditRoadmapNodeDialog({
  toastOptions,
  setToastOptions,
  handleCloseToast,
  opType,
  setOpType,
  roadmapNode,
  setRoadmapNode,
  hasChildNodes,
  selectedRoadmapNodeIndex,
  roadmapNodes,
  setRoadmapNodes,
  isAddEditRoadmapNodeDialogOpen,
  handleCloseRoadmapNodeAddEditDialog,
  ...props
}) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  //to hold form values,
  // we could use roadmapNode prop directly but slows down render
  const [dialogForm, setDialogForm] = React.useState(
    Factory.createRoadmapNode()
  );

  //domain area
  const [domainArea, setDomainArea] = React.useState(null);

  //completion date
  const [completionDate, setCompletionDate] = React.useState(Date.now());

  //role
  const [role, setRole] = React.useState("Personal");

  const [skills, setSkills] = React.useState([]);

  const [processes, setProcesses] = React.useState([]);
  const [watermarks, setWatermarks] = React.useState([]);

  //for editing - loads current roadmap node selected
  useEffect(() => {
    setDialogForm(roadmapNode);
    setDomainArea(roadmapNode.domainArea);
    setSkills(roadmapNode.skills ? roadmapNode.skills : []);
    setProcesses(roadmapNode.processes ? roadmapNode.processes : []);
    setWatermarks(roadmapNode.watermarks ? roadmapNode.watermarks : []);
    roadmapNode.role ? setRole(roadmapNode.role) : setRole("Student");
    setCompletionDate(moment(roadmapNode.completionDate));
  }, [roadmapNode]);

  /*
   check if enable save button; for backwards compatibility we have some conditionals checks; 
   old roadmap doesn't have skills, processes, watermarks and new indicators of success
  */
  function isFormValid() {
    const checkIndicatorsOfSuccessValid = () => {
      //check array not empty
      if (dialogForm.indicatorsOfSuccess.length == 0) {
        return false;
      }

      //if array not empty check fields
      let valid = true;
      valid = dialogForm.indicatorsOfSuccess.every((item) => {
        if (
          item.indicator.length == 0 ||
          item.indicatorCompletionDate == null ||
          item.indicatorProgress === ""
        ) {
          return false;
        }
        return true;
      });
      return valid;
    };

    return (
      domainArea &&
      domainArea._id &&
      dialogForm.goal &&
      dialogForm.actionPlan &&
      (!skills || skills.length > 0) &&
      (!processes || processes.length > 0) &&
      (!watermarks || watermarks.length > 0) &&
      // dialogForm.indOfSuccess &&
      (!dialogForm.indicatorsOfSuccess || checkIndicatorsOfSuccessValid()) &&
      dialogForm.completionDate
    );
  }

  //set goal completion date to max date for indicators
  const setGoalCompletionDate = (temp) => {
    if (temp.indicatorsOfSuccess.length == 0) {
      setCompletionDate(Date.now());
    } else if (temp.indicatorsOfSuccess.length == 1) {
      setCompletionDate(temp.indicatorsOfSuccess[0].indicatorCompletionDate);
    } else {
      let max = temp.indicatorsOfSuccess[0].indicatorCompletionDate;
      for (let i = 1; i < temp.indicatorsOfSuccess.length; i++) {
        let date = temp.indicatorsOfSuccess[i].indicatorCompletionDate;
        if (moment(date).isAfter(max)) {
          max = date;
        }
      }
      setCompletionDate(max);
    }
  };

  const addIndicatorOfSucess = () => {
    let temp = { ...dialogForm };
    temp.indicatorsOfSuccess.push({
      indicator: "",
      indicatorCompletionDate: null,
      indicatorProgress: 0,
    });
    setDialogForm(temp);
  };

  const removeIndicatorOfSucess = (e, index) => {
    let temp = { ...dialogForm };
    let tempInds = [];
    temp.indicatorsOfSuccess.forEach((item, i) => {
      if (i != index) {
        tempInds.push(item);
      }
    });

    temp.indicatorsOfSuccess = tempInds;
    setGoalCompletionDate(temp);
    setDialogForm(temp);
  };

  function handleInputChange(e, index) {
    switch (e.target.name) {
      case "indicator":
      case "indicatorProgress":
        let temp = { ...dialogForm };
        temp.indicatorsOfSuccess[index][e.target.name] = e.target.value;
        setDialogForm(temp);
        break;
      default:
        setDialogForm({ ...dialogForm, [e.target.name]: e.target.value });
    }
  }

  //handle To date change
  const handleCompletionDateChange = (date) => {
    if (date === "Invalid Date" || date === null) {
    } else {
      setCompletionDate(date);
      // dialogForm.completionDate = moment(date);
    }
  };

  //handle individual indicator To date change
  const handleIndicatorCompletionDateChange = (date, index) => {
    if (date === "Invalid Date" || date === null) {
    } else {
      let temp = { ...dialogForm };
      temp.indicatorsOfSuccess[index]["indicatorCompletionDate"] = date;
      setDialogForm(temp);
      setGoalCompletionDate(temp);
    }
  };

  async function handleSaveRoadmapNode(saveAndNew = false) {
    //save form data, don't send status and approved fields, updates handled separately
    let data = {
      user: currentUser.id,
      domainArea: domainArea._id,
      goal: dialogForm.goal,
      actionPlan: dialogForm.actionPlan.replace(/↵/g, "\n"),
      indOfSuccess: dialogForm.indOfSuccess.replace(/↵/g, "\n"),
      skills: skills,
      processes: processes,
      watermarks: watermarks,
      role: role,
      completionDate: moment(completionDate).format("YYYY/MM/DD"),
      indicatorsOfSuccess: dialogForm.indicatorsOfSuccess,
    };

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      //check save or edit

      if (opType === "add") {
        const savedRoadmapNode = await RoadmapService.addRoadmapNodeByOrgId(
          currentUser.organisation.id,
          data
        );
        //add announcement to announcements to update table
        let temp = [...roadmapNodes];
        temp.unshift(savedRoadmapNode);
        setRoadmapNodes(temp);

        if (saveAndNew) {
          setRoadmapNode(Factory.createRoadmapNode());
        } else {
          handleCloseRoadmapNodeAddEditDialog();
        }

        // setRoadmapNode(savedRoadmapNode);
        // setOpType("edit");
      } else {
        const updatedRoadmapNode =
          await RoadmapService.updateRoadmapNodeByOrgIdAndRoadmapNodeId(
            currentUser.organisation.id,
            roadmapNode._id,
            data
          );

        //update roadmap node for dispaly
        setRoadmapNode({ ...updatedRoadmapNode });

        //update roadmapnode in roadmapnodes
        let temp = [...roadmapNodes];
        temp[selectedRoadmapNodeIndex] = updatedRoadmapNode;
        setRoadmapNodes(temp);

        if (saveAndNew) {
          setRoadmapNode(Factory.createRoadmapNode());
        } else {
          handleCloseRoadmapNodeAddEditDialog();
        }
      }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Roadmap Node Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error saving roadmap node",
      });
    }
  }

  return (
    <Fragment>
      <Dialog
        open={isAddEditRoadmapNodeDialogOpen}
        onClose={handleCloseRoadmapNodeAddEditDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {opType === "edit" ? "Edit Roadmap Node" : "Add Roadmap Node"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {opType === "add" ? <Grid item xs={12}></Grid> : null}
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <SelectDomainArea
                    domainArea={domainArea}
                    setDomainArea={setDomainArea}
                    showAllOption={true}
                    orgId={currentUser.organisation.id}
                    type={""}
                  />
                </Grid>
                {currentUser.role !== "student" ? (
                  <Grid item xs={12} sm={4}>
                    <SelectRole
                      role={role}
                      setRole={setRole}
                      showAllOption={false}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} sm={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.filterElements}
                      disabled={true}
                      margin="normal"
                      id="date-picker-dialog-from"
                      label="Completion Date"
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      value={completionDate}
                      onChange={handleCompletionDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {/* <Grid item xs={12} sm={3}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    style={{ width: "90%" }}
                  >
                    <InputLabel id="approved-select-label">Approved</InputLabel>
                    <Select
                      labelId="approved-select-label"
                      id="approved-select"
                      name="approved"
                      // defaultValue={dialogForm.status}
                      value={dialogForm.approved}
                      onChange={handleInputChange}
                      size="small"
                      label="Approved"
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    style={{ width: "90%" }}
                  >
                    <InputLabel id="status-select-label">Status</InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="status"
                      name="status"
                      // defaultValue={dialogForm.status}
                      value={dialogForm.status}
                      onChange={handleInputChange}
                      size="small"
                      label="Status"
                    >
                      <MenuItem value="locked">Locked</MenuItem>
                      <MenuItem value="unlocked">Unlocked</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="goal"
                name="goal"
                label="Goal"
                type="text"
                value={dialogForm.goal}
                onChange={handleInputChange}
                disabled={hasChildNodes ? true : false}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Accordion
                style={{
                  background: "transparent",
                  marginLeft: "8px",
                  marginRight: "8px",
                }}
              >
                <AccordionSummary
                  expandIcon={<InfoIcon color={"primary"} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ background: "#eaeff1" }}
                >
                  <Typography variant="body2">
                    List of Skills, Processes, etc.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ background: "#eaeff1" }}>
                  <p>
                    <Typography variant="body2">
                      This is not an exhaustive list so please feel free to add
                      your own if it's missing from the list. Also, some terms
                      apply across categories.
                    </Typography>
                    <br />
                    <Typography variant="body2">Skills</Typography>
                    <Typography variant="caption">
                      analysis, articulation, assessment, coding, communication,
                      comprehension, creativity, decision making, discipline,
                      exploration, handwriting, inquiry, logical thinking,
                      metacognition, navigation, problem solving, team work,
                      time management,
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">Processes</Typography>
                    <Typography variant="caption">
                      assessment, coding, communication, community engagement,
                      comprehension, creativity, design, exploration,
                      handwriting, inquiry, metacognition, outdoor activities,
                      peer mentoring, problem solving, reflectiond
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">Watermarks</Typography>
                    <Typography variant="caption">
                      articulation, awareness, choice, compassion, creativity,
                      discipline, leadership, resilience, responsibility,
                      rigour, time management
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">Concept</Typography>
                    <Typography variant="caption">
                      accuracy, change, construction, design, diversity,
                      environment, global awareness, heritage and culture,
                      nature, perspective, symmetry, patterns, space, time,
                      transformation
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">
                      Actualizing Potential
                    </Typography>
                    <Typography variant="caption">
                      citizenship, community learning, leadership, mentorship,
                    </Typography>
                  </p>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectSPW
                spw={skills}
                setSPW={setSkills}
                selectLabel={"Skills"}
                disabled={hasChildNodes ? true : false}
              />
              {/* <SearchTag tags={skills} setTags={setSkills} label={"Skills"} /> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectSPW
                spw={processes}
                setSPW={setProcesses}
                selectLabel={"Processes"}
                disabled={hasChildNodes ? true : false}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectSPW
                spw={watermarks}
                setSPW={setWatermarks}
                selectLabel={"Watermarks"}
                disabled={hasChildNodes ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={3}
                margin="dense"
                id="actionPlan"
                name="actionPlan"
                label="Action Plan"
                type="text"
                value={dialogForm.actionPlan}
                onChange={handleInputChange}
                variant="outlined"
                disabled={hasChildNodes ? true : false}
                fullWidth
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                multiline
                rows={6}
                margin="dense"
                id="indOfSuccess"
                name="indOfSuccess"
                label="Indicators of Success"
                type="text"
                value={dialogForm.indOfSuccess}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
              />
            </Grid> */}
            <Grid item xs={12}>
              {dialogForm.indicatorsOfSuccess && (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    Indicators of Success
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={3}>
                {dialogForm.indicatorsOfSuccess &&
                  dialogForm.indicatorsOfSuccess.map((item, index) => {
                    return (
                      <Fragment key={"indicator" + index}>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            style={{ width: "90%" }}
                          >
                            <TextField
                              multiline
                              variant="outlined"
                              id="indicator"
                              name="indicator"
                              label="Indicator"
                              type="text"
                              //disabled={hasChildNodes ? true : false}
                              value={item.indicator}
                              onChange={(e) => handleInputChange(e, index)}
                              fullWidth
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.filterElements}
                              margin="normal"
                              id="date-picker-dialog-from"
                              label="Completion Date"
                              format="dd/MM/yyyy"
                              inputVariant="outlined"
                              value={item.indicatorCompletionDate}
                              // onChange={handleCompletionDateChange}
                              onChange={(date) =>
                                handleIndicatorCompletionDateChange(date, index)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              //disabled={hasChildNodes ? true : false}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        {/* <Grid item xs={12} sm={2}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            style={{ width: "90%" }}
                          >
                            <TextField
                              variant="outlined"
                              id="indicatorProgress"
                              name="indicatorProgress"
                              label="Progress %"
                              type="number"
                              value={item.indicatorProgress}
                              onChange={(e) => handleInputChange(e, index)}
                              fullWidth
                            />
                          </FormControl>
                        </Grid> */}
                        {/* {hasChildNodes ? null : ( */}
                        <Grid
                          item
                          xs={12}
                          sm={1}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Tooltip title="Remove Indicator of Success">
                            <IconButton
                              aria-label="removeIndicatorOfSuccess"
                              onClick={(e) => removeIndicatorOfSucess(e, index)}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        {/* )} */}
                      </Fragment>
                    );
                  })}
              </Grid>
            </Grid>
            {/* {hasChildNodes ? null : ( */}
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "column",
                }}
              >
                <Tooltip title="Add Indicator of Success">
                  <IconButton
                    aria-label="addIndicatorOfSuccess"
                    onClick={addIndicatorOfSucess}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            {/* )} */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRoadmapNodeAddEditDialog} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={() => {
              handleSaveRoadmapNode(false);
            }}
            color="primary"
          >
            Save
          </Button>
          {opType === "add" ? (
            <Button
              disabled={!isFormValid()}
              onClick={() => {
                handleSaveRoadmapNode(true);
              }}
              color="primary"
            >
              Save and Add Another
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AddEditRoadmapNodeDialog;
