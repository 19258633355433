import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { UserService } from "../../../services/user";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    // padding: "0px 24px",
    // padding: "0px px",
    minWidth: 120,
    width: "100%",
  },
  dividerTop: {
    backgroundColor: "#dadce0",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    // width: "calc(100% + 24px)",
  },
  divider: {
    backgroundColor: "#dadce0",
    marginLeft: "-24px",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    width: "calc(100% + 48px)",
  },
  formFieldBlockTitle: {
    paddingLeft: theme.spacing(1),
  },
  formField: {
    width: "95%",
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
  },
}));

const countries = [
  { name: "None", code: "" },
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

function UserDialog({
  user,
  setUser,
  opType,
  setOpType,
  toastOptions,
  setToastOptions,
  ...props
}) {
  const classes = useStyles();
  const { currentUser } = useAppContext();

  //to hold form values,
  // we could use announcement prop directly but slows down render
  const [dialogForm, setDialogForm] = React.useState({});

  //for updating announcements from table
  useEffect(() => {
    setDialogForm(user);
  }, [user]);

  //check if enable save button
  function isFormValid() {
    return (
      dialogForm.username &&
      ((opType === "add" && dialogForm.password) || opType === "edit") &&
      dialogForm.role &&
      dialogForm.accountStatus &&
      dialogForm.firstName &&
      dialogForm.lastName &&
      (dialogForm.role != "student" ||
        /^([0-9]{1}[0-2]?)$/.test(dialogForm.grade)) &&
      (dialogForm.role != "student" ||
        /^([1-2]{1}[0-9]{3})$/.test(dialogForm.yearJoined)) &&
      (dialogForm.role != "student" ||
        /^([0-9]{1}[0-2]?)$/.test(dialogForm.gradeJoined))
    );
  }

  //input change
  function handleInputChange(e) {
    switch (e.target.name) {
      case "addressLine1":
        setDialogForm({
          ...dialogForm,
          ["address"]: { ...dialogForm.address, line1: e.target.value },
        });
        break;
      case "addressSuburb":
        setDialogForm({
          ...dialogForm,
          ["address"]: { ...dialogForm.address, suburb: e.target.value },
        });
        break;
      case "addressCity":
        setDialogForm({
          ...dialogForm,
          ["address"]: { ...dialogForm.address, city: e.target.value },
        });
        break;
      case "addressState":
        setDialogForm({
          ...dialogForm,
          ["address"]: { ...dialogForm.address, state: e.target.value },
        });
        break;
      case "addressCountry":
        setDialogForm({
          ...dialogForm,
          ["address"]: { ...dialogForm.address, country: e.target.value },
        });
        break;
      case "guardianName":
        setDialogForm({
          ...dialogForm,
          ["guardianEmergencyContact"]: {
            ...dialogForm.guardianEmergencyContact,
            name: e.target.value,
          },
        });
        break;
      case "guardianEmail":
        setDialogForm({
          ...dialogForm,
          ["guardianEmergencyContact"]: {
            ...dialogForm.guardianEmergencyContact,
            email: e.target.value,
          },
        });
        break;
      case "guardianPhone":
        setDialogForm({
          ...dialogForm,
          ["guardianEmergencyContact"]: {
            ...dialogForm.guardianEmergencyContact,
            phone: e.target.value,
          },
        });
        break;
      default:
        setDialogForm({ ...dialogForm, [e.target.name]: e.target.value });
    }
  }

  //save update user
  const handleSaveUser = async () => {
    //save form data, using extra object because setState on dialogForm doesn't update immediately
    let data = {
      username: dialogForm.username,
      alias: dialogForm.alias,
      role: dialogForm.role,
      email: dialogForm.email,
      organisation: currentUser.organisation.id, //add org id required
      firstName: dialogForm.firstName,
      lastName: dialogForm.lastName,
      phone: dialogForm.phone,
      nationalIdNo: dialogForm.nationalIdNo ? dialogForm.nationalIdNo : "N/A",
      yearJoined: dialogForm.yearJoined ? dialogForm.yearJoined : "N/A",
      yearGraduated: dialogForm.yearGraduated
        ? dialogForm.yearGraduate
        : "None",
      grade: dialogForm.grade ? dialogForm.grade : "N/A",
      gradeJoined: dialogForm.gradeJoined ? dialogForm.gradeJoined : "N/A",
      groupSection: dialogForm.groupSection ? dialogForm.groupSection : "N/A",
      nationalStudentCode: dialogForm.nationalStudentCode
        ? dialogForm.nationalStudentCode
        : "N/A",
      nationalTeacherCode: dialogForm.nationalTeacherCode
        ? dialogForm.nationalTeacherCode
        : "N/A",
      // organisation: dialogForm.organisation,
      address: {
        line1: dialogForm.address ? dialogForm.address.line1 : "N/A",
        suburb: dialogForm.address ? dialogForm.address.suburb : "N/A",
        city: dialogForm.address ? dialogForm.address.city : "N/A",
        state: dialogForm.address ? dialogForm.address.state : "N/A",
        country: dialogForm.address ? dialogForm.address.country : "N/A",
      },
      guardianEmergencyContact: {
        name: dialogForm.guardianEmergencyContact
          ? dialogForm.guardianEmergencyContact.name
          : "N/A",
        email: dialogForm.guardianEmergencyContact
          ? dialogForm.guardianEmergencyContact.email
          : "N/A",
        phone: dialogForm.guardianEmergencyContact
          ? dialogForm.guardianEmergencyContact.phone
          : "N/A",
      },
      accountStatus: dialogForm.accountStatus,
      notes: dialogForm.notes,
    };

    //add password field if optype is add new user
    if (opType === "add") {
      data["password"] = dialogForm.password;
    } else {
      data["_id"] = user._id;
    }

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving...",
      });

      //check save or edit

      if (opType === "add") {
        const savedUser = await UserService.addUserByOrgId(
          currentUser.organisation.id,
          data
        );
        setOpType("edit");
        setUser(savedUser);
      } else {
        const updatedUser = await UserService.updateUserByOrgIdAndUserId(
          currentUser.organisation.id,
          user._id,
          data
        );
        // setUser(updatedUser);
        //edit user in update table if selected from table
        // let temp = [...announcements];
        // temp[selectedRowIndex] = updatedAnnouncement;
        // temp[selectedRowIndex] = updatedAnnouncement;
        // setAnnouncements(temp);
      }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "User Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  };

  return (
    <Dialog
      open={props.isDialogOpen}
      onClose={props.handleCloseDialog}
      // fullWidth={true}
      // maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <IconButton color="inherit" className={classes.iconButtonAvatar}>
              <Avatar src={user.picUrl ? user.picUrl : ""} alt="My Avatar" />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            {(() => {
              if (opType === "edit" && currentUser.role === "schoolAdmin")
                return "Edit User";
              if (opType === "add") return "Add User";
              return "User Information";
            })()}
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider className={classes.dividerTop} />
      <DialogContent>
        {currentUser.role === "schoolAdmin" && (
          <Fragment>
            <Grid container spacing={3} justify="space-between">
              <Grid item xs={12}>
                <br />
                <Typography
                  className={classes.formFieldBlockTitle}
                  variant="subtitle2"
                >
                  Login Details
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} justify="space-evenly">
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    id="username"
                    name="username"
                    label="User Name"
                    // defaultValue={user.userName ? user.userName : ""}
                    value={dialogForm.username}
                    className={classes.formField}
                    size="small"
                    onChange={handleInputChange}
                    inputProps={{
                      readOnly:
                        currentUser.role === "schoolAdmin" ? false : true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                {opType === "add" && (
                  <FormControl className={classes.formControl}>
                    <TextField
                      required
                      type="password"
                      id="password"
                      name="password"
                      label="Password"
                      // defaultValue={user.password ? user.password : ""}
                      value={dialogForm.password}
                      className={classes.formField}
                      onChange={handleInputChange}
                      size="small"
                      autoComplete="new-password"
                      inputProps={{
                        readOnly:
                          currentUser.role === "schoolAdmin" ? false : true,
                      }}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} justify="space-evenly">
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="role-select-label">Role</InputLabel>
                  <Select
                    labelId="role-select-label"
                    name="role"
                    value={dialogForm.role}
                    onChange={handleInputChange}
                    className={classes.formField}
                    inputProps={{
                      readOnly:
                        currentUser.role === "schoolAdmin" ? false : true,
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"areaCoordinator"}>
                      Area Coordinator
                    </MenuItem>
                    <MenuItem value={"domainHead"}>Domain Head</MenuItem>
                    <MenuItem value={"headOfSchool"}>Head of School</MenuItem>
                    <MenuItem value={"parent"}>Parent</MenuItem>
                    <MenuItem value={"researcher"}>Researcher</MenuItem>
                    <MenuItem value={"schoolAdmin"}>System Admin</MenuItem>
                    <MenuItem value={"headOfAdmin"}>
                      Head of Adminstration
                    </MenuItem>
                    <MenuItem value={"staff"}>Staff</MenuItem>
                    <MenuItem value={"student"}>Student</MenuItem>
                    <MenuItem value={"teacher"}>Teacher</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="account-status-select-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="account-status-select-label"
                    name="accountStatus"
                    value={dialogForm.accountStatus}
                    onChange={handleInputChange}
                    className={classes.formField}
                    inputProps={{
                      readOnly:
                        currentUser.role === "schoolAdmin" ? false : true,
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"suspended"}>Suspended</MenuItem>
                    <MenuItem value={"deactivated"}>Deactivated</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                {/* <Divider /> */}
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
          </Fragment>
        )}
        <Grid container spacing={3} justify="space-between">
          <Grid item xs={12}>
            <br />
            <Typography
              className={classes.formFieldBlockTitle}
              variant="subtitle2"
            >
              User Details
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} justify="space-evenly">
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                required
                id="firstName"
                name="firstName"
                label="First Name"
                onChange={handleInputChange}
                value={dialogForm.firstName}
                className={classes.formField}
                size="small"
                inputProps={{
                  readOnly: currentUser.role === "schoolAdmin" ? false : true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                required
                id="Last Name"
                name="lastName"
                label="Last Name"
                onChange={handleInputChange}
                value={dialogForm.lastName}
                size="small"
                inputProps={{
                  readOnly: currentUser.role === "schoolAdmin" ? false : true,
                }}
                className={classes.formField}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} justify="space-evenly">
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                id="email"
                name="email"
                label="E-mail"
                onChange={handleInputChange}
                value={dialogForm.email}
                size="small"
                className={classes.formField}
                inputProps={{
                  readOnly: currentUser.role === "schoolAdmin" ? false : true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                id="alias"
                name="alias"
                label="Alias"
                onChange={handleInputChange}
                value={dialogForm.alias}
                className={classes.formField}
                size="small"
                inputProps={{
                  readOnly: currentUser.role === "schoolAdmin" ? false : true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                required
                id="yearJoined"
                name="yearJoined"
                label="Year Joined"
                onChange={handleInputChange}
                value={dialogForm.yearJoined}
                size="small"
                inputProps={{
                  readOnly: currentUser.role === "schoolAdmin" ? false : true,
                }}
                className={classes.formField}
              />
            </FormControl>
          </Grid>
          {(user.role === "student" || dialogForm.role === "student") &&
            currentUser.role === "schoolAdmin" &&
            dialogForm.role === "student" && (
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="nationalStudentCode"
                    name="nationalStudentCode"
                    label="National Student Code"
                    onChange={handleInputChange}
                    value={dialogForm.nationalStudentCode}
                    size="small"
                    inputProps={{
                      readOnly:
                        currentUser.role === "schoolAdmin" ? false : true,
                    }}
                    className={classes.formField}
                  />
                </FormControl>
              </Grid>
            )}
          {([
            "teacher",
            "areaCoordinator",
            "domainHead",
            "headOfSchool",
          ].includes(user.role) ||
            [
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
            ].includes(dialogForm.role)) &&
            currentUser.role === "schoolAdmin" && (
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="nationalTeacherCode"
                    name="nationalTeacherCode"
                    label="National Teacher Code"
                    onChange={handleInputChange}
                    value={dialogForm.nationalTeacherCode}
                    size="small"
                    inputProps={{
                      readOnly:
                        currentUser.role === "schoolAdmin" ? false : true,
                    }}
                    className={classes.formField}
                  />
                </FormControl>
              </Grid>
            )}
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* <Divider /> */}
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
        {(user.role === "student" || dialogForm.role === "student") && (
          <Fragment>
            <Grid container spacing={3} justify="space-between">
              <Grid item xs={12}>
                <br />
                <Typography
                  className={classes.formFieldBlockTitle}
                  variant="subtitle2"
                >
                  Student Additional Details
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} justify="space-evenly">
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    id="grade"
                    name="grade"
                    label="Grade (0-12)"
                    onChange={handleInputChange}
                    value={dialogForm.grade}
                    className={classes.formField}
                    size="small"
                    inputProps={{
                      readOnly:
                        currentUser.role === "schoolAdmin" ? false : true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="groupSection"
                    name="groupSection"
                    label="Section / Group"
                    onChange={handleInputChange}
                    value={dialogForm.groupSection}
                    size="small"
                    inputProps={{
                      readOnly:
                        currentUser.role === "schoolAdmin" ? false : true,
                    }}
                    className={classes.formField}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    id="gradeJoined"
                    name="gradeJoined"
                    label="Grade Joined (0-12)"
                    onChange={handleInputChange}
                    value={dialogForm.gradeJoined}
                    size="small"
                    inputProps={{
                      readOnly:
                        currentUser.role === "schoolAdmin" ? false : true,
                    }}
                    className={classes.formField}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    id="yearJoined"
                    name="yearJoined"
                    label="Year Joined"
                    onChange={handleInputChange}
                    value={dialogForm.yearJoined}
                    size="small"
                    inputProps={{
                      readOnly:
                        currentUser.role == "schoolAdmin" ? false : true,
                    }}
                    className={classes.formField}
                  />
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="yearGraduated"
                    name="yearGraduated"
                    label="Year Graduated"
                    onChange={handleInputChange}
                    value={dialogForm.yearGraduated}
                    size="small"
                    inputProps={{
                      readOnly:
                        currentUser.role === "schoolAdmin" ? false : true,
                    }}
                    className={classes.formField}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                {/* <Divider /> */}
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
          </Fragment>
        )}
        <Grid container spacing={3} justiry="space-evenly">
          <Grid item xs={12}>
            <br />
            <Typography
              className={classes.formFieldBlockTitle}
              variant="subtitle2"
            >
              Address
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} justify="space-evenly">
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                id="line1"
                name="addressLine1"
                label="Line1"
                onChange={handleInputChange}
                value={dialogForm.address && dialogForm.address.line1}
                className={classes.formField}
                size="small"
                inputProps={{
                  readOnly: currentUser.role === "schoolAdmin" ? false : true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                id="suburb"
                name="addressSuburb"
                label="Suburb / Village"
                onChange={handleInputChange}
                value={dialogForm.address && dialogForm.address.suburb}
                size="small"
                inputProps={{
                  readOnly: currentUser.role === "schoolAdmin" ? false : true,
                }}
                className={classes.formField}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                id="city"
                name="addressCity"
                label="Town / City / Gewog"
                size="small"
                onChange={handleInputChange}
                value={dialogForm.address && dialogForm.address.city}
                inputProps={{
                  readOnly: currentUser.role === "schoolAdmin" ? false : true,
                }}
                className={classes.formField}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                id="state"
                name="addressState"
                label="State / Dzongkhag"
                size="small"
                onChange={handleInputChange}
                value={dialogForm.address && dialogForm.address.state}
                inputProps={{
                  readOnly: currentUser.role === "schoolAdmin" ? false : true,
                }}
                className={classes.formField}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} justify="space-evenly">
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="country-select-label">Country</InputLabel>
              <Select
                labelId="country-select-label"
                name="addressCountry"
                value={dialogForm.address && dialogForm.address.country}
                onChange={handleInputChange}
                className={classes.formField}
                inputProps={{
                  readOnly: currentUser.role === "schoolAdmin" ? false : true,
                }}
              >
                {countries.map((country) => (
                  <MenuItem value={country.name}>{country.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>
        <Grid container>
          <Divider className={classes.divider} />
        </Grid>
        <Grid container spacing={3} justify="space-evenly">
          <Grid item xs={12}>
            <br />
            <Typography
              className={classes.formFieldBlockTitle}
              variant="subtitle2"
            >
              Guardian / Emergency Contact
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} justify="space-evenly">
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                id="guardianName"
                name="guardianName"
                label="name"
                onChange={handleInputChange}
                value={
                  dialogForm.guardianEmergencyContact &&
                  dialogForm.guardianEmergencyContact.name
                }
                size="small"
                className={classes.formField}
                inputProps={{
                  readOnly: currentUser.role === "schoolAdmin" ? false : true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                id="guardianEmail"
                name="guardianEmail"
                label="e-mail"
                onChange={handleInputChange}
                value={
                  dialogForm.guardianEmergencyContact &&
                  dialogForm.guardianEmergencyContact.email
                }
                className={classes.formField}
                size="small"
                inputProps={{
                  readOnly: currentUser.role === "schoolAdmin" ? false : true,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} justify="space-evenly">
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                id="guardianPhone"
                name="guardianPhone"
                label="Phone"
                onChange={handleInputChange}
                value={
                  dialogForm.guardianEmergencyContact &&
                  dialogForm.guardianEmergencyContact.phone
                }
                className={classes.formField}
                size="small"
                inputProps={{
                  readOnly: currentUser.role === "schoolAdmin" ? false : true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>
        {currentUser.role === "schoolAdmin" && (
          <Fragment>
            <Grid container spacing={0}>
              <Divider className={classes.divider} />
            </Grid>

            <Grid container spacing={3} justify="space-between">
              <Grid item xs={12}>
                <br />
                <Typography
                  className={classes.formFieldBlockTitle}
                  variant="subtitle2"
                >
                  Notes
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} justify="space-evenly">
              <Grid item xs={12} sm={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    multiline
                    id="notes"
                    name="notes"
                    label=""
                    onChange={handleInputChange}
                    value={dialogForm.notes}
                    className={classes.formField}
                    size="small"
                    inputProps={{
                      readOnly:
                        currentUser.role === "schoolAdmin" ? false : true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
          </Fragment>
        )}
      </DialogContent>
      <Divider className={classes.dividerTop} />
      <DialogActions>
        <Button onClick={props.handleCloseDialog} color="primary">
          Cancel
        </Button>
        {currentUser.role === "schoolAdmin" && (
          <Button
            disabled={!isFormValid()}
            onClick={handleSaveUser}
            color="primary"
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default UserDialog;
