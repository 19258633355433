/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const LeResourcesService = {
  getLeResourcesByOrgId,
  addLeResourcesByOrgId,
  deleteLeResourcesByOrgIdAndLeResourceId,
  updateLeResourcesByOrgIdAndLearningResourceId,
};

function getLeResourcesByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_leResources_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function addLeResourcesByOrgId(orgId, announcement) {
  const requestOptions = RequestHelper.getPostRequestOptions(announcement);

  var api_url = config.api_leResources_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.leResource;
    });
}

function updateLeResourcesByOrgIdAndLearningResourceId(
  orgId,
  leResourceId,
  leResource
) {
  const requestOptions = RequestHelper.getPutRequestOptions(leResource);

  var api_url = config.api_leResources_by_orgId_and_leResourceId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    leResourceId: leResourceId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.leResource;
    });
}

function deleteLeResourcesByOrgIdAndLeResourceId(orgId, leResourceId) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_leResources_by_orgId_and_leResourceId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    leResourceId: leResourceId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data._id;
    });
}
