import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import SearchTag from "../../helperComponents/searchTags";
import SelectDomainArea from "../../helperComponents/selectDomainArea";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";

import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Factory } from "../../../helpers/factories";
import { DomainRoadmapService } from "../../../services/domainRoadmap";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterElements: {
    marginLeft: "16px",
    marginRight: "16px",
    width: "90%",
  },
  textField1: {
    "& > *": {
      margin: theme.spacing(2),
      width: "25ch",
    },
  },
}));

function AddEditDomainRoadmap({
  domainRoadmap,
  setDomainRoadmap,
  domainRoadmaps,
  setDomainRoadmaps,
  selectedDomainRoadmapIndex,
  opType,
  setOpType,
  isAddEditDomainRoadmapDialogOpen,
  handleCloseAddEditDomainRoadmapDialog,
  toastOptions,
  setToastOptions,
  handleCloseToast,
  ...props
}) {
  const { currentUser } = useAppContext();

  const classes = useStyles();

  //temp domainRoadmap state to prevent lag
  const [tempDomainRoadmap, setTempDomainRoadmap] =
    React.useState(domainRoadmap);

  //populating year dropdwon
  const currentYear = moment().year();
  const yearArray = Array.from(
    Array(currentYear - (currentYear - 2)),
    (_, i) => currentYear - i
  );

  //for search domain component
  const [domainArea, setDomainArea] = React.useState(null);

  const [skills, setSkills] = React.useState([]);

  const [processes, setProcesses] = React.useState([]);
  const [watermarks, setWatermarks] = React.useState([]);

  //for keeping some values when using save and new; saves from typing
  const [keepValues, setKeepValues] = React.useState(false);

  //load lesson plan if edit
  useEffect(() => {
    if (opType === "edit") {
      setTempDomainRoadmap({
        ...domainRoadmap,
      });
      setDomainArea(domainRoadmap.domainArea);
      setSkills(domainRoadmap.skills);
      setProcesses(domainRoadmap.processes);
      setWatermarks(domainRoadmap.watermarks);
    } else if (opType === "add" && !keepValues) {
      setTempDomainRoadmap(Factory.createDomainRoadmap());
      setDomainArea(null);
      setSkills([]);
      setProcesses([]);
      setWatermarks([]);

      // setUser(null);
    }
  }, [opType]);

  function isFormValid() {
    return (
      tempDomainRoadmap.year &&
      tempDomainRoadmap.grade &&
      tempDomainRoadmap.learningCycle &&
      tempDomainRoadmap.startDate &&
      tempDomainRoadmap.duration &&
      domainArea &&
      domainArea._id &&
      skills.length > 0 &&
      processes.length > 0 &&
      watermarks.length > 0 &&
      tempDomainRoadmap.focusArea &&
      tempDomainRoadmap.subAreas &&
      tempDomainRoadmap.actionPlan &&
      tempDomainRoadmap.indOfSuccess
    );
  }

  function handleInputChange(e) {
    let temp = { ...tempDomainRoadmap };
    temp[e.target.name] = e.target.value;
    setTempDomainRoadmap(temp);
  }

  //handle  date change
  const handleDateChange = (date) => {
    if (date === "Invalid Date" || date === null) {
    } else {
      setTempDomainRoadmap({ ...tempDomainRoadmap, startDate: date });
    }
  };

  async function handleSaveDomainRoadmap(addNewAfterSave) {
    // saving message
    setToastOptions({
      ...toastOptions,
      open: true,
      message: "Saving",
    });

    try {
      //prepare data
      var data = {
        organisation: currentUser.organisation.id,
        domainArea: domainArea
          ? domainArea._id
          : tempDomainRoadmap.domainArea._id,
        grade: tempDomainRoadmap.grade,
        year: tempDomainRoadmap.year,
        learningCycle: tempDomainRoadmap.learningCycle,
        startDate: tempDomainRoadmap.startDate,
        duration: tempDomainRoadmap.duration,
        createdBy: currentUser.id,
        skills: skills,
        processes: processes,
        watermarks: watermarks,
        focusArea: tempDomainRoadmap.focusArea.trim(),
        subAreas: tempDomainRoadmap.subAreas.trim(),
        actionPlan: tempDomainRoadmap.actionPlan.replace(/↵/g, "\n").trim(),
        indOfSuccess: tempDomainRoadmap.indOfSuccess.replace(/↵/g, "\n").trim(),
        comments: tempDomainRoadmap.comments.replace(/↵/g, "\n").trim(),
      };

      //check whether new lesson plan added or lesson plan being updated

      if (opType === "add") {
        const savedDomainRoadmap =
          await DomainRoadmapService.addDomainRoadmapByOrgId(
            currentUser.organisation.id,
            data
          );
        setDomainRoadmap({ ...domainRoadmap, _id: savedDomainRoadmap._id });

        //update lessons
        let temp = [...domainRoadmaps];
        temp.unshift(savedDomainRoadmap);
        setDomainRoadmaps(temp);
      } else {
        //update domainRoadmap
        const updatedDomainRoadmap =
          await DomainRoadmapService.updateDomainRoadmapById(
            currentUser.organisation.id,
            domainRoadmap._id,
            data
          );

        //update roadmaps
        let temp = [...domainRoadmaps];
        temp[selectedDomainRoadmapIndex] = updatedDomainRoadmap;
        setDomainRoadmaps(temp);
      }

      //add new domain roadmap after save
      if (!addNewAfterSave) {
        setTempDomainRoadmap(Factory.createDomainRoadmap());
        setDomainArea(null);
        setSkills([]);
        setProcesses([]);
        setWatermarks([]);
        handleCloseAddEditDomainRoadmapDialog();
      } else {
        setTempDomainRoadmap({
          ...tempDomainRoadmap,
          focusArea: "",
          subAreas: "",
          actionPlan: "",
          indOfSuccess: "",
          comments: "",
        });
        setOpType("add");
      }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Domain Roadmap Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error saving domain roadmap",
      });
    }
  }

  //const { title } = dialogPropsMain;
  // setDialogProps(row);
  return (
    <Fragment>
      <Dialog
        open={isAddEditDomainRoadmapDialogOpen}
        onClose={handleCloseAddEditDomainRoadmapDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {opType === "edit" ? "Edit Domain Roadmap" : "Add Domain Roadmap"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <InputLabel id="type-description">Year</InputLabel>
                <Select
                  labelId="year"
                  id="year"
                  name="year"
                  value={tempDomainRoadmap.year}
                  onChange={handleInputChange}
                  label="Year"
                >
                  {yearArray.map((year) => (
                    <MenuItem value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <InputLabel id="type-description">Grade</InputLabel>
                <Select
                  labelId="grade"
                  id="grade"
                  name="grade"
                  value={tempDomainRoadmap.grade}
                  onChange={handleInputChange}
                  label="Grade"
                >
                  <MenuItem value={"0"}>PP</MenuItem>
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                  <MenuItem value={"3"}>3</MenuItem>
                  <MenuItem value={"4"}>4</MenuItem>
                  <MenuItem value={"5"}>5</MenuItem>
                  <MenuItem value={"6"}>6</MenuItem>
                  <MenuItem value={"7"}>7</MenuItem>
                  <MenuItem value={"8"}>8</MenuItem>
                  <MenuItem value={"9"}>9</MenuItem>
                  <MenuItem value={"10"}>10</MenuItem>
                  <MenuItem value={"11"}>11</MenuItem>
                  <MenuItem value={"12"}>12</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectDomainArea
                domainArea={domainArea}
                setDomainArea={setDomainArea}
                showAllOption={false}
                orgId={currentUser.organisation.id}
                style={{ display: "flex" }}
                type={""}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <TextField
                  id="learningCycle"
                  name="learningCycle"
                  label="Learning Cycle"
                  type="text"
                  value={tempDomainRoadmap.learningCycle}
                  placeholder="Eg. 1"
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.filterElements}
                  inputVariant="outlined"
                  margin="normal"
                  id="date-picker-dialog-from"
                  label="Start Date"
                  format="dd/MM/yyyy"
                  value={tempDomainRoadmap.startDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <TextField
                  id="duration"
                  name="duration"
                  label="Duration in Weeks"
                  type="text"
                  value={tempDomainRoadmap.duration}
                  variant="outlined"
                  placeholder="Duration in no of weeks"
                  onChange={handleInputChange}
                  fullWidth
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Accordion
                style={{
                  background: "transparent",
                  marginLeft: "16px",
                  marginRight: "16px",
                }}
              >
                <AccordionSummary
                  expandIcon={<InfoIcon color={"primary"} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ background: "#eaeff1" }}
                >
                  <Typography variant="body2">
                    List of Skills, Processes, etc.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ background: "#eaeff1" }}>
                  <p>
                    <Typography variant="body2">
                      This is not an exhaustive list so please feel free to add
                      your own if it's missing from the list. Also, some terms
                      apply across categories.
                    </Typography>
                    <br />
                    <Typography variant="body2">Skills</Typography>
                    <Typography variant="caption">
                      analysis, articulation, assessment, coding, communication,
                      comprehension, creativity, decision making, discipline,
                      exploration, handwriting, inquiry, logical thinking,
                      metacognition, navigation, problem solving, team work,
                      time management,
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">Processes</Typography>
                    <Typography variant="caption">
                      assessment, coding, communication, community engagement,
                      comprehension, creativity, design, exploration,
                      handwriting, inquiry, metacognition, outdoor activities,
                      peer mentoring, problem solving, reflectiond
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">Watermarks</Typography>
                    <Typography variant="caption">
                      articulation, awareness, choice, compassion, creativity,
                      discipline, leadership, resilience, responsibility,
                      rigour, time management
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">Concept</Typography>
                    <Typography variant="caption">
                      accuracy, change, construction, design, diversity,
                      environment, global awareness, heritage and culture,
                      nature, perspective, symmetry, patterns, space, time,
                      transformation
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">
                      Actualizing Potential
                    </Typography>
                    <Typography variant="caption">
                      citizenship, community learning, leadership, mentorship,
                    </Typography>
                  </p>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <SearchTag tags={skills} setTags={setSkills} label={"Skills"} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <SearchTag
                  tags={processes}
                  setTags={setProcesses}
                  label={"Processes"}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <SearchTag
                  tags={watermarks}
                  setTags={setWatermarks}
                  label={"Watermarks"}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ margin: "16px" }}>
            <TextField
              multiline
              margin="dense"
              id="focusArea"
              name="focusArea"
              label="Focus Area / Concepts"
              type="text"
              rows={1}
              value={tempDomainRoadmap.focusArea}
              onChange={handleInputChange}
              variant="outlined"
              placeholder="Eg. Human Body"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ margin: "16px" }}>
            <TextField
              multiline
              margin="dense"
              id="subAreas"
              name="subAreas"
              label="Subareas / Sub-concepts"
              type="text"
              rows={2}
              value={tempDomainRoadmap.subAreas}
              onChange={handleInputChange}
              variant="outlined"
              placeholder="Eg. Digestive System"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ margin: "16px" }}>
            <TextField
              multiline
              margin="dense"
              id="actionPlan"
              name="actionPlan"
              label="Action Plan"
              type="text"
              rows={8}
              placeholder="Eg. We will start by talking about the importance of the digestive system in all animals."
              value={tempDomainRoadmap.actionPlan}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ margin: "16px" }}>
            <TextField
              multiline
              margin="dense"
              id="indOfSuccess"
              name="indOfSuccess"
              label="Indicators of Success"
              placeholder="Eg. Students will know why the digestive system is important and be able to identify the organs responsible for digestion."
              type="text"
              rows={8}
              value={tempDomainRoadmap.indOfSuccess}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ margin: "16px" }}>
            <TextField
              multiline
              margin="dense"
              id="comments"
              name="comments"
              label="Comments"
              placeholder="Comments on whether this target was met. Please enter after end of learning cycle."
              type="text"
              rows={8}
              value={tempDomainRoadmap.comments}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setTempDomainRoadmap(Factory.createDomainRoadmap());
              setDomainArea(null);
              setSkills([]);
              setProcesses([]);
              setWatermarks([]);
              handleCloseAddEditDomainRoadmapDialog();
            }}
            color="primary"
          >
            Close
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={() => {
              setKeepValues(false);
              handleSaveDomainRoadmap(false);
            }}
            color="primary"
          >
            Save & Close
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={() => {
              setKeepValues(true);
              handleSaveDomainRoadmap(true);
            }}
            color="primary"
          >
            Save & Add Another
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AddEditDomainRoadmap;
