import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import SearchUser from "../../helperComponents/searchUser";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import SelectDomainArea from "../../helperComponents/selectDomainArea";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { Factory } from "../../../helpers/factories";
import { filterStateSelector } from "@material-ui/data-grid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterElements: {
    margin: theme.spacing(2),
    width: "90%",
  },
}));

export default function LessonPlansFilter({
  toastOptions,
  setToastOptions,
  queryString,
  setQueryString,
  ...props
}) {
  const classes = useStyles();

  //current user from app context
  const { currentUser } = useAppContext();
  const location = useLocation();

  // const [user, setUser] = React.useState(Factory.createUser());
  const [user, setUser] = React.useState({
    _id: currentUser.id,
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
  });

  //keywords in title
  const [keywords, setKeywords] = React.useState("");
  const [timer, setTimer] = React.useState(null); //to wait until user stops typing keyword search
  const [keywordsBeingTyped, setKeywordsBeingTyped] = React.useState(false);

  //role type for goal
  const [grade, setGrade] = React.useState("");

  //dates
  const [selectedFromDate, setSelectedFromDate] = React.useState(
    moment().subtract(3, "years")
  );
  const [selectedToDate, setSelectedToDate] = React.useState(moment());

  const [domainArea, setDomainArea] = React.useState(
    Factory.createDomainArea()
  );

  //pre-load domain/araes
  useEffect(() => {
    //set initial query string
    if (!keywordsBeingTyped) {
      setQueryString({
        keywords: keywords,
        teacher: user._id ? user._id : "",
        domainArea: domainArea._id ? domainArea._id : "",
        grade: grade != "All" ? grade : "",
        from: selectedFromDate ? selectedFromDate : "",
        to: selectedFromDate ? selectedToDate : "",
      });
    }
  }, [domainArea, user, grade, keywordsBeingTyped]);

  //handle From date change
  const handleFromDateChange = (date) => {
    if (date === "Invalid Date" || date === null) {
    } else {
      setSelectedFromDate(date);
      setQueryString({
        ...queryString,
        from: moment(date),
      });
    }
  };

  //handle To date change
  const handleToDateChange = (date) => {
    if (date === "Invalid Date" || date === null) {
    } else {
      setSelectedToDate(date);
      setQueryString({
        ...queryString,
        to: moment(date),
      });
    }
  };

  //handle keyword change
  const handleKeywordsChange = (e) => {
    setKeywords(e.target.value);
    setKeywordsBeingTyped(true);
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      setKeywordsBeingTyped(false);
    }, 500);

    setTimer(newTimer);
  };

  //handle  grade change
  const handleGradeChange = (e) => {
    setGrade(e.target.value);
  };

  //handle assessment Type  change
  //   const handleTypeChange = (e) => {
  //     setAssessmentType(e.target.value);
  //     if (e.target.value == "all") {
  //       setQueryString({
  //         ...queryString,
  //         type: "",
  //       });
  //     } else {
  //       setQueryString({
  //         ...queryString,
  //         type: e.target.value,
  //       });
  //     }
  //   };

  return (
    <Grid container justify="space-between" spacing={3}>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ backgroundColor: "white", borderRadius: "5px" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%" }}
            >
              <TextField
                margin="dense"
                id="search-title"
                name="search-title"
                label="Search Title / Tags"
                type="text"
                // variant="outlined"
                value={keywords}
                placeholder="Enter keywords to search lesson titles / tags"
                onChange={handleKeywordsChange}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SearchUser
              user={user}
              setUser={setUser}
              // role={"student"}
              style={{ display: "flex" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <SelectDomainArea
              domainArea={domainArea}
              setDomainArea={setDomainArea}
              showAllOption={true}
              orgId={currentUser.organisation.id}
              type={""}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%" }}
            >
              <InputLabel id="type-description">Grade</InputLabel>
              <Select
                labelId="grade"
                id="grade"
                name="grade"
                value={grade}
                onChange={handleGradeChange}
                label="Grade"
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"1"}>1</MenuItem>
                <MenuItem value={"2"}>2</MenuItem>
                <MenuItem value={"3"}>3</MenuItem>
                <MenuItem value={"4"}>4</MenuItem>
                <MenuItem value={"5"}>5</MenuItem>
                <MenuItem value={"6"}>6</MenuItem>
                <MenuItem value={"7"}>7</MenuItem>
                <MenuItem value={"8"}>8</MenuItem>
                <MenuItem value={"9"}>9</MenuItem>
                <MenuItem value={"10"}>10</MenuItem>
                <MenuItem value={"11"}>11</MenuItem>
                <MenuItem value={"12"}>12</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={3} justify="space-between">
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    className={classes.filterElements}
                    margin="normal"
                    id="date-picker-dialog-from"
                    label="From"
                    format="dd/MM/yyyy"
                    value={selectedFromDate}
                    onChange={handleFromDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    margin="normal"
                    className={classes.filterElements}
                    id="date-picker-dialog-t0"
                    label="Date  Create"
                    format="dd/MM/yyyy"
                    value={selectedToDate}
                    onChange={handleToDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
