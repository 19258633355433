import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import deleteIcon from "../../../assets/images/delete-32.png";
import { Utils } from "../../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function LearningExperienceBadge({
  learnExp,
  setActiveLearningExperience,
  setLessonPlanId,
  setIsViewLessonPlanDialogOpen,
  handleViewEditLearningExperience,
  handleDeleteLearningExperience,
  weekIndex,
  slotIndex,
  dayIndex,
  leIndex,
  ...props
}) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  const location = useLocation();

  return (
    <div
      style={{
        border: "none",
        color: "white",
        padding: "6px",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        margin: "4px 2px",
        backgroundColor: Utils.getColorForGrade(
          location.pathname === "/timetable",
          learnExp.grade,
          learnExp.lesson,
          learnExp.type
        ),
        fontSize: "0.7rem",
        borderRadius: "8px",
        minWidth: "96px",
      }}
      onClick={(event) => {
        if (location.pathname === "/timetable") {
          setActiveLearningExperience(learnExp);
          setLessonPlanId(learnExp.lesson);
          setIsViewLessonPlanDialogOpen(true);
        } else {
          handleViewEditLearningExperience(
            event,
            (() => {
              return {
                weekIndex: weekIndex,
                slotIndex: slotIndex,
                dayIndex: dayIndex,
                leIndex: leIndex,
              };
            })()
          );
        }
      }}
    >
      <div>
        <div style={{ position: "relative" }}>
          <span>
            {(() => {
              let temp = "";
              if (learnExp.type === "other") {
                temp = temp + learnExp.title;
              } else {
                // to keep chips short
                // temp = temp + learnExp.domainArea.shortName;
              }
              if (learnExp.teacher && learnExp.title) {
                temp =
                  temp +
                  ", " +
                  learnExp.teacher.firstName.substring(0, 3) +
                  learnExp.teacher.lastName.substring(0, 3);
              } else if (learnExp.teacher) {
                temp =
                  temp +
                  learnExp.teacher.firstName.substring(0, 3) +
                  learnExp.teacher.lastName.substring(0, 3);
              }

              if (learnExp.location) {
                temp = temp + ", " + learnExp.location.toUpperCase();
              }

              return temp;
            })()}
          </span>
          {location.pathname === "/manageTimeTable" && (
            <img
              src={deleteIcon}
              width="12px"
              alt="Delete"
              style={{ float: "right", position: "relative", top: "1px" }}
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteLearningExperience(
                  event,
                  (() => {
                    return {
                      type: "chip",
                      weekIndex: weekIndex,
                      slotIndex: slotIndex,
                      dayIndex: dayIndex,
                      leIndex: leIndex,
                    };
                  })()
                );
              }}
            ></img>
          )}
        </div>
        <div>
          {" "}
          {(() => {
            let temp = "";
            if (!learnExp.isForAllGrades) {
              temp = temp + "G" + learnExp.grade;
            }
            if (!learnExp.isForAllGrades && learnExp.group != "") {
              temp = temp + ", " + learnExp.group;
            }
            if (learnExp.domainArea) {
              // console.log("da", learnExp.domainArea);
              temp = temp + ", " + learnExp.domainArea.shortName;
            }

            return temp;
          })()}
        </div>
      </div>
    </div>
  );
}

export default LearningExperienceBadge;
