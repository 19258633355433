import React from "react";
import { Box, Grid, Button, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import lotus from "../../assets/lotus.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#3fc1d5",
    height: "100%",
  },
  gridRoot: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(20, 0, 6),
    // backgroundImage: 'url("https://wallup.net/wp-content/uploads/2017/11/17/228112-blurred-mountain-sun_rays.jpg")',
    // backgroundSize: "cover"
  },
  appBar: {
    backgroundColor: "#fff",
    boxShadow: "none",
    color: "#000",
  },
  rightToolBar: {
    marginLeft: "auto",
  },
  left: {
    width: "80%",
    margin: "auto",
  },
  right: {
    width: "80%",
    margin: "auto",
  },
  title: {
    flexGrow: 1,
    display: "inline",
    marginRight: theme.spacing(2),
    color: "#a52323",
    //fontFamily: "Sacramento, cursive",
    fontFamily: "Playfair Display, serif",
  },
  title2: {
    marginLeft: theme.spacing(2),
    color: "#000",
    //fontFamily: "Sacramento, cursive",
    //fontFamily: "Playfair Display, serif",
  },
  footer: {
    marginTop: "30px",
    textAlign: "center",
    //borderTop: "1px solid #e0e0e0",
    fontFamily: "Playfair Display, serif",
  },
}));

function Landing() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h5" className={classes.title}>
            {/* རྒྱལ་འཛིན་སློབ་རིག་སྤེལ་ཁང་། */}
            Druk Gyalpo's Institute - Bhutan Baccalaureate
          </Typography>
          <section className={classes.rightToolBar}>
            {/* <Link to={"/login"} style={{ textDecoration: "none" }}>
              <Button color="primary" variant="default">
                About
              </Button>
            </Link> */}
            <Link to={"/login"} style={{ textDecoration: "none" }}>
              <Button color="primary" variant="contained">
                Login
              </Button>
            </Link>
          </section>
        </Toolbar>
      </AppBar>
      <Grid container className={classes.gridRoot} spacing={0}>
        <Grid item xs={12} sm={12} md={6}>
          <Box className={classes.left}>
            <Typography
              variant="h2"
              style={{ color: "#ffe45b", fontFamily: "Sacramento, cursive" }}
            >
              The Bhutan Baccalaureate Learning Process
            </Typography>
            <br />
            <br />
            <Typography variant="body1" style={{ textAlign: "justify" }}>
              The Bhutan Baccalaureate strives to create an environment for
              learners to thrive within and beyond school. The Learning Process
              in the Five Areas of Development helps all learners, including
              students and faculty, to develop continuously and wholistically,
              so that the school and its wider community evolve toward a more
              just and harmonious society.
              <br />
              <br />
              The Learning Process in the Five Areas of Development creates
              opportunities for all learners to develop and showcase their inner
              treasures. The school and wider community becomes a safety net in
              which learners become adventurous and take risks. Learners
              continuously actualize their innate potential to become
              constructive contributory citizens of their communities.
            </Typography>
            <br />
            <br />
            {/* <Button
              color="primary"
              variant="contained"
              size="large"
              type="submit"
            >
              Login
            </Button> */}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={classes.right}>
            <img src={lotus} width="100%" alt="Road To Knowledge"></img>
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.gridRoot}>
        <Grid item xs={12}>
          <Box className={classes.footer}>
            <Typography
              variant="caption"
              display="block"
              style={{ color: "#fff" }}
              gutterBottom
            >
              &#169; {new Date().getFullYear()}
            </Typography>
            <Typography
              variant="h6"
              display="block"
              style={{ fontFamily: "Playfair Display, serif ", color: "#fff" }}
              gutterBottom
            >
              The Royal Academy
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Landing;
