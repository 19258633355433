import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import { Factory } from "../../../helpers/factories";

import { AffiliationService } from "../../../services/affiliation";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  input: {
    display: "none",
  },
}));

function AddEditAffiliationDialog({
  toastOptions,
  setToastOptions,
  handleCloseToast,
  opType,
  setOpType,
  affiliation,
  setAffiliation,
  selectedRowIndex,
  affiliations,
  setAffiliations,
  isAddEditAffiliationDialogOpen,
  handleCloseAddEditAffiliationDialog,
  ...props
}) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  //to hold form values,
  // we could use affiliation prop directly but slows down render
  const [dialogForm, setDialogForm] = React.useState(
    Factory.createAffiliation()
  );

  const [affiliationDocument, setAffiliationDocument] = React.useState(null);
  const [isFilePicked, setIsFilePicked] = React.useState(false);
  const [fileName, setFileName] = React.useState("");

  //for editing - loads current roadmap node selected
  useEffect(() => {
    setDialogForm(affiliation);
  }, [affiliation]);

  //check if enable save button
  function isFormValid() {
    return (
      dialogForm.schoolName &&
      dialogForm.schoolType &&
      dialogForm.country &&
      dialogForm.status
    );
  }

  function handleInputChange(e) {
    setDialogForm({ ...dialogForm, [e.target.name]: e.target.value });
  }

  const handleSetAffiliationDocument = (event) => {
    const size = (event.target.files[0].size / 1024 / 1024).toFixed(2);
    if (size > 2) {
      alert("File must not bigger than 2 MB");
      return;
    }
    setIsFilePicked(true);
    setFileName(event.target.files[0].name);
    setAffiliationDocument(event.target.files[0]);
  };

  async function handleSaveAffiliation() {
    //save form data, don't send status and approved fields, updates handled separately
    let data = {
      schoolName: dialogForm.schoolName,
      schoolType: dialogForm.schoolType,
      country: dialogForm.country,
      status: dialogForm.status,
      affiliationDocument: affiliationDocument,
    };

    //upload form
    const formData = new FormData();
    // formData.append("schoolName", "kin");
    formData.append("schoolName", dialogForm.schoolName);
    formData.append("schoolType", dialogForm.schoolType);
    formData.append("country", dialogForm.country);
    formData.append("status", dialogForm.status);
    formData.append("file", affiliationDocument);
    formData.append("createdBy", currentUser.id);

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      //check save or edit

      if (opType === "add") {
        const savedAffiliation = await AffiliationService.addAffiliation(
          formData
        );
        //add announcement to announcements to update table
        let temp = [...affiliations];
        temp.unshift(savedAffiliation);
        setAffiliations(temp);

        setIsFilePicked(false);
        setFileName(null);
        setAffiliationDocument(null);

        handleCloseAddEditAffiliationDialog();

        // setAffiliation(savedAffiliation);
        // setOpType("edit");
      } else {
        const updatedAffiliation =
          await AffiliationService.updateAffiliationById(
            affiliation._id,
            formData
          );

        //update roadmap node for dispaly
        setAffiliation({ ...updatedAffiliation });

        //update roadmapnode in roadmapnodes
        let temp = [...affiliations];
        temp[selectedRowIndex] = updatedAffiliation;
        setAffiliations(temp);

        setIsFilePicked(false);
        setFileName(null);
        setAffiliationDocument(null);

        handleCloseAddEditAffiliationDialog();
      }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Affiliation Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  }

  return (
    <Fragment>
      <Dialog
        open={isAddEditAffiliationDialogOpen}
        onClose={handleCloseAddEditAffiliationDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          {opType === "edit" ? "Edit Affiliation" : "Add Affiliation"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <TextField
                  variant="outlined"
                  id="schoolName"
                  name="schoolName"
                  label="School Name"
                  type="text"
                  value={dialogForm.schoolName}
                  onChange={handleInputChange}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <TextField
                  variant="outlined"
                  id="country"
                  name="country"
                  label="Country"
                  type="text"
                  value={dialogForm.country}
                  onChange={handleInputChange}
                  fullWidth
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <InputLabel id="unit-select-status">School Type</InputLabel>
                <Select
                  labelId="unit-select-schoolType"
                  id="unit-select-schoolType"
                  size="small"
                  value={dialogForm.schoolType}
                  name="schoolType"
                  onChange={handleInputChange}
                  label="School Type"
                >
                  <MenuItem value={"Private"}>Private</MenuItem>
                  <MenuItem value={"Public"}>Public</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <InputLabel id="unit-select-status">Status</InputLabel>
                <Select
                  labelId="unit-select-status"
                  id="unit-select-status"
                  size="small"
                  value={dialogForm.status}
                  name="status"
                  onChange={handleInputChange}
                  label="Status"
                >
                  <MenuItem value={"Under Review"}>Under Review</MenuItem>
                  <MenuItem value={"Rejected"}>Rejected</MenuItem>
                  <MenuItem value={"Returned for Change"}>
                    Returned for Change
                  </MenuItem>
                  <MenuItem value={"Accepted"}>Accepted</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <input
                accept=".pdf"
                onChange={(e) => {
                  handleSetAffiliationDocument(e);
                }}
                onClick={(e) => {
                  setIsFilePicked(false);
                  setFileName("No file selected");
                  setAffiliationDocument(null);
                  e.target.value = null;
                }}
                className={classes.input}
                id="contained-button-file"
                type="file"
                name="affiliationDocument"
                enctype="multipart/form-data"
                val={affiliationDocument}
              />
              <label htmlFor="contained-button-file">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                >
                  Upload Document (.pdf)
                </Button>
              </label>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <div style={{ fontStyle: "italic" }}>
                  If a document was previously uploaded, it will be replaced by
                  the newer one.
                </div>
                <div>File: {fileName}</div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddEditAffiliationDialog} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={() => {
              handleSaveAffiliation(false);
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AddEditAffiliationDialog;
