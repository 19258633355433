import React, { useEffect, useState } from "react";
import SearchUserAdvanced from "../../helperComponents/searchUserAdvanced";
import Grid from "@material-ui/core/Grid";

export default function UsersFilter({
  user,
  setUser,
  role,
  opType,
  setOpType,
  setIsDialogOpen,
  ...props
}) {
  const [userQueryOptions, setUserQueryOptions] = React.useState({});

  useEffect(() => {
    //open dialog if user selected
    if (user._id !== "") {
      setOpType("edit");
      setIsDialogOpen(true);
    }
  }, [user]);

  //return comp
  return (
    <Grid container justify="space-between" spacing={3}>
      <Grid item xs={12} sm={12}>
        <SearchUserAdvanced
          user={user}
          setUser={setUser}
          userQueryOptions={userQueryOptions}
          setUserQueryOptions={setUserQueryOptions}
          style={{ display: "flex" }}
        />
      </Grid>
    </Grid>
  );
}
