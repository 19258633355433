import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import Skeleton from "@material-ui/lab/Skeleton";

// const data = [
//   {
//     name: "2022-01-01",
//     mainlyPositive: 40,
//     mainlyNegative: 24,
//     mixed: 24,
//   },
//   {
//     name: "2022-02-01",
//     mainlyPositive: 30,
//     mainlyNegative: 13,
//   },
//   {
//     name: "2022-03-01",
//     mainlyPositive: 20,
//     mainlyNegative: 45,
//     mixed: 22,
//   },
//   {
//     name: "2022-04-01",
//     mainlyPositive: 39,
//     mainlyNegative: 12,
//     mixed: 20,
//   },
//   {
//     name: "2022-05-01",
//     mainlyPositive: 18,
//     mainlyNegative: 48,
//     mixed: 21,
//   },
//   {
//     name: "2022-06-01",
//     mainlyPositive: 23,
//     mainlyNegative: 38,
//     mixed: 25,
//   },
//   {
//     name: "2022-07-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2022-08-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2022-09-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2022-10-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2022-11-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2022-12-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2022-02-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-03-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-04-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-05-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-06-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-07-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-08-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-09-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-10-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-11-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-12-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-12-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-12-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-12-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-12-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-12-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
//   {
//     name: "2023-12-01",
//     mainlyPositive: 34,
//     mainlyNegative: 43,
//     mixed: 21,
//   },
// ];

export default function ChartAssessment({ data }) {
  return (
    <ResponsiveContainer width="100%" height={300}>
      {data.length > 0 ? (
        <BarChart
          width={500}
          height={200}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={10}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="needs work" stackId="a" fill="#ff0000" />
          <Bar dataKey="mixed" stackId="a" fill="#FFBB28" />
          <Bar dataKey="mainly positive" stackId="a" fill="#00C49F" />
        </BarChart>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton variant="rect" width="80%" height={250} />
        </div>
      )}
    </ResponsiveContainer>
  );
}
