import React from "react";

import moment from "moment";

import { useAppContext } from "../../../libs/contextLib";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { Fragment } from "react";

import SearchMentees from "../../helperComponents/searchMentees";
import { ReportService } from "../../../services/report";

import SearchUserAdvanced from "../../helperComponents/searchUserAdvanced";

import { Factory } from "../../../helpers/factories";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { Utils } from "../../../helpers/utils";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import ChooseDomainDialog from "./chooseDomainDialog";
import DeleteDomainReportDialog from "./deleteDomainReportDialog";

import Toast from "../../helperComponents/toast";
import Slide from "@material-ui/core/Slide";

import { makeStyles } from "@material-ui/core/styles";

//placeholder image
import placeHolderImageURL from "../../../assets/images/placeHolderImage.png";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  formImage: {
    margin: "16px",
    padding: "20px",
    height: "200px",
    objectFit: "contain",
    border: "1px solid #eaeff2",
  },
  formReports: {
    marginBottom: "16px",
  },
  paper: {
    padding: "20px",
    marginBottom: "40px",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReportDialog({
  activeReport,
  setActiveReport,
  reports,
  setReports,
  isDialogOpen,
  handleCloseDialog,
  selectedRowIndex,
  setSelectedRowIndex,
  opType,
  setOpType,
  ...props
}) {
  const classes = useStyles();

  const { currentUser } = useAppContext();

  //report state - there is an activeReport state but this one is to deal with rendering issues
  const [report, setReport] = React.useState(Factory.createReport());

  const [userQueryOptions, setUserQueryOptions] = React.useState({});

  //childreports
  const [childReports, setChildReports] = React.useState({
    fiveAreasReports: {},
    domainReports: {},
    mentorReport: {},
  });

  //chosen domain area state for individual domain reports
  const [domainArea, setDomainArea] = React.useState(null);

  //for domain filter - area or domain
  const [childReportType, setChildReportType] = React.useState("");

  //child report to be deleted
  const [childReportIdToBeDeleted, setChildRecordIdToBeDeleted] =
    React.useState("");
  const [childReportTypeToBeDeleted, setChildRecordTypeToBeDeleted] =
    React.useState("");
  const [
    childReportDomainAreaToBeDeleted,
    setChildRecordDomainAreaToBeDeleted,
  ] = React.useState("");

  //student
  const [user, setUser] = React.useState(null);

  //report states
  const [yearRange, setYearRange] = React.useState(
    Utils.getYearRange(
      parseInt(moment().subtract(1, "years").format("YYYY")),
      parseInt(moment().format("YYYY"))
    )
  );

  //choose domain dialog
  const [isOpenChooseDomainDialog, setIsOpenChooseDomainDialog] =
    React.useState(false);

  //delete domain report dialog

  const [isOpenDeleteReportDialog, setIsOpenDeleteReportDialog] =
    React.useState(false);

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 2000,
  });

  /*
   * Helpers
   */

  //usestate for updatind mentee id in report
  React.useEffect(() => {
    if (user != null) {
      //add mentee,mentor, report type, report year to report state
      setReport({
        ...report,
        mentee: user,
      });
    }
  }, [user]);

  React.useEffect(() => {
    if (opType === "edit") {
      setReport(activeReport);
    } else if (opType === "add") {
      setReport(Factory.createReport);
    }
  }, [activeReport]);

  //get child reports
  React.useEffect(() => {
    if (opType === "edit") {
      setChildReports({
        fiveAreasReports: {},
        domainReports: {},
        mentorReport: {},
      });
      getChildReports();
    }
  }, [activeReport]);

  //form is valid for saving

  function isFormValid() {
    return report && report.mentee && report.reportType && report.reportYear;
  }

  //check if domain area already added
  const domainAreaAlreadyAdded = () => {
    if (
      domainArea.name in childReports.fiveAreasReports ||
      domainArea.name in childReports.domainReports
    ) {
      return true;
    }
    return false;
  };

  //sort object by keys
  function sortObject(unordered) {
    let ordered = Object.keys(unordered)
      .sort()
      .reduce((obj, key) => {
        obj[key] = unordered[key];
        return obj;
      }, {});
    return ordered;
  }

  //check if field is editable when opType is edit; only mentor can edit all fields
  function fieldDisabled() {
    //if opType is add; then all fields are editable as mentor is starting a new report
    if (opType === "add") {
      return false;
    }
    //if opType is edit; only mentor can edit some fields
    if (
      opType === "edit" &&
      activeReport.mentor &&
      activeReport.mentor._id === currentUser.id
    ) {
      return false;
    } else {
      return true;
    }
  }

  /*
   * Get child reports for this report: domain, five areas, mentor
   */

  async function getChildReports() {
    try {
      const tempReports = await ReportService.getChildReportsByOrgIdAndReportId(
        currentUser.organisation.id,
        activeReport._id
      );

      //process reports
      let temp = {
        fiveAreasReports: {},
        domainReports: {},
        mentorReport: {},
      };
      tempReports.forEach((item) => {
        switch (item.type) {
          case "domain":
            temp["domainReports"][item.domainArea.name] = item;
            break;
          case "fiveAreas":
            temp["fiveAreasReports"][item.domainArea.name] = item;
            break;
          default:
            temp["mentorReport"] = item;
        }
      });
      //order child reports
      let orderedFiveAreaReports = sortObject(temp["fiveAreasReports"]);
      let orderedDomainReports = sortObject(temp["domainReports"]);
      temp["fiveAreasReports"] = orderedFiveAreaReports;
      temp["domainReports"] = orderedDomainReports;
      //set child reports
      setChildReports(temp);
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error getting child reports",
      });
    }
  }

  /*
   * Handlers
   */

  const handleStudentImageUpdate = (e) => {
    //check if url is from gdrive
    var url = e.target.value;
    if (url.startsWith("https://drive.google.com")) {
      url = Utils.rewriteGDriveImageURL(url);
    }
    setReport({ ...report, studentImageURL: url });
  };

  const handleStudentRelectionImageUpdate = (e) => {
    var url = e.target.value;
    if (url.startsWith("https://drive.google.com")) {
      url = Utils.rewriteGDriveImageURL(url);
    }
    setReport({ ...report, studentReflectionImageURL: url });
  };

  const handleReportChange = (e) => {
    switch (e.target.name) {
      case "Cerebral":
      case "Emotional":
      case "Social":
      case "Spiritual":
      case "Physical":
        let temp = { ...childReports.fiveAreasReports };
        temp[e.target.name].comment = e.target.value;
        temp[e.target.name].type = "fiveAreas";
        temp[e.target.name].lastEditedBy = {
          _id: currentUser.id,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
        };
        temp[e.target.name].timeLastEdited = moment().format(
          "DD-MM-YYYY HH:mm:ss"
        );
        setChildReports({ ...childReports, fiveAreasReports: temp });
        break;
      case "mentorReport":
        let temp2 = { ...childReports.mentorReport };
        temp2.comment = e.target.value;
        temp2.type = "mentor";
        temp2.lastEditedBy = currentUser.id;
        temp2.timeLastEdited = moment().format("DD-MM-YYYY HH:mm:ss");
        setChildReports({ ...childReports, mentorReport: temp2 });
        break;
      default:
        let temp3 = { ...childReports.domainReports };
        temp3[e.target.name].comment = e.target.value;
        temp3[e.target.name].type = "domain";
        temp3[e.target.name].lastEditedBy = {
          _id: currentUser.id,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
        };
        temp3[e.target.name].timeLastEdited = moment().format(
          "DD-MM-YYYY HH:mm:ss"
        );
        setChildReports({ ...childReports, domainReports: temp3 });
    }
  };

  //choose domain dialog component
  const handleAddDomainAreaReport = () => {
    setIsOpenChooseDomainDialog(true);
  };

  const handleCloseAddDomainAreaReport = () => {
    setIsOpenChooseDomainDialog(false);
  };

  const handleDeleteChildReport = (childReportId, domainArea, type) => {
    setChildRecordIdToBeDeleted(childReportId);
    setChildRecordTypeToBeDeleted(type);
    setChildRecordDomainAreaToBeDeleted(domainArea);
    setIsOpenDeleteReportDialog(true);
  };

  const handleCloseDeleteDomainReport = () => {
    setIsOpenDeleteReportDialog(false);
  };

  const handleCompleteAddDomainAreaReport = () => {
    if (domainAreaAlreadyAdded()) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Area/Domain has already been added.",
      });
    } else {
      switch (domainArea.name) {
        case "Cerebral":
        case "Emotional":
        case "Social":
        case "Spiritual":
        case "Physical":
          let temp = childReports.fiveAreasReports;
          temp[domainArea.name] = {
            domainArea: domainArea,
            comment: "",
            createdBy: currentUser.id,
          };
          setChildReports({ ...childReports, fiveAreasReports: temp });
          break;
        default:
          let temp2 = childReports.domainReports;
          temp2[domainArea.name] = {
            domainArea: domainArea,
            comment: "",
            createdBy: currentUser.id,
          };
          setChildReports({ ...childReports, domainReports: temp2 });
          break;
      }
    }
    setIsOpenChooseDomainDialog(false);
  };

  const handleCompleteDeleteDomainReport = async () => {
    setIsOpenDeleteReportDialog(false);

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Deleted",
      });

      await ReportService.deleteChildReportByOrgIdAndReportIdAndChildReportId(
        currentUser.organisation.id,
        activeReport._id,
        childReportIdToBeDeleted
      );

      //remove deleted child report from child reports
      let temp = { ...childReports };
      if (childReportTypeToBeDeleted === "mentorReport") {
        temp["mentorReport"] = {};
      } else {
        delete temp[childReportTypeToBeDeleted][
          childReportDomainAreaToBeDeleted
        ];
      }
      setChildReports(temp);

      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Deleted",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error Deleting Child Report",
      });
    }
  };

  //saves only the particular domain's report; to prevent overwrites when the app is open in multiple browsers
  const handleSaveChildReport = async (
    childReportType,
    domainAreaName = ""
  ) => {
    let isNew = true;

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      //prepare child report
      let temp = {};
      switch (childReportType) {
        case "domain":
          temp = { ...childReports["domainReports"][domainAreaName] };
          //remove domain area details
          temp["domainArea"] = temp["domainArea"]["_id"];
          temp["lastEditedBy"] = temp["lastEditedBy"]["_id"];
          isNew = childReports["domainReports"][domainAreaName]["report"]
            ? false
            : true;
          break;
        case "fiveAreas":
          temp = { ...childReports["fiveAreasReports"][domainAreaName] };
          //remove domain area details
          temp["domainArea"] = temp["domainArea"]["_id"];
          temp["lastEditedBy"] = temp["lastEditedBy"]["_id"];
          isNew = childReports["fiveAreasReports"][domainAreaName]["report"]
            ? false
            : true;
          break;
        default:
          temp = { ...childReports["mentorReport"] };
          isNew = childReports["mentorReport"]["report"] ? false : true;
      }

      //add or update child report depening on whether it is new
      if (isNew) {
        //add created by field
        temp["createdBy"] = currentUser.id;
        const savedChildReport =
          await ReportService.addChildReportByOrgIdAndReportId(
            currentUser.organisation.id,
            activeReport._id,
            temp
          );
        //add report field so it is now an update
        let childReportsCopy = { ...childReports };
        if (childReportType === "domain") {
          childReportsCopy["domainReports"][domainAreaName] = savedChildReport;
        } else if (childReportType === "fiveAreas") {
          childReportsCopy["fiveAreasReports"][domainAreaName] =
            savedChildReport;
        } else {
          childReportsCopy["mentorReport"] = savedChildReport;
        }
        setChildReports(childReportsCopy);
      } else {
        await ReportService.updateChildReportByOrgIdAndReportIdAndChildReportId(
          currentUser.organisation.id,
          activeReport._id,
          temp._id,
          temp
        );
      }

      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message:
          "Error saving report. Make sure child report doesn't exist already by reloading the page or report is not locked",
      });
    }
  };

  /*
   * Add/Update Report
   */

  const handleSaveReport = async () => {
    //prepare to save
    let temp = { ...report };

    //make sure only mentor/mentee has only id, api returns other information
    temp["mentee"] = report.mentee._id;

    //check whether it's a update or a new addition
    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      //check save or edit

      if (opType === "add") {
        //add mentor and organsation only if report type is new
        temp["mentor"] = currentUser.id;
        temp["organisation"] = currentUser.organisation.id;

        const savedReport = await ReportService.addReportByOrgId(
          currentUser.organisation.id,
          temp
        );

        //add report to reports to update table

        setActiveReport(savedReport);
        let updatedReportList = [...reports];
        updatedReportList.unshift(savedReport);
        setReports(updatedReportList);
        setSelectedRowIndex(0);
        handleCloseDialog();
        // setOpType("edit");
      } else {
        //edit report in reports to update table
        temp["mentor"] = temp.mentor._id; //make sure only mentor id is sent
        temp["organisation"] = temp.organisation._id;

        const updatedReport =
          await ReportService.updateReportsByOrgIdAndReportId(
            currentUser.organisation.id,
            activeReport._id,
            temp
          );
        let updatedReportList = [...reports];
        updatedReport["id"] = selectedRowIndex;
        updatedReportList[selectedRowIndex] = updatedReport;
        setReports(updatedReportList);
      }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Report Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error saving report",
      });
    }
  };

  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  //return component

  return (
    <Dialog
      fullScreen
      open={isDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      {/* toast for messages */}
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />
      {/* add domain report dialog */}
      <ChooseDomainDialog
        currentUser={currentUser}
        isOpenChooseDomainDialog={isOpenChooseDomainDialog}
        setIsOpenChooseDomainDialog={setIsOpenChooseDomainDialog}
        handleCloseAddDomainAreaReport={handleCloseAddDomainAreaReport}
        domainArea={domainArea}
        setDomainArea={setDomainArea}
        handleCompleteAddDomainAreaReport={handleCompleteAddDomainAreaReport}
        type={childReportType}
      />
      {/* delete domain report dialog */}
      <DeleteDomainReportDialog
        isOpenDeleteReportDialog={isOpenDeleteReportDialog}
        setIsOpenDeleteReportDialog={setIsOpenDeleteReportDialog}
        handleCompleteDeleteDomainReport={handleCompleteDeleteDomainReport}
        handleCloseDeleteDomainReport={handleCloseDeleteDomainReport}
      />
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {opType === "add" ? "Create Report" : "Edit Report"}
          </Typography>
        </Toolbar>
      </AppBar>

      {/* student/report details */}
      <div
        style={{
          height: "100vh",
          backgroundColor: "#eaeff1",
        }}
      >
        <Grid
          container
          spacing={3}
          style={{
            justifyContent: "center",
            backgroundColor: "#eaeff1",
          }}
        >
          <Grid item xs={8} sm={10}>
            <Paper className={classes.paper} style={{ marginTop: "20px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Report Details
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SearchUserAdvanced
                    label={"Select mentee"}
                    user={activeReport.mentee}
                    setUser={setUser}
                    userQueryOptions={userQueryOptions}
                    setUserQueryOptions={setUserQueryOptions}
                    style={{ display: "flex" }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    style={{ width: "90%" }}
                    disabled={fieldDisabled()}
                  >
                    <InputLabel id="type-select-label">Type</InputLabel>
                    <Select
                      labelId="type-select-label"
                      id="type-select-report-type"
                      size="small"
                      value={report.reportType}
                      onChange={(e) => {
                        setReport({ ...report, reportType: e.target.value });
                      }}
                      label="Report Type"
                    >
                      <MenuItem value={"Midterm"}>Midterm </MenuItem>
                      <MenuItem value={"End of Year"}>End of Year</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    style={{ width: "90%" }}
                    disabled={fieldDisabled()}
                  >
                    <InputLabel id="reportYear-select-label">Year</InputLabel>
                    <Select
                      labelId="reportYear-select-label"
                      id="reportYear-select-report"
                      size="small"
                      value={report.reportYear}
                      onChange={(e) => {
                        setReport({ ...report, reportYear: e.target.value });
                      }}
                      label="Year"
                    >
                      {yearRange.map((year) => {
                        return (
                          <MenuItem value={year} key={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    style={{ width: "90%" }}
                    disabled={fieldDisabled()}
                  >
                    <InputLabel id="status-select-label">Status</InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="status-select-report"
                      size="small"
                      value={report.status}
                      onChange={(e) => {
                        setReport({ ...report, status: e.target.value });
                      }}
                      label="Status"
                    >
                      <MenuItem value={"locked"} key={"locked"}>
                        Locked
                      </MenuItem>
                      <MenuItem value={"unlocked"} key={"unlocked"}>
                        Unlocked
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    style={{ width: "90%" }}
                  >
                    <TextField
                      margin="dense"
                      id="studentImageURL"
                      name="studentImageURL"
                      label="Student Image"
                      type="text"
                      value={report.studentImageURL}
                      onChange={handleStudentImageUpdate}
                      disabled={fieldDisabled()}
                      fullWidth
                    />
                    <img
                      src={
                        report.studentImageURL
                          ? report.studentImageURL
                          : placeHolderImageURL
                      }
                      className={classes.formImage}
                    ></img>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    style={{ width: "90%" }}
                  >
                    <TextField
                      margin="dense"
                      id="studentReflectionImageURL"
                      name="studentReflectionImageURL"
                      label="Student Reflection Image"
                      type="text"
                      value={report.studentReflectionImageURL}
                      onChange={handleStudentRelectionImageUpdate}
                      disabled={fieldDisabled()}
                      fullWidth
                    />
                    <img
                      src={
                        report.studentReflectionImageURL
                          ? report.studentReflectionImageURL
                          : placeHolderImageURL
                      }
                      className={classes.formImage}
                    ></img>
                  </FormControl>
                </Grid>
              </Grid>
              {/* save close */}
              {opType === "edit" &&
              activeReport.mentor &&
              currentUser.id === activeReport.mentor._id ? (
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "40px",
                    }}
                  >
                    <div style={{ padding: "10px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!isFormValid()}
                        onClick={handleSaveReport}
                      >
                        Update
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              {/* end save close */}
            </Paper>

            {/* FIVE AREAS REPORT AUTO */}
            {opType === "edit" ? (
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Five Areas of Development
                    </Typography>
                  </Grid>
                </Grid>
                {Object.values(childReports.fiveAreasReports).length > 0 ? (
                  <Fragment>
                    <Grid container spacing={3}>
                      {Object.values(childReports.fiveAreasReports).map(
                        (rep) => {
                          return (
                            <Grid item xs={12} key={rep.domainArea.name}>
                              <FormControl style={{ width: "100%" }}>
                                <TextField
                                  multiline
                                  className={classes.formReports}
                                  rows={10}
                                  margin="dense"
                                  id={rep.domainArea.name}
                                  name={rep.domainArea.name}
                                  label={rep.domainArea.displayName + " Report"}
                                  type="text"
                                  variant="filled"
                                  disabled={fieldDisabled()}
                                  value={
                                    childReports.fiveAreasReports[
                                      rep.domainArea.name
                                    ].comment || ""
                                  }
                                  onChange={handleReportChange}
                                  fullWidth
                                />
                              </FormControl>
                              {activeReport.mentor &&
                              currentUser.id === activeReport.mentor._id ? (
                                <div style={{ textAlign: "right" }}>
                                  {/* <Typography variant="caption" gutterBottom>
                            {domain.lastEditedBy
                              ? "Last Edited On " +
                                domain.timeLastEdited +
                                " by " +
                                domain.lastEditedBy.name
                              : ""}
                          </Typography> */}
                                  <Tooltip
                                    title={`Save ${rep.domainArea.displayName} Report`}
                                  >
                                    <IconButton
                                      aria-label="Save Domain Report"
                                      color="primary"
                                      onClick={() => {
                                        handleSaveChildReport(
                                          "fiveAreas",
                                          rep.domainArea.name
                                        );
                                      }}
                                      //   onClick={handleDeleteChildReport}
                                    >
                                      <SaveIcon fontSize="medium" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title={`Delete ${rep.domainArea.displayName} Report`}
                                  >
                                    <IconButton
                                      aria-label="Delete Report"
                                      color="primary"
                                      onClick={() => {
                                        handleDeleteChildReport(
                                          rep._id,
                                          rep.domainArea.name,
                                          "fiveAreasReports"
                                        );
                                      }}
                                      //   onClick={handleDeleteChildReport}
                                    >
                                      <DeleteIcon fontSize="medium" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              ) : null}
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </Fragment>
                ) : (
                  <Grid container spacing={3}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="subtitle1" gutterBottom>
                        There are no reports
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {activeReport.mentor &&
                currentUser.id === activeReport.mentor._id ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Tooltip title="Add Five Areas Report">
                      <IconButton
                        aria-label="Add Five Areas Report"
                        color="primary"
                        // disabled={fieldDisabled()}
                        onClick={() => {
                          setChildReportType("area");
                          return handleAddDomainAreaReport();
                        }}
                      >
                        <AddCircleOutlineIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null}
              </Paper>
            ) : null}

            {/* DOMAIN REPORT */}
            {opType === "edit" ? (
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Domain Reports
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  {Object.values(childReports.domainReports).length > 0 ? (
                    <Fragment>
                      {Object.values(childReports.domainReports).map((rep) => {
                        return (
                          <Grid item xs={12} key={rep.domainArea.name}>
                            <FormControl style={{ width: "100%" }}>
                              <TextField
                                multiline
                                className={classes.formReports}
                                // inputProps={{
                                //   style: { fontFamily: "Jomolhari, serif" },
                                // }}
                                inputProps={
                                  rep.domainArea &&
                                  rep.domainArea.name === "dzongkha"
                                    ? {
                                        style: {
                                          fontFamily: "Jomolhari, serif",
                                          fontSize: "20px",
                                          lineHeight: "1.4",
                                        },
                                      }
                                    : {
                                        style: null,
                                      }
                                }
                                rows={10}
                                margin="dense"
                                id={rep.domainArea.name}
                                name={rep.domainArea.name}
                                label={rep.domainArea.displayName + " Report"}
                                type="text"
                                variant="filled"
                                disabled={
                                  !(
                                    activeReport.mentor._id ===
                                      currentUser.id ||
                                    (activeReport.status === "unlocked" &&
                                      rep.createdBy === currentUser.id)
                                  )
                                }
                                // disabled={(() => {
                                //   return activeReport.status === "locked";
                                // })()}
                                value={
                                  childReports.domainReports[
                                    rep.domainArea.name
                                  ].comment
                                }
                                onChange={handleReportChange}
                                fullWidth
                              />
                            </FormControl>
                            <div style={{ textAlign: "right" }}>
                              <Typography variant="caption" gutterBottom>
                                {rep.lastEditedBy
                                  ? "Last Edited On " +
                                    moment(rep.lastEditedOn).format(
                                      "dddd, MMMM Do YY, h:mm a"
                                    ) +
                                    " by " +
                                    rep.lastEditedBy.firstName +
                                    " " +
                                    rep.lastEditedBy.lastName
                                  : ""}
                              </Typography>
                              {activeReport.mentor._id === currentUser.id ||
                              (activeReport.status === "unlocked" &&
                                rep.createdBy === currentUser.id) ? (
                                <Fragment>
                                  <Tooltip
                                    title={`Save ${rep.domainArea.displayName} Report`}
                                  >
                                    <IconButton
                                      aria-label="Save Domain Report"
                                      color="primary"
                                      // disabled={(() => {
                                      //   return (
                                      //     report.status === "locked" &&
                                      //     currentUser.id !=
                                      //       activeReport.mentor._id
                                      //   );
                                      // })()}
                                      onClick={() => {
                                        handleSaveChildReport(
                                          "domain",
                                          rep.domainArea.name
                                        );
                                      }}
                                      //   onClick={handleDeleteChildReport}
                                    >
                                      <SaveIcon fontSize="medium" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title={`Delete ${rep.domainArea.displayName} Report`}
                                  >
                                    <IconButton
                                      aria-label="Delete Report"
                                      color="primary"
                                      // disabled={(() => {
                                      //   return (
                                      //     !rep.report ||
                                      //     (report.status === "locked" &&
                                      //       currentUser.id !=
                                      //         activeReport.mentor._id)
                                      //   );
                                      // })()}
                                      onClick={() => {
                                        handleDeleteChildReport(
                                          rep._id,
                                          rep.domainArea.name,
                                          "domainReports"
                                        );
                                      }}
                                      //   onClick={handleDeleteChildReport}
                                    >
                                      <DeleteIcon fontSize="medium" />
                                    </IconButton>
                                  </Tooltip>
                                </Fragment>
                              ) : null}
                            </div>
                          </Grid>
                        );
                      })}
                    </Fragment>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography variant="subtitle1" gutterBottom>
                          There are no reports
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {activeReport.mentor._id === currentUser.id ||
                  activeReport.status === "unlocked" ? (
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Tooltip title="Add Domain Report">
                        <IconButton
                          aria-label="Add Domain Report"
                          color="primary"
                          onClick={() => {
                            setChildReportType("domain");
                            return handleAddDomainAreaReport();
                          }}
                        >
                          <AddCircleOutlineIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ) : null}
                </Grid>
              </Paper>
            ) : null}

            {/* MENTOR REPORT */}
            {opType === "edit" ? (
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Mentor
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        multiline
                        className={classes.formReports}
                        rows={10}
                        margin="dense"
                        id="mentorReport"
                        name="mentorReport"
                        label="Mentor Report"
                        type="text"
                        variant="filled"
                        value={childReports.mentorReport.comment || ""}
                        onChange={handleReportChange}
                        fullWidth
                        disabled={currentUser.id != activeReport.mentor._id}
                      />
                    </FormControl>
                    {activeReport.mentor &&
                    currentUser.id === activeReport.mentor._id ? (
                      <div style={{ textAlign: "right" }}>
                        <Tooltip title={`Save Mentor Report`}>
                          <IconButton
                            aria-label="Save Mentor Report"
                            color="primary"
                            disabled={(() => {
                              return (
                                report.status === "locked" &&
                                currentUser.id != report.mentor._id
                              );
                            })()}
                            onClick={() => {
                              handleSaveChildReport("mentorReport");
                            }}
                            //   onClick={handleDeleteChildReport}
                          >
                            <SaveIcon fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={`Reset/Delete Mentor Report`}>
                          <IconButton
                            aria-label="Reset Mentor Report"
                            color="primary"
                            // disabled={(() => {
                            //   return (
                            //     !childReports.mentorReport.report ||
                            //     (report.status === "locked" &&
                            //       currentUser.id != report.mentor._id)
                            //   );
                            // })()}
                            onClick={() => {
                              handleDeleteChildReport(
                                childReports.mentorReport._id,
                                "",
                                "mentorReport"
                              );
                            }}
                            //   onClick={handleDeleteChildReport}
                          >
                            <ClearIcon fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </Paper>
            ) : null}

            {/* save close */}
            {opType === "add" ? (
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "40px",
                  }}
                >
                  <div style={{ padding: "10px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleCloseDialog}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={!isFormValid()}
                      onClick={handleSaveReport}
                    >
                      Create Report
                    </Button>
                  </div>
                </Grid>
              </Grid>
            ) : null}
            {/* end save close */}
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}
