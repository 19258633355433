import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import ReportPDF from "./reportPDF";
import { ReportService } from "../../../services/report";
import { Utils } from "../../../helpers/utils";

const generatePDF = async (orgId, report, imageDownloadOption) => {
  let childReports = null;
  /*
  get child reports and check if lifescience field present
  */
  try {
    const tempReports = await ReportService.getChildReportsByOrgIdAndReportId(
      orgId,
      report._id
    );

    //process reports
    let temp = {
      fiveAreasReports: {},
      domainReports: {},
      mentorReport: {},
    };
    tempReports.forEach((item) => {
      switch (item.type) {
        case "domain":
          temp["domainReports"][item.domainArea.name] = item;
          break;
        case "fiveAreas":
          temp["fiveAreasReports"][item.domainArea.name] = item;
          break;
        default:
          temp["mentorReport"] = item;
      }
    });
    //order child reports
    let orderedFiveAreaReports = Utils.sortObject(temp["fiveAreasReports"]);
    let orderedDomainReports = Utils.sortObject(temp["domainReports"]);
    temp["fiveAreasReports"] = orderedFiveAreaReports;
    temp["domainReports"] = orderedDomainReports;
    childReports = temp;
  } catch (err) {
    return Promise.reject(new Error("Error downloading child report"));
  }

  /*
  check if lifescience  present in childReports; if so we assume no lifescience subdomains present. owise gather domains under lifescience
  */
  let lifeScienceSubDomains = [
    "History",
    "Geography",
    "Biology",
    "Physics",
    "Chemistry",
    "Economics",
    "Media Studies",
    "Agriculture",
  ];

  let hasOnlyLifeScience = false;
  Object.keys(childReports.domainReports).map((key, index) => {
    if (key === "Life Science") {
      hasOnlyLifeScience = true;
    }
  });

  //format life science sub-domains if lifescience report not present
  let temp = {};
  // childReports["lifeScienceReports"] = temp;
  if (!hasOnlyLifeScience) {
    Object.keys(childReports.domainReports).map((key, index) => {
      if (lifeScienceSubDomains.includes(key)) {
        temp[key] = childReports.domainReports[key];
        delete childReports.domainReports[key];
      }
    });

    //create new life science property property
    childReports["domainReports"]["Life Science"] = temp;

    //order reports again
    let orderedDomainReports = Utils.sortObject(childReports["domainReports"]);
    childReports["domainReports"] = orderedDomainReports;
  }

  /*
  generate report 
  */
  try {
    const blob = await pdf(
      <ReportPDF
        report={report}
        childReports={childReports}
        hasOnlyLifeScience={hasOnlyLifeScience}
        imageDownloadOption={imageDownloadOption}
      />
    ).toBlob();
    let fileName = (() => {
      const date = Date().split(" ");
      const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
      return `${
        report.mentee.firstName + report.mentee.lastName
      }_report_${dateStr}.pdf`;
    })();
    saveAs(blob, fileName);
  } catch (err) {
    return Promise.reject(new Error("Error downloading report"));
  }
};

export default generatePDF;
