import React, { Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { Utils } from "../../../helpers/utils";
import Typography from "@material-ui/core/Typography";

function LeResourceNotesDialog({
  description,
  isLeResourceNotesDialogOpen,
  handleCloseLeResourceNotesDialog,
  ...props
}) {
  const { currentUser } = useAppContext();

  return (
    <Fragment>
      <Dialog
        open={isLeResourceNotesDialogOpen}
        onClose={handleCloseLeResourceNotesDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="form-dialog-title">{"Notes / Discussion"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* {description} */}
              {description &&
                description.split("\n").map(function (item, index) {
                  return (
                    <Typography variant="body1" key={index} gutterBottom>
                      {Utils.urlify(item)}
                      <br />
                    </Typography>
                  );
                })}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLeResourceNotesDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default LeResourceNotesDialog;
