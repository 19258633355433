import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  contentWrapper: {
    margin: "40px 16px",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
}));

function AssessmentHome(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {/* <Grid item xs={12}>
          <Typography variant="h5">
            Transitioning the Bhutan Baccalaureate
          </Typography>
        </Grid> */}
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                marginBottom: "20px",
                marginTop: "40px",
              }}
            >
              Transitioning the Bhutan Baccalaureate
            </Typography>
            <div
              style={{
                textAlign: "justify",
              }}
            >
              <p>
                This programme is a part of the process of Transitioning the
                Bhutan Baccalaureate across the country.
              </p>
              <p>
                The main focus of the programme is to help the participants to
                understand the Learning Process of the Five Areas of
                Development. The programme will develop and strengthen
                relationships amongst participants from 22 schools and faculty
                from The Royal Academy. It will also lay the foundation for the
                process of Transitioning the Bhutan Baccalaureate to their
                respective schools. The faculty for this programme includes
                teachers and students from The Royal Academy as well as eminent
                guest speakers. Designed as a blended programme with in-person
                and online learning experiences, engagement of participants will
                include using the online learning platform, discussions based on
                readings, presentations, podcasts, videos and more. Mentorship
                is the key modality for learning, and participants will have
                one-on-one conversations with their mentors in addition to group
                discussions.
              </p>
              <p>
                This programme is designed to inspire participants to embark on
                a lifelong journey of personal and professional growth in the
                Five Areas of Development. It will enable them to develop the
                Roadmap for Transitioning The Bhutan Baccalaureate systems and
                processes in their respective schools.
              </p>
              <p>
                We believe that assessment is the engine that drives learning.
                There will be regular self-assessment, peer reviews and feedback
                from the faculty and mentors for each participant to facilitate
                continuous growth and development.
              </p>
            </div>
            {/* <div
              style={{
                display: "flex",
                marginTop: "50px",
                marginBottom: "20px",
                justifyContent: "center",
              }}
            >
              <a
                href="https://theroyalacademy.learnworlds.com/course/transitioning-the-bhutan-baccalaureate"
                target="_blank"
              >
                <Button color="primary" variant="outlined" size="large">
                  Transitioning The Bhutan Baccalaureate Online Course
                </Button>
              </a>
            </div> */}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default AssessmentHome;
