import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import { Factory } from "../../../helpers/factories";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterElements: {
    margin: theme.spacing(2),
    width: "90%",
  },
}));

export default function FiveAreasRoadmapFilter({
  toastOptions,
  setToastOptions,
  queryString,
  setQueryString,
  ...props
}) {
  const classes = useStyles();

  //current user from app context
  const { currentUser } = useAppContext();
  const location = useLocation();

  //area
  const [area, setArea] = React.useState("");

  //year
  const [year, setYear] = React.useState("");

  //populating year dropdwon
  const currentYear = moment().year();
  const yearArray = Array.from(
    Array(currentYear - 2020),
    (_, i) => currentYear - i
  );

  //query
  useEffect(() => {
    //set initial query string
    setQueryString({
      area: area,
      year: year,
    });
  }, [area, year]);

  //handle  grade change
  const handleAreaChange = (e) => {
    setArea(e.target.value);
  };

  //handle  year change
  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  return (
    <Grid container justify="space-between" spacing={3}>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ backgroundColor: "white", borderRadius: "5px" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%" }}
            >
              <InputLabel id="type-description">Five Areas</InputLabel>
              <Select
                labelId="area"
                id="area"
                name="area"
                value={area}
                onChange={handleAreaChange}
                label="Five Area"
              >
                <MenuItem value={"Cerebral"}>Cerebral</MenuItem>
                <MenuItem value={"Emotional"}>Emotional</MenuItem>
                <MenuItem value={"Physical"}>Physical</MenuItem>
                <MenuItem value={"Social"}>Social</MenuItem>
                <MenuItem value={"Spiritual"}>Spiritual</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%" }}
            >
              <InputLabel id="type-description">Year</InputLabel>
              <Select
                labelId="year"
                id="year"
                name="year"
                value={year}
                onChange={handleYearChange}
                label="Year"
              >
                {yearArray.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
