import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: "90%",
  },
}));

export default function SelectRole({ role, setRole, showAllOption }) {
  const classes = useStyles();

  return (
    <FormControl
      className={classes.formControl}
      variant="outlined"
      style={{ width: "90%" }}
    >
      <InputLabel id="role-select-label">Roadmap Role</InputLabel>
      <Select
        labelId="role-select-label"
        id="role-select-role"
        size="small"
        value={role}
        onChange={(e) => {
          setRole(e.target.value);
        }}
        label="Roadmap Role"
      >
        {showAllOption ? (
          <MenuItem value={"All"} key={"All"}>
            All
          </MenuItem>
        ) : null}
        <MenuItem value={"Area Coordinator"} key={"Area Coordinator"}>
          Area Coordinator
        </MenuItem>
        <MenuItem value={"Domain Head"} key={"Domain Head"}>
          Domain Head
        </MenuItem>
        <MenuItem value={"Dorm Parent"} key={"Dorm Parent"}>
          Dorm Parent
        </MenuItem>
        <MenuItem value={"Head of School"} key={"Head of School"}>
          Head of School
        </MenuItem>
        <MenuItem value={"Head of Admin"} key={"Head of School"}>
          Head of Adminstration
        </MenuItem>
        <MenuItem value={"Mentor"} key={"Mentor"}>
          Mentor
        </MenuItem>
        <MenuItem value={"Personal"} key={"Personal"}>
          Personal
        </MenuItem>
        <MenuItem value={"Student"} key={"Student"}>
          Student
        </MenuItem>
        <MenuItem value={"Professional"} key={"Professional"}>
          Staff
        </MenuItem>
        <MenuItem value={"Teacher"} key={"Teacher"}>
          Teacher
        </MenuItem>
      </Select>
    </FormControl>
  );
}
