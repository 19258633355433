import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import TimeTablePDF from "./timeTablePDF";
const generateTimeTablePDF = async (
  startDate,
  timePeriods,
  timetable,
  value,
  user,
  domainArea,
  grade
) => {
  // prep timetable

  /*
  generate timetable 
  */
  try {
    const blob = await pdf(
      <TimeTablePDF
        startDate={startDate}
        timePeriods={timePeriods}
        timetable={timetable}
        value={value}
        user={user}
        domainArea={domainArea}
        grade={grade}
      />
    ).toBlob();
    let fileName = (() => {
      const date = Date().split(" ");
      const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
      return `${"timeTable"}_Weekly_${dateStr}.pdf`;
    })();
    saveAs(blob, fileName);
  } catch (err) {
    return Promise.reject(new Error("Error downloading timetable"));
  }
};

export default generateTimeTablePDF;
