import React, { Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Factory } from "../../../helpers/factories";
import Grid from "@material-ui/core/Grid";
import SearchUser from "../../helperComponents/searchUser";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import { Typography } from "@material-ui/core";
import { AttendanceService } from "../../../services/attendance";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

// import Toast from "../../helperComponents/toast";
// import Toast from "../../helperComponents/toastComonent";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  absenteeList: {
    margin: theme.spacing(2),
  },
}));

function AbsenteeDialog({
  toastOptions,
  setToastOptions,
  handleCloseToast,
  isAbsenteeDialogOpen,
  activeLearningExperience,
  handleCloseAbsenteeDialog,
  ...props
}) {
  const { currentUser } = useAppContext();

  const classes = useStyles();

  //absentee
  const [absentee, setAbsentee] = React.useState(Factory.createUser());

  //absentee
  const [absenteeList, setAbsenteeList] = React.useState([]);

  const [date, setDate] = React.useState(moment());

  const [dialogForm, setDialogForm] = React.useState({});

  const AbsenteeSearch = (
    <SearchUser
      label={"Search student"}
      user={absentee}
      setUser={setAbsentee}
      role={"student"}
    />
  );

  React.useEffect(() => {
    if (isAbsenteeDialogOpen) {
      setAbsentee(Factory.createUser());
      setAbsenteeList([]);
      setDialogForm({});
    }
  }, [isAbsenteeDialogOpen]);

  //check if enable save button
  function isFormValid() {
    return absentee && absentee._id && dialogForm.reason;
  }

  function handleInputChange(e) {
    setDialogForm({ ...dialogForm, [e.target.name]: e.target.value });
  }

  //handle From date change
  const handleDateChange = (date) => {
    if (date === "Invalid Date" || date === null) {
    } else {
      setDate(date);
    }
  };

  async function handleSaveAbsentee() {
    //save form data, using extra object because setState on dialogForm doesn't update immediately for oraganisation and createdBy
    let data = {
      student: absentee._id,
      teacher: activeLearningExperience.teacher._id,
      domainArea: activeLearningExperience.domainArea._id,
      grade: activeLearningExperience.grade,
      reason: dialogForm.reason,
      date: date,
    };

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      //save
      let result = await AttendanceService.addAttendanceByOrgId(
        currentUser.organisation.id,
        data
      );

      let temp = [...absenteeList];
      temp.push(result);
      setAbsenteeList(temp);

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Absentee Recorded",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  }

  async function handleDeleteAttendance(index) {
    let temp = [...absenteeList];
    temp.splice(index, 1);
    try {
      await AttendanceService.deleteAttendanceByOrgIdAndAttendanceId(
        currentUser.organisation.id,
        absenteeList[index]["_id"]
      );
      setAbsenteeList(temp);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Absentee Deleted",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error deleting absentee",
      });
    }
  }

  return (
    <Fragment>
      <Dialog
        open={isAbsenteeDialogOpen}
        onClose={handleCloseAbsenteeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="form-dialog-title">{"Mark Absentees"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="caption">
                This is a permanent record against students so please ascertain
                that the students are actually absent and preferably do it after
                the lesson. The date recorded will be the date for today.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {AbsenteeSearch}
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div style={{ width: "90%" }}>
                  <KeyboardDatePicker
                    className={classes.filterElements}
                    margin="normal"
                    id="date-picker-dialog-from"
                    label="Date"
                    format="dd/MM/yyyy"
                    value={date}
                    onChange={handleDateChange}
                    inputVariant={"outlined"}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <TextField
                  multiline
                  variant="outlined"
                  id="reason"
                  name="reason"
                  label="Reason for absence"
                  type="text"
                  value={dialogForm.reason}
                  onChange={handleInputChange}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.absenteeList}>
              <div>
                {absenteeList.map((data, index) => {
                  return (
                    <Chip
                      size="small"
                      key={
                        data.student.firstName + data.student.lastName + index
                      }
                      label={
                        data.student.firstName + " " + data.student.lastName
                      }
                      variant="outlined"
                      color="primary"
                      onDelete={() => {
                        handleDeleteAttendance(index);
                      }}
                      style={{ margin: "3px" }}
                    />
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAbsenteeDialog} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={handleSaveAbsentee}
            color="primary"
          >
            Mark as absent
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AbsenteeDialog;
