import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

// const classes = {
//   root: {
//     display: "flex",
//     height: "100%",
//     alignItems: "center",
//     justifyContent: "center",
//   },
// };

function Loader(props) {
  const myStyle = {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    visibility: props.visible === "true" ? "visible" : "hidden",
  };
  return (
    <div style={myStyle}>
      <div>
        <CircularProgress
          style={{
            visibility: props.visible === "true" ? "visible" : "hidden",
          }}
        />
      </div>
    </div>
  );
}
// class Loader() {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       <div>
//         <CircularProgress />
//       </div>
//     </div>
//   );
// }

export default Loader;
