import React, { useEffect } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import moment from "moment";

import * as dfd from "danfojs";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function SPWBarChart({ dataForCharts, chartDomainArea }) {
  const [dataForBarChart, setDataForBarChart] = React.useState([]);

  useEffect(() => {
    function prepData() {
      let df = new dfd.DataFrame(dataForCharts);

      //filter only particular domain oor area if clicked on radar chart
      if (chartDomainArea) {
        df = df.loc({ rows: df["domainArea"].eq(chartDomainArea) });
      }

      // df.print();

      //count number of skills, proocesses,watermarks mentioned
      let t = dfd.toJSON(df);
      let spw = {};
      t.forEach((node) => {
        //find frequency of skills
        node.skills.forEach((skill) => {
          if (Object.keys(spw).includes(skill)) {
            spw[skill] = spw[skill] + 1;
          } else {
            spw[skill] = 1;
          }
        });

        //find frequency of watermarks
        node.watermarks.forEach((watermark) => {
          if (Object.keys(spw).includes(watermark)) {
            spw[watermark] = spw[watermark] + 1;
          } else {
            spw[watermark] = 1;
          }
        });

        //find frequency of processes
        node.processes.forEach((process) => {
          if (Object.keys(spw).includes(process)) {
            spw[process] = spw[process] + 1;
          } else {
            spw[process] = 1;
          }
        });
      });

      let temp = [];
      Object.keys(spw).forEach((key) => {
        temp.push({ name: key, count: spw[key] });
      });
      setDataForBarChart(temp);
    }

    if (dataForCharts.length > 0) {
      prepData();
    }
  }, [dataForCharts, chartDomainArea]);

  return (
    <ResponsiveContainer height={225}>
      <BarChart
        data={dataForBarChart}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="count"
          fill="#82ca9d"
          activeBar={<Rectangle fill="pink" stroke="blue" />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

// import React from "react";

// const SPWBarChart = ({ spwData }) => {
//   if (!spwData || !spwData.skills) {
//     return null; // or display a message indicating data is not available
//   }

//   return (
//     <div style={{ margin: "20px" }}>
//       <h3>Skills</h3>
//       <ul>
//         {spwData.skills.map((skill, index) => (
//           <li key={index}>{skill}</li>
//         ))}
//       </ul>
//       <h3>Processes</h3>
//       <ul>
//         {spwData.processes.map((process, index) => (
//           <li key={index}>{process}</li>
//         ))}
//       </ul>
//       <h3>Watermarks</h3>
//       <ul>
//         {spwData.watermarks.map((watermark, index) => (
//           <li key={index}>{watermark}</li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default SPWBarChart;
