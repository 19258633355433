import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppContext } from "../../../libs/contextLib";

import { DataGrid } from "@material-ui/data-grid";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import { makeStyles } from "@material-ui/core/styles";
import SchoolRoadmapFilter from "./schoolRoadmapFilter";

import { Button } from "@material-ui/core";

import Fab from "@material-ui/core/Fab";
import AlertDialog from "../../helperComponents/alertDialog";

import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import AddEditSchoolRoadmapDialog from "./addEditSchoolRoadmapDialog";
import { Factory } from "../../../helpers/factories";
import { SchoolRoadmapService } from "../../../services/schoolRoadmap";

import moment from "moment";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { Fragment } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import Toast from "../../helperComponents/toast";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  //   avatar: {
  //     backgroundColor: "#009be5",
  //   },
  contentWrapper: {
    margin: "40px 16px",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
  accordionFilter: {
    marginBottom: "5px",
    border: 0,
    // borderRadius: "8px",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  cardRoot: {
    minWidth: 190,
  },

  cardTitle: {
    fontSize: 14,
    fontWeight: "bold",
  },
  cardPos: {
    marginBottom: 12,
  },
  progress: {
    width: "100%",
    backgroundColor: "#e9ecef",
    borderRadius: ".25rem",
    height: "0.6rem",
    lineHeight: 0,
    fontSize: ".75rem",
    overflow: "hidden",
    marginBottom: "16px",
  },
  progressBarSelf: {
    height: "100%",
    // backgroundColor: "#007bff",
    backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  progressBarOther: {
    height: "100%",
    backgroundColor: "#5bc0de",
    // backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  fab: {
    margin: "0px",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
  },
  fabItem: {
    margin: "4px",
  },
  listCard: {
    boxShadow: "none",
    background: "transparent",
  },
  dataGrid: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  roundedBadge: {
    borderRadius: "4px",
    backgroundColor: "#89b17c",
    color: "white",
    display: "inline-block",
    padding: "4px 8px",
    minHeight: "26px",
    maxHeight: "200px",
    textAlign: "left",
    fontSize: "13px",
  },
}));

function SchoolRoadmap({ ...props }) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  const location = useLocation();
  //   const history = useHistory();

  const [showLoader, setShowLoader] = React.useState(true);

  //const test datammnn
  const [schoolRoadmap, setSchoolRoadmap] = React.useState(
    Factory.createSchoolRoadmap()
  );

  const [selectedSchoolRoadmapIndex, setSelectedSchoolRoadmapIndex] =
    React.useState("");

  const [schoolRoadmaps, setSchoolRoadmaps] = React.useState([]);

  //data is loading
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  //for getting more lessons
  const [page, setPage] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(false);

  //query string
  const [queryString, setQueryString] = React.useState({});

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 2000,
  });

  const [opType, setOpType] = React.useState("add");

  const [
    isAddEditSchoolRoadmapDialogOpen,
    setIsAddEditSchoolRoadmapDialogOpen,
  ] = React.useState(false);

  //alert dialog
  const [alertDialogOptions, setAlertDialogOptions] = React.useState({
    open: false,
    title: "",
    message: "Delete this roadmap?",
    cancelMessage: "Cancel",
    confirmMessage: "OK",
  });

  //close dialog
  function handleCloseAddEditSchoolRoadmapDialog() {
    setOpType("add");
    setIsAddEditSchoolRoadmapDialogOpen(false);
  }

  //delete roadmap
  function handleOpenAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: true });
  }

  function handleCloseAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
  }

  async function handleProceedDeleteSchoolRoadmap() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
    let temp = [...schoolRoadmaps];
    temp.splice(selectedSchoolRoadmapIndex, 1);
    try {
      await SchoolRoadmapService.deleteSchoolRoadmapById(
        currentUser.organisation.id,
        schoolRoadmap._id
      );
      setSchoolRoadmaps(temp);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "School Roadmap Deleted",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  /*
  fetch SchoolRoadmap nodes, pre-load verify token
  */

  useEffect(() => {
    onLoad();
    // }
  }, [queryString]);

  async function onLoad() {
    try {
      let data = await SchoolRoadmapService.getSchoolRoadmapByOrgId(
        currentUser.organisation.id,
        queryString
      );
      setSchoolRoadmaps(data.schoolRoadmaps);
      setHasMore(data.hasMore);
      setPage(data.page);
      setIsDataLoading(false);
    } catch (err) {
      setErrorLoadingPage(true);
    }
    //hide loader
    setShowLoader(false);
  }

  // handle load more roadmaps
  const handleLoadMoreSchoolRoadmaps = async () => {
    try {
      //update query page
      queryString.page = page;
      //get data
      let data = await SchoolRoadmapService.getSchoolRoadmapByOrgId(
        currentUser.organisation.id,
        queryString
      );
      //update lessons in grid
      let temp = [...schoolRoadmaps];
      temp = temp.concat(data.schoolRoadmaps);
      setSchoolRoadmaps(temp);
      setHasMore(data.hasMore);
      setPage(data.page);
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error downloading school roadmaps",
      });
    }
  };

  //grid view for lessons
  const gridView = () => {
    return (
      <Grid
        container
        spacing={3}
        style={{ marginTop: "32px", padding: "24px" }}
      >
        {schoolRoadmaps.map((schoolRoadmap, index) => {
          return (
            <Grid
              item
              xs={12}
              sm={12}
              key={"schoolRoadmap" + index}
              style={{
                borderRadius: "10px",
                backgroundColor: "#fff",
                marginBottom: "32px",
                padding: "32px",
              }}
            >
              <div>
                <div className={classes.roundedBadge}>
                  {schoolRoadmap.area +
                    ", School Roadmap " +
                    schoolRoadmap.year}
                </div>
                <div style={{ float: "right" }}>
                  Completion Date:{" "}
                  {moment(schoolRoadmap.completionDate).format("DD-MMM-YYYY")}
                  <br />
                  Created By:{" "}
                  {schoolRoadmap.createdBy.firstName +
                    " " +
                    schoolRoadmap.createdBy.lastName}
                </div>
              </div>
              <br />
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Focus Area / Components
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography variant="body2" gutterBottom>
                    {schoolRoadmap.focusArea
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={index + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </div>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Skills, Processes, Watermarks and School Attributes
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ fontStyle: "italic" }}>
                      Skills: {schoolRoadmap.skills.join(", ")}
                    </span>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ fontStyle: "italic" }}>
                      Processes: {schoolRoadmap.processes.join(", ")}
                    </span>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ fontStyle: "italic" }}>
                      Watermarks: {schoolRoadmap.watermarks.join(", ")}
                    </span>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ fontStyle: "italic" }}>
                      School Attributes:{" "}
                      {schoolRoadmap.schoolAttributes.join(", ")}
                    </span>
                  </Typography>
                </div>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Action Plan
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography variant="body2" gutterBottom>
                    {schoolRoadmap.actionPlan
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={"ap" + index + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </div>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Indicators of Success
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography variant="body2" gutterBottom>
                    {schoolRoadmap.indOfSuccess
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={"ind" + index + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </div>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  People Responsible
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography variant="body2" gutterBottom>
                    {schoolRoadmap.peopleResponsible
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={"pr" + index + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </div>
              </div>
              {[
                "areaCoordinator",
                "teacher",
                "headOfSchool",
                "staff",
                "headOfAdmin",
                "domainHead",
              ].includes(currentUser.role) && (
                <div style={{ marginBottom: "12px" }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Comments
                  </Typography>
                  <div style={{ marginLeft: "12px" }}>
                    <Typography variant="body2" gutterBottom>
                      {schoolRoadmap.comments
                        .split("\n")
                        .map(function (item, bodyIndex) {
                          return (
                            <span
                              key={"ind" + index + bodyIndex}
                              style={{ wordBreak: "break-word" }}
                            >
                              {item}
                              <br />
                            </span>
                          );
                        })}
                    </Typography>
                  </div>
                </div>
              )}
              {["areaCoordinator"].includes(currentUser.role) && (
                <div style={{ float: "right" }}>
                  <Tooltip title="Edit">
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        setOpType("edit");
                        setSelectedSchoolRoadmapIndex(index);
                        setSchoolRoadmap(schoolRoadmap);
                        setIsAddEditSchoolRoadmapDialogOpen(true);
                      }}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => {
                        setSelectedSchoolRoadmapIndex(index);
                        setSchoolRoadmap(schoolRoadmap);
                        handleOpenAlertDialog();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </Grid>
          );
        })}

        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!hasMore}
            onClick={handleLoadMoreSchoolRoadmaps}
            style={{
              marginBottom: "32px",
            }}
          >
            {hasMore ? "Load More" : "No more  results"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const SchoolRoadmapsContainer = (
    <Fragment>
      <AlertDialog
        alertDialogOptions={alertDialogOptions}
        handleClose={handleCloseAlertDialog}
        handleConfirm={handleProceedDeleteSchoolRoadmap}
      />
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />

      <AddEditSchoolRoadmapDialog
        schoolRoadmap={schoolRoadmap}
        setSchoolRoadmap={setSchoolRoadmap}
        schoolRoadmaps={schoolRoadmaps}
        setSchoolRoadmaps={setSchoolRoadmaps}
        selectedSchoolRoadmapIndex={selectedSchoolRoadmapIndex}
        opType={opType}
        setOpType={setOpType}
        isAddEditSchoolRoadmapDialogOpen={isAddEditSchoolRoadmapDialogOpen}
        handleCloseAddEditSchoolRoadmapDialog={
          handleCloseAddEditSchoolRoadmapDialog
        }
        toastOptions={toastOptions}
        setToastOptions={setToastOptions}
        handleCloseToast={handleCloseToast}
      />
      <div className={classes.root}>
        {currentUser.role === "areaCoordinator" && (
          <div className={classes.fab}>
            <Fab
              color="primary"
              aria-label="add"
              size="small"
              className={classes.fabItem}
            >
              <AddIcon
                onClick={() => {
                  setOpType("add");
                  //clear dialog
                  setSchoolRoadmap(Factory.createSchoolRoadmap());
                  setIsAddEditSchoolRoadmapDialogOpen(true);
                }}
              />
            </Fab>
          </div>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SchoolRoadmapFilter
              queryString={queryString}
              setQueryString={setQueryString}
              toastOptions={toastOptions}
              setToastOptions={setToastOptions}
            />
          </Grid>
        </Grid>
        {/* loading icon */}
        <Grid
          item
          xs={12}
          style={{
            display: showLoader ? "block" : "none",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress />
          </div>
        </Grid>
        {/* no SchoolRoadmaps */}
        <Grid
          item
          xs={12}
          style={{
            display:
              schoolRoadmaps.length == 0 && !showLoader ? "block" : "none",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "block" }}>
              <HourglassEmptyIcon style={{ fontSize: "40px" }} />
            </div>
            <div style={{ display: "block" }}>
              <Typography variant="h6" className={classes.textCenter}>
                There are no roadmaps. Make sure area and year are selected.
              </Typography>
            </div>
          </div>
        </Grid>
        {/* SchoolRoadmap container grid  view */}
        {gridView()}
      </div>
    </Fragment>
  );

  if (errorLoadingPage) {
    return <ErrorLoadingPage />;
  } else {
    return SchoolRoadmapsContainer;
  }
}

export default SchoolRoadmap;
