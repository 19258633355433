import React, { useEffect, Fragment } from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

function TimeTableEditNameDialog({
  activeTimeTable,
  setActiveTimeTable,
  isTimeTableEditTitleDialogOpen,
  setIsTimeTableEditTitleDialogOpen,
  setHasTimeTableHasChanged,
  ...props
}) {
  const [timeTableTitle, setTimeTableTitle] = React.useState("");

  const handleInputChange = (e) => {
    setTimeTableTitle(e.target.value);
  };

  useEffect(() => {
    setTimeTableTitle(activeTimeTable.title);
  }, [activeTimeTable]);

  function handleCloseDialog() {
    setActiveTimeTable({ ...activeTimeTable, title: timeTableTitle });
    setHasTimeTableHasChanged(true);
    setIsTimeTableEditTitleDialogOpen(false);
  }

  function handleCancelDialog() {
    setIsTimeTableEditTitleDialogOpen(false);
  }

  return (
    <Fragment>
      <Dialog
        open={isTimeTableEditTitleDialogOpen}
        onClose={handleCancelDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Title</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                name="title"
                label=""
                type="text"
                value={timeTableTitle}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseDialog} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default TimeTableEditNameDialog;
