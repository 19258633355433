import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: "90%",
  },
}));

export default function SelectSPW({ spw, setSPW, selectLabel, disabled }) {
  const classes = useStyles();
  const [test, setTest] = React.useState([]);

  const handleChangeMultiple = (e) => {
    const { options } = e.target;
    console.log("options", e.target);
    // const value = [];
    // for (let i = 0, l = options.length; i < l; i += 1) {
    //   if (options[i].selected) {
    //     value.push(options[i].value);
    //   }
    // }
    // setTest(value);
    // setTest([...test, event.target.value]);
    setSPW(e.target.value);
  };

  return (
    <FormControl
      className={classes.formControl}
      variant="outlined"
      style={{ width: "90%" }}
    >
      <InputLabel id="spw-select-label">{selectLabel}</InputLabel>
      <Select
        labelId="spw-select-label"
        id="spw-select-label"
        multiple
        size="small"
        value={spw}
        onChange={handleChangeMultiple}
        disabled={disabled}
        label={selectLabel}
        input={<Input />}
      >
        <MenuItem value={"Accountability"} key={"Accountability"}>
          Accountability
        </MenuItem>
        <MenuItem value={"Adaptability"} key={"Adaptability"}>
          Adaptability
        </MenuItem>
        <MenuItem value={"Analytical Skills"} key={"Analytical Skills"}>
          Analytical Skills
        </MenuItem>
        <MenuItem value={"Articulation"} key={"Articulation"}>
          Articulation
        </MenuItem>
        <MenuItem value={"Assessment"} key={"Assessment"}>
          Assessment
        </MenuItem>
        <MenuItem value={"Astute"} key={"Astute"}>
          Astute
        </MenuItem>
        <MenuItem value={"Awareness"} key={"Awareness"}>
          Awareness
        </MenuItem>
        <MenuItem value={"Citizenship"} key={"Citizenship"}>
          Citizenship
        </MenuItem>
        <MenuItem value={"Coding"} key={"Coding"}>
          Coding
        </MenuItem>
        <MenuItem
          value={"Collaboration + Teamwork"}
          key={"Collaboration + Teamwork"}
        >
          Collaboration + Teamwork
        </MenuItem>
        <MenuItem value={"Communication"} key={"Communication"}>
          Communication
        </MenuItem>
        <MenuItem value={"Community Engagement"} key={"Community Engagement"}>
          Community Engagement
        </MenuItem>
        <MenuItem value={"Compassion"} key={"Compassion"}>
          Compassion
        </MenuItem>
        <MenuItem value={"Comprehension"} key={"Comprehension"}>
          Comprehension
        </MenuItem>
        <MenuItem value={"Consistency"} key={"Consistency"}>
          Consistency
        </MenuItem>
        <MenuItem value={"Conscientiousness"} key={"Conscientiousness"}>
          Conscientiousness
        </MenuItem>
        <MenuItem value={"Coordination"} key={"Coordination"}>
          Coordination
        </MenuItem>
        <MenuItem value={"Courage"} key={"Courage"}>
          Courage
        </MenuItem>
        <MenuItem value={"Creativity"} key={"Creativity"}>
          Creativity
        </MenuItem>
        <MenuItem value={"Critical Thinking"} key={"Critical Thinking"}>
          Critical Thinking
        </MenuItem>
        <MenuItem value={"Curiosity"} key={"Curiosity"}>
          Curiosity
        </MenuItem>
        <MenuItem value={"Decision Making"} key={"Decision Making"}>
          Decision Making
        </MenuItem>
        <MenuItem value={"Dependability"} key={"Dependability"}>
          Dependability
        </MenuItem>
        <MenuItem value={"Design"} key={"Design"}>
          Design
        </MenuItem>
        <MenuItem value={"Digital Literacy"} key={"Digital Literacy"}>
          Digital Literacy
        </MenuItem>
        <MenuItem value={"Discipline"} key={"Discipline"}>
          Discipline
        </MenuItem>
        <MenuItem value={"Empathy"} key={"Empathy"}>
          Empathy
        </MenuItem>
        <MenuItem value={"Ethics"} key={"Ethics"}>
          Ethics
        </MenuItem>
        <MenuItem value={"Exploration"} key={"Exploration"}>
          Exploration
        </MenuItem>
        <MenuItem value={"Global Awareness"} key={"Global Awareness"}>
          Global Awareness
        </MenuItem>
        <MenuItem value={"Handwriting Skills"} key={"Handwriting Skills"}>
          Handwriting Skills
        </MenuItem>
        <MenuItem value={"Inquiry"} key={"Inquiry"}>
          Inquiry
        </MenuItem>
        <MenuItem value={"Integrity"} key={"Integrity"}>
          Integrity
        </MenuItem>
        <MenuItem value={"Leadership"} key={"Leadership"}>
          Leadership
        </MenuItem>
        <MenuItem value={"Listening"} key={"Listening"}>
          Listening
        </MenuItem>
        <MenuItem value={"Logical Thinking"} key={"Logical Thinking"}>
          Logical Thinking
        </MenuItem>
        <MenuItem value={"Mentorship"} key={"Mentorship"}>
          Mentorship
        </MenuItem>
        <MenuItem value={"Metacognition"} key={"Metacognition"}>
          Metacognition
        </MenuItem>
        <MenuItem value={"Motivation"} key={"Motivation"}>
          Motivation
        </MenuItem>
        <MenuItem
          value={"Nature (Outdoor Activities)"}
          key={"Nature (Outdoor Activities)"}
        >
          Nature (Outdoor Activities)
        </MenuItem>
        <MenuItem value={"Navigation"} key={"Navigation"}>
          Navigation
        </MenuItem>
        <MenuItem value={"Performance"} key={"Performance"}>
          Performance
        </MenuItem>
        <MenuItem value={"Reflection"} key={"Reflection"}>
          Reflection
        </MenuItem>
        <MenuItem value={"Relationship"} key={"Relationship"}>
          Relationship
        </MenuItem>
        <MenuItem value={"Research"} key={"Research"}>
          Research
        </MenuItem>
        <MenuItem value={"Resilience"} key={"Resilience"}>
          Resilience
        </MenuItem>
        <MenuItem value={"Resourcefulness"} key={"Resourcefulness"}>
          Resourcefulness
        </MenuItem>
        <MenuItem value={"Responsibility"} key={"Responsibility"}>
          Responsibility
        </MenuItem>
        <MenuItem value={"Rigour"} key={"Rigour"}>
          Rigour
        </MenuItem>
        <MenuItem value={"Risk Taking"} key={"Risk Taking"}>
          Risk Taking
        </MenuItem>
        <MenuItem value={"Time Management"} key={"Time Management"}>
          Time Management
        </MenuItem>
        <MenuItem value={"Trust"} key={"Trust"}>
          Trust
        </MenuItem>
      </Select>
    </FormControl>
  );
}
