import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import Skeleton from "@material-ui/lab/Skeleton";

// const data = [
//   { name: "Group A", value: 400 },
//   { name: "Group B", value: 300 },
//   { name: "Group C", value: 300 },
//   { name: "Group D", value: 200 },
// ];

// const COLORS = ["#ff0000", "#00C49F", "#FFBB28", "#FF8042"];
const COLORS = {
  "needs work": "#ff0000",
  "mainly positive": "#00C49F",
  mixed: "#FFBB28",
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
export default function ChartPieAssessment({ data }) {
  return (
    <ResponsiveContainer width="100%" height={60}>
      {data.length > 0 ? (
        <PieChart>
          <Pie
            data={data}
            cx={25}
            cy={25}
            labelLine={false}
            // label
            innerRadius={20}
            outerRadius={30}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[entry["name"]]}
                // fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "30px",
          }}
        >
          <Skeleton variant="rect" width="80%" height={60} />
        </div>
      )}
    </ResponsiveContainer>
  );
}
