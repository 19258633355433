//handles fetch response
export function handleResponse(response) {
  console.log("handleResponse.jsx - in handleReponse 1");
  return response.json().then((result) => {
    console.log("handleResponse.jsx - in handleResponse 1 - result:", result);
    const data = result.data;
    console.log("handleResponse.jsx - in handleResponse 1 - result:", response);
    if (!response.ok) {
      console.log(
        "handleResponse.jsx - in handleResponse 1 - response not ok:"
      );
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        //Auth.logout();
        //window.location.reload();
      }
      console.log(
        "handleResponse.jsx - in handleResponse 1 - result:" +
          result.message +
          " status" +
          response.status
      );
      return Promise.reject(new Error(result.message));
    }
    console.log(
      "handleResponse.jsx - in handleResponse 1 - response  OK:",
      data
    );
    return data;
  });
}

//handles fetch response try
export function handleResponse2(response, request) {
  console.log("handleResponse.jsx - in handleResponse 2");
  return response.json().then((result) => {
    const data = result.data;
    console.log("handleResponse.jsx - handleResponse2 stsatu", response.status);
    if (!response.ok) {
      console.log("handleResponse.jsx - handleResponse2 not OK");
      if ([401].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        // Auth.logout();
        // window.location.reload();
        console.log(
          "handleResponse.jsx - handleResponse2 error:" +
            result.message +
            " status" +
            response.status
        );
        console.log(
          "handleResponse.jsx - handleResponse2 set refresh token true"
        );
        let result = { refreshToken: true };
        return result;
      }
      console.log(
        "handleResponse.jsx - ERROR - IN HANDLE RESPONSE 1" +
          result.message +
          " status" +
          response.stateus
      );
      //return Promise.reject(error);
      return Promise.reject(new Error({ message: result.message }));
    }
    console.log("handleResponse.jsx - handleResponse2 not OK");
    return data;
  });
}

//handles fetch response for file download
export function handleResponseFileDownload(response) {
  if (!response.ok) {
    return Promise.reject(new Error(response.message));
  }
  return response;
}
