import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAppContext } from "../../../libs/contextLib";
import EmojiFoodBeverageIcon from "@material-ui/icons/EmojiFoodBeverage";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AssessmentsSnapshot() {
  const classes = useStyles();

  const { currentUser } = useAppContext();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (currentUser.organisation.id === "5fdc48c1097684456369ec97") {
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography variant="h6" style={{ display: "flex" }}>
              This is an experimental feature. Please use the button below to
              launch the Analytics Dashboard.
              <br />
              It may take some time to load.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div style={{ display: "block" }}>
              <ul>
                <li>
                  You can use the mouse to zoom into a particular area. Double
                  Click to zoom out.
                </li>
                <li>
                  If you Double Click on a domain name, you will only see
                  statistics from that domain. To see all domains, Double Click
                  the domain name again.
                </li>
              </ul>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
            >
              Open Analytics Dashboard
            </Button>
          </Grid>
        </Grid>

        <Dialog
          fullScreen
          // fullWidth={fullWidth}
          // maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            <iframe
              title="Assessment Stats"
              src="https://dashass.pangbisa.com"
              style={{ borderStyle: "none", width: "100%", height: "100vh" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div className={classes.centerText}>
        <div style={{ display: "flex" }}>
          <EmojiFoodBeverageIcon style={{ fontSize: "80px" }} />
        </div>
        <div style={{ display: "flex" }}>
          <Typography
            variant="h2"
            gutterBottom
            style={{ fontFamily: "Sacramento, cursive", textAlign: "center" }}
          >
            Coming Soon!
          </Typography>
        </div>
        <div style={{ display: "flex", textAlign: "center" }}>
          <Typography variant="h6" gutterBottom>
            This feature is coming soon. Meanwhile, enjoy a cup of tea!!!
          </Typography>
        </div>
      </div>
    );
  }
}

export default AssessmentsSnapshot;
