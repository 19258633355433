import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { TimeTableService } from "../../../services/timeTable";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
// import Toast from "../../helperComponents/toast";
// import Toast from "../../helperComponents/toastComonent";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function AddTimeTableDialog({
  toastOptions,
  setToastOptions,
  handleCloseToast,
  formData,
  activeTimeTable,
  setActiveTimeTable,
  timeTables,
  setTimeTables,
  selectedRowIndex,
  setSelectedRowIndex,
  isDialogOpen,
  handleCloseDialog,
  ...props
}) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  //to hold form values,
  // we could use timeTable prop directly but slows down render
  const [dialogForm, setDialogForm] = React.useState({});

  // variable to check if date is valid; used in isFormValid
  const [isStartDateValid, setIsStartDateValid] = React.useState(false);

  //for updating timeTables from table
  useEffect(() => {
    setDialogForm(activeTimeTable);
  }, [activeTimeTable]);

  //check if enable save button
  function isFormValid() {
    return (
      dialogForm.title &&
      dialogForm.noSlotsPerDay > 0 &&
      dialogForm.noWeeks &&
      isStartDateValid
    );
  }

  function handleInputChange(e) {
    setDialogForm({ ...dialogForm, [e.target.name]: e.target.value });
  }

  function handleFromDateChange(date) {
    if (date === "Invalid Date" || date === null) {
      setIsStartDateValid(false);
    } else {
      setDialogForm({
        ...dialogForm,
        // startDate: moment(date, "DD/MM/YYYY"),
        // endDate: moment(date, "DD/MM/YYYY").add(20, "days"),
        startDate: moment(date).utc(),
        endDate: moment(date).utc().add(20, "days"),
      });
      setIsStartDateValid(true);
    }
  }

  async function handleSaveTimeTable() {
    //save form data, using extra object because setState on dialogForm doesn't update immediately for oraganisation and createdBy
    let data = {
      title: dialogForm.title,
      startDate: dialogForm.startDate,
      endDate: dialogForm.endDate,
      organisation: currentUser.organisation.id,
      createdBy: currentUser.id,
      noSlotsPerDay: dialogForm.noSlotsPerDay,
      noWeeks: dialogForm.noWeeks,
    };

    // console.log("data", data);

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      //save

      const savedTimeTable = await TimeTableService.addTimeTableByOrgId(
        currentUser.organisation.id,
        data
      );
      //add timeTable to timeTables to update table
      let temp = [...timeTables];
      temp.unshift(savedTimeTable);
      setActiveTimeTable(savedTimeTable);
      setTimeTables(temp);
      setSelectedRowIndex(temp.length - 1);

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Timetable Saved",
      });
      handleCloseDialog();
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error saving timetable",
      });
    }
  }

  return (
    <Fragment>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add 3 Week Timetable</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                name="title"
                label="Calendar Title"
                type="text"
                value={dialogForm.title}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="noSlotsPerDay"
                name="noSlotsPerDay"
                label="No. of Slots / Day"
                type="number"
                value={dialogForm.noSlotsPerDay}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="noWeeks"
                name="noWeeks"
                label="No of Weeks to Show"
                type="number"
                value={dialogForm.noWeeks}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  className={classes.datePicker}
                  style={{ marginTop: "6px", width: "100%" }}
                  margin="normal"
                  id="date-picker-dialog-from"
                  label={"Start Date"}
                  value={(() => {
                    if (dialogForm.startDate) {
                      // return moment(dialogForm.startDate, "DD/MM/YYYY");
                      return moment(dialogForm.startDate);
                    }
                    return null;
                  })()}
                  readOnly={false}
                  onChange={handleFromDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={handleSaveTimeTable}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AddTimeTableDialog;
