// export default FitnessTest;
import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { FitnessTestService } from "../../../services/fitnessTest";
import SearchUserAdvanced from "../../helperComponents/searchUserAdvanced";

import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";

import moment from "moment";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import FitnessTestDialog from "./fitnessTestDialog";
import AlertDialog from "../../helperComponents/alertDialog";
import Toast from "../../helperComponents/toast";

import { Factory } from "../../../helpers/factories";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dataGridRoot: {
    height: "100vh",
    width: "100%",
  },
  dataGrid: {
    backgroundColor: "white",
  },
}));

function FitnessTest(props) {
  const classes = useStyles();
  const { currentUser } = useAppContext();

  const [searchedUser, setSearchedUser] = React.useState(Factory.createUser);
  const [queryString, setQueryString] = React.useState({});
  const [userQueryOptions, setUserQueryOptions] = React.useState({});

  const [activeFitnessTest, setActiveFitnessTest] = React.useState(
    Factory.createFitnessTest
  );
  const [fitnessTests, setFitnessTests] = React.useState([]);
  //console.log("fitness", fitnessTests);
  const [selectedRowIndex, setSelectedRowIndex] = React.useState("");
  const [opType, setOpType] = React.useState("");
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 6000,
  });
  const [alertDialogOptions, setAlertDialogOptions] = React.useState({
    open: false,
    title: "",
    message: "Delete this fitness test?",
    cancelMessage: "Cancel",
    confirmMessage: "OK",
  });

  let rows = [];

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "_id", headerName: "_id", width: 120, hide: true },
    {
      field: "student",
      headerName: "Student",
      width: 120,
      renderCell: (params) => {
        return params.row.student.firstName + " " + params.row.student.lastName;
      },
    },
    {
      field: "Events",
      headerName: "",
      width: 210,
      renderCell: (params) => {
        let temp = "";
        params.row.events.map((event) => {
          temp =
            temp + event.name + "(" + event.result + " " + event.unit + ") ";
        });
        return (
          <div
            style={{
              overflow: "scroll",
              whiteSpace: "pre-wrap",
              lineHeight: "1em",
              height: "80%",
              padding: "20px",
            }}
          >
            {temp}
          </div>
        );
      },
    },
    { field: "grade", headerName: "Grade", width: 70 },
    { field: "learningCycle", headerName: "L. Cycle", width: 80 },
    { field: "year", headerName: "Year", width: 70 },
    { field: "bmi", headerName: "BMI", width: 75 },
    { field: "bmiRemarks", headerName: "Remarks", width: 100 },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 130,
      renderCell: (params) => {
        return (
          params.row.createdBy.firstName + " " + params.row.createdBy.lastName
        );
      },
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 120,
      renderCell: (params) => {
        return moment(params.row.dateCreated).format("DD-MM-yyyy");
      },
    },
    {
      field: "",
      headerName: "",
      disableClickEventBubbling: true,
      width: 120,
      renderCell: (params) => {
        const onClick = async (op) => {
          let temp = { ...params.row };
          setActiveFitnessTest(temp);
          setSelectedRowIndex(params.row.id);
          switch (op) {
            case "edit":
              handleOpenDialog("edit");
              break;
            case "view":
              handleOpenDialog("view");
              break;
            case "delete":
              handleConfirmDeleteFitnessTest();
              break;
            default:
          }
        };

        return (
          <div>
            {currentUser.id !== params.row.createdBy._id ? (
              <Tooltip title="View">
                <IconButton aria-label="view" onClick={() => onClick("view")}>
                  <VisibilityIcon color="primary" />
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip title="Edit">
              <IconButton aria-label="edit" onClick={() => onClick("edit")}>
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
            {currentUser.id === params.row.createdBy._id ? (
              <Fragment>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => onClick("delete")}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Fragment>
            ) : null}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (props.startFetchData) {
      onLoad();
    }
  }, [props.startFetchData, queryString]);

  useEffect(() => {
    if (searchedUser._id !== "") {
      setQueryString({ ...queryString, student: searchedUser._id });
    } else {
      setQueryString({});
    }
  }, [searchedUser]);

  async function onLoad() {
    try {
      setFitnessTests(
        await FitnessTestService.getFitnessTestsByOrgId(
          currentUser.organisation.id,
          queryString
        )
      );
      setIsDataLoading(false);
    } catch (err) {
      alert(err);
      setErrorLoadingPage(true);
    }
  }

  function loadRows() {
    let temp = [];
    fitnessTests.forEach((fitnessTest, index) => {
      fitnessTest["id"] = index;
      temp.push(fitnessTest);
    });
    return temp;
  }

  function handleCreateFitnessTest() {
    setActiveFitnessTest(Factory.createFitnessTest);
    setOpType("add");
    setIsDialogOpen(true);
  }

  const handleCloseDialog = () => {
    setActiveFitnessTest(Factory.createFitnessTest);
    setIsDialogOpen(false);
  };

  const handleOpenDialog = (ops) => {
    setOpType(ops);
    setIsDialogOpen(true);
  };

  function handleOpenAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: true });
  }

  function handleCloseAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
  }

  function handleConfirmDeleteFitnessTest() {
    handleOpenAlertDialog();
  }

  async function handleProceedDeleteFitnessTest() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
    let temp = [...fitnessTests];
    temp.splice(selectedRowIndex, 1);
    try {
      await FitnessTestService.deleteFitnessTestsByOrgIdAndFitnessTestId(
        currentUser.organisation.id,
        activeFitnessTest._id
      );
      setFitnessTests(temp);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Fitness Test Deleted",
      });
    } catch (err) {
      console.log(err);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error deleting fitness test. It may be locked.",
      });
    }
  }

  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  function FitnessTestDataGrid(props) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.dataGridRoot}>
          <DataGrid
            className={classes.dataGrid}
            rows={rows}
            rowHeight={150}
            columns={columns}
            pageSize={10}
            autoHeight
            loading={isDataLoading}
            checkboxSelection={false}
            {...props}
          />
        </Grid>
      </Grid>
    );
  }

  function fitnessTestComponent(props) {
    return (
      <Fragment>
        <Toast
          toastOptions={toastOptions}
          handleCloseToast={handleCloseToast}
        />
        <AlertDialog
          alertDialogOptions={alertDialogOptions}
          handleClose={handleCloseAlertDialog}
          handleConfirm={handleProceedDeleteFitnessTest}
        />
        <FitnessTestDialog
          activeFitnessTest={activeFitnessTest}
          setActiveFitnessTest={setActiveFitnessTest}
          fitnessTests={fitnessTests}
          setFitnessTests={setFitnessTests}
          isDialogOpen={isDialogOpen}
          handleCloseDialog={handleCloseDialog}
          selectedRowIndex={selectedRowIndex}
          setSelectedRowIndex={setSelectedRowIndex}
          opType={opType}
          setOpType={setOpType}
          toastOptions={toastOptions}
          setToastOptions={setToastOptions}
        />
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCreateFitnessTest}
              >
                Add Fitness Test
              </Button>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SearchUserAdvanced
                  user={searchedUser}
                  setUser={setSearchedUser}
                  userQueryOptions={userQueryOptions}
                  setUserQueryOptions={setUserQueryOptions}
                  style={{ display: "flex" }}
                />
              </Grid>
            </Grid>
            {FitnessTestDataGrid()}
          </Grid>
        </div>
      </Fragment>
    );
  }

  if (!errorLoadingPage) {
    rows = loadRows();
    return fitnessTestComponent();
  } else {
    return <ErrorLoadingPage />;
  }
}

export default FitnessTest;
