import React, { useEffect } from "react";
//import { useAppContext } from "../../../libs/contextLib";
import Grid from "@material-ui/core/Grid";
//import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { OfTheDayService } from "../../../services/ofTheDay";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "32px",
  },
  roundedBadge: {
    borderRadius: "4px",
    backgroundColor: "#eea39b",
    color: "white",
    display: "inline-block",
    padding: "4px 8px",
    minHeight: "26px",
    maxHeight: "26px",
    textAlign: "center",
    fontSize: "15px",
  },
}));

function DzoWordOfTheDay(props) {
  const classes = useStyles();

  // const { currentUser } = useAppContext();
  const [dzoWordOfTheDay, setDzoWordOfTheDay] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorRetrieving, setErrorRetrieving] = React.useState(false);

  //pre-load verify token
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      setDzoWordOfTheDay(await OfTheDayService.getDzoWordOfTheDay());
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrorRetrieving(true);
      // console.log(err);
    }
  }

  return (
    <div className={classes.root}>
      {/* top row */}
      <Grid
        container
        spacing={3}
        style={{
          borderRadius: "10px",
          minHeight: "100px",
          backgroundColor: "#f6eceb",
        }}
      >
        <Grid item xs={12} sm={12}>
          <span className={classes.roundedBadge}>ཉིན་བསྟར་གྱི་མིང་ཚིག།</span>
        </Grid>
        {isLoading ? (
          <div
            style={{
              padding: "0px 12px",
              minHeight: "60px",
              maxHeight: "60px",
              width: "100%",
            }}
          >
            <Skeleton variant="rect" width="100%" height="100%" />
          </div>
        ) : errorRetrieving ? (
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              minHeight: "60px",
              maxHeight: "60px",
            }}
          >
            <span>ཕབ་ལེན་འབད་ནི་ལུ་ནོར་འཁྲུལ་འདུག།</span>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              overflow: "scroll",
              paddingTop: "0",
              minHeight: "60px",
              maxHeight: "60px",
            }}
          >
            <span
              style={{
                fontStyle: "italic",
                fontSize: "16px",
                marginRight: "5px",
                fontWeight: "bold",
              }}
            >
              {dzoWordOfTheDay.word}
            </span>
            <span style={{ fontSize: "16px" }}>{dzoWordOfTheDay.meaning}</span>
          </Grid>
        )}

        <Grid item xs={12} sm={12} style={{ paddingBottom: "2px" }}></Grid>
      </Grid>
    </div>
  );
}

export default DzoWordOfTheDay;
