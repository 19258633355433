import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import SearchTag from "../../helperComponents/searchTags";
import SelectDomainArea from "../../helperComponents/selectDomainArea";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";

import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Factory } from "../../../helpers/factories";
import { SchoolRoadmapService } from "../../../services/schoolRoadmap";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterElements: {},
  textField1: {
    "& > *": {
      margin: theme.spacing(2),
      width: "25ch",
    },
  },
}));

function AddEditSchoolRoadmapDialog({
  schoolRoadmap,
  setSchoolRoadmap,
  schoolRoadmaps,
  setSchoolRoadmaps,
  selectedSchoolRoadmapIndex,
  opType,
  setOpType,
  isAddEditSchoolRoadmapDialogOpen,
  handleCloseAddEditSchoolRoadmapDialog,
  toastOptions,
  setToastOptions,
  handleCloseToast,
  ...props
}) {
  const { currentUser } = useAppContext();

  const classes = useStyles();

  //temp schoolRoadmap state to prevent lag
  const [tempSchoolRoadmap, setTempSchoolRoadmap] =
    React.useState(schoolRoadmap);

  //populating year dropdwon
  const currentYear = moment().year();
  const yearArray = Array.from(
    Array(currentYear - (currentYear - 2)),
    (_, i) => currentYear - i
  );

  const [skills, setSkills] = React.useState([]);

  const [processes, setProcesses] = React.useState([]);
  const [watermarks, setWatermarks] = React.useState([]);

  const [schoolAttributes, setSchoolAttributes] = React.useState([]);

  const [completionDate, setCompletionDate] = React.useState(moment());

  //for keeping some values when using save and new; saves from typing
  const [keepValues, setKeepValues] = React.useState(false);

  //load lesson plan if edit
  useEffect(() => {
    if (opType === "edit") {
      setTempSchoolRoadmap({
        ...schoolRoadmap,
      });
      setSkills(schoolRoadmap.skills);
      setProcesses(schoolRoadmap.processes);
      setWatermarks(schoolRoadmap.watermarks);
      setSchoolAttributes(schoolRoadmap.schoolAttributes);
    } else if (opType === "add" && !keepValues) {
      setTempSchoolRoadmap(Factory.createSchoolRoadmap());
      setSkills([]);
      setProcesses([]);
      setWatermarks([]);
      setSchoolAttributes([]);
      // setUser(null);
    }
  }, [opType]);

  function isFormValid() {
    return (
      tempSchoolRoadmap.year &&
      tempSchoolRoadmap.area &&
      completionDate &&
      skills.length > 0 &&
      processes.length > 0 &&
      watermarks.length > 0 &&
      schoolAttributes.length > 0 &&
      tempSchoolRoadmap.focusArea &&
      tempSchoolRoadmap.actionPlan &&
      tempSchoolRoadmap.indOfSuccess
    );
  }

  function handleAttributesChange(e) {
    setSchoolAttributes(e.target.value);
  }

  function handleInputChange(e) {
    let temp = { ...tempSchoolRoadmap };
    temp[e.target.name] = e.target.value;
    setTempSchoolRoadmap(temp);
  }

  //handle  date change
  const handleDateChange = (date) => {
    if (date === "Invalid Date" || date === null) {
    } else {
      setCompletionDate(date);
    }
  };

  async function handleSaveSchoolRoadmap(addNewAfterSave) {
    // saving message
    setToastOptions({
      ...toastOptions,
      open: true,
      message: "Saving",
    });

    try {
      //prepare data
      var data = {
        organisation: currentUser.organisation.id,
        area: tempSchoolRoadmap.area,
        year: tempSchoolRoadmap.year,
        completionDate: completionDate,
        createdBy: currentUser.id,
        skills: skills,
        processes: processes,
        watermarks: watermarks,
        schoolAttributes: schoolAttributes,
        focusArea: tempSchoolRoadmap.focusArea.trim(),
        actionPlan: tempSchoolRoadmap.actionPlan.replace(/↵/g, "\n").trim(),
        indOfSuccess: tempSchoolRoadmap.indOfSuccess.replace(/↵/g, "\n").trim(),
        comments: tempSchoolRoadmap.comments.replace(/↵/g, "\n").trim(),
        peopleResponsible: tempSchoolRoadmap.peopleResponsible
          .replace(/↵/g, "\n")
          .trim(),
      };

      //check whether new lesson plan added or lesson plan being updated

      if (opType === "add") {
        const savedSchoolRoadmap =
          await SchoolRoadmapService.addSchoolRoadmapByOrgId(
            currentUser.organisation.id,
            data
          );
        setSchoolRoadmap({
          ...schoolRoadmap,
          _id: savedSchoolRoadmap._id,
        });

        //update lessons
        let temp = [...schoolRoadmaps];
        temp.unshift(savedSchoolRoadmap);
        setSchoolRoadmaps(temp);
      } else {
        //update schoolRoadmap
        const updatedSchoolRoadmap =
          await SchoolRoadmapService.updateSchoolRoadmapById(
            currentUser.organisation.id,
            schoolRoadmap._id,
            data
          );

        //update roadmaps
        let temp = [...schoolRoadmaps];
        temp[selectedSchoolRoadmapIndex] = updatedSchoolRoadmap;
        setSchoolRoadmaps(temp);
      }

      //add new domain roadmap after save
      if (!addNewAfterSave) {
        setTempSchoolRoadmap(Factory.createSchoolRoadmap());
        setSkills([]);
        setProcesses([]);
        setWatermarks([]);
        setSchoolAttributes([]);
        handleCloseAddEditSchoolRoadmapDialog();
      } else {
        setTempSchoolRoadmap({
          ...tempSchoolRoadmap,
          focusArea: "",
          actionPlan: "",
          indOfSuccess: "",
          comments: "",
          peopleResponsible: "",
        });
        setOpType("add");
      }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "School Roadmap Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error saving school roadmap",
      });
    }
  }

  //const { title } = dialogPropsMain;
  // setDialogProps(row);
  return (
    <Fragment>
      <Dialog
        open={isAddEditSchoolRoadmapDialogOpen}
        onClose={handleCloseAddEditSchoolRoadmapDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {opType === "edit" ? "Edit School Roadmap" : "Add School Roadmap"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <InputLabel id="type-description">Year</InputLabel>
                <Select
                  labelId="year"
                  id="year"
                  name="year"
                  value={tempSchoolRoadmap.year}
                  onChange={handleInputChange}
                  label="Year"
                >
                  {yearArray.map((year) => (
                    <MenuItem value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <InputLabel id="type-description">Area</InputLabel>
                <Select
                  labelId="area"
                  id="area"
                  name="area"
                  value={tempSchoolRoadmap.area}
                  onChange={handleInputChange}
                  label="Area"
                >
                  <MenuItem value={"Cerebral"}>Cerebral</MenuItem>
                  <MenuItem value={"Emotional"}>Emotional</MenuItem>
                  <MenuItem value={"Physical"}>Physical</MenuItem>
                  <MenuItem value={"Social"}>Social</MenuItem>
                  <MenuItem value={"Spiritual"}>Spiritual</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <InputLabel id="type-description">School Attributes</InputLabel>
                <Select
                  labelId="schoolAttributes"
                  id="schoolAttributes"
                  name="schoolAttributes"
                  value={schoolAttributes}
                  // value={tempLessonPlan.schoolAttributes}
                  onChange={handleAttributesChange}
                  label="School Attributes"
                  multiple
                >
                  <MenuItem value={"individualism"}>Individualism</MenuItem>
                  <MenuItem value={"mastery"}>Mastery</MenuItem>
                  <MenuItem value={"purpose"}>Purpose</MenuItem>
                  <MenuItem value={"leadership"}>Leadership</MenuItem>
                  <MenuItem value={"teachers as learners"}>
                    Teachers as Learners
                  </MenuItem>
                  <MenuItem value={"students as teachers"}>
                    Students as Teachers
                  </MenuItem>
                  <MenuItem value={"community"}>Community</MenuItem>
                  <MenuItem value={"risk-tasking"}>Risk-Tasking</MenuItem>
                  <MenuItem value={"joy"}>Joy</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.filterElements}
                  inputVariant="outlined"
                  margin="normal"
                  id="date-picker-dialog-from"
                  label="Completion Date"
                  format="dd/MM/yyyy"
                  value={completionDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Accordion
                style={{
                  background: "transparent",
                  marginLeft: "16px",
                  marginRight: "16px",
                }}
              >
                <AccordionSummary
                  expandIcon={<InfoIcon color={"primary"} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ background: "#eaeff1" }}
                >
                  <Typography variant="body2">
                    List of Skills, Processes, etc.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ background: "#eaeff1" }}>
                  <p>
                    <Typography variant="body2">
                      This is not an exhaustive list so please feel free to add
                      your own if it's missing from the list. Also, some terms
                      apply across categories.
                    </Typography>
                    <br />
                    <Typography variant="body2">Skills</Typography>
                    <Typography variant="caption">
                      analysis, articulation, assessment, coding, communication,
                      comprehension, creativity, decision making, discipline,
                      exploration, handwriting, inquiry, logical thinking,
                      metacognition, navigation, problem solving, team work,
                      time management,
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">Processes</Typography>
                    <Typography variant="caption">
                      assessment, coding, communication, community engagement,
                      comprehension, creativity, design, exploration,
                      handwriting, inquiry, metacognition, outdoor activities,
                      peer mentoring, problem solving, reflectiond
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">Watermarks</Typography>
                    <Typography variant="caption">
                      articulation, awareness, choice, compassion, creativity,
                      discipline, leadership, resilience, responsibility,
                      rigour, time management
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">Concept</Typography>
                    <Typography variant="caption">
                      accuracy, change, construction, design, diversity,
                      environment, global awareness, heritage and culture,
                      nature, perspective, symmetry, patterns, space, time,
                      transformation
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">
                      Actualizing Potential
                    </Typography>
                    <Typography variant="caption">
                      citizenship, community learning, leadership, mentorship,
                    </Typography>
                  </p>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <SearchTag tags={skills} setTags={setSkills} label={"Skills"} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <SearchTag
                  tags={processes}
                  setTags={setProcesses}
                  label={"Processes"}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <SearchTag
                  tags={watermarks}
                  setTags={setWatermarks}
                  label={"Watermarks"}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ margin: "16px" }}>
            <TextField
              multiline
              margin="dense"
              id="focusArea"
              name="focusArea"
              label="Focus Area / Component"
              type="text"
              rows={1}
              value={tempSchoolRoadmap.focusArea}
              onChange={handleInputChange}
              variant="outlined"
              placeholder="Eg. Human Body"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} style={{ margin: "16px" }}>
            <TextField
              multiline
              margin="dense"
              id="actionPlan"
              name="actionPlan"
              label="Action Plan"
              type="text"
              rows={8}
              placeholder="Eg. We will start by talking about the importance of the digestive system in all animals."
              value={tempSchoolRoadmap.actionPlan}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ margin: "16px" }}>
            <TextField
              multiline
              margin="dense"
              id="indOfSuccess"
              name="indOfSuccess"
              label="Indicators of Success"
              placeholder="Eg. Students will know why the digestive system is important and be able to identify the organs responsible for digestion."
              type="text"
              rows={8}
              value={tempSchoolRoadmap.indOfSuccess}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ margin: "16px" }}>
            <TextField
              multiline
              margin="dense"
              id="peopleResponsible"
              name="peopleResponsible"
              label="People Responsible"
              placeholder="Eg. Dorji will look into this."
              type="text"
              rows={8}
              value={tempSchoolRoadmap.peopleResponsible}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ margin: "16px" }}>
            <TextField
              multiline
              margin="dense"
              id="comments"
              name="comments"
              label="Comments"
              placeholder="Comments on whether this target was met."
              type="text"
              rows={8}
              value={tempSchoolRoadmap.comments}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setTempSchoolRoadmap(Factory.createSchoolRoadmap());
              setSkills([]);
              setProcesses([]);
              setWatermarks([]);
              setSchoolAttributes([]);
              handleCloseAddEditSchoolRoadmapDialog();
            }}
            color="primary"
          >
            Close
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={() => {
              setKeepValues(false);
              handleSaveSchoolRoadmap(false);
            }}
            color="primary"
          >
            Save & Close
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={() => {
              setKeepValues(true);
              handleSaveSchoolRoadmap(true);
            }}
            color="primary"
          >
            Save & Add Another
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AddEditSchoolRoadmapDialog;
