import moment from "moment";

export const Factory = {
  createUser,
  createDomainArea,
  createLearningExperience,
  createAssessmentSummary,
  createReport,
  createOrganisation,
  createAffiliation,
  createLessonPlan,
  createRoadmapNode,
  createRoadmapChildNode,
  createAppraisal,
  createLeResource,
  createDomainRoadmap,
  createFiveAreasRoadmap,
  createGroupRoadmap,
  createSchoolRoadmap,
  createFitnessTest,
  createPortrait,
};

function createUser(
  _id = "",
  username = "",
  password = "",
  role = "",
  email = "",
  firstName = "",
  lastName = "",
  phone = "",
  nationalIdNo = "",
  yearJoined = "",
  yearGraduated = "",
  grade = "",
  gradeJoined = "",
  groupSection = "",
  nationalStudentCode = "",
  nationalTeacherCode = "",
  organisation = {},
  address = {
    line1: "",
    suburb: "",
    city: "",
    state: "",
    country: "",
  },
  guardianEmergencyContact = {
    name: "",
    email: "",
    phone: "",
  },
  accountStatus = "",
  notes = ""
) {
  return {
    _id: _id,
    username: username,
    password: password,
    role: role,
    email: email,
    phone: phone,
    firstName: firstName,
    lastName: lastName,
    nationalIdNo: "",
    yearJoined: yearJoined,
    yearGraduated: yearGraduated,
    grade: grade,
    gradeJoined: gradeJoined,
    groupSection: groupSection,
    nationalStudentCode: nationalStudentCode,
    nationalTeacherCode: nationalTeacherCode,
    organisation: organisation,
    address: address,
    guardianEmergencyContact: guardianEmergencyContact,
    accountStatus: accountStatus,
    notes: notes,
  };
}

function createDomainArea(
  _id = "",
  name = "",
  displayName = "",
  shortName = "",
  type = "",
  isGlobal = "",
  createdBy = ""
) {
  return {
    _id: _id,
    name: name,
    displayName: displayName,
    shortName: shortName,
    type: type,
    isGlobal: isGlobal,
    createdBy: createdBy,
  };
}

function createLearningExperience(
  timeTable = "",
  week = "",
  day = "",
  slot = "",
  type = "",
  isForAllGrades = false,
  title = "",
  teacher = null,
  domainArea = null,
  grade = "",
  group = "",
  location = "",
  lesson = ""
) {
  return {
    timeTable: timeTable,
    week: week,
    day: day,
    slot: slot,
    type: type,
    isForAllGrades: isForAllGrades,
    title: title,
    teacher: teacher,
    domainArea: domainArea,
    grade: grade,
    group: group,
    location: location,
    lesson: lesson,
  };
}

function createReport(
  organisation = "",
  mentee = null,
  mentor = null,
  status = "unlocked",
  reportYear = "",
  reportType = "",
  studentImageURL = "",
  studentReflectionImageURL = ""
) {
  return {
    organisation: organisation,
    mentee: mentee,
    mentor: mentor,
    status: status,
    reportYear: reportYear,
    reportType: reportType,
    studentImageURL: studentImageURL,
    studentReflectionImageURL: studentReflectionImageURL,
  };
}

function createAssessmentSummary(
  organisation = "",
  student = null,
  createdBy = null,
  domainArea = null,
  startDate = "",
  endDate = "",
  year = "",
  verified = false,
  comment = ""
) {
  return {
    organisation: organisation,
    student: student,
    createdBy: createdBy,
    domainArea: domainArea,
    startDate: startDate,
    endDate: endDate,
    year: year,
    verified: verified,
    comment: comment,
  };
}

function createAppraisal(
  organisation = "",
  user = null,
  createdBy = null,
  status = "unlocked",
  year = "",
  type = "",
  learningCycle = "",
  comment = ""
) {
  return {
    organisation: organisation,
    user: user,
    createdBy: createdBy,
    status: status,
    year: year,
    type: type,
    learningCycle: learningCycle,
    comment: comment,
  };
}

function createOrganisation(
  name = "",
  type = "",
  yearJoined = "",
  picURL = "",
  prefix = "",
  contact = {},
  address = {}
) {
  return {
    name: name,
    type: type,
    yearJoined: yearJoined,
    picURL: picURL,
    prefix: prefix,
    contact: contact,
    address: address,
  };
}

function createAffiliation(
  schoolName = "",
  schoolType = "",
  country = "",
  status = ""
) {
  return {
    schoolName: schoolName,
    schoolType: schoolType,
    country: country,
    status: status,
  };
}

function createPortrait(
  student = "",
  organisation = "",
  portraitYear = "",
  portraitGrade = "",
  createdBy = ""
) {
  return {
    student: student,
    organisation: organisation,
    portraitYear: portraitYear,
    portraitGrade: portraitGrade,
    createdBy: createdBy,
  };
}

function createLessonPlan(
  title = "",
  overview = "",
  details = "",
  teacher = null,
  domainArea = null,
  grade = "",
  linkToResources = "",
  organisation = "",
  tags = [],
  skills = [],
  processes = [],
  watermarks = [],
  schoolAttributes = [],
  learningCycle = ""
) {
  return {
    title: title,
    overview: overview,
    details: details,
    teacher: teacher,
    domainArea: domainArea,
    grade: grade,
    linkToResources: linkToResources,
    organisation: organisation,
    tags: tags,
    skills: skills,
    processes: processes,
    watermarks: watermarks,
    schoolAttributes: schoolAttributes,
    learningCycle: learningCycle,
  };
}

function createRoadmapNode(
  organisation = "",
  goal = "",
  indOfSuccess = "",
  indicatorsOfSuccess = [],
  actionPlan = "",
  username = "",
  mentor = "",
  createdBy = "",
  concept = "",
  reviewers = [],
  domainArea = "",
  tags = [],
  skills = [],
  processes = [],
  watermarks = [],
  approved = "",
  status = "",
  completionDate = moment(),
  progressSelf = "",
  progressOther = "",
  dateCreated = ""
) {
  return {
    organisation: organisation,
    goal: goal,
    indOfSuccess: indOfSuccess,
    indicatorsOfSuccess: indicatorsOfSuccess,
    actionPlan: actionPlan,
    username: username,
    mentor: mentor,
    createdBy: createdBy,
    concept: concept,
    reviewers: reviewers,
    domainArea: domainArea,
    tags: tags,
    skills: skills,
    processes: processes,
    watermarks: watermarks,
    approved: approved,
    status: status,
    completionDate: completionDate,
    progressSelf: progressSelf,
    progressOther: progressOther,
    dateCreated: dateCreated,
  };
}

function createRoadmapChildNode(
  parent = "",
  comment = "",
  commentType = "",
  commenter = "",
  progress = 0,
  tags = [],
  dateCreated = ""
) {
  return {
    parent: parent,
    comment: comment,
    commentType: commentType,
    commenter: commenter,
    progress: progress,
    tags: tags,
    dateCreated: dateCreated,
  };
}

function createLeResource(
  title = "",
  description = "",
  link = "",
  domainArea = null,
  uploadedBy = null,
  organisation = null,
  status = "",
  type = "",
  tags = [],
  visibility = ""
) {
  return {
    title: title,
    description: description,
    link: link,
    domainArea: domainArea,
    uploadedBy: uploadedBy,
    organisation: organisation,
    status: status,
    type: type,
    tags: tags,
    visibility: visibility,
  };
}

function createDomainRoadmap(
  grade = "",
  year = "",
  domainArea = "",
  organisation = "",
  learningCycle = "",
  startDate = moment.now(),
  duration = "",
  focusArea = "",
  subAreas = "",
  actionPlan = "",
  indOfSuccess = "",
  skills = [],
  processes = [],
  watermarks = [],
  comments = "",
  createdBy = ""
) {
  return {
    grade: grade,
    year: year,
    domainArea: domainArea,
    organisation: organisation,
    learningCycle: learningCycle,
    startDate: startDate,
    duration: duration,
    focusArea: focusArea,
    subAreas: subAreas,
    actionPlan: actionPlan,
    indOfSuccess: indOfSuccess,
    skills: skills,
    processes: processes,
    watermarks: watermarks,
    comments: comments,
    createdBy: createdBy,
  };
}

function createFiveAreasRoadmap(
  year = "",
  area = "",
  organisation = "",
  focusArea = "",
  actionPlan = "",
  indOfSuccess = "",
  skills = [],
  processes = [],
  watermarks = [],
  peopleResponsible = "",
  comments = "",
  createdBy = ""
) {
  return {
    year: year,
    area: area,
    organisation: organisation,
    focusArea: focusArea,
    actionPlan: actionPlan,
    indOfSuccess: indOfSuccess,
    skills: skills,
    processes: processes,
    watermarks: watermarks,
    comments: comments,
    peopleResponsible: peopleResponsible,
    createdBy: createdBy,
  };
}

function createSchoolRoadmap(
  year = "",
  area = "",
  organisation = "",
  focusArea = "",
  actionPlan = "",
  indOfSuccess = "",
  skills = [],
  processes = [],
  watermarks = [],
  peopleResponsible = "",
  comments = "",
  createdBy = ""
) {
  return {
    year: year,
    area: area,
    organisation: organisation,
    focusArea: focusArea,
    actionPlan: actionPlan,
    indOfSuccess: indOfSuccess,
    skills: skills,
    processes: processes,
    watermarks: watermarks,
    comments: comments,
    peopleResponsible: peopleResponsible,
    createdBy: createdBy,
  };
}

function createGroupRoadmap(
  mentor = "",
  year = "",
  area = "",
  organisation = "",
  focusArea = "",
  actionPlan = "",
  indOfSuccess = "",
  skills = [],
  processes = [],
  watermarks = [],
  peopleResponsible = "",
  comments = "",
  createdBy = ""
) {
  return {
    mentor: mentor,
    year: year,
    area: area,
    organisation: organisation,
    focusArea: focusArea,
    actionPlan: actionPlan,
    indOfSuccess: indOfSuccess,
    skills: skills,
    processes: processes,
    watermarks: watermarks,
    comments: comments,
    createdBy: createdBy,
  };
}

function createFitnessTest(
  grade = "",
  year = "",
  organisation = "",
  learningCycle = "",
  events = [],
  gender = "",
  comments = "",
  createdBy = "",
  bmi = "",
  bmiRemarks = "",
  height = "",
  weight = ""
) {
  return {
    grade: grade,
    year: year,
    organisation: organisation,
    learningCycle: learningCycle,
    events: events,
    gender: gender,
    comments: comments,
    createdBy: createdBy,
    bmi: bmi,
    bmiRemarks: bmiRemarks,
    height: height,
    weight: weight,
  };
}
