import React, { Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Factory } from "../../../helpers/factories";
import Grid from "@material-ui/core/Grid";
import SearchUser from "../../helperComponents/searchUser";
import SearchUserAdvanced from "../../helperComponents/searchUserAdvanced";

import { MenteeService } from "../../../services/mentee";
// import Toast from "../../helperComponents/toast";
// import Toast from "../../helperComponents/toastComonent";

function MentorMenteeDialog({
  toastOptions,
  setToastOptions,
  handleCloseToast,
  mentorMentee,
  setMentorMentee,
  isDialogOpen,
  handleCloseDialog,
  ...props
}) {
  const { currentUser } = useAppContext();

  //mentor mentee
  const [mentor, setMentor] = React.useState(Factory.createUser());
  const [mentee, setMentee] = React.useState(Factory.createUser());

  const [userQueryOptions, setUserQueryOptions] = React.useState({});

  const MentorSearch = (
    <SearchUserAdvanced
      label={"Choose a mentor"}
      user={mentor}
      setUser={setMentor}
      userQueryOptions={userQueryOptions}
      setUserQueryOptions={setUserQueryOptions}
      style={{ display: "flex" }}
    />
    // <SearchUser
    //   label={"Choose a mentor"}
    //   user={mentor}
    //   setUser={setMentor}
    //   role={""}
    // />
  );
  const MenteeSearch = (
    <SearchUserAdvanced
      label={"Select a mentee"}
      user={mentee}
      setUser={setMentee}
      userQueryOptions={userQueryOptions}
      setUserQueryOptions={setUserQueryOptions}
      style={{ display: "flex" }}
    />
    // <SearchUser
    //   label={"Select a mentee"}
    //   user={mentee}
    //   setUser={setMentee}
    //   role={"student"}
    // />
  );

  //for updating announcements from table
  //   useEffect(() => {
  //     setDialogForm(announcement);
  //   }, [announcement]);

  //check if enable save button
  function isFormValid() {
    return mentor._id && mentee._id;
  }

  //   function handleInputChange(e) {
  //     setDialogForm({ ...dialogForm, [e.target.name]: e.target.value });
  //   }

  async function handelSaveMentorMentee() {
    //save form data, using extra object because setState on dialogForm doesn't update immediately for oraganisation and createdBy
    let data = {
      mentee: mentee._id,
    };

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      //check save or edit

      //   if (opType == "add") {

      await MenteeService.addMentorMenteeByOrgIdAndUserId(
        currentUser.organisation.id,
        mentor._id,
        data
      );
      //add announcement to announcements to update table
      // let temp = [...announcements];
      // temp.push(savedAnnouncement);
      // setAnnouncement(savedAnnouncement);
      // setAnnouncements(temp);
      // setSelectedRowIndex(temp.length - 1);
      // setOpType("edit");
      //   } else {
      // const updatedAnnouncement = await AnnouncementService.updateAnnouncementByOrgIdAndAnnouncementId(
      //   currentUser.organisation.id,
      //   announcement._id,
      //   data
      // );
      // //edit announcement in announcements to update table
      // let temp = [...announcements];
      // temp[selectedRowIndex] = updatedAnnouncement;
      // temp[selectedRowIndex] = updatedAnnouncement;
      // setAnnouncements(temp);
      //   }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Mentor Mentee Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  }

  return (
    <Fragment>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="form-dialog-title">{"Add Mentor Mentee"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {MentorSearch}
            </Grid>
            <Grid item xs={12}>
              {MenteeSearch}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={handelSaveMentorMentee}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default MentorMenteeDialog;
