import React from "react";
import { Auth } from "../../services/auth";

export default function RouteAuthHOC(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      console.log(props);
      super(props);
      this.state = { fetchData: false };
    }

    async componentDidMount() {
      try {
        // console.log("routeAuthHOC.jsx - Calling auth.currentSession");
        const user = await Auth.currentSession();
        // console.log("routeAuthHOC.jsx - user found", user);
        // console.log("routeAuthHOC.jsx - set fetchData true");
        this.setState({ fetchData: true });
      } catch (err) {
        // console.log("routeAuthHOC.jsx - error:", err);
      }
    }

    render() {
      return (
        <WrappedComponent
          startFetchData={this.state.fetchData}
          {...this.props}
        />
      );
    }
  };
}
