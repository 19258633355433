import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppContext } from "../../../libs/contextLib";

import { DataGrid } from "@material-ui/data-grid";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import { makeStyles } from "@material-ui/core/styles";
import GroupRoadmapFilter from "./groupRoadmapFilter";

import { Button } from "@material-ui/core";

import Fab from "@material-ui/core/Fab";
import AlertDialog from "../../helperComponents/alertDialog";

import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import AddEditGroupRoadmapDialog from "./addEditGroupRoadmapDialog";
import { Factory } from "../../../helpers/factories";
import { GroupRoadmapService } from "../../../services/groupRoadmap";

import moment from "moment";
import { MenteeService } from "../../../services/mentee";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { Fragment } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import Toast from "../../helperComponents/toast";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  //   avatar: {
  //     backgroundColor: "#009be5",
  //   },
  contentWrapper: {
    margin: "40px 16px",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
  accordionFilter: {
    marginBottom: "5px",
    border: 0,
    // borderRadius: "8px",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  cardRoot: {
    minWidth: 190,
  },

  cardTitle: {
    fontSize: 14,
    fontWeight: "bold",
  },
  cardPos: {
    marginBottom: 12,
  },
  progress: {
    width: "100%",
    backgroundColor: "#e9ecef",
    borderRadius: ".25rem",
    height: "0.6rem",
    lineHeight: 0,
    fontSize: ".75rem",
    overflow: "hidden",
    marginBottom: "16px",
  },
  progressBarSelf: {
    height: "100%",
    // backgroundColor: "#007bff",
    backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  progressBarOther: {
    height: "100%",
    backgroundColor: "#5bc0de",
    // backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  fab: {
    margin: "0px",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
  },
  fabItem: {
    margin: "4px",
  },
  listCard: {
    boxShadow: "none",
    background: "transparent",
  },
  dataGrid: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  roundedBadge: {
    borderRadius: "4px",
    backgroundColor: "#89b17c",
    color: "white",
    display: "inline-block",
    padding: "4px 8px",
    minHeight: "26px",
    maxHeight: "200px",
    textAlign: "left",
    fontSize: "13px",
  },
}));

function GroupRoadmap({ ...props }) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  const location = useLocation();
  //   const history = useHistory();

  //for search user
  const [user, setUser] = React.useState(null);

  //mentor of the group
  const [mentor, setMentor] = React.useState({});

  const [showLoader, setShowLoader] = React.useState(true);

  //const test datammnn
  const [groupRoadmap, setGroupRoadmap] = React.useState(
    Factory.createGroupRoadmap()
  );

  const [selectedGroupRoadmapIndex, setSelectedGroupRoadmapIndex] =
    React.useState("");

  const [groupRoadmaps, setGroupRoadmaps] = React.useState([]);

  //data is loading
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  //for getting more lessons
  const [page, setPage] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(false);

  //query string
  const [queryString, setQueryString] = React.useState({});

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 2000,
  });

  const [opType, setOpType] = React.useState("add");

  const [isAddEditGroupRoadmapDialogOpen, setIsAddEditGroupRoadmapDialogOpen] =
    React.useState(false);

  //alert dialog
  const [alertDialogOptions, setAlertDialogOptions] = React.useState({
    open: false,
    title: "",
    message: "Delete this roadmap?",
    cancelMessage: "Cancel",
    confirmMessage: "OK",
  });

  //close dialog
  function handleCloseAddEditGroupRoadmapDialog() {
    setOpType("add");
    setIsAddEditGroupRoadmapDialogOpen(false);
  }

  //delete roadmap
  function handleOpenAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: true });
  }

  function handleCloseAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
  }

  async function handleProceedDeleteGroupRoadmap() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
    let temp = [...groupRoadmaps];
    temp.splice(selectedGroupRoadmapIndex, 1);
    try {
      await GroupRoadmapService.deleteGroupRoadmapById(
        currentUser.organisation.id,
        groupRoadmap._id
      );
      setGroupRoadmaps(temp);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Group Roadmap Deleted",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  /*
  fetch groupRoadmap nodes, pre-load verify token
  */

  useEffect(() => {
    onLoad();
    // }
  }, [queryString]);

  async function getGroupRoadmap() {
    if (queryString["year"] && queryString["mentor"]) {
      try {
        let data = await GroupRoadmapService.getGroupRoadmapByOrgId(
          currentUser.organisation.id,
          queryString
        );
        setGroupRoadmaps(data.groupRoadmaps);
        setHasMore(data.hasMore);
        setPage(data.page);
        setIsDataLoading(false);
      } catch (err) {
        setErrorLoadingPage(true);
      }
      //hide loader
      setShowLoader(false);
    } else {
      setHasMore(false);
      setPage(0);
      setGroupRoadmaps([]);
      setShowLoader(false);
    }
  }

  async function onLoad() {
    getGroupRoadmap();
  }

  // handle load more roadmaps
  const handleLoadMoreGroupRoadmaps = async () => {
    try {
      //update query page
      queryString.page = page;
      //get data
      let data = await GroupRoadmapService.getGroupRoadmapByOrgId(
        currentUser.organisation.id,
        queryString
      );
      //update lessons in grid
      let temp = [...groupRoadmaps];
      temp = temp.concat(data.groupRoadmaps);
      setGroupRoadmaps(temp);
      setHasMore(data.hasMore);
      setPage(data.page);
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error downloading group roadmaps",
      });
    }
  };

  //grid view for lessons
  const gridView = () => {
    return (
      <Grid
        container
        spacing={3}
        style={{ marginTop: "32px", padding: "24px" }}
      >
        {groupRoadmaps.map((groupRoadmap, index) => {
          return (
            <Grid
              item
              xs={12}
              sm={12}
              key={"groupRoadmap" + index}
              style={{
                borderRadius: "10px",
                backgroundColor: "#fff",
                marginBottom: "32px",
                padding: "32px",
              }}
            >
              <div>
                <div className={classes.roundedBadge}>
                  {groupRoadmap.mentor.firstName +
                    " " +
                    groupRoadmap.mentor.lastName +
                    "'s Group, " +
                    groupRoadmap.year +
                    " Roadmap"}
                </div>
                <div style={{ float: "right" }}>
                  Completion Date:{" "}
                  {moment(groupRoadmap.completionDate).format("DD-MMM-YYYY")}
                  <br />
                  Created By:{" "}
                  {groupRoadmap.createdBy.firstName +
                    " " +
                    groupRoadmap.createdBy.lastName}
                </div>
              </div>
              <br />
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Domain / Area
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography variant="body2" gutterBottom>
                    {groupRoadmap.domainArea.displayName}
                  </Typography>
                </div>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Focus Area / Components
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography variant="body2" gutterBottom>
                    {groupRoadmap.focusArea
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={index + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </div>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Skills, Processes, and Watermarks
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ fontStyle: "italic" }}>
                      Skills: {groupRoadmap.skills.join(", ")}
                    </span>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ fontStyle: "italic" }}>
                      Processes: {groupRoadmap.processes.join(", ")}
                    </span>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <span style={{ fontStyle: "italic" }}>
                      Watermarks: {groupRoadmap.watermarks.join(", ")}
                    </span>
                  </Typography>
                </div>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Action Plan
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography variant="body2" gutterBottom>
                    {groupRoadmap.actionPlan
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={"ap" + index + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </div>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Indicators of Success
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography variant="body2" gutterBottom>
                    {groupRoadmap.indOfSuccess
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={"ind" + index + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </div>
              </div>

              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Comments
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography variant="body2" gutterBottom>
                    {groupRoadmap.comments
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={"ind" + index + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </div>
              </div>

              <div style={{ float: "right" }}>
                {(mentor._id == groupRoadmap.mentor._id ||
                  groupRoadmap.mentor._id === currentUser.id) && (
                  <Tooltip title="Edit">
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        setOpType("edit");
                        setSelectedGroupRoadmapIndex(index);
                        setGroupRoadmap(groupRoadmap);
                        setIsAddEditGroupRoadmapDialogOpen(true);
                      }}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                )}
                {groupRoadmap.mentor._id === currentUser.id && (
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => {
                        setSelectedGroupRoadmapIndex(index);
                        setGroupRoadmap(groupRoadmap);
                        handleOpenAlertDialog();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </Grid>
          );
        })}

        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!hasMore}
            onClick={handleLoadMoreGroupRoadmaps}
            style={{
              marginBottom: "32px",
            }}
          >
            {hasMore ? "Load More" : "No more  results"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const groupRoadmapsContainer = (
    <Fragment>
      <AlertDialog
        alertDialogOptions={alertDialogOptions}
        handleClose={handleCloseAlertDialog}
        handleConfirm={handleProceedDeleteGroupRoadmap}
      />
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />

      <AddEditGroupRoadmapDialog
        mentor={mentor}
        groupRoadmap={groupRoadmap}
        setGroupRoadmap={setGroupRoadmap}
        groupRoadmaps={groupRoadmaps}
        setGroupRoadmaps={setGroupRoadmaps}
        selectedGroupRoadmapIndex={selectedGroupRoadmapIndex}
        opType={opType}
        setOpType={setOpType}
        isAddEditGroupRoadmapDialogOpen={isAddEditGroupRoadmapDialogOpen}
        handleCloseAddEditGroupRoadmapDialog={
          handleCloseAddEditGroupRoadmapDialog
        }
        toastOptions={toastOptions}
        setToastOptions={setToastOptions}
        handleCloseToast={handleCloseToast}
      />
      <div className={classes.root}>
        {(mentor._id ||
          ["areaCoordinator", "domainHead", "teacher", "headOfSchool"].includes(
            currentUser.role
          )) && (
          <div className={classes.fab}>
            <Fab
              color="primary"
              aria-label="add"
              size="small"
              className={classes.fabItem}
            >
              <AddIcon
                onClick={() => {
                  setOpType("add");
                  //clear dialog
                  setGroupRoadmap(Factory.createGroupRoadmap());
                  setIsAddEditGroupRoadmapDialogOpen(true);
                }}
              />
            </Fab>
          </div>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <GroupRoadmapFilter
              user={user}
              setUser={setUser}
              mentor={mentor}
              setMentor={setMentor}
              queryString={queryString}
              setQueryString={setQueryString}
              toastOptions={toastOptions}
              setToastOptions={setToastOptions}
            />
          </Grid>
        </Grid>

        {/* loading icon */}
        <Grid
          item
          xs={12}
          style={{
            display: showLoader ? "block" : "none",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress />
          </div>
        </Grid>
        {/* no groupRoadmaps */}
        <Grid
          item
          xs={12}
          style={{
            display:
              groupRoadmaps.length == 0 && !showLoader ? "block" : "none",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "block" }}>
              <HourglassEmptyIcon style={{ fontSize: "40px" }} />
            </div>
            <div style={{ display: "block" }}>
              <Typography variant="h6" className={classes.textCenter}>
                There are no roadmaps.
              </Typography>
            </div>
          </div>
        </Grid>
        {/* groupRoadmap container grid  view */}
        {gridView()}
      </div>
    </Fragment>
  );

  if (errorLoadingPage) {
    return <ErrorLoadingPage />;
  } else {
    return groupRoadmapsContainer;
  }
}

export default GroupRoadmap;
