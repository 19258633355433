import React from "react";
import { Route } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";

export default function AuthenticatedRoute({
  unauthComp: UnauthComp,
  authComp: AuthComp,
  ...rest
}) {
  //const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();
  // if (isAuthenticated) {
  //   <Route {...rest}>children</Route>;
  // } else {
  //   <Route {...rest}>children</Route>;
  // }

  // const history = useHistory();
  // console.log("path", path);
  // if (isAuthenticated && path == "/") {
  //   history.push("/announcements");
  // }

  return (
    // <Route {...rest}>{isAuthenticated ? children : <Redirect to="/" />}</Route>

    <Route {...rest}>
      {isAuthenticated ? (
        <AuthComp />
      ) : (
        //<Redirect to={`/login?redirect=${pathname}${search}`} />
        <UnauthComp />
      )}
    </Route>
  );
}
