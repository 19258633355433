/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const FitnessTestService = {
  getFitnessTestsByOrgId,
  addFitnessTestsByOrgId,
  updateFitnessTestsByOrgIdAndFitnessTestId,
  deleteFitnessTestsByOrgIdAndFitnessTestId,
};

function getFitnessTestsByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_fitness_tests_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.fitnessTests;
    });
}

function addFitnessTestsByOrgId(orgId, fitnessTest) {
  const requestOptions = RequestHelper.getPostRequestOptions(fitnessTest);

  var api_url = config.api_fitness_tests_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.fitnessTest;
    });
}

function updateFitnessTestsByOrgIdAndFitnessTestId(
  orgId,
  fitnessTestId,
  fitnessTest
) {
  const requestOptions = RequestHelper.getPutRequestOptions(fitnessTest);

  var api_url = config.api_fitness_tests_by_orgId_and_fitnessTestId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    fitnessTestId: fitnessTestId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.fitnessTest;
    });
}

function deleteFitnessTestsByOrgIdAndFitnessTestId(orgId, fitnessTestId) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_fitness_tests_by_orgId_and_fitnessTestId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    fitnessTestId: fitnessTestId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
