import React, { useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function LeEditTimePeriodsCodTodDialog({
  noSlotsPerDay,
  activeTimeTable,
  setActiveTimeTable,
  isLeEditTimePeriodsCodTodDialogOpen,
  setIsLeEditTimePeriodsCodTodDialogOpen,
  handleTimePeriodsCodTodChangeDone,
  ...props
}) {
  const [tempTimePeriods, setTempTimePeriods] = React.useState(
    activeTimeTable.timePeriods.join()
  );
  const [tempCod, setTempCod] = React.useState(activeTimeTable.cod.join());
  const [tempTods, setTempTods] = React.useState(activeTimeTable.tods.join());

  const isFormValid = () => {
    return (
      tempTimePeriods.length < 1 ||
      tempTimePeriods.replace(/\s\s+/g, "").split(",").length != noSlotsPerDay
    );
  };

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "timePeriods":
        setTempTimePeriods(e.target.value);
        break;
      case "cod":
        setTempCod(e.target.value);
        break;
      case "tods":
        setTempTods(e.target.value);
        break;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   setSlotName(activeLeName);
  // }, [activeLeName]);

  function handleCloseDialog() {
    setActiveTimeTable({
      ...activeTimeTable,
      timePeriods: tempTimePeriods.replace(/\s\s+/g, "").split(","),
      cod: tempCod.trim().split(","),
      tods: tempTods.trim("").split(","),
    });
    setIsLeEditTimePeriodsCodTodDialogOpen(false);
    handleTimePeriodsCodTodChangeDone();
  }

  function handleCancelDialog() {
    setIsLeEditTimePeriodsCodTodDialogOpen(false);
  }

  return (
    <Fragment>
      <Dialog
        open={isLeEditTimePeriodsCodTodDialogOpen}
        onClose={handleCancelDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Edit Name / Time Periods
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="caption" display="block" gutterBottom>
                Please use commas to separate names and time periods. Make sure
                they match the maximum number of slots in a day.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="timePeriods"
                name="timePeriods"
                label="Time Periods"
                type="text"
                value={tempTimePeriods}
                placeholder="9:30-10:00, 10:00-11:00, etc."
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="cod"
                name="cod"
                label="Coordinator on Duty"
                type="text"
                value={tempCod}
                placeholder="Pema Lingpa"
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="tods"
                name="tods"
                label="Teachers on Duty"
                type="text"
                value={tempTods}
                placeholder="Dorji Lingpa, Longchenpa, etc."
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            disabled={isFormValid()}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default LeEditTimePeriodsCodTodDialog;
