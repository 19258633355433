import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import SelectDomainArea from "../../helperComponents/selectDomainArea";
import SearchUserAdvanced from "../../helperComponents/searchUserAdvanced";

import { Factory } from "../../../helpers/factories";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterElements: {
    margin: theme.spacing(2),
    width: "90%",
  },
}));

export default function AssessmentSummariesFilter({
  queryString,
  setQueryString,
}) {
  const classes = useStyles();

  //current user from app context
  const { currentUser } = useAppContext();

  //populating year dropdwon

  const [domainArea, setDomainArea] = React.useState(
    Factory.createDomainArea()
  );

  const [user, setUser] = React.useState(null);
  const [userQueryOptions, setUserQueryOptions] = React.useState({});

  //dates of assessments
  const [assessmentFrom, setAssessmentFrom] = React.useState(
    moment().subtract(30, "days").startOf("day")
  );
  const [assessmentTo, setAssessmentTo] = React.useState(moment().endOf("day"));

  //pre-load domain/araes
  useEffect(() => {
    //set initial query string
    setQueryString({
      student: user ? user._id : "",
      domainArea: domainArea._id ? domainArea._id : "",
      from: assessmentFrom ? moment(assessmentFrom).startOf("day") : "",
      to: assessmentTo ? moment(assessmentTo).endOf("day") : "",
    });
  }, [domainArea, assessmentFrom, assessmentTo, user]);

  //handle From date change
  const handleFromDateChange = (date) => {
    if (JSON.stringify(date) === "null") {
    } else {
      setAssessmentFrom(date);
      // setQueryString({
      //   ...queryString,
      //   from: moment(date),
      // });
    }
  };

  //handle To date change
  const handleToDateChange = (date) => {
    if (JSON.stringify(date) === "null") {
    } else {
      setAssessmentTo(date);
      // setQueryString({
      //   ...queryString,
      //   to: moment(date),
      // });
    }
  };

  //handle assessment Type  change
  //   const handleTypeChange = (e) => {
  //     setAssessmentType(e.target.value);
  //     if (e.target.value == "all") {
  //       setQueryString({
  //         ...queryString,
  //         type: "",
  //       });
  //     } else {
  //       setQueryString({
  //         ...queryString,
  //         type: e.target.value,
  //       });
  //     }
  //   };

  return (
    <Grid container justify="space-between" spacing={3}>
      <Grid item xs={12}>
        <SearchUserAdvanced
          label={"Select a student"}
          user={user}
          setUser={setUser}
          userQueryOptions={userQueryOptions}
          setUserQueryOptions={setUserQueryOptions}
          style={{ display: "flex" }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ backgroundColor: "white", borderRadius: "5px" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SelectDomainArea
              domainArea={domainArea}
              setDomainArea={setDomainArea}
              showAllOption={true}
              orgId={currentUser.organisation.id}
              type={""}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.filterElements}
                inputVariant="outlined"
                margin="normal"
                id="date-picker-dialog-from"
                label="Summaries Created From"
                format="dd/MM/yyyy"
                value={assessmentFrom}
                onChange={handleFromDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.filterElements}
                inputVariant="outlined"
                margin="normal"
                id="date-picker-dialog-from"
                label="Summaries Created To "
                format="dd/MM/yyyy"
                value={assessmentTo}
                onChange={handleToDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
