import React, { useEffect, forwardRef, useRef } from "react";
import randomColor from "randomcolor";
import { Fragment } from "react";

import { makeStyles } from "@material-ui/core/styles";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

const useStyles = makeStyles(() => ({
  customTooltip: {
    margin: "0px",
    padding: "10px",
    backgroundColor: "rgb(255, 255, 255)",
    border: "1px solid rgb(204, 204, 204)",
    width: "250px", // Adjust width as needed
  },
}));

const data = [
  { dateCreated: "2023-10-29", goal1: 0 },
  { dateCreated: "2023-11-29", goal1: 39.5 },
  { dateCreated: "2023-12-27", goal1: 0 },
];

export default function GoalLineChart({
  individualGoalToChartData,
  referenceLineDate,
  selectedGoalTitle,
}) {
  const classes = useStyles();

  //new code, to get other comment from individualGoalToChartData
  console.log("individualGoalToChartData", individualGoalToChartData);
  const otherComments = individualGoalToChartData.filter(
    (item) => item.commentType === "other"
  );
  // console.log("Other Comments:", otherComments);

  let commentsRef = useRef(null);
  //console.log("comments", commentsRef);

  const [indicatorIndexes, setIndicatorIndexes] = React.useState([]);

  //custom tooltip
  function MyCustomTooltip(props) {
    //new code,

    //diplay others comment
    const { otherComments } = props;
    useEffect(() => {
      if (otherComments && otherComments.length > 0) {
        const newestComment = otherComments[otherComments.length - 1].comment;
        commentsRef.current.innerHTML = newestComment;
      }
    }, [otherComments]);

    if (props.payload && props.payload.length > 0) {
      //commentsRef.current.innerHTML = props.payload[0]["payload"]["comment"];

      // new code
      //Display self Comment
      //avoid duplicate Comment
      let selfComments = [];
      let seenComments = new Set();
      props.payload.forEach((item) => {
        const comment = item.payload["comment"];
        const commentType = item.payload["commentType"];

        if (!seenComments.has(comment)) {
          seenComments.add(comment);

          if (commentType === "self") {
            selfComments.push(comment);
          }
        }
      });
      return (
        <div className={classes.customTooltip}>
          {/* //new code */}
          {/* Display student Self comments */}
          {selfComments.length > 0 && (
            <div>
              <p style={{ fontSize: "11px", display: "inline-block" }}>
                <strong>Self Comments:</strong>{" "}
              </p>
              {selfComments.map((comment, index) => (
                <span
                  key={`self-comment-${index}`}
                  style={{
                    fontSize: "11px",
                    display: "inline-block",
                    marginLeft: "4px", // Adjust spacing between comments
                  }}
                >
                  {comment}
                </span>
              ))}
            </div>
          )}

          {props.payload.map((item, index) => {
            return (
              //mew code
              <div key={index}>
                <p style={{ fontSize: "11px" }}>
                  <strong>Indicator {index + 1}:</strong>{" "}
                  {item.payload[`Title${index}`]}
                </p>
                <p style={{ fontSize: "11px" }}>
                  <strong>Progress :</strong>{" "}
                  {item.payload[`Indicator${index}`]} %
                </p>
              </div>
            );
          })}

          {/* <p>{props.payload[0]["payload"]["Indicator0"]}</p>
          <p>{props.payload[0]["payload"]["Indicator1"]}</p> */}
          {/* <p>{props.payload[0]["payload"]["comment"]}</p> */}
        </div>
      );
    }
    return <div></div>;
  }

  useEffect(() => {
    if (individualGoalToChartData.length > 0) {
      //find number of indicators
      let keys = Object.keys(individualGoalToChartData[0]);
      //indicators  start with Ind
      var count = (keys.toString().match(/Ind/g) || []).length;
      //make array of indicators for plot
      setIndicatorIndexes(Array.from(Array(count).keys()));
    }
  }, [individualGoalToChartData]);

  const CommentComponent = forwardRef((props, ref) => {
    return (
      <div>
        {/* New Code */}
        <span
          style={{
            fontSize: "10px",
            fontWeight: "bold",
            display: "inline-block",
          }}
        >
          Teacher Comments
        </span>
        <br />
        <span
          {...props}
          ref={ref}
          style={{ fontStyle: "italic", fontSize: "10px" }}
        >
          {/* {comments} */}
        </span>
      </div>
    );
  });

  const GoalTitleComponet = forwardRef((props, ref) => {
    return <span {...props} ref={ref} style={{ fontStyle: "italic" }}></span>;
  });

  return (
    <Fragment>
      <div
        style={{
          minHeight: "50px",
          margin: "0px 20px",
          fontSize: "12px",
          fontStyle: "italic",
        }}
      ></div>

      <div style={{ display: "flex" }}>
        <div style={{ width: "80%" }}>
          <ResponsiveContainer height={250}>
            <LineChart
              data={individualGoalToChartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="dateCreated" type="category" />
              <YAxis />

              {/* Added new code, where it send the otherComments */}
              <Tooltip
                content={<MyCustomTooltip otherComments={otherComments} />}
              />
              <Legend />
              <ReferenceLine
                x={referenceLineDate}
                stroke="red"
                strokeDasharray="3 3"
                label={"End Date"}
              />
              {indicatorIndexes.map((index) => {
                return (
                  <Line
                    key={"Indicator" + index}
                    type="monotone"
                    connectNulls
                    dataKey={"Indicator" + index}
                    // stroke="#8884d8"
                    stroke={randomColor({ luminosity: "dark" })}
                    activeDot={{ r: 8 }}
                    strokeWidth={3}
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div
          style={{
            width: "20%",
            padding: "10px",
            overflow: "scroll",
            fontSize: "12px",
            backgroundColor: "#f6f8f8",
            height: "75%",
            marginRight: "10px",
            borderRadius: "10px",
          }}
        >
          <CommentComponent ref={commentsRef} />
          {/* <span style={{ fontWeight: "bold" }}>Comments</span>
        <br />
        <span style={{ fontStyle: "italic" }}>{commentsRef.current}</span> */}
        </div>
      </div>
    </Fragment>
  );
}
