import React, { Fragment, useEffect } from "react";
import moment from "moment";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import useKeyPress from "./useKeyPress";

import generateTimeTablePDF from "./generateTimeTablePDF";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import ScheduleIcon from "@material-ui/icons/Schedule";

import ViewLessonPlanDialog from "../lessonPlans/viewLessonPlanDialog";
import AddLessonPlanToLEDialog from "./addLessonPlanToLEDialog";
import AbsenteeDialog from "./absenteeDialog";
import SelectDomainArea from "../../helperComponents/selectDomainArea";
import SearchUser from "../../helperComponents/searchUser";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FilterListIcon from "@material-ui/icons/FilterList";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core";

import { TimeTableService } from "../../../services/timeTable";
import { LearningExperienceService } from "../../../services/learningExperiences";
import LearningExperienceBadge from "./learningExperienceBadge";
import Toast from "../../helperComponents/toast";
import zIndex from "@material-ui/core/styles/zIndex";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: "90%",
  },

  tableContainer: {
    maxHeight: "600px",
    background: "white",
  },
  table: {
    minWidth: 650,
  },
  tableCellBorder: {
    border: "1px solid rgb(222 219 219)",
  },
  tableCellSlots: {
    width: "10%",
    border: "1px solid rgb(222 219 219)",
  },
  tableCellSlotsInput: {
    fontSize: "0.875rem",
    // color: "red !important",
  },
  tableCellDays: {
    width: "15%",
    cursor: "pointer",
    padding: "8px",
    border: "1px solid rgb(222 219 219)",
  },

  tableCellChip: {
    marginBottom: "5px",
  },
  tableCellAddLearningExperience: { float: "right" },
  leTextField: {
    padding: "8px",
  },

  datePicker: {
    marginTop: "0px",
  },
  input: {
    display: "none",
  },
  button: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  accordionFilter: {
    marginBottom: "5px",
    border: 0,
    // borderRadius: "8px",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <h1>{children}</h1>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

export default function TimeTableCurrent({ ...props }) {
  const classes = useStyles();

  //current user
  const { currentUser } = useAppContext();

  //learning experiences being loaded
  const [areLesBeingLoaded, setAreLesBeingLoaded] = React.useState(true);

  //filter states
  const [domainArea, setDomainArea] = React.useState(null);
  // const [user, setUser] = React.useState({
  //   _id: currentUser.id,
  //   firstName: currentUser.firstName,
  //   lastName: currentUser.lastName,
  // });
  const [user, setUser] = React.useState(
    (() => {
      return ["teacher", "domainHead", "areaCoordinator"].includes(
        currentUser.role
      )
        ? {
            _id: currentUser.id,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
          }
        : null;
    })()
  );
  const [grade, setGrade] = React.useState("All");

  //op type - add or edit
  const [opType, setOpType] = React.useState("add");

  //current timeTable
  const [timeTables, setTimeTables] = React.useState(null);
  console.log("timetable", timeTables);
  const [activeTimeTable, setActiveTimeTable] = React.useState(null);

  //cods, tods
  const [cods, setCods] = React.useState([]);
  const [tods, setTods] = React.useState([]);

  //active learning  experience
  const [activeLearningExperience, setActiveLearningExperience] =
    React.useState(null);

  //learning experiences for timetable
  const [learningExperiences, setLearningExperiences] = React.useState(null);

  //lesson plan to be viewed if any
  const [lessonPlan, setLessonPlan] = React.useState(null);

  // id of lesson plan in selected learning experience
  const [lessonPlanId, setLessonPlanId] = React.useState(null);

  //dialog view lesson
  const [isViewLessonPlanDialogOpen, setIsViewLessonPlanDialogOpen] =
    React.useState(false);

  //absentee dialog
  const [isAbsenteeDialogOpen, setIsAbsenteeDialogOpen] = React.useState(false);

  //add lesson to learning experience dialog
  const [isAddLessonPlanToLEDialogOpen, setIsAddLessonPlanToLEDialogOpen] =
    React.useState(false);

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 1000,
  });

  //cell event and cell data
  const [cellEvent, setCellEvent] = React.useState(null);
  const [cellEventData, setCellEventData] = React.useState(null);

  //dialog add / edit event
  // const [
  //   isAddLearningExperienceDialogOpen,
  //   setIsAddLearningExperienceDialogOpen,
  // ] = React.useState(false);

  const [localTimeTable, setLocalTimeTable] = React.useState(null);

  //tab
  const [value, setValue] = React.useState(-1);

  //can edit localTimeTable
  const canEdit = ["schoolAdmin", "domainHead"].includes(currentUser.role)
    ? true
    : false;

  //arrow pressed for navigating between tabs
  const arrowLeftPressed = useKeyPress("ArrowLeft");
  const arrowRightPressed = useKeyPress("ArrowRight");

  /*
   * handlers
   */

  //pre-load verify token
  useEffect(() => {
    if (props.startFetchData) {
      onLoad();
    }
  }, [props.startFetchData]);

  //get published timetable
  async function onLoad() {
    try {
      //returns a list of timetables; but only one in this case as there can only be only published timetable
      let tt = await TimeTableService.getTimeTablesByOrgId(
        currentUser.organisation.id,
        { status: "published" }
      );

      setTimeTables(tt);

      if (tt.length === 0) {
        setActiveTimeTable(null);
      } else {
        setActiveTimeTable(tt[0]);
        setCods(tt[0].cod.join().split(","));
        setTods(tt[0].tods.join().split("|"));
      }

      // setIsDataLoading(false);
    } catch (err) {
      // setErrorLoadingPage(true);
    }
  }

  //set no of weeks and slots if activeTimeTable updated
  useEffect(() => {
    if (activeTimeTable) {
      //prepare stucture for displaying timetable on grid
      const noWeeksArray = Array.from(Array(activeTimeTable.noWeeks).keys());
      const slotsArray = Array.from(
        Array(activeTimeTable.noSlotsPerDay).keys()
      );
      const noDays = [0, 1, 2, 3, 4, 5, 6];
      const timeTableDisplayStructure = {
        weeks: noWeeksArray.map((week) => {
          return {
            slots: slotsArray.map((slot, slotIndex) => {
              return {
                name: slot + 1,
                timePeriod: "",
                days: noDays.map((day) => {
                  return { les: [] };
                }),
              };
            }),
          };
        }),
      };
      setLocalTimeTable(timeTableDisplayStructure);

      // set tab value for current date
      setValue(
        (() => {
          // activeTimeTable.noWeeks
          let tabValues = Array.from(Array(activeTimeTable.noWeeks).keys());
          let startDate = activeTimeTable
            ? moment(activeTimeTable.startDate)
            : moment();
          let dateNow = moment();

          let diff = dateNow.diff(startDate, "days");
          let index = Math.floor(diff / 7);
          if (tabValues.includes(index)) {
            return index;
          } else {
            return tabValues[tabValues.length - 1];
          }
        })()
      );
    }
  }, [activeTimeTable]);

  //retrieve learning experiences
  useEffect(async () => {
    if (activeTimeTable) {
      try {
        setLearningExperiences(
          await LearningExperienceService.getLearningExperiencesByOrgIdAndTimeTableId(
            currentUser.organisation.id,
            activeTimeTable._id
          )
        );
        setAreLesBeingLoaded(false);
      } catch (err) {
        setToastOptions({
          ...toastOptions,
          open: true,
          message: "Error gettingn learning experiences",
        });
      }
    }
  }, [activeTimeTable]);

  //load les for display
  useEffect(() => {
    //insert le into local timetable for display
    let tempTable = { ...localTimeTable };
    if (learningExperiences) {
      learningExperiences.forEach((le, index) => {
        tempTable["weeks"][le.week]["slots"][le.slot]["days"][le.day][
          "les"
        ].push(le);
      });
    }
    //update local timetable to show les
    setLocalTimeTable({ ...tempTable });
  }, [learningExperiences]);

  // arrow pressed
  useEffect(() => {
    if (arrowLeftPressed) {
      if (value != 0) {
        handleChangeWeekTab(null, value - 1);
      }
    }
  }, [arrowLeftPressed]);

  useEffect(() => {
    if (arrowRightPressed) {
      if (value != activeTimeTable.noWeeks - 1) {
        handleChangeWeekTab(null, value + 1);
      }
    }
  }, [arrowRightPressed]);

  //change timetable tab
  const handleChangeWeekTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleGradeChange = (event) => {
    setGrade(event.target.value);
  };

  //handle close add lesson to le
  const handleCloseAddLessonPlanToLEDialog = () => {
    setIsAddLessonPlanToLEDialogOpen(false);
  };

  //handle close absentee dialog
  const handleCloseAbsenteeDialog = () => {
    setIsAbsenteeDialogOpen(false);
  };

  //handle lesson plan added to LE
  const handleLessonPlanAddedToLE = (lessonId) => {
    let tempLEs = [...learningExperiences];
    for (let [index, le] of tempLEs.entries()) {
      if (le._id === activeLearningExperience._id) {
        tempLEs[index].lesson = lessonId;
        break;
      }
    }
  };

  //handle lesson plan removed from LE
  const handleLessonPlanRemovedFromLE = () => {
    let tempLEs = [...learningExperiences];
    for (let [index, le] of tempLEs.entries()) {
      if (le._id === activeLearningExperience._id) {
        tempLEs[index].lesson = null;
        break;
      }
    }
  };

  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  //prep timetable for download
  const handleDownloadTimeTable = () => {
    generateTimeTablePDF(
      activeTimeTable.startDate,
      activeTimeTable.timePeriods,
      localTimeTable,
      value,
      user,
      domainArea,
      grade
    );
  };

  //timetable container
  const WeeklyTimetable = ({ index, ...rest }) => {
    //  helper function for alternated background color
    const setCellHeaderBackgroundColor = (a) => {
      return a % 2 == 0
        ? { backgroundColor: "rgb(245 242 224)" }
        : { backgroundColor: "rgb(255 239 239)" };
    };

    const setCellBackgroundColor = (a) => {
      return a % 2 == 0
        ? { backgroundColor: "#eaeff1" }
        : { backgroundColor: "#fff" };
    };
    // end

    if (localTimeTable.weeks != null) {
      return (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellSlots}>Slots</TableCell>
                <TableCell
                  className={classes.tableCellBorder}
                  style={setCellHeaderBackgroundColor(0)}
                >
                  {moment(activeTimeTable.startDate)
                    .add(index * 7 + 0, "days")
                    .format("ddd, MMM-DD")}
                </TableCell>
                <TableCell
                  className={classes.tableCellBorder}
                  style={setCellHeaderBackgroundColor(1)}
                >
                  {moment(activeTimeTable.startDate)
                    .add(index * 7 + 1, "days")
                    .format("ddd, MMM-DD")}
                </TableCell>
                <TableCell
                  className={classes.tableCellBorder}
                  style={setCellHeaderBackgroundColor(2)}
                >
                  {moment(activeTimeTable.startDate)
                    .add(index * 7 + 2, "days")
                    .format("ddd, MMM-DD")}
                </TableCell>
                <TableCell
                  className={classes.tableCellBorder}
                  style={setCellHeaderBackgroundColor(3)}
                >
                  {moment(activeTimeTable.startDate)
                    .add(index * 7 + 3, "days")
                    .format("ddd, MMM-DD")}
                </TableCell>
                <TableCell
                  className={classes.tableCellBorder}
                  style={setCellHeaderBackgroundColor(4)}
                >
                  {moment(activeTimeTable.startDate)
                    .add(index * 7 + 4, "days")
                    .format("ddd, MMM-DD")}
                </TableCell>
                <TableCell
                  className={classes.tableCellBorder}
                  style={setCellHeaderBackgroundColor(5)}
                >
                  {moment(activeTimeTable.startDate)
                    .add(index * 7 + 5, "days")
                    .format("ddd, MMM-DD")}
                </TableCell>
                <TableCell
                  className={classes.tableCellBorder}
                  style={setCellHeaderBackgroundColor(6)}
                >
                  {moment(activeTimeTable.startDate)
                    .add(index * 7 + 6, "days")
                    .format("ddd, MMM-DD")}
                </TableCell>
                {/* <TableCell className={classes.tableCellOptions}></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {localTimeTable.weeks[index].slots.map((slot, slotIndex) => (
                <TableRow key={index + "" + slotIndex}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.tableCellSlots}
                    style={{
                      verticalAlign: "top",
                    }}
                  >
                    <Typography variant="caption" gutterBottom>
                      {activeTimeTable.timePeriods[slotIndex]
                        ? activeTimeTable.timePeriods[slotIndex]
                        : slotIndex}
                    </Typography>
                  </TableCell>
                  {slot.days.map((day, dayIndex) => (
                    <TableCell
                      key={index + "" + slotIndex + dayIndex}
                      component="tc"
                      scope="row"
                      className={classes.tableCellDays}
                      onClick={null}
                      // style={{ verticalAlign: "top" }}
                      style={setCellBackgroundColor(dayIndex)}
                    >
                      {day.les.map((learnExp, leIndex) => {
                        function showLE() {
                          let a =
                            user != null && user._id !== "" && learnExp.teacher
                              ? user._id == learnExp.teacher._id
                                ? true
                                : false
                              : true;
                          let b =
                            domainArea != null && domainArea._id !== ""
                              ? learnExp.domainArea !== null &&
                                domainArea._id == learnExp.domainArea._id
                                ? true
                                : false
                              : true;

                          let c =
                            grade !== "All"
                              ? grade == learnExp.grade
                                ? true
                                : false
                              : true;

                          let d = learnExp.isForAllGrades ? true : false;
                          return (a && b && c) || d;
                        }
                        if (showLE()) {
                          return (
                            <LearningExperienceBadge
                              learnExp={learnExp}
                              setActiveLearningExperience={
                                setActiveLearningExperience
                              }
                              setLessonPlanId={setLessonPlanId}
                              setIsViewLessonPlanDialogOpen={
                                setIsViewLessonPlanDialogOpen
                              }
                            />
                          );
                          // return (
                          //   <Chip
                          //     key={dayIndex + leIndex}
                          //     className={classes.tableCellChip}
                          //     style={{
                          //       backgroundColor: Utils.getColorForGrade(
                          //         learnExp.grade
                          //       ),
                          //       fontSize: "0.7rem",
                          //     }}
                          //     size="small"
                          //     label={(() => {
                          //       let temp = "";
                          //       if (learnExp.type === "other") {
                          //         temp = temp + learnExp.title;
                          //       } else {
                          //         // to keep chips short
                          //         // temp = temp + learnExp.domainArea.shortName;
                          //       }
                          //       if (learnExp.teacher && learnExp.title) {
                          //         temp =
                          //           temp +
                          //           ", " +
                          //           learnExp.teacher.firstName.substring(0, 3) +
                          //           learnExp.teacher.lastName.substring(0, 3);
                          //       } else if (learnExp.teacher) {
                          //         temp =
                          //           temp +
                          //           learnExp.teacher.firstName.substring(0, 3) +
                          //           learnExp.teacher.lastName.substring(0, 3);
                          //       }
                          //       if (!learnExp.isForAllGrades) {
                          //         temp = temp + ", G" + learnExp.grade;
                          //       }
                          //       if (
                          //         !learnExp.isForAllGrades &&
                          //         learnExp.group != ""
                          //       ) {
                          //         temp = temp + ", " + learnExp.group;
                          //       }

                          //       return temp;
                          //     })()}
                          //     clickable
                          //     color="primary"
                          //     onClick={(event) => {
                          //       setActiveLearningExperience(learnExp);
                          //       setLessonPlanId(learnExp.lesson);
                          //       setIsViewLessonPlanDialogOpen(true);
                          //     }}
                          //   />
                          // );
                        }
                      })}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />
      {/* loading les dialog */}
      {/* <Dialog
        onClose={null}
        aria-labelledby="simple-dialog-title"
        open={areLesBeingLoaded && activeTimeTable != null}
      >
        <CircularProgress style={{ backgroundColor: "Red" }} />
      </Dialog> */}

      {/* absenteeDialog */}
      <AbsenteeDialog
        toastOptions={toastOptions}
        setToastOptions={setToastOptions}
        handleCloseToast={handleCloseToast}
        isAbsenteeDialogOpen={isAbsenteeDialogOpen}
        activeLearningExperience={activeLearningExperience}
        handleCloseAbsenteeDialog={handleCloseAbsenteeDialog}
      />

      {/* view lesson plan dialog */}
      <ViewLessonPlanDialog
        lessonPlanId={lessonPlanId}
        setLessonPlanId={setLessonPlanId}
        lessonPlan={lessonPlan}
        setLessonPlan={setLessonPlan}
        isViewLessonPlanDialogOpen={isViewLessonPlanDialogOpen}
        setIsViewLessonPlanDialogOpen={setIsViewLessonPlanDialogOpen}
        setIsAddLessonPlanToLEDialogOpen={setIsAddLessonPlanToLEDialogOpen}
        activeLearningExperience={activeLearningExperience}
        handleLessonPlanRemovedFromLE={handleLessonPlanRemovedFromLE}
        setIsAbsenteeDialogOpen={setIsAbsenteeDialogOpen}
        toastOptions={toastOptions}
        setToastOptions={setToastOptions}
      />

      <AddLessonPlanToLEDialog
        isAddLessonPlanToLEDialogOpen={isAddLessonPlanToLEDialogOpen}
        handleCloseAddLessonPlanToLEDialog={handleCloseAddLessonPlanToLEDialog}
        activeLearningExperience={activeLearningExperience}
        handleLessonPlanAddedToLE={handleLessonPlanAddedToLE}
        startFetchData={true}
      />
      {/* filters */}

      {/* 
     App bar and tabs 
     */}
      {!areLesBeingLoaded && activeTimeTable != null ? (
        <Fragment>
          <Accordion className={classes.accordionFilter}>
            <AccordionSummary
              expandIcon={<FilterListIcon color="primary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {activeTimeTable != null ? (
                <div>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    style={{ display: "inline" }}
                  >
                    {"COD: "}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ display: "inline" }}
                  >
                    {cods[value]}
                  </Typography>
                  <br />
                  <Typography
                    variant="subtitle2"
                    component="p"
                    style={{ display: "inline" }}
                  >
                    {"TODs: "}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    style={{ display: "inline" }}
                  >
                    {tods[value]}
                  </Typography>
                </div>
              ) : null}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                {currentUser.role != "student" ? (
                  <Grid item xs={4}>
                    <SearchUser
                      user={user}
                      setUser={setUser}
                      role={""}
                      style={{ display: "flex" }}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={4}>
                  <SelectDomainArea
                    domainArea={domainArea}
                    setDomainArea={setDomainArea}
                    showAllOption={true}
                    type={""}
                    orgId={currentUser.organisation.id}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="grade-label">Grade</InputLabel>
                    <Select
                      labelId="grade-label"
                      id="grade"
                      value={grade}
                      onChange={handleGradeChange}
                      label="Grade"
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={0}>PP</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={11}>11</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChangeWeekTab}
              aria-label="simple tabs example"
            >
              {Array.from(Array(activeTimeTable.noWeeks).keys()).map(
                (index) => {
                  return (
                    <Tab
                      label={(() => {
                        let a = moment(activeTimeTable.startDate)
                          .add(index * 7, "days")
                          .format("MMM-DD");
                        let b = moment(activeTimeTable.startDate)
                          .add(index * 7 + 6, "days")
                          .format("MMM-DD");
                        return (
                          "Week " + (index + 1) + "( " + a + " - " + b + " )"
                        );
                      })()}
                    />
                  );
                }
              )}
            </Tabs>
          </AppBar>
          {/* <Grid container spacing={3}>
            {Array.from(Array(activeTimeTable.noWeeks).keys()).map((index) => {
              console.log("generating weeks", index);
              return (
                <Grid item xs={12} name={"weekPanel" + index}>
                  <WeeklyTimetable index={index} />
                </Grid>
              );
            })}
          </Grid> */}
          {Array.from(Array(activeTimeTable.noWeeks).keys()).map((index) => {
            return (
              <TabPanel value={value} index={index}>
                <WeeklyTimetable index={index} />
              </TabPanel>
            );
          })}{" "}
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleDownloadTimeTable}
              >
                Download TimeTable
              </Button>
            </Grid>
          </Grid>
        </Fragment>
      ) : areLesBeingLoaded && activeTimeTable != null ? (
        <Grid
          container
          spacing={3}
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={3}
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <ScheduleIcon />
            <Typography variant="h6" style={{ marginTop: "32px" }}>
              There is no published timetable.
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
