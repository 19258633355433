/*
 * prepare assessment data for time series chart
 */

import * as dfd from "danfojs";
import moment from "moment";

export default function prepareAssessmentDataForTimeChart(tempAss, groupByKey) {
  // console.log("prep ass, tempAss", tempAss);
  let dfAssTimeChart = new dfd.DataFrame(tempAss);

  dfAssTimeChart = dfAssTimeChart.dropNa({ axis: 1 });

  //check that frame not empty after dropping undefined values
  if (dfAssTimeChart.shape[0] == 0) {
    return { isEmpty: true };
  }

  // dfAssTimeChart["month"] = dfAssTimeChart["date"];
  let dt = dfd.toDateTime(dfAssTimeChart["date"]);
  dfAssTimeChart.sortValues("date", { inplace: true });
  // dfAssTimeChart.print();

  dfAssTimeChart.addColumn("year", dt.year().values, { inplace: true });

  //add quarters column
  let quarters = [];
  for (let index = 0; index < dfAssTimeChart.shape[0]; index++) {
    let date = moment(dfAssTimeChart.iat(index, 3));
    let quarter = date.utc().quarter();
    let year = date.year();
    let quarterDate = "";
    switch (quarter) {
      case 1:
        quarterDate = "Jan-" + year;
        break;
      case 2:
        quarterDate = "Apr-" + year;
        break;
      case 3:
        quarterDate = "Jul-" + year;
        break;
      case 4:
        quarterDate = "Oct-" + year;
        break;
    }

    quarters.push(quarterDate);
  }
  let qs = new dfd.Series(quarters);
  dfAssTimeChart.addColumn("quarter", qs.values, {
    inplace: true,
  });

  //add months column
  let months = [];
  for (let index = 0; index < dfAssTimeChart.shape[0]; index++) {
    let date = moment(dfAssTimeChart.iat(index, 3));
    let month = date.month();
    let year = date.year();
    let monthDate = "";
    switch (month) {
      case 0:
        monthDate = "Jan-" + year;
        break;
      case 1:
        monthDate = "Feb-" + year;
        break;
      case 2:
        monthDate = "Mar-" + year;
        break;
      case 3:
        monthDate = "Apr-" + year;
        break;
      case 4:
        monthDate = "May-" + year;
        break;
      case 5:
        monthDate = "Jun-" + year;
        break;
      case 6:
        monthDate = "Jul-" + year;
        break;
      case 7:
        monthDate = "Aug-" + year;
        break;
      case 8:
        monthDate = "Sep-" + year;
        break;
      case 9:
        monthDate = "Oct-" + year;
        break;
      case 10:
        monthDate = "Nov-" + year;
        break;
      case 11:
        monthDate = "Dec-" + year;
        break;
    }

    months.push(monthDate);
  }
  let ms = new dfd.Series(months);
  dfAssTimeChart.addColumn("month", ms.values, {
    inplace: true,
  });

  //group data according to frequency
  let grpAssTimeSeries = dfAssTimeChart.groupby([groupByKey, "description"]);
  let summedAssTimeSeries = grpAssTimeSeries.col(["description"]).count();
  // summedAssTimeSeries.print();

  //sum by group
  let tempArr = [];
  let tempDict = {};
  if (groupByKey === "year") {
    dfd.toJSON(summedAssTimeSeries).forEach((item) => {
      const { year, description, description_count } = item;
      let t = {};
      t[description] = description_count;
      tempDict[year] = { ...tempDict[year], ...t };
    });
  } else if (groupByKey === "quarter") {
    dfd.toJSON(summedAssTimeSeries).forEach((item) => {
      const { quarter, description, description_count } = item;
      let t = {};
      t[description] = description_count;
      tempDict[quarter] = { ...tempDict[quarter], ...t };
    });
  } else {
    dfd.toJSON(summedAssTimeSeries).forEach((item) => {
      const { month, description, description_count } = item;
      let t = {};
      t[description] = description_count;
      tempDict[month] = { ...tempDict[month], ...t };
    });
  }

  //prepdata for graph
  let tempData = [];
  Object.keys(tempDict).forEach((key) => {
    tempData.push({
      name: key,
      "mainly positive": tempDict[key]["mainly positive"]
        ? tempDict[key]["mainly positive"]
        : 0,
      "needs work": tempDict[key]["mainly negative"]
        ? tempDict[key]["mainly negative"]
        : 0,
      mixed: tempDict[key]["mixed"] ? tempDict[key]["mixed"] : 0,
    });
  });
  return { data: tempData, isEmpty: false };
  // setAssessmentTimeSeriesData(tempData);

  // dfAssTimeChart.print();
}
