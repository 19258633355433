import React, { useEffect } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { constants } from "../../../helpers/constants";
import clsx from "clsx";
import { RoadmapService } from "../../../services/roadmap";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles } from "@material-ui/core/styles";
import { Factory } from "../../../helpers/factories";
import Switch from "@material-ui/core/Switch";

import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import { Button } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import AddEditRoadmapChildNodeDialog from "./addEditRoadmapChildNodeDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    position: "relative",
  },
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  progress: {
    width: "100%",
    backgroundColor: "#e9ecef",
    borderRadius: ".25rem",
    height: "0.6rem",
    lineHeight: 0,
    fontSize: ".75rem",
    overflow: "hidden",
    marginTop: "5px",
    marginBottom: "5px",
  },
  progressBarSelf: {
    height: "100%",
    backgroundColor: "#009be5",
    // backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  progressBarOther: {
    height: "100%",
    // backgroundColor: "#5bc0de",
    backgroundColor: "#f50057",
    transition: "width .6s ease",
  },
  contentWrapper: {
    margin: "40px 16px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#009be5",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
  fab: {
    margin: "0px",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ViewRoadmapDetails({
  opTypeRoadmapNode,
  setOpTypeRoadmapNode,
  roadmapNode,
  setRoadmapNode,
  roadmapNodes,
  setRoadmapNodes,
  setHasChildNodes,
  selectedRoadmapNodeIndex,
  setSelectedRoadmapNodeIndex,
  isViewRoadmapDetailsDialogOpen,
  handleCloseViewRoadmapDetailsDialog,
  setIsAddEditRoadmapNodeDialogOpen,
  handleCloseRoadmapNodeAddEditDialog,
  toastOptions,
  setToastOptions,
  handleCloseToast,
  isAddEditRoadmapNodeDialogOpenForStudent,
  setIsAddEditRoadmapNodeDialogOpenForStudent,
  handleCloseRoadmapNodeAddEditDialogForStudent,
  ...props
}) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  //console.log("selectedRoadmap", roadmapNode);

  const [roadmapChildNode, setRoadmapChildNode] = React.useState(null);

  const [selectedChildNodeIndex, setSelectedRoadmapChildNodeIndex] =
    React.useState("");

  const [roadmapChildNodes, setRoadmapChildNodes] = React.useState([]);

  //console.log("roadmapChildNode", roadmapChildNodes);

  const [expanded, setExpanded] = React.useState(false);

  const [isOpenDeleteNode, setIsOpenDeleteNode] = React.useState(false);
  const [deleteType, setDeleteType] = React.useState("");

  //show more roadmap details
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [opType, setOpType] = React.useState("add");

  //child node type ; update indicators OR add comment
  const [roadmapChildNodeType, setRoadmapChildNodeType] =
    React.useState("comment");

  //data is loading
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  //dialog add child node
  const [
    isAddEditRoadmapChidlNodeDialogOpen,
    setIsAddEditRoadmapChidlNodeDialogOpen,
  ] = React.useState(false);

  //handle close add/edit roadmapchild node
  const handleCloseAddEditRoadmapChildNodeDialog = () => {
    setIsAddEditRoadmapChidlNodeDialogOpen(false);
  };

  //handle lock roadmap; usually done by mentors

  const handleLockUnlockRoadmapNode = async () => {
    //save form data,
    let data = {
      status: roadmapNode.status === "locked" ? "unlocked" : "locked",
    };

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      const updatedRoadmapNode =
        await RoadmapService.updateRoadmapNodeByOrgIdAndRoadmapNodeIdStatus(
          currentUser.organisation.id,
          roadmapNode._id,
          data
        );

      //update status in roadmap node and roadmapnodes list

      setRoadmapNode({
        ...updatedRoadmapNode,
      });

      let temp = [...roadmapNodes];
      temp[selectedRoadmapNodeIndex] = { ...updatedRoadmapNode };
      setRoadmapNodes(temp);

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Goal status updated.",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  };

  //handle can mark as  complete roadmap; usually done by mentors

  const handleCanMarkAsCompleted = async () => {
    //save form data,
    let data = {
      canMarkAsCompleted:
        roadmapNode.canMarkAsCompleted === "yes" ? "no" : "yes",
    };

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      const updatedRoadmapNode =
        await RoadmapService.updateRoadmapNodeByOrgIdAndRoadmapNodeIdCanMarkAsCompleted(
          currentUser.organisation.id,
          roadmapNode._id,
          data
        );

      //update status in roadmap node and roadmapnodes list

      setRoadmapNode({
        ...updatedRoadmapNode,
      });

      let temp = [...roadmapNodes];
      temp[selectedRoadmapNodeIndex] = { ...updatedRoadmapNode };
      setRoadmapNodes(temp);

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Updated.",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  };

  //handel close delete node
  const handleCloseDeleteNode = () => {
    setIsOpenDeleteNode(false);
  };

  //handle delete node or child node
  const handleProceedDeleteNode = async () => {
    setToastOptions({
      ...toastOptions,
      open: true,
      message: "Deleting...",
    });

    //check delete type
    if (deleteType === "roadmapChildNode") {
      try {
        await RoadmapService.deleteRoadmapChildNodeByOrgIdAndRoadmapNodeIdAndRoadmapChildNodeId(
          currentUser.organisation.id,
          roadmapNode._id,
          roadmapChildNode._id
        );
        //remove element from childnodes array
        let temp = [...roadmapChildNodes];
        temp.splice(selectedChildNodeIndex, 1);
        setRoadmapChildNodes(temp);

        //close dialot
        handleCloseDeleteNode();

        setToastOptions({
          ...toastOptions,
          open: true,
          message: "Deleted",
        });
      } catch (err) {
        //close dialot
        handleCloseDeleteNode();

        setToastOptions({
          ...toastOptions,
          open: true,
          message: "Error deleting child node",
        });
      }
    } else {
      try {
        await RoadmapService.deleteRoadmapNodeByOrgIdAndRoadmapNodeId(
          currentUser.organisation.id,
          roadmapNode._id
        );
        //remove element from childnodes array
        let temp = [...roadmapNodes];
        temp.splice(selectedRoadmapNodeIndex, 1);
        setRoadmapNodes(temp);

        //close dialog
        handleCloseDeleteNode();

        //close view details dialog
        handleCloseViewRoadmapDetailsDialog();

        setToastOptions({
          ...toastOptions,
          open: true,
          message: "Deleted",
        });
      } catch (err) {
        //close dialot
        handleCloseDeleteNode();
        setToastOptions({
          ...toastOptions,
          open: true,
          message: "Error deleting goal",
        });
      }
    }
  };

  //get child nodes
  useEffect(() => {
    async function getRoadmapChildNodeByOrgIdAndRoadmapNodeId() {
      if (roadmapNode._id) {
        try {
          let data =
            await RoadmapService.getRoadmapChildNodeByOrgIdAndRoadmapNodeId(
              currentUser.organisation.id,
              roadmapNode._id
            );
          setHasChildNodes(data.length > 0 ? true : false);
          setRoadmapChildNodes(data);
          setIsDataLoading(false);
        } catch (err) {
          setErrorLoadingPage(true);
        }
      }
    }

    getRoadmapChildNodeByOrgIdAndRoadmapNodeId();
  }, [roadmapNode]);

  return (
    <Dialog
      fullScreen
      open={isViewRoadmapDetailsDialogOpen}
      onClose={handleCloseViewRoadmapDetailsDialog}
      TransitionComponent={Transition}
      PaperProps={{ style: { backgroundColor: "rgb(232 232 232)" } }}
    >
      {/* fab */}
      {/* Check the roadmap based on creator */}
      <div className={classes.fab}>
        {((roadmapNode.indicatorsOfSuccess &&
          roadmapNode.user &&
          roadmapNode.user._id === currentUser.id) ||
          (roadmapNode.createdBy &&
            roadmapNode.createdBy._id === currentUser.id)) && (
          <div style={{ marginBottom: "5px" }}>
            <Fab
              color="primary"
              aria-label="add"
              size="small"
              disabled={
                roadmapNode.createdBy &&
                roadmapNode.createdBy._id !== currentUser.id
              }
            >
              <Tooltip title="Update Indicators of Success">
                <EditIcon
                  onClick={() => {
                    setOpType("add");
                    setRoadmapChildNodeType("indicatorUpdate");
                    setRoadmapChildNode(Factory.createRoadmapChildNode());
                    setIsAddEditRoadmapChidlNodeDialogOpen(true);
                  }}
                />
              </Tooltip>
            </Fab>
          </div>
        )}

        <div style={{ marginBottom: "5px" }}>
          <Fab color="primary" aria-label="add" size="small">
            <Tooltip title="Add Reflection or Comment">
              <AddIcon
                onClick={() => {
                  setOpType("add");
                  setRoadmapChildNodeType("comment");
                  setRoadmapChildNode(Factory.createRoadmapChildNode());
                  setIsAddEditRoadmapChidlNodeDialogOpen(true);
                }}
              />
            </Tooltip>
          </Fab>
        </div>
      </div>
      {/* delete roadmap node or child node  */}
      <Dialog
        open={isOpenDeleteNode}
        onClose={handleCloseDeleteNode}
        aria-labelledby="alert-deleteNode-title"
        aria-describedby="alert-deleteNode-description"
      >
        {/* <DialogTitle id="alert-deleteNode-title">{"Delete Node"}</DialogTitle> */}
        <DialogTitle id="alert-deleteNode-title">
          {deleteType === "roadmapNode"
            ? "Delete Goal"
            : "Delete Comment / Reflection"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-deleteNode-description">
            {deleteType === "roadmapNode"
              ? "Are you sure you want to delete this goal? All reflections and comments will also  be deleted."
              : "Are you sure you want to delete this comment? If comment was saved as an assessment, the assessment won't be deleted."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProceedDeleteNode} color="primary">
            Yes
          </Button>
          <Button onClick={handleCloseDeleteNode} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      {/* add/edit roadmap child node */}
      <AddEditRoadmapChildNodeDialog
        opType={opType}
        setOpType={setOpType}
        roadmapChildNodeType={roadmapChildNodeType}
        roadmapChildNodes={roadmapChildNodes}
        setRoadmapChildNodes={setRoadmapChildNodes}
        roadmapNode={roadmapNode}
        selectedRoadmapNodeIndex={selectedRoadmapNodeIndex}
        roadmapNodes={roadmapNodes}
        setRoadmapNodes={setRoadmapNodes}
        roadmapChildNode={roadmapChildNode}
        setRoadmapChildNode={setRoadmapChildNode}
        selectedChildNodeIndex={selectedChildNodeIndex}
        isAddEditRoadmapChidlNodeDialogOpen={
          isAddEditRoadmapChidlNodeDialogOpen
        }
        handleCloseAddEditRoadmapChildNodeDialog={
          handleCloseAddEditRoadmapChildNodeDialog
        }
        toastOptions={toastOptions}
        setToastOptions={setToastOptions}
        handleCloseToast={handleCloseToast}
      />

      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseViewRoadmapDetailsDialog}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Roadmap Node Details
          </Typography>
        </Toolbar>
      </AppBar>
      {!errorLoadingPage ? (
        <Grid
          container
          spacing={0}
          style={{
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            style={{ margin: "20px 10px 0px 20px" }}
          >
            <Card className={classes.root}>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="roadmap child node"
                    className={classes.avatar}
                  >
                    {!roadmapNode.user ? "" : roadmapNode.user.firstName[0]}
                  </Avatar>
                }
                title={(() => {
                  if (!roadmapNode.user) {
                    return "";
                  }
                  return (
                    roadmapNode.user.firstName +
                    " " +
                    roadmapNode.user.lastName
                  ).length > 16
                    ? (
                        roadmapNode.user.firstName +
                        " " +
                        roadmapNode.user.lastName
                      ).slice(0, 16)
                    : roadmapNode.user.firstName +
                        " " +
                        roadmapNode.user.lastName;
                })()}
                subheader={
                  moment(roadmapNode.dateCreated).format("DD/MM/YYYY") +
                  " - " +
                  moment(roadmapNode.completionDate).format("DD/MM/YYYY")
                }
              />

              <CardContent>
                {/* <Typography variant="subtitle2">Domain</Typography> */}
                <Typography variant="subtitle2">
                  {(() => {
                    if (!roadmapNode.domainArea) {
                      return "";
                    }
                    return (
                      roadmapNode.domainArea.displayName +
                      " (" +
                      roadmapNode.role +
                      ")"
                    );
                  })()}
                </Typography>
                <br />
                <Typography variant="subtitle2">Goal</Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {roadmapNode.goal}
                </Typography>
                <br />
                {/* new code to display the concept */}
                {roadmapNode.createdBy && (
                  <>
                    <Typography variant="subtitle2">Concepts</Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {/* Add this line */}
                      {roadmapNode.concept ? (
                        roadmapNode.concept.split("\n").map((item, index) => (
                          <span key={index}>
                            {item}
                            <br />
                          </span>
                        ))
                      ) : (
                        <span>&nbsp;</span>
                      )}
                    </Typography>
                  </>
                )}

                <br />

                {/* new roadmaps have skills, processes and watermarks */}
                {roadmapNode.skills &&
                  roadmapNode.processes &&
                  roadmapNode.watermarks && (
                    <div style={{ marginTop: "8px" }}>
                      <Typography variant="subtitle2" gutterBottom>
                        Skills, Processes and Watermarks
                      </Typography>
                      <div style={{ marginLeft: "12px" }}>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ fontStyle: "italic" }}>
                            Skills: {roadmapNode.skills.join(", ")}
                          </span>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ fontStyle: "italic" }}>
                            Processes: {roadmapNode.processes.join(", ")}
                          </span>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <span style={{ fontStyle: "italic" }}>
                            Watermarks: {roadmapNode.watermarks.join(", ")}
                          </span>
                        </Typography>
                      </div>
                    </div>
                  )}
              </CardContent>
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography variant="subtitle2">Action Plan</Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {roadmapNode.actionPlan.split("\n").map(function (item) {
                      return (
                        <span>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                  </Typography>
                  <br />

                  {/* for older model of roadmap */}
                  {roadmapNode.indOfSuccess && (
                    <div>
                      <Typography variant="subtitle2">
                        Indicators of Success
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {roadmapNode.indOfSuccess
                          .split("\n")
                          .map(function (item) {
                            return (
                              <span>
                                {item}
                                <br />
                              </span>
                            );
                          })}
                      </Typography>
                    </div>
                  )}
                  {/* for new model of roadmap */}
                  {roadmapNode.indicatorsOfSuccess &&
                    roadmapNode.indicatorsOfSuccess.length > 0 && (
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "10px",
                            marginTop: "10px",
                            justifyContent: "space-between ",
                            fontWeight: "bold",
                          }}
                        >
                          <div style={{ flexBasis: "70%" }}>Indicator</div>
                          <div style={{ flexBasis: "30%" }}>Progress</div>
                        </div>
                        {roadmapNode.indicatorsOfSuccess.map((item, index) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                marginBottom: "10px",
                                justifyContent: "space-between ",
                              }}
                              key={index + "ind"}
                            >
                              <div style={{ flexBasis: "70%" }}>
                                {item.indicator}
                              </div>
                              <div style={{ flexBasis: "30%" }}>
                                <div className={classes.progress}>
                                  <div
                                    className={classes.progressBarSelf}
                                    role="progressbar"
                                    aria-valuenow="100"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{
                                      width: `${item.indicatorProgress}%`,
                                    }}
                                  ></div>
                                </div>
                                <div>
                                  <span style={{ float: "right" }}>
                                    {item.indicatorProgress + "%"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  {/* allow student to to mark as compelted */}
                  {constants.roles.l1.includes(currentUser.role) ||
                  (roadmapNode.mentor &&
                    roadmapNode.mentor._id === currentUser.id) ? (
                    <div style={{ display: "flex" }}>
                      <div style={{ fontWeight: "bold" }}>
                        Allow all indicators to be marked as completed (100%)?
                      </div>
                      <div style={{ marginRight: "10px" }}>
                        <Switch
                          checked={
                            roadmapNode.canMarkAsCompleted === "yes"
                              ? true
                              : false
                          }
                          onChange={handleCanMarkAsCompleted}
                          name="canMarkAsCompleted"
                          size="small"
                          color="primary"
                          inputProps={{
                            "aria-label": "can mark as completed checkbox",
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* edit, delete, lock */}
                  {/* <div style={{ float: "right", padding: "10px 0 10px  0" }}>
                    {roadmapNode.status != "locked" &&
                    roadmapNode.user &&
                    roadmapNode.user._id === currentUser.id ? (
                      <Tooltip title="Edit">
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            setOpTypeRoadmapNode("edit");
                            setIsAddEditRoadmapNodeDialogOpen(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {constants.roles.l1.includes(currentUser.role) ||
                    (roadmapNode.mentor &&
                      roadmapNode.mentor._id === currentUser.id) ? (
                      <Tooltip
                        title={
                          roadmapNode.status === "locked" ? "Unlock" : "Lock"
                        }
                      >
                        <IconButton
                          aria-label="lock"
                          onClick={() => {
                            handleLockUnlockRoadmapNode();
                          }}
                        >
                          {roadmapNode.status === "locked" ? (
                            <LockIcon />
                          ) : (
                            <LockOpenIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    {roadmapNode.status != "locked" &&
                    roadmapNode.user &&
                    roadmapNode.user._id === currentUser.id ? (
                      <Tooltip title="Delete">
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setDeleteType("roadmapNode");
                            setIsOpenDeleteNode(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </div> */}
                  {/* new code, creator can edit and delete the */}
                  <div style={{ float: "right", padding: "10px 0 10px 0" }}>
                    {/* {(roadmapNode.status !== "locked" &&
                      roadmapNode.user &&
                      roadmapNode.user._id === currentUser.id) ||
                    roadmapNode.creator ? (
                      <Tooltip title="Edit">
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            setOpTypeRoadmapNode("edit");
                            setIsAddEditRoadmapNodeDialogOpen(true);
                            setIsAddEditRoadmapNodeDialogOpenForStudent(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null} */}
                    {/* display different dialogform based on the creator raodmap */}
                    {(roadmapNode.status !== "locked" &&
                      roadmapNode.user &&
                      roadmapNode.user._id === currentUser.id) ||
                    roadmapNode.createdBy ? (
                      <Tooltip title="Edit">
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            setOpTypeRoadmapNode("edit");
                            if (roadmapNode.createdBy) {
                              setIsAddEditRoadmapNodeDialogOpenForStudent(true);
                            } else {
                              setIsAddEditRoadmapNodeDialogOpen(true);
                            }
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    {constants.roles.l1.includes(currentUser.role) ||
                    (roadmapNode.mentor &&
                      roadmapNode.mentor._id === currentUser.id) ? (
                      <Tooltip
                        title={
                          roadmapNode.status === "locked" ? "Unlock" : "Lock"
                        }
                      >
                        <IconButton
                          aria-label="lock"
                          onClick={() => {
                            handleLockUnlockRoadmapNode();
                          }}
                        >
                          {roadmapNode.status === "locked" ? (
                            <LockIcon />
                          ) : (
                            <LockOpenIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    {(roadmapNode.status !== "locked" &&
                      roadmapNode.user &&
                      roadmapNode.user._id === currentUser.id) ||
                    roadmapNode.createdBy ? (
                      <Tooltip title="Delete">
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setDeleteType("roadmapNode");
                            setIsOpenDeleteNode(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </div>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            {roadmapChildNodes.length > 0 ? (
              <Timeline align="alternate">
                {roadmapChildNodes.map((roadmapChildNode, index) => {
                  return (
                    <TimelineItem key={"roadmapChildNode" + index}>
                      <TimelineOppositeContent>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ fontWeight: "bold" }}
                        >
                          {moment(roadmapChildNode.dateCreated).format(
                            "DD/MM/YYYY h:mm A"
                          )}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot
                          variant="outlined"
                          color={
                            roadmapChildNode.commentType === "self"
                              ? "primary"
                              : "secondary"
                          }
                        >
                          {roadmapChildNode.commentType === "self" ? (
                            <AccountCircleIcon color="primary" />
                          ) : (
                            <SupervisedUserCircleIcon color="secondary" />
                          )}
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} className={classes.paper}>
                          <Typography variant="subtitle2">
                            {roadmapChildNode.commenter.firstName +
                              " " +
                              roadmapChildNode.commenter.lastName}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {roadmapChildNode.comment}
                          </Typography>
                          {/* old model has progres self and other for goal */}
                          {roadmapChildNode.progress && (
                            <div style={{ margin: "8px" }}>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  marginBottom: "5px",
                                }}
                              >
                                <span style={{ float: "left" }}>Progress</span>
                                <span style={{ float: "right" }}>
                                  {roadmapChildNode.progress + "%"}
                                </span>
                              </p>
                              <div className={classes.progress}>
                                <div
                                  className={(() => {
                                    return roadmapChildNode.commentType ===
                                      "self"
                                      ? classes.progressBarSelf
                                      : classes.progressBarOther;
                                  })()}
                                  role="progressbar"
                                  aria-valuenow="100"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{
                                    width: `${roadmapChildNode.progress}%`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          )}
                          {/* new model has indicators of success */}
                          {roadmapChildNode.indicatorsOfSuccess &&
                            roadmapChildNode.indicatorsOfSuccess.length > 0 && (
                              <div
                                style={{
                                  textAlign: "left",
                                  padding: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    marginBottom: "10px",
                                    marginTop: "10px",
                                    justifyContent: "space-between ",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <div style={{ flexBasis: "70%" }}>
                                    Indicator
                                  </div>
                                  <div style={{ flexBasis: "30%" }}>
                                    Progress
                                  </div>
                                </div>
                                {roadmapChildNode.indicatorsOfSuccess.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "10px",
                                          justifyContent: "space-between ",
                                        }}
                                        key={index + "ind"}
                                      >
                                        <div style={{ flexBasis: "70%" }}>
                                          {item.indicator}
                                        </div>
                                        <div style={{ flexBasis: "30%" }}>
                                          <div className={classes.progress}>
                                            <div
                                              className={
                                                classes.progressBarSelf
                                              }
                                              role="progressbar"
                                              aria-valuenow="100"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                              style={{
                                                width: `${item.indicatorProgress}%`,
                                              }}
                                            ></div>
                                          </div>
                                          <div>
                                            <span style={{ float: "right" }}>
                                              {item.indicatorProgress + "%"}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          {roadmapChildNode.commenter._id === currentUser.id ? (
                            <div style={{}}>
                              <Tooltip title="Edit">
                                <IconButton
                                  aria-label="edit"
                                  onClick={() => {
                                    setOpType("edit");
                                    setSelectedRoadmapChildNodeIndex(index);
                                    roadmapChildNode.indicatorsOfSuccess
                                      ? setRoadmapChildNodeType(
                                          "indicatorUpdate"
                                        )
                                      : setRoadmapChildNodeType("comment");
                                    setRoadmapChildNode(
                                      roadmapChildNodes[index]
                                    );
                                    setIsAddEditRoadmapChidlNodeDialogOpen(
                                      true
                                    );
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              {/* to delete comment */}
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => {
                                    setSelectedRoadmapChildNodeIndex(index);
                                    setRoadmapChildNode({
                                      ...roadmapChildNodes[index],
                                    });
                                    setDeleteType("roadmapChildNode");
                                    setIsOpenDeleteNode(true);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ) : null}
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
              </Timeline>
            ) : (
              <div
                style={{
                  minHeight: 300,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "block" }}>
                  <HourglassEmptyIcon style={{ fontSize: "40px" }} />
                </div>
                <div style={{ display: "block" }}>
                  <Typography variant="h6" className={classes.textCenter}>
                    No comments or reflections have been added.
                  </Typography>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      ) : (
        <ErrorLoadingPage />
      )}
    </Dialog>
  );
}

export default ViewRoadmapDetails;
