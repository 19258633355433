import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  TextField,
  Link,
  Snackbar,
} from "@material-ui/core";
import { useAppContext } from "../../libs/contextLib";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import blurredMountain from "../../assets/blurredMountainSunRays.jpg";
import { Auth } from "../../services/auth";

//styles

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "100vh",
    width: "100%",
    //backgroundColor: "#3fc1d5",
    backgroundImage: `url(${blurredMountain})`,
    backgroundSize: "cover",
  },
  root: {
    display: "flex",
    backgroundColor: "#f5f5f5",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: 5,
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "30px 0px 80px 0px",
    width: "100%",
    "& > *": {
      margin: theme.spacing(1),
      width: "75%",
    },
  },
  title: {
    padding: "20px 0px 20px 0px",
    //fontFamily: "Sacramento, cursive",
    fontFamily: "Playfair Display, serif",
    //color: GREY[600],
  },
}));

//functions

function Login() {
  const { isAuthenticated, userHasAuthenticated, setCurrentUser } =
    useAppContext();

  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("dfdfdff");
  const classes = useStyles();

  //redirect to home if already authenticaed
  //pre-load verify token
  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
  }, []);

  //snackbar
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  //login
  async function handleSubmit(event) {
    event.preventDefault(); //prevent browser default submit

    Auth.login(username, password).then(
      (user) => {
        setCurrentUser(user);
        userHasAuthenticated(true);
        history.push("/");
      },
      (error) => {
        console.log("logineerro", error);
        setMessage(error.message);
        setOpen(true);
      }
    );
  }

  //validate form simple
  function validateForm() {
    return username.length >= 3 && password.length >= 8;
  }

  return (
    <Grid
      container
      className={classes.formContainer}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={10} sm={6} md={4}>
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          align="center"
          color="secondary"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5" className={classes.title + " logoColor"}>
            Druk Gyalpo's Institute
            <br />
            The Bhutan Baccalaureate
          </Typography>
          <TextField
            id="txtUsername"
            label="Username"
            variant="outlined"
            value={username}
            onChange={(e) => {
              setOpen(false);
              setUsername(e.target.value);
            }}
            autoFocus
          />
          <br />
          <TextField
            id="txtPassword"
            label="Password"
            variant="outlined"
            value={password}
            type="password"
            onChange={(e) => {
              setOpen(false);
              setPassword(e.target.value);
            }}
          />
          <br />
          <Button
            color="primary"
            onClick={handleSubmit}
            variant="contained"
            size="large"
            type="submit"
            disabled={!validateForm(username, password)}
          >
            Login
          </Button>
          <Typography variant="caption">
            <Link href="#">Forgot your password?</Link>
          </Typography>
        </form>
      </Grid>
      <Grid item xs={12}>
        <Snackbar open={open} autoHideDuration={6000}>
          <Alert severity="error">{message}</Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}

export default Login;
