/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const OfTheDayService = {
  getDzoWordOfTheDay,
  getEngWordOfTheDay,
  getQuoteOfTheDay,
};

function getDzoWordOfTheDay() {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_dzoWordOfTheDay;

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.dzoWordOfTheDay;
    });
}

function getEngWordOfTheDay() {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_engWordOfTheDay;

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.engWordOfTheDay;
    });
}

function getQuoteOfTheDay() {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_quoteOfTheDay;

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.quoteOfTheDay;
    });
}
