import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
//import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
//import { useAppContext } from "../../../libs/contextLib";
import { OfTheDayService } from "../../../services/ofTheDay";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "32px",
  },
  roundedBadge: {
    borderRadius: "4px",
    backgroundColor: "#eea39b",
    color: "white",
    display: "inline-block",
    padding: "4px 8px",
    minHeight: "26px",
    maxHeight: "26px",
    textAlign: "center",
    fontSize: "13px",
  },
}));

function WordOfTheDay(props) {
  const classes = useStyles();

  const [engWordOfTheDay, setEngWordOfTheDay] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorRetrieving, setErrorRetrieving] = React.useState(false);

  //pre-load verify token
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      setEngWordOfTheDay(await OfTheDayService.getEngWordOfTheDay());
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrorRetrieving(true);
      // console.log(err);
    }
  }

  return (
    <div className={classes.root}>
      {/* top row */}
      <Grid
        container
        spacing={3}
        style={{
          borderRadius: "10px",
          minHeight: "100px",
          backgroundColor: "#f6eceb",
        }}
      >
        <Grid item xs={12} sm={8}>
          <span className={classes.roundedBadge}>Word Of The Day</span>
        </Grid>
        {isLoading ? (
          <div
            style={{
              padding: "0px 12px",
              minHeight: "60px",
              maxHeight: "60px",
              width: "100%",
            }}
          >
            <Skeleton variant="rect" width="100%" height="100%" />
          </div>
        ) : errorRetrieving ? (
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              minHeight: "60px",
              maxHeight: "60px",
            }}
          >
            <span>Error retrieving food for thought</span>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              overflow: "scroll",
              paddingTop: "0",
              minHeight: "60px",
              maxHeight: "60px",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontStyle: "italic",
                fontSize: "13px",
                marginRight: "5px",
              }}
            >
              {engWordOfTheDay.word}
            </span>
            <span style={{ fontSize: "13px" }}>{engWordOfTheDay.meaning}</span>
          </Grid>
        )}
        <Grid item xs={12} sm={12} style={{ paddingBottom: "2px" }}></Grid>
      </Grid>
    </div>
  );
}

export default WordOfTheDay;
