import React, { useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { RoadmapService } from "../../../../services/roadmap";
// import { RoadmapProgressChart } from "./roadmapProgressChart";
import RadarProgressChart from "./radarProgressChart";

import SPWBarChart from "./spwBarChart";
import DomainLineChart from "./domainLineChart";
import GoalLineChart from "./goalLineChart";
import { Select } from "@material-ui/core";
import { MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { constants } from "../../../../helpers/constants";

import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";

import * as dfd from "danfojs";
// import natural from "natural";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import ErrorLoadingPage from "../../../errorPages/errorLoadingPage";
import Toast from "../../../helperComponents/toast";
import SearchUserAdvanced from "../../../helperComponents/searchUserAdvanced";

import { Factory } from "../../../../helpers/factories";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //overflow: "auto",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    display: "flex",
  },
  comp: {
    padding: theme.spacing(2),
  },
  filterElements: {
    margin: theme.spacing(2),
    width: "90%",
  },
  graphOuterContainer: {
    padding: "5px",
    display: "flex",
    justifyContent: "center",
  },
  graphInnerContainer: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    width: "100%",
  },
  filter: {
    backgroundColor: "white",
    padding: "5px",
    borderRadius: "10px",
  },

  //added my own style for redflag
  fixedHeightContainer: {
    height: "200px",
  },
  fixedGridContainer: {
    height: "400px", // Adjust the height as needed
    // Add any other styles you want to apply
  },
  status: {
    fontWeight: "bold",
    color: "#4285f4",
  },
}));

export default function RoadmapTracker(props) {
  //added new code to get user Id,

  const location = useLocation();
  console.log("Location state:", location.state);

  const { _id } = location.state || {};

  const classes = useStyles();
  const { currentUser } = useAppContext();
  const [isDataLoading, setIsDataLoading] = React.useState(false);

  //valid date
  const [isDateValid, setIsDateValid] = React.useState(true);

  //list of assessments
  const [assessments, setAssessments] = React.useState([]);

  //mentor of student
  const [mentor, setMentor] = React.useState(null);

  //list of roadmap goals
  const [roadmapNodes, setRoadmapNodes] = React.useState([]);

  console.log("roadmapNodes", roadmapNodes);

  const [user, setUser] = React.useState(Factory.createUser());

  const [domainArea, setDomainArea] = React.useState(
    Factory.createDomainArea()
  );

  //dates
  const [selectedFromDate, setSelectedFromDate] = React.useState(
    moment().startOf("year")
  );
  const [selectedToDate, setSelectedToDate] = React.useState(moment());

  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  const [userQueryOptions, setUserQueryOptions] = React.useState({});

  const [queryString, setQueryString] = React.useState({ limit: 5000 });

  const [selectDataFilter, setSelectDataFilter] = React.useState(
    "All Areas and Domains"
  );

  const [dataForCharts, setDataForCharts] = React.useState([]);
  //console.log("dataforChart", dataForCharts);

  const [chartDomainArea, setChartDomainArea] = React.useState("");
  //console.log("chartdomain", chartDomainArea);

  const [individualGoalToChartData, setIndividualGoalToChartData] =
    React.useState([]);

  const [comments, setComments] = React.useState("");

  //for individual goal chart for  domains
  const [roadmapFocusNodeIndex, setRoadmapFocusNodeIndex] = React.useState("");
  const [clearIndividualGoalLineChart, setClearIndividualGoalLineChart] =
    React.useState(false);

  const [referenceLineDate, setReferenceLineDate] = React.useState(null);

  //selected goal by clicking on domain or area line chart
  const [selectedGoalTitle, setSelectedGoalTitle] = React.useState("");

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 2000,
  });

  //New Codes
  // Added new Code to generate the particular Tracker of _id
  useEffect(() => {
    // Set user _id based on _id or currentUser.id
    const userId = _id ? _id : currentUser.id;
    setUser({ ...user, _id: userId });
    setQueryString({ ...queryString, user: userId });
  }, [_id, currentUser.id]);

  //Previous code

  //   if (!constants.roles.l1.includes(currentUser.role)) {
  //     setUser({ ...user, _id: currentUser.id });
  //     setQueryString({
  //       ...queryString,
  //       user: currentUser.id,
  //     });
  //   }
  // }, []);

  //pre-load
  useEffect(() => {
    //only fetch assessments if dates are valid and user is present

    //fetch assessments and roadmap data
    if (props.startFetchData && isDateValid && user && user._id) {
      (async () => {
        setIsDataLoading(true);
        try {
          //   //assessments
          //   const newRows = await loadAssessments();
          //   setAssessments(newRows);
          //roadmap
          setChartDomainArea(null);
          setClearIndividualGoalLineChart(true);
          getRoadmap();
          //   //mentor
          //   getMentorsByOrgIdAndUserId();
          //   //overall student assessments
          //   getOverallAssessmentDataStudentsByOrgId();
          //   //get attednace
          //   getNoLEsMissed();

          setIsDataLoading(false);
        } catch (err) {
          setErrorLoadingPage(true);
        }
      })();
    } else {
      setToastOptions({
        ...toastOptions,
        duration: 3000,
        message:
          "This features is for roadmaps created after Dec 2023. Max date range is 2 years.",
        open: true,
      });
    }
  }, [props.startFetchData, queryString]);

  //get roadmap
  async function getRoadmap() {
    try {
      setRoadmapNodes(
        await RoadmapService.getRoadmapNodesAndRoadmapChildNodesByOrgId(
          currentUser.organisation.id,
          queryString
        )
      );

      setIsDataLoading(false);
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        duration: 3000,
        message: "Error fetching roadmap data",
        open: true,
      });
      //   setErrorLoadingPage(true);
    }
  }

  //get no les missed

  //get student's mentor

  //get ovearll assessment summary data for all students to compare with selected student

  //query string update for user and domain, date handles itself
  useEffect(() => {
    //set initial query string
    setQueryString({
      ...queryString,
      student: user._id ? user._id : "", //assessment uses student
      user: user._id ? user._id : _id ? _id : "", //roadmpa uses user
      domainArea: domainArea._id ? domainArea._id : "",
    });
  }, [domainArea, user]);

  //call prepare data frame once assessments loaded
  useEffect(() => {
    function prepData() {
      let temp = [];
      roadmapNodes.forEach((node) => {
        if (node.indicatorsOfSuccess) {
          // getting the all indicator
          let indicatorsArray = node.indicatorsOfSuccess.map(
            (indicatorObj) => ({
              indicator: indicatorObj.indicator,
            })
          );
          let elem = {
            //added goal
            goal: node.goal,
            domainArea: node.domainArea,
            domainAreaShortName: node.domainArea.slice(0, 3),
            dateCreated: moment(node.dateCreated).format("YYYY-MM-DD"),
            //added to completion date for flags
            completionDate: moment(node.completionDate).format("YYYY-MM-DD"),
            // progress: (() => {
            //   let sum = node.indicatorsOfSuccess.reduce((acc, indicator) => {
            //     return acc + indicator.indicatorProgress;
            //   }, 0);
            //   return sum;
            // })(),

            // new array  for the indicator

            indicator: indicatorsArray,

            progressAverage: (() => {
              let sum = node.indicatorsOfSuccess.reduce((acc, indicator) => {
                return acc + indicator.indicatorProgress;
              }, 0);
              return sum / node.indicatorsOfSuccess.length;
            })(),
            skills: node.skills,
            processes: node.processes,
            watermarks: node.watermarks,
          };

          temp.push(elem);
        }
      });
      //printing the data
      //console.log("predata", temp);

      //prep data according to all, areas only or donamins oonly
      let df = new dfd.DataFrame(temp);
      //df.print();

      switch (selectDataFilter) {
        case "All Domains":
          let condition1 = df["domainArea"]
            .ne("Emotional")
            .and(df["domainArea"].ne("Cerebral"))
            .and(df["domainArea"].ne("Spiritual"))
            .and(df["domainArea"].ne("Physical"))
            .and(df["domainArea"].ne("Social"));
          df = df.loc({ rows: condition1 });

          //   df.print();
          break;
        case "All Areas":
          let condition2 = df["domainArea"]
            .eq("Emotional")
            .or(df["domainArea"].eq("Cerebral"))
            .or(df["domainArea"].eq("Spiritual"))
            .or(df["domainArea"].eq("Physical"))
            .or(df["domainArea"].eq("Social"));
          df = df.loc({ rows: condition2 });
          //   df.print();
          break;
        default:
      }
      setDataForCharts(dfd.toJSON(df));
    }
    if (roadmapNodes.length > 0) {
      prepData();
    } else {
      setDataForCharts([]);
    }
  }, [roadmapNodes, selectDataFilter]);

  //prepare data for dataframe and charts

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  //handle From date change
  const handleFromDateChange = (date) => {
    // setIsDateValid(moment(date).isAfter(moment("01/12/2023", "DD-MM-YYYY")));
    let cond1 = moment(date).isAfter(moment("01/12/2023", "DD-MM-YYYY"));
    let cond2 = moment(selectedToDate).diff(moment(date), "years") < 2;

    if (cond1 && cond2) {
      setIsDateValid(true);
    } else {
      setIsDateValid(false);
    }
    if (date === "Invalid Date" || date === null) {
    } else {
      setSelectedFromDate(date);
      setQueryString({
        ...queryString,
        from: moment(date),
      });
    }
  };

  //handle To date change
  const handleToDateChange = (date) => {
    let cond1 = moment(date).isAfter(moment(selectedFromDate));
    let cond2 = moment(date).diff(moment(selectedFromDate), "years") < 2;
    if (cond1 && cond2) {
      setIsDateValid(true);
    } else {
      setIsDateValid(false);
    }

    if (date === "Invalid Date" || date === null) {
    } else {
      setSelectedToDate(date);
      setQueryString({
        ...queryString,
        to: moment(date),
      });
    }
  };

  //

  if (errorLoadingPage) {
    return (
      <ErrorLoadingPage message="There was a problem loading the page. Try refreshing the page." />
    );
  } else {
    return (
      <Fragment>
        <Toast
          toastOptions={toastOptions}
          handleCloseToast={handleCloseToast}
        />

        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container className={classes.filter}>
                {constants.roles.l1.includes(currentUser.role) ? (
                  <Grid item xs={12} sm={12}>
                    {/* <SearchUser
                    label={"Search Student"}
                    user={user}
                    setUser={setUser}
                    role={"student"}
                    style={{ display: "flex" }}
                  /> */}
                    <SearchUserAdvanced
                      user={user}
                      setUser={setUser}
                      userQueryOptions={userQueryOptions}
                      setUserQueryOptions={setUserQueryOptions}
                      style={{ display: "flex" }}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} sm={12}>
                  <Grid container>
                    {/* <Grid item xs={12} sm={3}>
                      <SelectDomainArea
                        domainArea={domainArea}
                        setDomainArea={setDomainArea}
                        showAllOption={true}
                        orgId={currentUser.organisation.id}
                        type={""}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={3}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={classes.filterElements}
                          margin="normal"
                          inputVariant="outlined"
                          id="date-picker-dialog-from"
                          label="From"
                          format="dd/MM/yyyy"
                          value={selectedFromDate}
                          //   minDate={"01/12/2023"}
                          //   minDateMessage={"Date cannot be before 01/12/2023"}
                          onChange={handleFromDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          className={classes.filterElements}
                          inputVariant="outlined"
                          id="date-picker-dialog-t0"
                          label="To"
                          format="dd/MM/yyyy"
                          value={selectedToDate}
                          onChange={handleToDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* 
            top row 
            */}
            <Grid item xs={12} style={{ paddingBottom: "2px" }}>
              {/* i added some style */}
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  className={classes.graphOuterContainer}
                >
                  <div className={classes.graphInnerContainer}>
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                    >
                      <InputLabel id="type-select-label">Select</InputLabel>
                      <Select
                        labelId="type-select-domainAreaAll"
                        id="type-select-domainAreaAll"
                        size="small"
                        value={selectDataFilter}
                        onChange={(e) => {
                          setSelectDataFilter(e.target.value);
                          setChartDomainArea(null);
                          setClearIndividualGoalLineChart(true);
                        }}
                        label="Select"
                      >
                        <MenuItem value={"All Areas and Domains"}>All</MenuItem>
                        <MenuItem value={"All Areas"}>Areas Only </MenuItem>
                        <MenuItem value={"All Domains"}>Domains Only</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classes.graphOuterContainer}
                >
                  <div className={classes.graphInnerContainer}>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          padding: "30px 30px 0px 30px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{
                              color: "#84888f",
                            }}
                          >
                            {"Overall Progress"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div>
                          <RadarProgressChart
                            dataForCharts={dataForCharts}
                            chartDomainArea={chartDomainArea}
                            setChartDomainArea={setChartDomainArea}
                            clearIndividualGoalLineChart={
                              clearIndividualGoalLineChart
                            }
                            setClearIndividualGoalLineChart={
                              setClearIndividualGoalLineChart
                            }
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.graphOuterContainer}
                >
                  <div className={classes.graphInnerContainer}>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          padding: "30px 30px 0px 30px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{
                              color: "#84888f",
                            }}
                          >
                            SPW {": "}
                            {chartDomainArea
                              ? chartDomainArea
                              : selectDataFilter}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div>
                          <SPWBarChart
                            dataForCharts={dataForCharts}
                            chartDomainArea={chartDomainArea}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/* 
            next row 
            */}
            <Grid item xs={12} style={{ paddingTop: "2px" }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  className={classes.graphOuterContainer}
                >
                  <div className={classes.graphInnerContainer}>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          padding: "30px 30px 0px 30px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{
                              color: "#84888f",
                            }}
                          >
                            {chartDomainArea
                              ? chartDomainArea + " Roadmap Goals"
                              : "Select a domain or area"}
                          </Typography>
                        </div>
                        {/* <div>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{
                              color: "#84888f",
                            }}
                          >
                            {selectedGoalTitle
                              ? selectedGoalTitle + " Roadmap Goals"
                              : "Select a domain or area"}
                          </Typography>
                        </div> */}
                      </Grid>
                      <Grid item xs={12}>
                        <DomainLineChart
                          roadmapNodes={roadmapNodes}
                          chartDomainArea={chartDomainArea}
                          setChartDomainArea={setChartDomainArea}
                          dataForCharts={dataForCharts}
                          setIndividualGoalToChartData={
                            setIndividualGoalToChartData
                          }
                          roadmapFocusNodeIndex={roadmapFocusNodeIndex}
                          setRoadmapFocusNodeIndex={setRoadmapFocusNodeIndex}
                          setReferenceLineDate={setReferenceLineDate}
                          clearIndividualGoalLineChart={
                            clearIndividualGoalLineChart
                          }
                          setClearIndividualGoalLineChart={
                            setClearIndividualGoalLineChart
                          }
                          setSelectedGoalTitle={setSelectedGoalTitle}
                          //added new one for goal title
                          selectedGoalTitle={selectedGoalTitle}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={7}
                  className={classes.graphOuterContainer}
                >
                  <div className={classes.graphInnerContainer}>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          padding: "30px 30px 0px 30px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{
                              color: "#84888f",
                            }}
                          >
                            {"Goal Chart with Indicators"}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <GoalLineChart
                          individualGoalToChartData={individualGoalToChartData}
                          referenceLineDate={referenceLineDate}
                          selectedGoalTitle={selectedGoalTitle}
                        />

                        {/* <div style={{ width: "80%" }}>
                          {" "}
                          <GoalLineChart
                            individualGoalToChartData={
                              individualGoalToChartData
                            }
                            setComments={setComments}
                            referenceLineDate={referenceLineDate}
                          />
                        </div>
                        <div
                          style={{
                            width: "20%",
                            padding: "5px",
                            overflow: "scroll",
                            fontSize: "12px",

                            height: "75%",
                            boxShadow: "2px 2px #efeded",
                            marginRight: "10px",
                            border: "1px solid #efeded",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Comments</span>
                          <br />
                          <span style={{ fontStyle: "italic" }}>
                            {comments}
                          </span>
                        </div> */}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    );
  }
}
