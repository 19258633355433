import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function UploadTimeTableDialog({
  uploadOutputText,
  setUploadOutputText,
  isFileProcessed,
  toastOptions,
  setToastOptions,
  handleCloseToast,
  activeTimeTable,
  isUploadTimeTableDialogOpen,
  handleCloseUploadTimeTableDialog,
  ...props
}) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  return (
    <Fragment>
      <Dialog
        open={isUploadTimeTableDialogOpen}
        onClose={handleCloseUploadTimeTableDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          Uploading Timetable File
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{ display: isFileProcessed ? "none" : "block" }}
            >
              <div style={{ width: "100%" }}>
                <LinearProgress />
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                variant="outlined"
                rows={16}
                margin="dense"
                id="body"
                name="body"
                label=""
                type="text"
                value={uploadOutputText}
                onChange={null}
                InputProps={{
                  style: {
                    fontSize: "12px",
                  },
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUploadTimeTableDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default UploadTimeTableDialog;
