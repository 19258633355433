import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";

export default function AuthenticatedRoute({
  children,
  allowedRoles,
  ...rest
}) {
  //const { pathname, search } = useLocation();
  const { isAuthenticated, currentUser } = useAppContext();
  // if (isAuthenticated) {
  //   <Route {...rest}>children</Route>;
  // } else {
  //   <Route {...rest}>children</Route>;
  // }

  // const history = useHistory();
  // console.log("path", path);
  // if (isAuthenticated && path == "/") {
  //   history.push("/announcements");
  // }

  // console.log("alowedRoles", allowedRoles);

  if (!isAuthenticated) {
    return <Route {...rest}>{<Redirect to="/" />}</Route>;
  }

  if (allowedRoles && !allowedRoles.includes(currentUser.role)) {
    return <Route {...rest}>{<Redirect to="/" />}</Route>;
  }

  return <Route {...rest}>{children}</Route>;

  // return (
  //   // <Route {...rest}>{isAuthenticated ? children : <Redirect to="/" />}</Route>

  //   // <Route {...rest}>
  //   //   {isAuthenticated ? (
  //   //     <AuthComp />
  //   //   ) : (
  //   //     //<Redirect to={`/login?redirect=${pathname}${search}`} />
  //   //     <UnauthComp />
  //   //   )}
  //   // </Route>
  // );
}
