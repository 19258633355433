import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { AssessmentService } from "../../../services/assessment";
import { RoadmapService } from "../../../services/roadmap";
import { MenteeService } from "../../../services/mentee";

import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import Typography from "@material-ui/core/Typography";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import * as dfd from "danfojs";
// import natural from "natural";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import Toast from "../../helperComponents/toast";
import SearchUser from "../../helperComponents/searchUser";
import SelectDomainArea from "../../helperComponents/selectDomainArea";

import { Factory } from "../../../helpers/factories";

import ChartPieAssessment from "./chartPieAssessment";
import ChartAssessment from "./chartAssessment";
import ChartBarTags from "./chartBarTags";
import ChartPieRoadmap from "./chartPieRoadmap";

import prepareDataForRoadmapPieChart from "./helpers/prepareDataForRoadmapPieChart";
import prepareTagDataForBarChart from "./helpers/prepareTagDataForPieChart";
import prepareAssessmentDataForTimeChart from "./helpers/prepareAssessmentDataForTimeChart";
import prepareAssessmentDataForPieChart from "./helpers/prepareAssessmentDataForPieChart";
import StudentPulse from "./studentPulse";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //overflow: "auto",
  },
  comp: {
    padding: theme.spacing(2),
  },
  filterElements: {
    margin: theme.spacing(2),
    width: "90%",
  },
  graphOuterContainer: {
    padding: "20px",
    display: "flex",
    justifyContent: "center",
  },
  graphInnerContainer: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    width: "100%",
  },
  filter: {
    backgroundColor: "white",
    padding: "5px",
    borderRadius: "10px",
  },
}));

export default function TeacherProfile(props) {
  const classes = useStyles();
  const { currentUser } = useAppContext();
  const [isDataLoading, setIsDataLoading] = React.useState(false);

  //valid date
  const [isDateValid, setIsDateValid] = React.useState(true);

  //list of assessments
  const [assessments, setAssessments] = React.useState([]);

  //mentor of student
  const [mentor, setMentor] = React.useState(null);

  //list of roadmap goals
  const [roadmapNodes, setRoadmapNodes] = React.useState([]);

  const [user, setUser] = React.useState({
    _id: currentUser.id,
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
  });

  const [domainArea, setDomainArea] = React.useState(
    Factory.createDomainArea()
  );

  //dates
  const [selectedFromDate, setSelectedFromDate] = React.useState(
    moment().startOf("year")
  );
  const [selectedToDate, setSelectedToDate] = React.useState(moment());

  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  const [queryString, setQueryString] = React.useState({ limit: 5000 });

  //chart data
  const [groupByKey, setGroupByKey] = React.useState("month");
  const [cleanedAssessmentData, setCleanedAssessmentData] = React.useState([]);
  const [totalAssessments, setTotalAssessments] = React.useState(0);
  const [assessmentPieData, setAssessmentPieChartData] = React.useState([]);
  const [assessmentTimeSeriesData, setAssessmentTimeSeriesData] =
    React.useState([]);
  const [tagChartBarData, setTagChartBarData] = React.useState([]);
  const [roadmapPieData, setRoadmapPieData] = React.useState([]);
  const [totalRoadmapGoals, setTotalRoadmapGoals] = React.useState(0);
  const [assessmentScore, setAssessmentScore] = React.useState(0);
  const [roadmapScore, setRoadmapScore] = React.useState(0);
  const [overallAssessmentTeachersStats, setOverallAssessmentTeachersStats] =
    React.useState({
      mean: "-",
      median: "-",
    });

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 2000,
  });

  //pre-load
  useEffect(() => {
    //only fetch assessments if dates are valid and user is present

    //reset chart data
    setAssessmentPieChartData([]);
    setAssessmentTimeSeriesData([]);
    setTagChartBarData([]);
    setTotalAssessments(0);
    setRoadmapPieData([]);
    setTotalRoadmapGoals(0);
    setAssessments([]);
    setRoadmapNodes([]);
    setMentor("");
    setOverallAssessmentTeachersStats({ mean: "-", median: "-" });

    //fetch assessments and roadmap data
    if (props.startFetchData && isDateValid && user && user._id) {
      (async () => {
        setIsDataLoading(true);
        try {
          //assessments
          const newRows = await loadAssessments();
          setAssessments(newRows);
          //roadmap
          getRoadmap();

          //overall student assessments
          getOverallAssessmentDataStudentsByOrgId();

          setIsDataLoading(false);
        } catch (err) {
          setErrorLoadingPage(true);
        }
      })();
    } else {
      setToastOptions({
        ...toastOptions,
        duration: 3000,
        message: "User must be selected and date/date range must be valid.",
        open: true,
      });
    }
  }, [props.startFetchData, queryString]);

  useEffect(() => {
    queryString.user = currentUser.id;
  }, []);

  async function loadAssessments() {
    //get current page and for query string
    let qs = { ...queryString };

    //toast message
    setToastOptions({
      ...toastOptions,
      duration: 100000,
      message: "Fetching data...",
      open: true,
    });

    try {
      const data = await AssessmentService.getAssessmentsByOrgId(
        currentUser.organisation.id,
        qs
      );
      if (data.length > 0)
        setToastOptions({
          ...toastOptions,
          duration: 2000,
          message: "Preparing data...",
          open: true,
        });
      else {
        setToastOptions({
          ...toastOptions,
          duration: 2000,
          message: "No data found",
          open: true,
        });
      }
      return new Promise((resolve) => {
        resolve(data);
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        duration: 3000,
        message: "Error fetching assessment data",
        open: true,
      });
      return new Promise((reject) => {
        reject(err);
      });
    }
  }

  //get roadmap
  async function getRoadmap() {
    try {
      setRoadmapNodes(
        await RoadmapService.getRoadmapNodesByOrgId(
          currentUser.organisation.id,
          queryString
        )
      );

      setIsDataLoading(false);
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        duration: 3000,
        message: "Error fetching roadmap data",
        open: true,
      });
      //   setErrorLoadingPage(true);
    }
  }

  //get ovearll assessment summary data for all students to compare with selected student

  async function getOverallAssessmentDataStudentsByOrgId() {
    //copy querystring and delete user and student from query string if there becasue we getting stats for all students
    let query = { ...queryString };
    if (query["user"]) {
      delete query["user"];
    }
    if (query["student"]) {
      delete query["student "];
    }

    try {
      const data =
        await AssessmentService.getOverallAssessmentDataTeachersByOrgId(
          currentUser.organisation.id,
          queryString
        );

      if (data.length > 0) {
        let dfOverallAssessment = new dfd.DataFrame(data);
        // dfOverallAssessment.print();

        let temp = dfOverallAssessment.describe();
        // temp.print();
        setOverallAssessmentTeachersStats({
          mean: Math.floor(temp.at("mean", "totalAssessments")),
          median: temp.at("median", "totalAssessments"),
        });
      }
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        duration: 3000,
        message: "Error fetching assessment data",
        open: true,
      });
    }
  }

  //query string update for user and domain, date handles itself
  useEffect(() => {
    //set initial query string
    setQueryString({
      ...queryString,
      teacher: user._id ? user._id : "", //assessment uses teacher
      user: user._id ? user._id : "", //roadmpa uses user
      domainArea: domainArea._id ? domainArea._id : "",
    });
  }, [domainArea, user]);

  //call prepare data frame once assessments loaded
  useEffect(() => {
    prepareData();
  }, [assessments, roadmapNodes, groupByKey]);

  //
  // useEffect(() => {
  //   if (cleanedAssessmentData.length > 0)
  //     prepareAssessmentDataForTimeChart(cleanedAssessmentData);
  // }, [groupByKey]);

  //prepare data for dataframe and charts

  const prepareData = () => {
    //prepare assessment data by destructuring domainArea
    let tempAss = [];
    let tempTags = [];
    let tempRoadmapNodes = [];
    if (assessments.length > 0) {
      assessments.forEach((assessment) => {
        const {
          comment,
          description,
          domainArea: { displayName },
          dateCreated,
          tags,
        } = assessment;
        tempAss.push({
          comment: comment,
          description: description,
          domainArea: displayName,
          date: dateCreated,
        });
        tempTags = tempTags.concat(tags);
      });

      setCleanedAssessmentData(tempAss);
      let assPieChartData = prepareAssessmentDataForPieChart(tempAss);
      if (assPieChartData.isEmpty) {
        setAssessmentScore(null);
      } else {
        setTotalAssessments(assPieChartData["total"]);
        setAssessmentPieChartData(assPieChartData["data"]);
      }
      setTagChartBarData(prepareTagDataForBarChart(tempTags));

      //time chart
      let assDataForTimeChart = prepareAssessmentDataForTimeChart(
        tempAss,
        groupByKey
      );
      if (assDataForTimeChart.isEmpty) {
        setAssessmentPieChartData([]);
        setAssessmentTimeSeriesData([]);
        setTotalAssessments(0);
      } else {
        setAssessmentTimeSeriesData(assDataForTimeChart["data"]);
      }
    } else {
      setAssessmentPieChartData([]);
      setAssessmentTimeSeriesData([]);
      setTotalAssessments(0);
      setTagChartBarData([]);
    }
    if (roadmapNodes.length > 0) {
      roadmapNodes.forEach((roadmap) => {
        const {
          goal,
          progressSelf,
          progressOther,
          domainArea: { displayName },
          dateCreated,
          tags,
        } = roadmap;
        tempRoadmapNodes.push({
          goal: goal,
          progressSelf: progressSelf,
          progressOther: progressOther,
          domainArea: displayName,
          date: dateCreated,
        });
      });

      const { data, total } = prepareDataForRoadmapPieChart(tempRoadmapNodes);
      setRoadmapPieData(data);
      setTotalRoadmapGoals(total);
    } else {
      setRoadmapPieData([]);
      setTotalRoadmapGoals(0);
    }
  };

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  //handle frequency change
  const handleFrequencyChange = (e) => {
    setGroupByKey(e.target.value);
  };

  //handle From date change
  const handleFromDateChange = (date) => {
    setIsDateValid(moment(date).isAfter(moment("31/12/2020", "DD-MM-YYYY")));
    if (date === "Invalid Date" || date === null) {
    } else {
      setSelectedFromDate(date);
      setQueryString({
        ...queryString,
        from: moment(date),
      });
    }
  };

  //handle To date change
  const handleToDateChange = (date) => {
    setIsDateValid(moment(date).isAfter(moment(selectedFromDate)));

    if (date === "Invalid Date" || date === null) {
    } else {
      setSelectedToDate(date);
      setQueryString({
        ...queryString,
        to: moment(date),
      });
    }
  };

  //

  if (errorLoadingPage) {
    return (
      <ErrorLoadingPage message="There was a problem loading the page. Try refreshing the page." />
    );
  } else {
    return (
      <Fragment>
        <Toast
          toastOptions={toastOptions}
          handleCloseToast={handleCloseToast}
        />

        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container className={classes.filter}>
                <Grid item xs={12} sm={3}>
                  <SearchUser
                    label={"Search Teacher"}
                    user={user}
                    setUser={setUser}
                    role={
                      "teacher, areaCoordinator, domainHead, researchers, staff"
                    }
                    style={{ display: "flex" }}
                    isDisabled={
                      [
                        "headOfSchool",
                        "domainHead",
                        "areaCoordinator",
                      ].includes(currentUser.role)
                        ? false
                        : true
                    }
                    isReadOnly={
                      [
                        "headOfSchool",
                        "domainHead",
                        "areaCoordinator",
                      ].includes(currentUser.role)
                        ? false
                        : true
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <SelectDomainArea
                    domainArea={domainArea}
                    setDomainArea={setDomainArea}
                    showAllOption={true}
                    orgId={currentUser.organisation.id}
                    type={""}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.filterElements}
                      margin="normal"
                      inputVariant="outlined"
                      id="date-picker-dialog-from"
                      label="From"
                      format="dd/MM/yyyy"
                      value={selectedFromDate}
                      // minDate={"31/12/2020"}
                      // minDateMessage={"Date cannot be before 31/12/2020"}
                      onChange={handleFromDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      className={classes.filterElements}
                      inputVariant="outlined"
                      id="date-picker-dialog-t0"
                      label="To"
                      format="dd/MM/yyyy"
                      value={selectedToDate}
                      onChange={handleToDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.graphOuterContainer}
                >
                  <div className={classes.graphInnerContainer}>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={8}
                        sm={6}
                        style={{
                          padding: "30px 0px 30px 30px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{
                              marginTop: "5px",
                              color: "#84888f",
                            }}
                          >
                            Teacher
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginTop: "5px",
                          }}
                        >
                          <Typography variant="h6" gutterBottom>
                            {user ? user.firstName + " " + user.lastName : ""}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {/* <StudentPulse
                          roadmapPieData={roadmapPieData}
                          assessmentPieData={assessmentPieData}
                        /> */}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.graphOuterContainer}
                >
                  <div className={classes.graphInnerContainer}>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={7}
                        style={{
                          padding: "30px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{
                              marginTop: "5px",
                              color: "#84888f",
                            }}
                          >
                            Total Assessments
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          <Typography variant="h6" gutterBottom>
                            {totalAssessments}
                          </Typography>
                          <Typography variant="caption" gutterBottom>
                            {"School Mean, Median: " +
                              overallAssessmentTeachersStats["mean"] +
                              ", " +
                              overallAssessmentTeachersStats["median"]}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <ChartPieAssessment data={assessmentPieData} />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.graphOuterContainer}
                >
                  <div className={classes.graphInnerContainer}>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          padding: "30px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{
                              marginTop: "5px",
                              color: "#84888f",
                            }}
                          >
                            Roadmap Goals
                          </Typography>
                        </div>
                        <div
                          style={{
                            marginTop: "5px",
                          }}
                        >
                          <Typography variant="h5" gutterBottom>
                            {totalRoadmapGoals}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <ChartPieRoadmap data={roadmapPieData} />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.graphOuterContainer}
                >
                  <div className={classes.graphInnerContainer}>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          padding: "30px 30px 0px 30px",
                          display: "flex",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          style={{
                            marginTop: "5px",
                            color: "#84888f",
                          }}
                        >
                          Top 10 Tags
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <ChartBarTags data={tagChartBarData} />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  className={classes.graphOuterContainer}
                >
                  <div className={classes.graphInnerContainer}>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          padding: "30px 30px 0px 30px",
                          display: "flex",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{
                              marginTop: "5px",
                              color: "#84888f",
                            }}
                          >
                            Assessments over time
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <FormControl
                            className={classes.formControl}
                            disabled={assessmentTimeSeriesData.length == 0}
                          >
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              SelectDisplayProps={{
                                style: {
                                  fontSize: 12,
                                },
                              }}
                              value={groupByKey}
                              onChange={handleFrequencyChange}
                            >
                              <MenuItem value={"month"}>Monthly</MenuItem>
                              <MenuItem value={"quarter"}>Quarterly</MenuItem>
                              <MenuItem value={"year"}>Yearly</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <ChartAssessment
                          data={assessmentTimeSeriesData}
                          groupByKey={groupByKey}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
              <Grid container spacing={3}>
                {["headOfSchool", "domainHead", "areaCoordinator"].includes(
                  currentUser.role
                ) && (
                  <Fragment>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className={classes.graphOuterContainer}
                    >
                      <div
                        className={classes.graphInnerContainer}
                        style={{
                          height: "300px",
                          padding: "30px 30px 0px 30px",
                        }}
                      >
                        <Grid
                          container
                          spacing={3}
                          style={{ height: "95%", overflow: "scroll" }}
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              style={{
                                marginTop: "5px",
                                color: "#84888f",
                              }}
                            >
                              Last 10 Roadmap Goals
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {roadmapNodes.length > 0 ? (
                              roadmapNodes
                                .slice(0, 10)
                                .map((roadmapNode, index) => {
                                  return (
                                    <div
                                      style={{ marginBottom: "15px" }}
                                      key={"r" + index}
                                    >
                                      <Typography
                                        variant="caption"
                                        gutterBottom
                                        style={{
                                          color: "#000",
                                        }}
                                      >
                                        {roadmapNode.domainArea.displayName}{" "}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        gutterBottom
                                        style={{
                                          color: "#000",
                                        }}
                                      >
                                        (Progress Self:{" "}
                                        {roadmapNode.progressSelf})
                                      </Typography>
                                      <br />
                                      <Typography
                                        variant="caption"
                                        gutterBottom
                                        style={{
                                          marginTop: "5px",
                                          color: "#84888f",
                                        }}
                                      >
                                        {roadmapNode.goal}
                                      </Typography>
                                    </div>
                                  );
                                })
                            ) : (
                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                style={{
                                  marginTop: "5px",
                                  color: "#84888f",
                                }}
                              >
                                No roadmap goals
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      className={classes.graphOuterContainer}
                    >
                      <div
                        className={classes.graphInnerContainer}
                        style={{
                          height: "300px",
                          padding: "30px 30px 0px 30px",
                        }}
                      >
                        <Grid
                          container
                          spacing={3}
                          style={{ height: "95%", overflow: "scroll" }}
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              style={{
                                color: "#84888f",
                              }}
                            >
                              Last 10 Assessments
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {assessments.length > 0 ? (
                              assessments
                                .slice(0, 10)
                                .map((assessment, index) => {
                                  return (
                                    <div
                                      style={{ marginBottom: "15px" }}
                                      key={"a" + index}
                                    >
                                      <Typography
                                        variant="caption"
                                        gutterBottom
                                        style={{
                                          color: "#000",
                                        }}
                                      >
                                        {assessment.domainArea.displayName}{" "}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        gutterBottom
                                        style={{
                                          color: "#000",
                                        }}
                                      >
                                        ({assessment.description})
                                      </Typography>
                                      <br />
                                      <Typography
                                        variant="caption"
                                        gutterBottom
                                        style={{
                                          marginTop: "5px",
                                          color: "#84888f",
                                        }}
                                      >
                                        {assessment.comment}
                                      </Typography>
                                    </div>
                                  );
                                })
                            ) : (
                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                style={{
                                  marginTop: "5px",
                                  color: "#84888f",
                                }}
                              >
                                No assessments found
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid> */}
          </Grid>
        </div>
      </Fragment>
    );
  }
}
