/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const DomainAreaService = {
  getDomainAreasByOrgId,
};

function getDomainAreasByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_domainAreas_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.domainAreas;
    });
}

// function addAnnouncementsByOrgId(orgId, announcement) {
//   const requestOptions = {
//     method: "POST",
//     credentials: "include", //important: for setting cookies
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(announcement),
//   };

//   var api_url = config.api_announcements_by_orgId;
//   api_url = RequestHelper.replaceParams(api_url, {
//     orgId: orgId,
//   });
//   //return Promise.reject(new Error());
//   return fetch(api_url, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       console.log(data);
//       return data.announcement;
//     });
// }

// function updateAnnouncementByOrgIdAndAnnouncementId(
//   orgId,
//   announcementId,
//   announcement
// ) {
//   const requestOptions = {
//     method: "PUT",
//     credentials: "include", //important: for setting cookies
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(announcement),
//   };

//   var api_url = config.api_announcements_by_orgId_and_announcementId;
//   api_url = RequestHelper.replaceParams(api_url, {
//     orgId: orgId,
//     announcementId: announcementId,
//   });
//   //return Promise.reject(new Error());
//   return fetch(api_url, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       return data;
//     });
// }

// function deleteAnnouncementsByOrgIdAndAnnouncementId(orgId, announcementId) {
//   const requestOptions = {
//     method: "DELETE",
//     credentials: "include", //important: for setting cookies
//     headers: { "Content-Type": "application/json" },
//   };

//   var api_url = config.api_announcements_by_orgId_and_announcementId;
//   api_url = RequestHelper.replaceParams(api_url, {
//     orgId: orgId,
//     announcementId: announcementId,
//   });

//   //return Promise.reject(new Error());
//   return fetch(api_url, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       return data.announcements;
//     });
// }
