import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Announcement from "./announcement";
import Notifications from "./notifications";
import DzoWordOfTheDay from "./dzoWordOfTheDay";
import EngWordOfTheDay from "./engWordOfTheDay";
import QuoteOfTheDay from "./quoteOfTheDay";
import RedFlags from "./redFlags";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  contentWrapper: {
    margin: "40px 16px",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
}));

function Landing(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* top row */}
      <RedFlags />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Announcement />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Notifications />
        </Grid>
      </Grid>
      {/* word row */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <DzoWordOfTheDay />
        </Grid>
        <Grid item xs={12} sm={4}>
          <EngWordOfTheDay />
        </Grid>
        <Grid item xs={12} sm={4}>
          <QuoteOfTheDay />
        </Grid>
      </Grid>
    </div>
  );
}

export default Landing;
