import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import SearchTag from "../../helperComponents/searchTags";
import SelectDomainArea from "../../helperComponents/selectDomainArea";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import InfoIcon from "@material-ui/icons/Info";

import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { LeResourcesService } from "../../../services/leResources";
import { Factory } from "../../../helpers/factories";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterElements: {
    marginTop: "8px",
    marginBottom: "8px",
  },
}));

function AddEditLeResource({
  leResource,
  setLeResource,
  leResources,
  setLeResources,
  selectedLeResourceIndex,
  opType,
  setOpType,
  isAddEditLeResourceDialogOpen,
  handleCloseLeResourceAddEditDialog,
  toastOptions,
  setToastOptions,
  handleCloseToast,
  ...props
}) {
  const { currentUser } = useAppContext();

  const classes = useStyles();

  //temp leResource state to prevent lag
  const [tempLeResource, setTempLeResource] = React.useState(leResource);

  //for search domain component
  const [domainArea, setDomainArea] = React.useState(null);

  //for search tag component
  const [tags, setTags] = React.useState([]);

  //load le resource if edit
  useEffect(() => {
    if (opType === "edit") {
      setTempLeResource({
        ...leResource,
        domainArea: null,
      });
      setTags(leResource.tags);
      setDomainArea(leResource.domainArea);
    } else {
      setTempLeResource(Factory.createLeResource());
      setDomainArea(null);
      setTags([]);
    }
  }, [opType]);

  function isFormValid() {
    return (
      domainArea &&
      domainArea._id &&
      tags.length > 0 &&
      tempLeResource.title &&
      tempLeResource.description &&
      tempLeResource.link &&
      tempLeResource.visibility
      //tempLeResource.orgName
    );
  }

  function handleInputChange(e) {
    let temp = { ...tempLeResource };
    temp[e.target.name] = e.target.value;
    setTempLeResource(temp);
  }

  async function handleSaveLeResource() {
    // saving message
    setToastOptions({
      ...toastOptions,
      open: true,
      message: "Saving",
    });

    try {
      //prepare data
      var data = {
        organisation: currentUser.organisation.id,
        title: tempLeResource.title.trim(),
        description: tempLeResource.description.replace(/↵/g, "\n").trim(),
        tags: tags,
        // orgName: tempLeResource.orgName,
        link: tempLeResource.link,
        domainArea: domainArea ? domainArea._id : tempLeResource.domainArea._id,
        visibility: tempLeResource.visibility,
        type: tempLeResource.type,
        uploadedBy: currentUser.id,
      };

      //check whether new lesson plan added or lesson plan being updated

      if (opType === "add") {
        const savedLeResource = await LeResourcesService.addLeResourcesByOrgId(
          currentUser.organisation.id,
          data
        );
        setLeResource({ ...leResource, _id: savedLeResource._id });

        //update lessons
        let temp = [...leResources];
        temp.unshift(savedLeResource);
        setLeResources(temp);
      } else {
        //update leResource
        const updatedLeResource =
          await LeResourcesService.updateLeResourcesByOrgIdAndLearningResourceId(
            currentUser.organisation.id,
            leResource._id,
            data
          );
        //update lesson s
        let temp = [...leResources];
        temp[selectedLeResourceIndex] = updatedLeResource;
        setLeResources(temp);
      }

      //close dialog
      handleCloseLeResourceAddEditDialog();

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Learning Resource Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error saving learning resource",
      });
    }
  }

  //const { title } = dialogPropsMain;
  // setDialogProps(row);
  return (
    <Fragment>
      <Dialog
        open={isAddEditLeResourceDialogOpen}
        onClose={handleCloseLeResourceAddEditDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {opType === "edit" ? "Edit Resource" : "Add Resource"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={4}>
              <SelectDomainArea
                domainArea={domainArea}
                setDomainArea={setDomainArea}
                showAllOption={false}
                orgId={currentUser.organisation.id}
                style={{ display: "flex" }}
                type={""}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <InputLabel id="type-type">Type</InputLabel>
                <Select
                  labelId="type"
                  id="type"
                  name="type"
                  value={tempLeResource.type}
                  onChange={handleInputChange}
                  label="type"
                >
                  <MenuItem value={"video"}>Video</MenuItem>
                  <MenuItem value={"audio"}>Audio</MenuItem>
                  <MenuItem value={"document"}>Document</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <InputLabel id="type-visibility">Visibility</InputLabel>
                <Select
                  labelId="visibility"
                  id="visibility"
                  name="visibility"
                  value={tempLeResource.visibility}
                  onChange={handleInputChange}
                  label="Visibility"
                >
                  <MenuItem value={"private"}>
                    Private (Only Organisation)
                  </MenuItem>
                  <MenuItem value={"public"}>
                    Public (All Organisations)
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* school Added */}
            {/* <Grid item sm={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "97%" }}
              >
                <InputLabel id="type-visibility">Organisation</InputLabel>
                <Select
                  labelId="orgName"
                  id="orgName"
                  name="orgName"
                  value={tempLeResource.orgName}
                  onChange={handleInputChange}
                  label="orgName"
                >
                  <MenuItem value={"The Royal Academy"}>
                    The Royal Academy
                  </MenuItem>
                  <MenuItem value={"Zhemgang Central School"}>
                    Zhemgang Central School
                  </MenuItem>
                  <MenuItem value={"Wangbama Central School"}>
                    Wangbama Central School
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} style={{ margin: "16px" }}>
              <TextField
                margin="dense"
                id="title"
                name="title"
                label="Title"
                type="text"
                value={tempLeResource.title}
                placeholder="Eg. Plastic-free is not easy"
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} style={{ margin: "16px" }}>
              <TextField
                multiline
                margin="dense"
                id="description"
                name="description"
                label="Description"
                type="text"
                rows={6}
                placeholder="Eg. This video is about flowers."
                value={tempLeResource.description}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "16px" }}>
              <TextField
                margin="dense"
                id="link"
                name="link"
                label="Link to Video"
                placeholder="https://www.nationalgeographic.com/environment/article/plastic-pollution"
                type="text"
                value={tempLeResource.link}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "16px" }}>
              <SearchTag
                tags={tags}
                setTags={setTags}
                label={"Content Tags - Eg. calculus, grammar"}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLeResourceAddEditDialog} color="primary">
            Close
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={handleSaveLeResource}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AddEditLeResource;
