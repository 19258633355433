import React, { Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import CategoryIcon from "@material-ui/icons/Category";
import BookIcon from "@material-ui/icons/Book";
import LabelIcon from "@material-ui/icons/Label";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MoodIcon from "@material-ui/icons/Mood";
import SecurityIcon from "@material-ui/icons/Security";

const useStyles = makeStyles((theme) => ({
  dataGridRoot: {
    width: "100%",
    display: "flex",
    //padding: theme.spacing(4),
    // marginBottom: "20px",
  },
  dataGrid: {
    flexGrow: 1,
    // height: "600px",
    // minHeight: "400px",
    backgroundColor: "white !important",
  },
  whiteBackground: {
    backgroundColor: "white !important",
  },
  moreVertIcon: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
}));

export default function AssessmentsDataGrid({
  setAssessment,
  assessments,
  setOpType,
  setSelectedRowIndex,
  setIsDialogOpen,
  isDataLoading,
  handleOpenAlertDialog,
  setSelectedAssessmentId,
  ...props
}) {
  const classes = useStyles();
  const { currentUser } = useAppContext();

  //data grid columns
  const columns = [
    { field: "id", headerName: "ID", width: 60 },
    {
      field: "teacher",
      headerName: "Details",
      flex: 0.4,
      width: 300,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              lineHeight: "normal",
              overflow: "scroll",
              height: "80%",
              padding: "20px",
            }}
          >
            <Chip
              icon={<FaceIcon />}
              size="small"
              label={
                params.row.student.firstName + " " + params.row.student.lastName
              }
              variant="outlined"
              color="primary"
              style={{ margin: "3px" }}
            />
            <Chip
              icon={<SupervisorAccountIcon />}
              size="small"
              label={
                params.row.teacher.firstName + " " + params.row.teacher.lastName
              }
              color="primary"
              variant="outlined"
              style={{ margin: "3px" }}
            />
            <Chip
              icon={<BookIcon />}
              size="small"
              color="primary"
              label={params.row.domainArea.displayName}
              variant="outlined"
              style={{ margin: "3px" }}
            />
            <Chip
              icon={<CategoryIcon />}
              size="small"
              color="primary"
              label={params.row.type}
              variant="outlined"
              style={{ margin: "3px" }}
            />
            {params.row.description && (
              <Chip
                icon={<MoodIcon />}
                size="small"
                color="primary"
                label={params.row.description}
                variant="outlined"
                style={{ margin: "3px" }}
              />
            )}
            <Chip
              icon={<CalendarTodayIcon />}
              size="small"
              label={params.row.dateCreated}
              variant="outlined"
              color="primary"
              style={{ margin: "3px" }}
            />
            <Fragment>
              {(() => {
                const l = params.row.tags.map((tag) => {
                  return (
                    <Chip
                      key={tag}
                      icon={<LabelIcon />}
                      size="small"
                      label={tag}
                      variant="outlined"
                      color="primary"
                      style={{ margin: "3px" }}
                    />
                  );
                });
                return l;
              })()}
            </Fragment>
          </div>
        );
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 610,
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            style={{
              overflow: "scroll",
              whiteSpace: "pre-wrap",
              height: "80%",
              padding: "20px",
            }}
          >
            <Typography variant="body2">
              {params.row.comment
                .replace("\r\n", "\n")
                .split("\n")
                .map(function (item, index) {
                  return (
                    <span key={index}>
                      {item}
                      <br />
                    </span>
                  );
                })}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "",
      width: 140,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = (op) => {
          if (op === "edit") {
            setAssessment(params.row);
            setOpType("edit");
            setSelectedRowIndex(params.row.id);
            setIsDialogOpen(true);
          } else {
            setSelectedRowIndex(params.row.id);
            setSelectedAssessmentId(params.row._id);
            handleOpenAlertDialog();
          }
        };
        return (
          <div
            style={{
              display: "flex",
              height: "80%",
              paddingTop: "20px",
              alignItems: "start",
            }}
          >
            {params.row.studentAccess && params.row.studentAccess === "hide" ? (
              <Tooltip title="Hidden From Student">
                <IconButton aria-label="Hidden From Student">
                  <SecurityIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            {params.row.teacher._id == currentUser.id && (
              <Fragment>
                <Tooltip title="Edit">
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      return onClick("edit");
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      return onClick("delete");
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Fragment>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className={classes.dataGridRoot}>
      <div className={classes.dataGrid}>
        <DataGrid
          className={classes.whiteBackground}
          rows={assessments}
          rowHeight={250}
          columns={columns}
          pagination
          pageSize={20}
          scrollbarSize={40}
          //rowCount={props.rowCount}
          //paginationMode="server"
          //onPageChange={handleDataGridPageChange}
          autoHeight={true}
          loading={isDataLoading}
        />
      </div>
    </div>
  );
}
