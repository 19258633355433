const config = {
  bb_jwt_cookie_name: process.env.REACT_APP_BB_JWT_COOKIE_NAME,
  bb_refresh_token_cookie_name:
    process.env.REACT_APP_BB_REFRESH_TOKEN_COOKIE_NAME,
};

const baseAPIURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_API_BASE_URL_PRODUCTION;

config.api_verify_token = baseAPIURL + process.env.REACT_APP_API_VERIFY_TOKEN;
config.api_login = baseAPIURL + process.env.REACT_APP_API_LOGIN;
config.api_refresh_token = baseAPIURL + process.env.REACT_APP_API_REFRESH_TOKEN;
config.api_reset_password =
  baseAPIURL + process.env.REACT_APP_API_RESET_PASSWORD;

//tags
config.api_tags = baseAPIURL + process.env.REACT_APP_API_TAGS;

//ofTheDay
config.api_dzoWordOfTheDay =
  baseAPIURL + process.env.REACT_APP_API_DZO_WORD_OF_THE_DAY;
config.api_engWordOfTheDay =
  baseAPIURL + process.env.REACT_APP_API_ENG_WORD_OF_THE_DAY;
config.api_quoteOfTheDay =
  baseAPIURL + process.env.REACT_APP_API_QUOTE_OF_THE_DAY;

//org
config.api_organisation = baseAPIURL + process.env.REACT_APP_API_ORGANISATION;

//affiliation
config.api_affiliation = baseAPIURL + process.env.REACT_APP_API_AFFILIATION;
config.api_affiliation_by_affiliationId =
  baseAPIURL + process.env.REACT_APP_API_AFFILIATION_BY_AFFILIATION_ID;
config.api_affiliation_download_google_cloud_storage_file =
  baseAPIURL +
  process.env.REACT_APP_API_AFFILIATION_DOWNLOAD_GOOGLE_CLOUD_STORAGE_FILE;

//user
config.api_user_by_orgId = baseAPIURL + process.env.REACT_APP_API_USER_BY_ORGID;
config.api_user_by_orgId_upload_csv =
  baseAPIURL + process.env.REACT_APP_API_USER_BY_ORGID_UPLOAD_CSV;
config.api_user_by_orgId_bulk_update_student_grade_section =
  baseAPIURL +
  process.env.REACT_APP_API_USER_BY_ORGID_BULK_UPDATE_STUDENT_GRADE_SECTION;
config.api_user_by_orgId_and_userId =
  baseAPIURL + process.env.REACT_APP_API_USER_BY_ORGID_AND_USERID;
config.api_user_profile_by_orgId_and_userId =
  baseAPIURL + process.env.REACT_APP_API_USER_PROFILE_BY_ORGID_AND_USERID;
config.api_reset_user_password_by_orgId_and_userId =
  baseAPIURL +
  process.env.REACT_APP_API_RESET_USER_PASSWORD_BY_ORGID_AND_USERID;

config.api_user = baseAPIURL + process.env.REACT_APP_API_USER;
config.api_user_admins = baseAPIURL + process.env.REACT_APP_API_USER_ADMINS;

//announcements
config.api_announcements_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_ANNOUNCEMENTS_BY_ORGID;
config.api_announcements_by_orgId_and_announcementId =
  baseAPIURL +
  process.env.REACT_APP_API_ANNOUNCEMENTS_BY_ORGID_AND_ANNOUNCEMENTID;

//notifications
config.api_notifications_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_NOTIFICATIONS_BY_ORGID;
config.api_notifications_by_orgId_and_notificationId =
  baseAPIURL +
  process.env.REACT_APP_API_NOTIFICATIONS_BY_ORGID_AND_NOTIFICATIONID;

//attendance
config.api_attendance_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_ATTENDANCE_BY_ORGID;
config.api_attendance_no_le_missed_by_student_by_orgId =
  baseAPIURL +
  process.env.REACT_APP_API_ATTENDANCE_NO_LE_MISSED_BY_STUDENT_BY_ORGID;
config.api_attendance_by_orgId_and_attendanceId =
  baseAPIURL + process.env.REACT_APP_API_ATTENDANCE_BY_ORGID_AND_ATTENDANCEID;

//learningResources
config.api_leResources_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_LERESOURCES_BY_ORGID;
config.api_leResources_by_orgId_and_leResourceId =
  baseAPIURL + process.env.REACT_APP_API_LERESOURCES_BY_ORGID_AND_LERESOURCEID;

//timetables
config.api_timeTables_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_TIMETABLES_BY_ORGID;
config.api_timeTables_by_orgId_and_timeTableId =
  baseAPIURL + process.env.REACT_APP_API_TIMETABLES_BY_ORGID_AND_TIMETABLEID;
config.api_timeTables_by_orgId_and_timeTableId_publish =
  baseAPIURL +
  process.env.REACT_APP_API_TIMETABLES_BY_ORGID_AND_TIMETABLEID_PUBLISH;
config.api_timeTables_by_orgId_and_timeTableId_unpublish =
  baseAPIURL +
  process.env.REACT_APP_API_TIMETABLES_BY_ORGID_AND_TIMETABLEID_UNPUBLISH;
config.api_timeTables_by_orgId_and_timeTableId_insertLesson =
  baseAPIURL +
  process.env.REACT_APP_API_TIMETABLES_BY_ORGID_AND_TIMETABLEID_INSERTLESSON;
config.api_timeTables_by_orgId_and_timeTableId_updateLesson =
  baseAPIURL +
  process.env.REACT_APP_API_TIMETABLES_BY_ORGID_AND_TIMETABLEID_UPDATELESSON;

//learning experiences
config.api_learning_experiences_by_orgId_and_timeTableId =
  baseAPIURL +
  process.env.REACT_APP_API_LEARNING_EXPERIENCES_BY_ORGID_AND_TIMETABLEID;
config.api_learning_experiences_by_orgId_and_timeTableId_and_learningExperienceId =
  baseAPIURL +
  process.env
    .REACT_APP_API_LEARNING_EXPERIENCES_BY_ORGID_AND_TIMETABLEID_LEARNINGEXPERIENCEID;
config.api_learning_experiences_by_orgId_and_timeTableId_and_learningExperienceId_lessonPlan =
  baseAPIURL +
  process.env
    .REACT_APP_API_LEARNING_EXPERIENCES_BY_ORGID_AND_TIMETABLEID_LEARNINGEXPERIENCEID_LESSON_PLAN;

//mentees-mentors
config.api_mentees_by_orgId_and_userId =
  baseAPIURL + process.env.REACT_APP_API_MENTEES_BY_ORGID_AND_USERID;
config.api_mentees_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_MENTEES_BY_ORGID;
config.api_mentors_by_orgId_and_userId =
  baseAPIURL + process.env.REACT_APP_API_MENTORS_BY_ORGID_AND_USERID;

//assessments
config.api_assessments_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_ASSESSMENTS_BY_ORGID;
config.api_assessments_by_orgId_overall_assessment_data_students =
  baseAPIURL +
  process.env
    .REACT_APP_API_ASSESSMENTS_BY_ORGID_OVERALL_ASSESSMENT_DATA_STUDENTS;
config.api_assessments_by_orgId_overall_assessment_data_teachers =
  baseAPIURL +
  process.env
    .REACT_APP_API_ASSESSMENTS_BY_ORGID_OVERALL_ASSESSMENT_DATA_TEACHERS;
config.api_assessments_by_orgId_and_assessmentId =
  baseAPIURL + process.env.REACT_APP_API_ASSESSMENTS_BY_ORGID_AND_ASSESSMENTID;

//appraisals
config.api_appraisals_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_APPRAISALS_BY_ORGID;
config.api_appraisals_collated_by_org_id =
  baseAPIURL + process.env.REACT_APP_API_APPRAISALS_COLLATED_BY_ORGID;
config.api_appraisals_by_orgId_and_appraisalId =
  baseAPIURL + process.env.REACT_APP_API_APPRAISALS_BY_ORGID_AND_APPRAISALID;

//fitness test
config.api_fitness_tests_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_FITNESS_TESTS_BY_ORGID;
config.api_fitness_tests_by_orgId_and_fitnessTestId =
  baseAPIURL +
  process.env.REACT_APP_API_FITNESS_TESTS_BY_ORGID_AND_FITNESSTESTID;

//reports
config.api_reports_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_REPORTS_BY_ORGID;
config.api_reports_by_orgId_and_reportId =
  baseAPIURL + process.env.REACT_APP_API_REPORTS_BY_ORGID_AND_REPORTID;
config.api_childReport_by_orgId_and_reportId =
  baseAPIURL + process.env.REACT_APP_API_CHILDREPORT_BY_ORGID_AND_REPORTID;
config.api_childReport_by_orgId_and_reportId_and_childReportId =
  baseAPIURL +
  process.env.REACT_APP_API_CHILDREPORT_BY_ORGID_AND_REPORTID_AND_CHILDREPORTID;

//portraits
config.api_portraits_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_PORTRAITS_BY_ORGID;
config.api_portraits_by_orgId_and_portraitId =
  baseAPIURL + process.env.REACT_APP_API_PORTRAITS_BY_ORGID_AND_PORTRAITID;
config.api_portraits_download_google_cloud_storage_file =
  baseAPIURL +
  process.env.REACT_APP_API_PORTRAITS_DOWNLOAD_GOOGLE_CLOUD_STORAGE_FILE;

//lessons
config.api_lessons_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_LESSONS_BY_ORGID;
config.api_lessons_by_orgId_and_lessonId =
  baseAPIURL + process.env.REACT_APP_API_LESSONS_BY_ORGID_AND_LESSONID;

//roadmaps
config.api_roadmapNodes_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_ROADMAPNODES_BY_ORGID;
config.api_roadmapNodes_and_roadmapChildNodes_by_orgId =
  baseAPIURL +
  process.env.REACT_APP_API_ROADMAPNODES_AND_ROADMAPCHILDNODES_BY_ORGID;
config.api_roadmapNode_by_orgId_and_roadmapNodeId =
  baseAPIURL + process.env.REACT_APP_API_ROADMAPNODE_BY_ORGID_AND_ROADMAPNODEID;
config.api_roadmapNode_by_orgId_and_roadmapNodeId_status =
  baseAPIURL +
  process.env.REACT_APP_API_ROADMAPNODE_BY_ORGID_AND_ROADMAPNODEID_STATUS;
config.api_roadmapNode_by_orgId_and_roadmapNodeId_canMarkAsCompleted =
  baseAPIURL +
  process.env
    .REACT_APP_API_ROADMAPNODE_BY_ORGID_AND_ROADMAPNODEID_CANMARKASCOMPLETED;
config.api_roadmapChildNodes_by_orgId_roadmapNodeId =
  baseAPIURL +
  process.env.REACT_APP_API_ROADMAPCHILDNODES_BY_ORGID_AND_ROADMAPNODEID;

config.api_roadmapNode_by_orgId_and_roadmapNodeId_and_roadmapChildNodeId =
  baseAPIURL +
  process.env
    .REACT_APP_API_ROADMAPCHILDNODE_BY_ORGID_AND_ROADMAPNODEID_AND_ROADMAPCHILDNODEID;

//roadmap_school
config.api_schoolRoadmap_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_SCHOOLROADMAP_BY_ORGID;
config.api_schoolRoadmap_by_orgId_and_schoolRoadmapId =
  baseAPIURL +
  process.env.REACT_APP_API_SCHOOLROADMAP_BY_ORGID_AND_SCHOOLROADMAPID;
config.api_schoolRoadmapNode_by_orgId_schoolRoadmapId =
  baseAPIURL +
  process.env.REACT_APP_API_SCHOOLROADMAPNODE_BY_ORGID_AND_SCHOOLROADMAPID;
config.api_schoolRoadmapNode_by_orgId_and_schoolRoadmapId_and_schoolRoadmapNodeId =
  baseAPIURL +
  process.env
    .REACT_APP_API_SCHOOLROADMAPNODE_BY_ORGID_AND_SCHOOLROADMAPID_AND_SCHOOLROADMAPNODEID;

//roadmap_domain
config.api_domainRoadmap_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_DOMAINROADMAP_BY_ORGID;
config.api_domainRoadmap_by_orgId_and_domainRoadmapId =
  baseAPIURL +
  process.env.REACT_APP_API_DOMAINROADMAP_BY_ORGID_AND_DOMAINROADMAPID;
config.api_spw_domainRoadmap_by_orgId_and_learningCycle_year_grade_domain =
  baseAPIURL +
  process.env
    .REACT_APP_API_SPW_DOMAINROADMAP_BY_ORGID_LEARNINGCYCLE_YEAR_GRADE_DOMAIN;

//roadmap_five_areas
config.api_fiveAreasRoadmap_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_FIVEAREASROADMAP_BY_ORGID;
config.api_fiveAreasRoadmap_by_orgId_and_fiveAreasRoadmapId =
  baseAPIURL +
  process.env.REACT_APP_API_FIVEAREASROADMAP_BY_ORGID_AND_FIVEAREASROADMAPID;

//roadmap_group
config.api_groupRoadmap_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_GROUPROADMAP_BY_ORGID;
config.api_groupRoadmap_by_orgId_and_groupRoadmapId =
  baseAPIURL +
  process.env.REACT_APP_API_GROUPROADMAP_BY_ORGID_AND_GROUPROADMAPID;

//domainAreas
config.api_domainAreas_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_DOMAINAREAS_BY_ORGID;

//summary assessment
config.api_assessment_summary_by_orgId =
  baseAPIURL + process.env.REACT_APP_API_ASSESSMENT_SUMMARY_BY_ORGID;

//google cloud service
config.api_download_google_cloud_storage_file =
  process.env.REACT_APP_API_DOWNLOAD_GOOGLE_CLOUD_STORAGE_FILE;

//openai
config.api_openai_summary =
  baseAPIURL + process.env.REACT_APP_API_OPENAI_SUMMARY;

//helpers
config.api_helper_download_gdrive_image =
  baseAPIURL + process.env.REACT_APP_API_HELPER_DOWNLOAD_GDRIVE_IMAGE;

//api_key
config.dgi_google_drive_api_key =
  process.env.REACT_APP_DGI_GOOGLE_DRIVE_API_KEY;

export default config;
