import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SearchUser from "../../helperComponents/searchUser";
import SearchUserAdvanced from "../../helperComponents/searchUserAdvanced";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Button } from "@material-ui/core";
import { UserService } from "../../../services/user";
import { useAppContext } from "../../../libs/contextLib";

import { makeStyles } from "@material-ui/core/styles";
import { Factory } from "../../../helpers/factories";
import Toast from "../../helperComponents/toast";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  contentWrapper: {
    margin: "40px 16px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
}));

function BulkUpdateStudent(props) {
  const classes = useStyles();

  const { currentUser } = useAppContext();

  //student
  const [student, setStudent] = React.useState(Factory.createUser());

  const [userQueryOptions, setUserQueryOptions] = React.useState({});

  //student list
  const [studentList, setStudentList] = React.useState([]);

  const [dialogForm, setDialogForm] = React.useState({});

  const [errorMessage, setErrorMessage] = React.useState("");

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 6000,
  });

  const StudentSearch = (
    <SearchUserAdvanced
      user={student}
      setUser={setStudent}
      userQueryOptions={userQueryOptions}
      setUserQueryOptions={setUserQueryOptions}
      style={{ display: "flex" }}
    />
  );

  React.useEffect(() => {
    if (student && student._id) {
      //make sure not more than 100
      if (studentList.length < 100) {
        let temp = [...studentList];
        temp.push(student);
        setStudentList(temp);
        setStudent(null);
      } else {
        setToastOptions({
          ...toastOptions,
          open: true,
          message: "Student list must be less than 100",
        });
      }
    }
  }, [student]);

  //check if enable save button
  function isFormValid() {
    return (
      (studentList.length > 0 &&
        dialogForm.grade &&
        /^([0-9]{1}[0-2]?)$/.test(dialogForm.grade)) ||
      dialogForm.section ||
      (dialogForm.yearGraduated &&
        /^([1-2]{1}[0-9]{3})$/.test(dialogForm.yearGraduated))
    );
  }

  //input change
  function handleInputChange(e) {
    switch (e.target.name) {
      case "grade":
        setDialogForm({
          ...dialogForm,
          grade: e.target.value,
        });
        break;
      case "section":
        setDialogForm({
          ...dialogForm,
          section: e.target.value,
        });
        break;
      case "yearGraduated":
        setDialogForm({
          ...dialogForm,
          yearGraduated: e.target.value,
        });
        break;
      default:
        break;
    }
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  //handle bulk update
  const handleBulkUpdate = async () => {
    //clear error message
    setErrorMessage("");

    //extract only student ids from studentlist
    let tempList = [];
    studentList.forEach((student) => {
      tempList.push({
        id: student._id,
        firstName: student.firstName,
        lastName: student.lastName,
      });
    });
    try {
      let data = {
        grade: dialogForm.grade,
        section: dialogForm.section,
        yearGraduated: dialogForm.yearGraduated,
        students: tempList,
      };
      //prepare fields
      if (dialogForm.grade) {
        data["grade"] = dialogForm.grade;
      }
      if (dialogForm.section) {
        data["section"] = dialogForm.section;
      }
      if (dialogForm.yearJoined) {
        data["yearJoined"] = dialogForm.yearJoined;
      }

      //call service
      await UserService.bulkUpdateStudentGradeSection(
        currentUser.organisation.id,
        data
      );
      setStudentList([]);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Student Records Updated",
      });
    } catch (err) {
      setErrorMessage(err.message);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error updating students",
      });
    }
  };

  async function handleDeleteStudent(index) {
    let temp = [...studentList];
    temp.splice(index, 1);
    setStudentList(temp);
  }

  return (
    <div className={classes.root}>
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6">Bulk Update Students</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Select students to update their grade, section or year
                  graduated. If a field is blank, it won't be updated. A maximum
                  of 100 students can be selected.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                {StudentSearch}
              </Grid>
              <Grid item xs={12} sm={12} className={classes.studentList}>
                <Typography variant="body1">Enter Update Details</Typography>
                <div>
                  {studentList.map((student, index) => {
                    return (
                      <Chip
                        size="small"
                        key={student.firstName + student.lastName + index}
                        label={student.firstName + " " + student.lastName}
                        variant="outlined"
                        color="primary"
                        onDelete={() => {
                          handleDeleteStudent(index);
                        }}
                        style={{ margin: "3px" }}
                      />
                    );
                  })}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="grade"
                    name="grade"
                    label="Grade (0-12)"
                    onChange={handleInputChange}
                    value={dialogForm.grade}
                    variant="outlined"
                    className={classes.formField}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="section"
                    name="section"
                    label="Section"
                    onChange={handleInputChange}
                    value={dialogForm.section}
                    variant="outlined"
                    className={classes.formField}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="yearGraduated"
                    name="yearGraduated"
                    label="Year Graduated"
                    onChange={handleInputChange}
                    value={dialogForm.yearGraduated}
                    variant="outlined"
                    className={classes.formField}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="body2">{errorMessage}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  disabled={!isFormValid()}
                  size="large"
                  onClick={handleBulkUpdate}
                  color="primary"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default BulkUpdateStudent;
