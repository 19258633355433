import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import getAssessmentJSPDF from "../../../helpers/getAssessmentJSPDF";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { AssessmentService } from "../../../services/assessment";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import AssessmentDialog from "./assessmentsAddEditDialog";
import AlertDialog from "../../helperComponents/alertDialog";
import Toast from "../../helperComponents/toast";
import AssessmentsDataGrid from "./assessmentsDataGrid";
import AssessmentsFilter from "./assessmentsFilter";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //overflow: "auto",
  },
  comp: {
    padding: theme.spacing(2),
  },
}));

export default function Assessment(props) {
  const classes = useStyles();
  const { currentUser } = useAppContext();
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  //active assessment
  const [assessment, setAssessment] = React.useState({});
  //list of assessments
  const [assessments, setAssessments] = React.useState([]);
  //selected row
  const [selectedRowIndex, setSelectedRowIndex] = React.useState("");

  const [selectedAssessmentId, setSelectedAssessmentId] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState("");
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);
  const [opType, setOpType] = React.useState("");

  const [queryString, setQueryString] = React.useState({});

  //data grid current page
  const [dataGridPage, setDataGridPage] = React.useState();

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 6000,
  });

  const [alertDialogOptions, setAlertDialogOptions] = React.useState({
    open: false,
    title: "",
    message: "Delete this assessment?",
    cancelMessage: "Cancel",
    confirmMessage: "OK",
  });

  //pre-load
  useEffect(() => {
    if (props.startFetchData) {
      (async () => {
        setIsDataLoading(true);
        try {
          const newRows = await loadAssessments();
          setAssessments(loadRows(newRows));
          setIsDataLoading(false);
        } catch (err) {
          setErrorLoadingPage(true);
        }
      })();
    } else {
      // console.log("IN ASSWSSMENT - NOT FETCHIN");
    }
  }, [props.startFetchData, queryString, dataGridPage]);

  async function loadAssessments() {
    //get current page and for query string
    let qs = { ...queryString };
    qs["page"] = dataGridPage;

    try {
      const data = await AssessmentService.getAssessmentsByOrgId(
        currentUser.organisation.id,
        qs
      );
      return new Promise((resolve) => {
        resolve(data);
      });
    } catch (err) {
      return new Promise((reject) => {
        reject(err);
      });
    }
  }

  function loadRows(assessments) {
    let temp = [];
    assessments.forEach((assessment, index, assessments) => {
      temp.push({
        id: index,
        _id: assessment._id,
        dateCreated: moment(assessment.dateCreated).format("DD/MM/YYYY"),
        //organisation: assessment.organisation._id,
        // _id: assessment._id,
        type: assessment.type,
        comment: assessment.comment,
        teacher: assessment.teacher,
        student: assessment.student,
        domainArea: assessment.domainArea,
        description: assessment.description,
        studentAccess: assessment.studentAccess ? assessment.studentAccess : "",
        tags: assessment.tags,
      });
    });
    return temp;
  }

  const handleDataGridPageChange = (params) => {
    setDataGridPage(params.page);
  };

  const handleAddAssessment = () => {
    setOpType("add");
    setIsDialogOpen(true);
  };

  const handleDownloadAssessment = () => {
    getAssessmentJSPDF(assessments);
  };

  function handleCloseDialog() {
    setAssessment({
      type: "",
      teacher: currentUser.id,
      student: "",
      domainArea: "",
      comment: "",
      tags: [],
    });
    setIsDialogOpen(false);
  }

  const handleOpenDialog = () => {
    setOpType("edit");
    setIsDialogOpen(true);
  };

  function handleOpenAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: true });
  }

  function handleCloseAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
  }

  function handleConfirmDeleteAssessment() {
    handleOpenAlertDialog();
  }

  async function handleProceedDeleteAssessment() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
    let temp = [...assessments];
    temp.splice(selectedRowIndex, 1);

    try {
      await AssessmentService.deleteAssessmentByOrgIdAndAssessmentId(
        currentUser.organisation.id,
        selectedAssessmentId
      );
      setAssessments(temp);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Assessment Deleted",
      });
      setAssessments(temp);
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error deleting assessment",
      });
    }
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  //

  if (errorLoadingPage) {
    return (
      <ErrorLoadingPage message="There was a problem loading the page. Try refreshing the page." />
    );
  } else {
    return (
      <Fragment>
        <Toast
          toastOptions={toastOptions}
          handleCloseToast={handleCloseToast}
        />
        <AlertDialog
          alertDialogOptions={alertDialogOptions}
          handleClose={handleCloseAlertDialog}
          handleConfirm={handleProceedDeleteAssessment}
        />
        <div className={classes.root}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
          <Typography variant="h4" className={classes.textCenter}>
            Assessments
          </Typography>
        </Grid> */}
            {[
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
            ].includes(currentUser.role) && (
              <Grid
                item
                xs={12}
                sm={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleAddAssessment}
                >
                  Add Assessment
                </Button>
                <Tooltip title="Download Assessment">
                  <IconButton
                    color="primary"
                    aria-label="open drawer"
                    onClick={handleDownloadAssessment}
                    className={classes.menuButton}
                  >
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
                {/* Download Link */}
                {/* <GetAssessmentReactPDF assessments={assessments} /> */}
              </Grid>
            )}
            <Grid item xs={12}>
              <AssessmentDialog
                isDialogOpen={isDialogOpen}
                assessment={assessment}
                setAssessment={setAssessment}
                assessments={assessments}
                setAssessments={setAssessments}
                selectedRowIndex={selectedRowIndex}
                handleCloseDialog={handleCloseDialog}
                opType={opType}
                setOpType={setOpType}
                toastOptions={toastOptions}
                setToastOptions={setToastOptions}
                handleCloseToast={handleCloseToast}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <AssessmentsFilter
                    setQueryString={setQueryString}
                    queryString={queryString}
                    setDataGridPage={setDataGridPage}
                    toastOptions={toastOptions}
                    setToastOptions={setToastOptions}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <AssessmentsDataGrid
                currentUser={currentUser}
                setDataGridPage={setDataGridPage}
                setAssessment={setAssessment}
                setSelectedRow={setSelectedRow}
                selectedRowIndex={selectedRowIndex}
                setSelectedRowIndex={setSelectedRowIndex}
                assessments={assessments}
                isDataLoading={isDataLoading}
                setIsDialogOpen={setIsDialogOpen}
                opType={opType}
                setOpType={setOpType}
                setSelectedAssessmentId={setSelectedAssessmentId}
                handleOpenAlertDialog={handleOpenAlertDialog}
              />
            </Grid>
          </Grid>
        </div>
      </Fragment>
    );
  }
}
