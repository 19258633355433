import { constants } from "./constants";
export const Utils = {
  getYearRange,
  rewriteGDriveImageURL,
  sortObjectByKey,
  isValidURL,
  urlify,
  sortObject,
  getColorForGrade,
};

//sort object by keys
function sortObject(unordered) {
  let ordered = Object.keys(unordered)
    .sort()
    .reduce((obj, key) => {
      obj[key] = unordered[key];
      return obj;
    }, {});
  return ordered;
}

//returns an array staring from start year to end year
function getYearRange(startYear, endYear) {
  let years = [];
  for (var i = endYear; i >= startYear; i--) {
    years.push(i);
  }
  return years;
}

//rewrite Google Drive image url in a pubilc folder
//image should be accessible to anyone with the link
function rewriteGDriveImageURL(url) {
  return (
    "https://drive.google.com/uc?export=view&id=" + url.match(/[-\w]{25,}/)
  );
}

//sorts object by keys
function sortObjectByKey(obj) {
  return Object.keys(obj)
    .sort()
    .reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
}

//check if valid url for rednering hyperlink tags

function isValidURL(url) {
  var urlCheck = new RegExp(
    "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
  );
  if (urlCheck.test(url)) {
    return true;
  }
  return false;
}

//parse text and replace links with hyperlink tags

// function urlify(text) {
//   var urlRegex = /(https?:\/\/[^\s]+)/g;
//   var a = text.split(urlRegex).map((url) => {
//     return url.replace(urlRegex, function (temp) {
//       return <a> {temp} + </a>;
//     });
//   });
//   console.log(text);
//   console.log(a);
//   return a;
// }

function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  var a = text.split(urlRegex);
  let arr = [];
  a.forEach((item, index) => {
    if (urlRegex.test(item)) {
      arr.push(
        <a href={item} key={item + index}>
          {item}
        </a>
      );
    } else {
      arr.push(item);
    }
  });
  return arr;
}

function getColorForGrade(fadeColor, grade, lesson, type) {
  let temp = "";

  switch (grade) {
    case 0:
      temp = constants.gradeColors["zero"];
    case 1:
      temp = constants.gradeColors["one"];
      break;
    case 2:
      temp = constants.gradeColors["two"];
      break;
    case 3:
      temp = constants.gradeColors["three"];
      break;
    case 4:
      temp = constants.gradeColors["four"];
      break;
    case 5:
      temp = constants.gradeColors["five"];
      break;
    case 6:
      temp = constants.gradeColors["six"];
      break;
    case 7:
      temp = constants.gradeColors["seven"];
      break;
    case 8:
      temp = constants.gradeColors["eight"];
      break;
    case 9:
      temp = constants.gradeColors["nine"];
      break;
    case 10:
      temp = constants.gradeColors["ten"];
      break;
    case 11:
      temp = constants.gradeColors["eleven"];
      break;
    case 12:
      temp = constants.gradeColors["twelve"];
      break;
    default:
      temp = constants.gradeColors["all"];
      break;
  }
  if (!fadeColor || type === "other" || lesson != null) {
    return "rgb(" + temp + ")";
  }
  return "rgb(" + temp + ", 0.4)";
}
