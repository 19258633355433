/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";
import { saveAs } from "file-saver";

import {
  handleResponse,
  handleResponseFileDownload,
} from "../helpers/handleResponse";

export const AffiliationService = {
  getAffiliations,
  addAffiliation,
  deleteAffiliationById,
  updateAffiliationById,
  downloadAffiliationDocument,
};

function getAffiliations(options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url =
    config.api_affiliation + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.affiliations;
    });
}

function addAffiliation(affiliation) {
  const requestOptions =
    RequestHelper.getPostRequestOptionsFormData(affiliation);

  var api_url = config.api_affiliation;
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.affiliation;
    });
}

function updateAffiliationById(affiliationId, affiliation) {
  const requestOptions =
    RequestHelper.getPutRequestOptionsFormData(affiliation);

  var api_url = config.api_affiliation_by_affiliationId;
  api_url = RequestHelper.replaceParams(api_url, {
    affiliationId: affiliationId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.affiliation;
    });
}

function deleteAffiliationById(affiliationId) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_affiliation_by_affiliationId;
  api_url = RequestHelper.replaceParams(api_url, {
    affiliationId: affiliationId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data._id;
    });
}

function downloadAffiliationDocument(options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptionsFileDownload("blob");

  var api_url = config.api_affiliation_download_google_cloud_storage_file;

  api_url = api_url + RequestHelper.formatQueryString(options);
  let fileName = (() => {
    var s = options.docURL.split("/");
    return s[s.length - 1];
  })();

  return fetch(api_url, requestOptions)
    .then(handleResponseFileDownload)
    .then((response) => response.blob())
    .then((blob) => {
      saveAs(blob, fileName + ".pdf");
      return URL.createObjectURL(blob);
    });

  //return Promise.reject(new Error());
  // return fetch(api_url, requestOptions)
  //   .then(handleResponse)
  //   .then((data) => {
  //     return data.affiliations;
  //   });
}
