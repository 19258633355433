import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { LessonPlansService } from "../../../services/lessonPlans";
import { LearningExperienceService } from "../../../services/learningExperiences";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterElements: {
    marginTop: "8px",
    marginBottom: "8px",
  },
}));

function ViewLessonPlanDialog({
  lessonPlanId,
  setLessonPlanId,
  lessonPlan,
  setLessonPlan,
  isViewLessonPlanDialogOpen,
  setIsViewLessonPlanDialogOpen,
  setIsAddLessonPlanToLEDialogOpen,
  activeLearningExperience,
  handleLessonPlanRemovedFromLE,
  setIsAbsenteeDialogOpen,
  toastOptions,
  setToastOptions,
  ...props
}) {
  const { currentUser } = useAppContext();

  const classes = useStyles();

  //load lesson plan if edit
  useEffect(async () => {
    //if lessonPlanID is supplied,  get lesson plan
    if (lessonPlanId) {
      try {
        const temp =
          await LessonPlansService.getLessonPlanByOrgIdAndLessonPlanId(
            currentUser.organisation.id,
            lessonPlanId
          );
        setLessonPlan({ ...temp });
      } catch (err) {
        setToastOptions({
          ...toastOptions,
          open: true,
          message: err.message,
        });
      }
    }
  }, [lessonPlanId]);

  //close dialog
  const handleCloseViewLessonPlanDialog = () => {
    setLessonPlan(null);
    setLessonPlanId(null);
    setIsViewLessonPlanDialogOpen(false);
  };

  //handle remove lesson plan from le
  const handleRemoveLessonPlanFromLE = async () => {
    try {
      await LearningExperienceService.removeLessonPlanFromLearningExperience(
        currentUser.organisation.id,
        activeLearningExperience.timeTable,
        activeLearningExperience._id
      );

      //call handler to add lessonplan to le in timetable
      handleLessonPlanRemovedFromLE();

      handleCloseViewLessonPlanDialog();

      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Lesson plan removed from learning experience",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  };

  //const { title } = dialogPropsMain;
  // setDialogProps(row);
  return (
    <Fragment>
      <Dialog
        open={isViewLessonPlanDialogOpen}
        onClose={handleCloseViewLessonPlanDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {lessonPlan ? lessonPlan.title : "Lesson Plan"}
        </DialogTitle>
        <DialogContent>
          {lessonPlan ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="primary">
                  {"Grade " +
                    lessonPlan.grade +
                    (lessonPlan.domainArea
                      ? ", " + lessonPlan.domainArea.displayName
                      : "") +
                    (lessonPlan.learningCycle
                      ? ", Learning Cycle " + lessonPlan.learningCycle
                      : "")}
                </Typography>
                <Typography variant="subtitle2" color="primary">
                  {lessonPlan.teacher
                    ? lessonPlan.teacher.firstName +
                      " " +
                      lessonPlan.teacher.lastName
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {lessonPlan.overview}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{lessonPlan.details}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {lessonPlan.linksToResources
                    ? lessonPlan.linksToResources
                        .split("\n")
                        .map((link, index) => {
                          let b = link.split("|");
                          return b.length === 2 ? (
                            <div style={{ display: "block" }} key={index}>
                              <a
                                href={b[1].trim()}
                                target="_blank"
                                rel="noreferrer"
                                color="primary"
                                style={{ cursor: "pointer" }}
                              >
                                {index + 1 + ". " + b[0]}
                              </a>
                            </div>
                          ) : (
                            <div style={{ display: "block" }} key={index}>
                              {b[0].trim()}
                            </div>
                          );
                        })
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {lessonPlan.tags
                  ? lessonPlan.tags.map((tag) => {
                      return (
                        <Chip
                          size="small"
                          label={tag}
                          variant="outlined"
                          color="primary"
                          style={{ margin: "3px" }}
                        />
                      );
                    })
                  : null}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="subtitle2">Skills</Typography>
                    <Typography variant="body2">
                      {lessonPlan.skills ? lessonPlan.skills.join(", ") : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="subtitle2">Processes</Typography>
                    <Typography variant="body2">
                      {lessonPlan.processes
                        ? lessonPlan.processes.join(", ")
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="subtitle2">Watermarks</Typography>
                    <Typography variant="body2">
                      {lessonPlan.watermarks
                        ? lessonPlan.watermarks.join(", ")
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="subtitle2">
                      School Attributes
                    </Typography>
                    <Typography variant="body2">
                      {lessonPlan.schoolAttributes
                        ? lessonPlan.schoolAttributes.join(", ")
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                There is no lesson plan for this learning experience.
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          {activeLearningExperience &&
            activeLearningExperience.teacher &&
            activeLearningExperience.teacher._id === currentUser.id && (
              <div>
                <Button
                  onClick={() => {
                    setIsViewLessonPlanDialogOpen(false);
                    setIsAbsenteeDialogOpen(true);
                  }}
                  color="primary"
                >
                  Mark Absentees
                </Button>
                <Button
                  onClick={() => {
                    setIsViewLessonPlanDialogOpen(false);
                    setIsAddLessonPlanToLEDialogOpen(true);
                  }}
                  color="primary"
                >
                  Attach Lesson Plan
                </Button>
              </div>
            )}
          {lessonPlan &&
            lessonPlan.teacher &&
            lessonPlan.teacher._id === currentUser.id && (
              <Button onClick={handleRemoveLessonPlanFromLE} color="primary">
                Remove Lesson Plan
              </Button>
            )}
          <Button onClick={handleCloseViewLessonPlanDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default ViewLessonPlanDialog;
