// import React, { Fragment, useEffect, forwardRef, useRef } from "react";
// import moment from "moment";
// import randomColor from "randomcolor";

// import { makeStyles } from "@material-ui/core/styles";

// import * as dfd from "danfojs";

// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";

// const useStyles = makeStyles(() => ({
//   customTooltip: {
//     margin: "0px",
//     padding: "10px",
//     backgroundColor: "rgb(255, 255, 255)",
//     border: "1px solid rgb(204, 204, 204)",
//   },
// }));

// const data = [
//   { dateCreated: "2023-10-29", goal1: 0 },
//   { dateCreated: "2023-11-29", goal1: 39.5 },
//   { dateCreated: "2023-12-27", goal1: 0 },
// ];

// export default function DomainLineChart({
//   roadmapNodes,
//   setChartDomainArea,
//   chartDomainArea,
//   setIndividualGoalToChartData,
//   setReferenceLineDate,
//   roadmapFocusNodeIndex,
//   setRoadmapFocusNodeIndex,
//   dataForCharts,
//   clearIndividualGoalLineChart,
//   setClearIndividualGoalLineChart,
//   setSelectedGoalTitle,
//   selectedGoalTitle,
// }) {
//   const classes = useStyles();

//   const [dataForLineChart, setDataForLineChart] = React.useState([]);
//   console.log("dataforLinechart", dataForLineChart);

//   const [uniqueGoals, setUniqueGoals] = React.useState([]);

//   const [domainGoalToNodeIndex, setDomainGoalToNodeIndex] = React.useState({});

//   useEffect(() => {
//     function prepData() {
//       let goalUpdates = [];

//       let goalCount = 0;
//       let mapIndex = {};
//       roadmapNodes.forEach((node, index) => {
//         if (node.domainArea === chartDomainArea) {
//           //map  domain goal to roadmap node index for individual  goal  charts
//           mapIndex["g" + goalCount] = index;

//           //include first child node as date when goal created
//           goalUpdates.push({
//             goalIndex: index,
//             goal: "g" + goalCount,
//             dateCreated: moment(node.dateCreated).format("YYYY-MM-DD"),
//             progressAverage: 0,
//           });

//           //cycle through each child node and average indictors
//           node.childNodes.forEach((childNode) => {
//             if (childNode.indicatorsOfSuccess) {
//               let elem = {
//                 goalIndex: index,
//                 goal: "g" + goalCount,

//                 dateCreated: moment(childNode.dateCreated).format("YYYY-MM-DD"),
//                 progressAverage: (() => {
//                   let sum = childNode.indicatorsOfSuccess.reduce(
//                     (acc, indicator) => {
//                       return acc + indicator.indicatorProgress;
//                     },
//                     0
//                   );
//                   return sum / childNode.indicatorsOfSuccess.length;
//                 })(),
//               };
//               goalUpdates.push(elem);
//             }
//           });
//           goalCount = goalCount + 1;
//         }
//       });

//       //create data frame and sort goal updates by date
//       let df = new dfd.DataFrame(goalUpdates);
//       df.sortValues("dateCreated", { inplace: true });
//       // df.print();
//       let goalUpdatesDatewise = [];
//       dfd.toJSON(df).forEach((item) => {
//         goalUpdatesDatewise.push(item);
//       });

//       setUniqueGoals(new dfd.Series(df["goal"].values).unique().values);

//       //prepare data for graphing
//       let temp2 = {};
//       let dates = [];
//       goalUpdatesDatewise.forEach((item) => {
//         if (dates.includes(item.dateCreated)) {
//           temp2[item.dateCreated]["goalIndex"] = item.goalIndex;
//           temp2[item.dateCreated][item.goal] = item.progressAverage;
//         } else {
//           dates.push(item.dateCreated);
//           temp2[item.dateCreated] = {};
//           temp2[item.dateCreated]["goalIndex"] = item.goalIndex;
//           temp2[item.dateCreated]["dateCreated"] = item.dateCreated;
//           temp2[item.dateCreated][item.goal] = item.progressAverage;
//         }
//       });

//       let temp3 = [];
//       for (const [key, value] of Object.entries(temp2)) {
//         temp3.push(value);
//       }
//       setDomainGoalToNodeIndex(mapIndex);
//       setDataForLineChart(temp3);
//     }

//     if (dataForCharts.length > 0 && chartDomainArea) {
//       prepData();
//     } else {
//       setDataForLineChart([]);
//     }
//   }, [chartDomainArea]);

//   useEffect(() => {
//     if (clearIndividualGoalLineChart) {
//       setClearIndividualGoalLineChart(false);
//       setIndividualGoalToChartData([]);
//       setRoadmapFocusNodeIndex(null);
//       setSelectedGoalTitle("");
//     }
//   }, [clearIndividualGoalLineChart]);

//   //prep data for invidual goal chart
//   function prepGoalProgressData(data, i) {
//     if (domainGoalToNodeIndex[data.name] != roadmapFocusNodeIndex) {
//       setRoadmapFocusNodeIndex(domainGoalToNodeIndex[data.name]);

//       setSelectedGoalTitle(
//         roadmapNodes[domainGoalToNodeIndex[data.name]]["goal"]
//       );
//       let temp = [];
//       let dates = []; //for end date reference line
//       let goalIndex = domainGoalToNodeIndex[data.name];
//       roadmapNodes[goalIndex]["childNodes"].forEach(
//         (childNode, childNodeIndex) => {
//           // new code
//           //keep track of dates for reference line

//           dates.push(
//             moment(roadmapNodes[goalIndex].dateCreated).format("YYYY-MM-DD")
//           );

//           //add start node so graph starts at  0, do this only oonce; also add end node for reference line
//           if (childNodeIndex == 0) {
//             //start node
//             let elem = {
//               dateCreated: moment(roadmapNodes[goalIndex].dateCreated).format(
//                 "YYYY-MM-DD"
//               ),
//               comment: "",
//               //new code
//               commentType: "", // Initialize commentType
//             };

//             roadmapNodes[
//               domainGoalToNodeIndex[data.name]
//             ].indicatorsOfSuccess.forEach((indicator, index) => {
//               elem["Indicator" + index] = 0;
//               //new code
//               elem[`Title${index}`] = indicator.indicator; // Include indicator title
//             });
//             temp.push(elem);
//           }

//           if (true) {
//             let elem = {
//               dateCreated: moment(childNode.dateCreated).format("YYYY-MM-DD"),
//               comment: childNode.comment,
//               //new code, added commenttype
//               commentType: childNode.commentType, // Include commentType
//             };
//             if (childNode.indicatorsOfSuccess) {
//               childNode.indicatorsOfSuccess.forEach((indicator, index) => {
//                 elem["Indicator" + index] = indicator.indicatorProgress;
//                 //new code
//                 elem[`Title ${index}`] = indicator.indicator; // Include indicator title
//               });
//             }

//             temp.push(elem);
//             //console.log("Child Node elem:", elem); // Log the child node elem here
//           }
//         }
//       );

//       // save end date as node if not already in dates ;
//       //reference line doesn't show up if we have duplicate data ; only create it if date doesn't exist

//       let goalCompletionDate = moment(
//         roadmapNodes[goalIndex].completionDate
//       ).format("YYYY-MM-DD");
//       if (!dates.includes(goalCompletionDate)) {
//         let elem = {
//           dateCreated: goalCompletionDate,
//         };
//         temp.push(elem);
//       }
//       setReferenceLineDate(
//         moment(roadmapNodes[goalIndex].completionDate).format("YYYY-MM-DD")
//       );

//       //create dataframe to sort by date
//       let df = new dfd.DataFrame(temp);
//       df.sortValues("dateCreated", { inplace: true });
//       //df.print();

//       setIndividualGoalToChartData(dfd.toJSON(df));
//     }
//   }

//   const GoalTitleComponet = forwardRef((props, ref) => {
//     return <span {...props} ref={ref} style={{ fontStyle: "italic" }}></span>;
//   });

//   return (
//     <Fragment>
//       <div
//         style={{
//           minHeight: "50px",
//           margin: "0px 20px",
//           fontSize: "12px",
//         }}
//       >
//         <span style={{ fontWeight: "bold" }}>Goal:</span> {selectedGoalTitle}
//       </div>

//       <div>
//         <ResponsiveContainer height={250}>
//           <LineChart
//             data={dataForLineChart}
//             margin={{
//               top: 5,
//               right: 30,
//               left: 20,
//               bottom: 5,
//             }}
//           >
//             <CartesianGrid strokeDasharray="3 3" />
//             <XAxis dataKey="dateCreated" />
//             <YAxis />
//             <Tooltip />
//             <Legend />
//             {uniqueGoals.map((item, index) => {
//               return (
//                 <Line
//                   type="monotone"
//                   connectNulls
//                   dataKey={item}
//                   // stroke="#8884d8"
//                   stroke={randomColor({ luminosity: "dark" })}
//                   name={item}
//                   activeDot={{ r: 8 }}
//                   strokeWidth={3}
//                   onClick={prepGoalProgressData}
//                 />
//               );
//             })}
//           </LineChart>
//         </ResponsiveContainer>
//       </div>
//     </Fragment>
//   );
// }

// import React, { Fragment, useEffect, forwardRef } from "react";
// import moment from "moment";
// import randomColor from "randomcolor";
// import { makeStyles } from "@material-ui/core/styles";
// import * as dfd from "danfojs";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import {
//   Paper,
//   Typography,
//   List,
//   ListItem,
//   ListItemText,
// } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   customTooltip: {
//     margin: "0px",
//     padding: "10px",
//     backgroundColor: "rgb(255, 255, 255)",
//     border: "1px solid rgb(204, 204, 204)",
//   },
//   spwContainer: {
//     margin: "5px",
//     padding: "5px",
//     backgroundColor: theme.palette.background.paper,
//     fontSize: "0.8rem",
//   },
//   spwTitle: {
//     marginBottom: "5px",
//     fontSize: "0.9rem",
//     fontWeight: "bold",
//   },

//   spwList: {
//     marginBottom: "5px",
//     paddingLeft: "15px",
//     fontSize: "0.8rem", // Adjust the font size for the entire list
//   },
//   spwListItem: {
//     padding: "2px 0",
//     fontSize: "0.8rem", // Adjust the font size for list items
//   },
// }));

// export default function DomainLineChart({
//   roadmapNodes,
//   setChartDomainArea,
//   chartDomainArea,
//   setIndividualGoalToChartData,
//   setReferenceLineDate,
//   roadmapFocusNodeIndex,
//   setRoadmapFocusNodeIndex,
//   dataForCharts,
//   clearIndividualGoalLineChart,
//   setClearIndividualGoalLineChart,
//   setSelectedGoalTitle,
//   selectedGoalTitle,
// }) {
//   const classes = useStyles();
//   const [dataForLineChart, setDataForLineChart] = React.useState([]);
//   const [uniqueGoals, setUniqueGoals] = React.useState([]);
//   const [domainGoalToNodeIndex, setDomainGoalToNodeIndex] = React.useState({});
//   const [spwData, setSpwData] = React.useState({
//     skills: [],
//     processes: [],
//     watermarks: [],
//   });

//   useEffect(() => {
//     if (dataForCharts.length > 0 && chartDomainArea) {
//       prepData();
//     } else {
//       setDataForLineChart([]);
//     }
//   }, [chartDomainArea, dataForCharts]);

//   useEffect(() => {
//     if (clearIndividualGoalLineChart) {
//       setClearIndividualGoalLineChart(false);
//       setIndividualGoalToChartData([]);
//       setRoadmapFocusNodeIndex(null);
//       setSelectedGoalTitle("");
//       setSpwData({ skills: [], processes: [], watermarks: [] });
//     }
//   }, [clearIndividualGoalLineChart]);

//   function prepData() {
//     const goalUpdates = [];
//     let goalCount = 0;
//     const mapIndex = {};

//     roadmapNodes.forEach((node, index) => {
//       if (node.domainArea === chartDomainArea) {
//         mapIndex[`g${goalCount}`] = index;
//         goalUpdates.push({
//           goalIndex: index,
//           goal: `g${goalCount}`,
//           dateCreated: moment(node.dateCreated).format("YYYY-MM-DD"),
//           progressAverage: 0,
//         });

//         node.childNodes.forEach((childNode) => {
//           if (childNode.indicatorsOfSuccess) {
//             const progressAverage =
//               childNode.indicatorsOfSuccess.reduce(
//                 (acc, indicator) => acc + indicator.indicatorProgress,
//                 0
//               ) / childNode.indicatorsOfSuccess.length;

//             goalUpdates.push({
//               goalIndex: index,
//               goal: `g${goalCount}`,
//               dateCreated: moment(childNode.dateCreated).format("YYYY-MM-DD"),
//               progressAverage,
//             });
//           }
//         });
//         goalCount += 1;
//       }
//     });

//     const df = new dfd.DataFrame(goalUpdates);
//     df.sortValues("dateCreated", { inplace: true });

//     const goalUpdatesDatewise = dfd.toJSON(df);
//     setUniqueGoals(new dfd.Series(df["goal"].values).unique().values);

//     const temp2 = {};
//     const dates = [];
//     goalUpdatesDatewise.forEach((item) => {
//       if (dates.includes(item.dateCreated)) {
//         temp2[item.dateCreated][item.goal] = item.progressAverage;
//       } else {
//         dates.push(item.dateCreated);
//         temp2[item.dateCreated] = {
//           goalIndex: item.goalIndex,
//           dateCreated: item.dateCreated,
//           [item.goal]: item.progressAverage,
//         };
//       }
//     });

//     const temp3 = Object.values(temp2);
//     setDomainGoalToNodeIndex(mapIndex);
//     setDataForLineChart(temp3);
//   }

//   function prepGoalProgressData(data) {
//     if (domainGoalToNodeIndex[data.name] !== roadmapFocusNodeIndex) {
//       const goalIndex = domainGoalToNodeIndex[data.name];
//       setRoadmapFocusNodeIndex(goalIndex);
//       setSelectedGoalTitle(roadmapNodes[goalIndex].goal);

//       const temp = [];
//       const dates = [];
//       roadmapNodes[goalIndex].childNodes.forEach(
//         (childNode, childNodeIndex) => {
//           dates.push(
//             moment(roadmapNodes[goalIndex].dateCreated).format("YYYY-MM-DD")
//           );

//           if (childNodeIndex === 0) {
//             const elem = {
//               dateCreated: moment(roadmapNodes[goalIndex].dateCreated).format(
//                 "YYYY-MM-DD"
//               ),
//               comment: "",
//               commentType: "",
//             };

//             roadmapNodes[goalIndex].indicatorsOfSuccess.forEach(
//               (indicator, index) => {
//                 elem[`Indicator${index}`] = 0;
//                 elem[`Title${index}`] = indicator.indicator;
//               }
//             );
//             temp.push(elem);
//           }

//           const elem = {
//             dateCreated: moment(childNode.dateCreated).format("YYYY-MM-DD"),
//             comment: childNode.comment,
//             commentType: childNode.commentType,
//           };

//           if (childNode.indicatorsOfSuccess) {
//             childNode.indicatorsOfSuccess.forEach((indicator, index) => {
//               elem[`Indicator${index}`] = indicator.indicatorProgress;
//               elem[`Title${index}`] = indicator.indicator;
//             });
//           }

//           temp.push(elem);
//         }
//       );

//       const goalCompletionDate = moment(
//         roadmapNodes[goalIndex].completionDate
//       ).format("YYYY-MM-DD");
//       if (!dates.includes(goalCompletionDate)) {
//         temp.push({ dateCreated: goalCompletionDate });
//       }

//       setReferenceLineDate(goalCompletionDate);

//       const df = new dfd.DataFrame(temp);
//       df.sortValues("dateCreated", { inplace: true });

//       setIndividualGoalToChartData(dfd.toJSON(df));

//       // Fetch SPW data
//       const spw = fetchSPWData(goalIndex);
//       console.log("SPW Data for Goal Index", goalIndex, spw);
//       setSpwData(spw);
//     }
//   }

//   // New function to fetch SPW data
//   function fetchSPWData(goalIndex) {
//     const spwData = {
//       skills: [],
//       processes: [],
//       watermarks: [],
//     };

//     const roadmapNode = roadmapNodes[goalIndex];
//     console.log("Roadmap Node:", roadmapNode); // Debug log

//     if (roadmapNode.skills) {
//       spwData.skills.push(...roadmapNode.skills);
//     }
//     if (roadmapNode.processes) {
//       spwData.processes.push(...roadmapNode.processes);
//     }
//     if (roadmapNode.watermarks) {
//       spwData.watermarks.push(...roadmapNode.watermarks);
//     }

//     console.log("Fetched SPW Data:", spwData); // Debug log
//     return spwData;
//   }

//   const GoalTitleComponent = forwardRef((props, ref) => (
//     <span {...props} ref={ref} style={{ fontStyle: "italic" }}></span>
//   ));

//   return (
//     <Fragment>
//       <div style={{ minHeight: "50px", margin: "0px 20px", fontSize: "12px" }}>
//         <span style={{ fontWeight: "bold" }}>Goal:</span> {selectedGoalTitle}
//       </div>

//       <div>
//         <ResponsiveContainer height={250}>
//           <LineChart
//             data={dataForLineChart}
//             margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
//           >
//             <CartesianGrid strokeDasharray="3 3" />
//             <XAxis dataKey="dateCreated" />
//             <YAxis />
//             <Tooltip />
//             <Legend />
//             {uniqueGoals.map((item) => (
//               <Line
//                 key={item}
//                 type="monotone"
//                 connectNulls
//                 dataKey={item}
//                 stroke={randomColor({ luminosity: "dark" })}
//                 name={item}
//                 activeDot={{ r: 8 }}
//                 strokeWidth={3}
//                 onClick={prepGoalProgressData}
//               />
//             ))}
//           </LineChart>
//         </ResponsiveContainer>
//       </div>

//       {/* SPW Data Display */}
//       <Paper className={classes.spwContainer} elevation={2}>
//         <Typography variant="subtitle1" className={classes.spwTitle}>
//           Skills
//         </Typography>
//         <List className={classes.spwList}>
//           {spwData.skills.map((skill, index) => (
//             <ListItem key={index} className={classes.spwListItem}>
//               <ListItemText primary={skill} />
//             </ListItem>
//           ))}
//         </List>
//         <Typography variant="subtitle1" className={classes.spwTitle}>
//           Processes
//         </Typography>
//         <List className={classes.spwList}>
//           {spwData.processes.map((process, index) => (
//             <ListItem key={index} className={classes.spwListItem}>
//               <ListItemText primary={process} />
//             </ListItem>
//           ))}
//         </List>
//         <Typography variant="subtitle1" className={classes.spwTitle}>
//           Watermarks
//         </Typography>
//         <List className={classes.spwList}>
//           {spwData.watermarks.map((watermark, index) => (
//             <ListItem key={index} className={classes.spwListItem}>
//               <ListItemText primary={watermark} />
//             </ListItem>
//           ))}
//         </List>
//       </Paper>
//     </Fragment>
//   );
// }
import React, { Fragment, useEffect, forwardRef } from "react";
import moment from "moment";
import randomColor from "randomcolor";
import { makeStyles } from "@material-ui/core/styles";
import * as dfd from "danfojs";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    margin: "0px",
    padding: "5px", // Reduced padding
    backgroundColor: "rgb(255, 255, 255)",
    border: "1px solid rgb(204, 204, 204)",
  },
  listItemText: {
    fontSize: "0.80rem", // Smaller font size
  },
  spwPaper: {
    margin: theme.spacing(1), // Reduced custom margin
    padding: theme.spacing(1), // Reduced custom padding
  },
  spwHeader: {
    marginBottom: theme.spacing(0.5), // Reduced margin for section headers
  },
  spwListItem: {
    paddingTop: theme.spacing(0), // Reduced padding for list items
    paddingBottom: theme.spacing(0), // Reduced padding for list items
    lineHeight: "1.4", // Reduced line height
  },
}));

export default function DomainLineChart({
  roadmapNodes,
  setChartDomainArea,
  chartDomainArea,
  setIndividualGoalToChartData,
  setReferenceLineDate,
  roadmapFocusNodeIndex,
  setRoadmapFocusNodeIndex,
  dataForCharts,
  clearIndividualGoalLineChart,
  setClearIndividualGoalLineChart,
  setSelectedGoalTitle,
  selectedGoalTitle,
}) {
  const classes = useStyles();
  const [dataForLineChart, setDataForLineChart] = React.useState([]);
  const [uniqueGoals, setUniqueGoals] = React.useState([]);
  const [domainGoalToNodeIndex, setDomainGoalToNodeIndex] = React.useState({});
  const [spwData, setSpwData] = React.useState({
    skills: [],
    processes: [],
    watermarks: [],
  });

  useEffect(() => {
    if (dataForCharts.length > 0 && chartDomainArea) {
      prepData();
    } else {
      setDataForLineChart([]);
    }
  }, [chartDomainArea, dataForCharts]);

  useEffect(() => {
    if (clearIndividualGoalLineChart) {
      setClearIndividualGoalLineChart(false);
      setIndividualGoalToChartData([]);
      setRoadmapFocusNodeIndex(null);
      setSelectedGoalTitle("");
      setSpwData({ skills: [], processes: [], watermarks: [] });
    }
  }, [clearIndividualGoalLineChart]);

  function prepData() {
    const goalUpdates = [];
    let goalCount = 0;
    const mapIndex = {};

    roadmapNodes.forEach((node, index) => {
      if (node.domainArea === chartDomainArea) {
        mapIndex[`g${goalCount}`] = index;
        goalUpdates.push({
          goalIndex: index,
          goal: `g${goalCount}`,
          dateCreated: moment(node.dateCreated).format("YYYY-MM-DD"),
          progressAverage: 0,
        });

        node.childNodes.forEach((childNode) => {
          if (childNode.indicatorsOfSuccess) {
            const progressAverage =
              childNode.indicatorsOfSuccess.reduce(
                (acc, indicator) => acc + indicator.indicatorProgress,
                0
              ) / childNode.indicatorsOfSuccess.length;

            goalUpdates.push({
              goalIndex: index,
              goal: `g${goalCount}`,
              dateCreated: moment(childNode.dateCreated).format("YYYY-MM-DD"),
              progressAverage,
            });
          }
        });
        goalCount += 1;
      }
    });

    const df = new dfd.DataFrame(goalUpdates);
    df.sortValues("dateCreated", { inplace: true });

    const goalUpdatesDatewise = dfd.toJSON(df);
    setUniqueGoals(new dfd.Series(df["goal"].values).unique().values);

    const temp2 = {};
    const dates = [];
    goalUpdatesDatewise.forEach((item) => {
      if (dates.includes(item.dateCreated)) {
        temp2[item.dateCreated][item.goal] = item.progressAverage;
      } else {
        dates.push(item.dateCreated);
        temp2[item.dateCreated] = {
          goalIndex: item.goalIndex,
          dateCreated: item.dateCreated,
          [item.goal]: item.progressAverage,
        };
      }
    });

    const temp3 = Object.values(temp2);
    setDomainGoalToNodeIndex(mapIndex);
    setDataForLineChart(temp3);
  }

  function prepGoalProgressData(data) {
    if (domainGoalToNodeIndex[data.name] !== roadmapFocusNodeIndex) {
      const goalIndex = domainGoalToNodeIndex[data.name];
      setRoadmapFocusNodeIndex(goalIndex);
      setSelectedGoalTitle(roadmapNodes[goalIndex].goal);

      const temp = [];
      const dates = [];
      roadmapNodes[goalIndex].childNodes.forEach(
        (childNode, childNodeIndex) => {
          dates.push(
            moment(roadmapNodes[goalIndex].dateCreated).format("YYYY-MM-DD")
          );

          if (childNodeIndex === 0) {
            const elem = {
              dateCreated: moment(roadmapNodes[goalIndex].dateCreated).format(
                "YYYY-MM-DD"
              ),
              comment: "",
              commentType: "",
            };

            roadmapNodes[goalIndex].indicatorsOfSuccess.forEach(
              (indicator, index) => {
                elem[`Indicator${index}`] = 0;
                elem[`Title${index}`] = indicator.indicator;
              }
            );
            temp.push(elem);
          }

          const elem = {
            dateCreated: moment(childNode.dateCreated).format("YYYY-MM-DD"),
            comment: childNode.comment,
            commentType: childNode.commentType,
          };

          if (childNode.indicatorsOfSuccess) {
            childNode.indicatorsOfSuccess.forEach((indicator, index) => {
              elem[`Indicator${index}`] = indicator.indicatorProgress;
              elem[`Title${index}`] = indicator.indicator;
            });
          }

          temp.push(elem);
        }
      );

      const goalCompletionDate = moment(
        roadmapNodes[goalIndex].completionDate
      ).format("YYYY-MM-DD");
      if (!dates.includes(goalCompletionDate)) {
        temp.push({ dateCreated: goalCompletionDate });
      }

      setReferenceLineDate(goalCompletionDate);

      const df = new dfd.DataFrame(temp);
      df.sortValues("dateCreated", { inplace: true });

      setIndividualGoalToChartData(dfd.toJSON(df));

      // Fetch SPW data
      const spw = fetchSPWData(goalIndex);
      console.log("SPW Data for Goal Index", goalIndex, spw);
      setSpwData(spw);
    }
  }

  // New function to fetch SPW data
  function fetchSPWData(goalIndex) {
    const spwData = {
      skills: [],
      processes: [],
      watermarks: [],
    };

    const roadmapNode = roadmapNodes[goalIndex];
    console.log("Roadmap Node:", roadmapNode); // Debug log

    if (roadmapNode.skills) {
      spwData.skills.push(...roadmapNode.skills);
    }
    if (roadmapNode.processes) {
      spwData.processes.push(...roadmapNode.processes);
    }
    if (roadmapNode.watermarks) {
      spwData.watermarks.push(...roadmapNode.watermarks);
    }

    console.log("Fetched SPW Data:", spwData); // Debug log
    return spwData;
  }

  const GoalTitleComponent = forwardRef((props, ref) => (
    <span {...props} ref={ref} style={{ fontStyle: "italic" }}></span>
  ));

  return (
    <Fragment>
      <div style={{ minHeight: "50px", margin: "0px 20px", fontSize: "12px" }}>
        <span style={{ fontWeight: "bold" }}>Goal:</span> {selectedGoalTitle}
      </div>

      <div>
        <ResponsiveContainer height={250}>
          <LineChart
            data={dataForLineChart}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="dateCreated" />
            <YAxis />
            <Tooltip />
            <Legend />
            {uniqueGoals.map((item) => (
              <Line
                key={item}
                type="monotone"
                connectNulls
                dataKey={item}
                stroke={randomColor({ luminosity: "dark" })}
                name={item}
                activeDot={{ r: 8 }}
                strokeWidth={3}
                onClick={prepGoalProgressData}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* SPW Data Display */}
      {/* <Paper className={classes.spwPaper} elevation={0}>
        <Typography className={classes.spwHeader} variant="subtitle1">
          Skills
        </Typography>
        <List>
          {spwData.skills.map((skill, index) => (
            <ListItem key={index} className={classes.spwListItem}>
              <ListItemText
                primary={skill}
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          ))}
        </List>
        <Typography className={classes.spwHeader} variant="subtitle1">
          Processes
        </Typography>
        <List>
          {spwData.processes.map((process, index) => (
            <ListItem key={index} className={classes.spwListItem}>
              <ListItemText
                primary={process}
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          ))}
        </List>
        <Typography className={classes.spwHeader} variant="subtitle1">
          Watermarks
        </Typography>
        <List>
          {spwData.watermarks.map((watermark, index) => (
            <ListItem key={index} className={classes.spwListItem}>
              <ListItemText
                primary={watermark}
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          ))}
        </List>
      </Paper> */}
      <Paper className={classes.spwPaper} elevation={0}>
        <Typography className={classes.spwHeader} variant="subtitle1">
          Skills
        </Typography>
        <List>
          {spwData.skills.length > 0 && (
            <ListItem className={classes.spwListItem}>
              <ListItemText
                primary={spwData.skills.join(", ")} // Join skills with comma
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          )}
        </List>
        <Typography className={classes.spwHeader} variant="subtitle1">
          Processes
        </Typography>
        <List>
          {spwData.processes.length > 0 && (
            <ListItem className={classes.spwListItem}>
              <ListItemText
                primary={spwData.processes.join(", ")} // Join processes with comma
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          )}
        </List>
        <Typography className={classes.spwHeader} variant="subtitle1">
          Watermarks
        </Typography>
        <List>
          {spwData.watermarks.length > 0 && (
            <ListItem className={classes.spwListItem}>
              <ListItemText
                primary={spwData.watermarks.join(", ")} // Join watermarks with comma
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          )}
        </List>
      </Paper>
    </Fragment>
  );
}
