import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppContext } from "../../../libs/contextLib";

import { DataGrid } from "@material-ui/data-grid";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import { makeStyles } from "@material-ui/core/styles";
import LessonPlansFilter from "./lessonPlansFilter";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Button } from "@material-ui/core";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import Chip from "@material-ui/core/Chip";
import LabelIcon from "@material-ui/icons/Label";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Fab from "@material-ui/core/Fab";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ListIcon from "@material-ui/icons/List";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import AlertDialog from "../../helperComponents/alertDialog";

import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import AddEditLessonPlansDialog from "./addEditLessonPlansDialog";
import ViewLessonPlanDialog from "./viewLessonPlanDialog";
import { Factory } from "../../../helpers/factories";
import { LessonPlansService } from "../../../services/lessonPlans";
import { LearningExperienceService } from "../../../services/learningExperiences";

import moment from "moment";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { Fragment } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import Toast from "../../helperComponents/toast";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  //   avatar: {
  //     backgroundColor: "#009be5",
  //   },
  contentWrapper: {
    margin: "40px 16px",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
  accordionFilter: {
    marginBottom: "5px",
    border: 0,
    // borderRadius: "8px",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  cardRoot: {
    minWidth: 190,
  },

  cardTitle: {
    fontSize: 14,
    fontWeight: "bold",
  },
  cardPos: {
    marginBottom: 12,
  },
  progress: {
    width: "100%",
    backgroundColor: "#e9ecef",
    borderRadius: ".25rem",
    height: "0.6rem",
    lineHeight: 0,
    fontSize: ".75rem",
    overflow: "hidden",
    marginBottom: "16px",
  },
  progressBarSelf: {
    height: "100%",
    // backgroundColor: "#007bff",
    backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  progressBarOther: {
    height: "100%",
    backgroundColor: "#5bc0de",
    // backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  fab: {
    margin: "0px",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
  },
  fabItem: {
    margin: "4px",
  },
  listCard: {
    boxShadow: "none",
    background: "transparent",
  },
  dataGrid: {
    flexGrow: 1,
    backgroundColor: "white",
  },
}));

function LessonPlans({
  activeLearningExperience,
  handleLessonPlanAddedToLE,
  handleCloseAddLessonPlanToLEDialog,
  ...props
}) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  const location = useLocation();
  //   const history = useHistory();

  const [showLoader, setShowLoader] = React.useState(true);

  const [viewAsList, setViewAsList] = React.useState(false);

  //const test datammnn
  const [lessonPlan, setLessonPlan] = React.useState(
    Factory.createLessonPlan()
  );
  const [lessonPlanId, setLessonPlanId] = React.useState(null); //not really used but need it for viewLesson which is called also from current timetbale

  const [selectedLessonPlanIndex, setSelectedLessonPlanIndex] =
    React.useState("");

  const [lessonPlans, setLessonPlans] = React.useState([]);

  const [addLessonToLE, setAddLessonToLE] = React.useState(false);

  //data is loading
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  //for getting more lessons
  const [page, setPage] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(false);

  //query string
  const [queryString, setQueryString] = React.useState({});

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 2000,
  });

  const [opType, setOpType] = React.useState("add");

  const [isAddEditLessonPlanDialogOpen, setIsAddEditLessonPlanDialogOpen] =
    React.useState(false);

  //alert dialog
  const [alertDialogOptions, setAlertDialogOptions] = React.useState({
    open: false,
    title: "",
    message:
      "Delete this lesson plan? It will be removed from all learning experiences also.",
    cancelMessage: "Cancel",
    confirmMessage: "OK",
  });

  //view lesson plan
  //dialog vie lesson
  const [isViewLessonPlanDialogOpen, setIsViewLessonPlanDialogOpen] =
    React.useState(false);

  //close lessonPlan node dialog
  function handleCloseLessonPlanAddEditDialog() {
    setOpType("add");
    setIsAddEditLessonPlanDialogOpen(false);
  }

  //use effect to add lesson plan to le; prevents delay
  useEffect(() => {
    if (addLessonToLE) {
      handleAddLessonPlanToLearningExperience();
      setAddLessonToLE(false);
    }
  }, [addLessonToLE]);

  //handle add  lessonplan  to learning experience
  async function handleAddLessonPlanToLearningExperience() {
    try {
      let updateLearningExperience =
        await LearningExperienceService.addLessonPlanToLearningExperience(
          currentUser.organisation.id,
          activeLearningExperience.timeTable,
          activeLearningExperience._id,
          lessonPlan._id
        );
      //call handler to add lessonplan to le in timetable
      handleLessonPlanAddedToLE(lessonPlan._id);

      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Lesson plan attached to learning experience",
      });
    } catch (err) {
      console.log("Erer ", err);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  }

  //delete lesson plan
  function handleOpenAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: true });
  }

  function handleCloseAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
  }

  function handleConfirmDeleteLessonPlan() {
    handleOpenAlertDialog();
  }

  async function handleProceedDeleteLessonPlan() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
    let temp = [...lessonPlans];
    temp.splice(selectedLessonPlanIndex, 1);
    try {
      await LessonPlansService.deleteLessonPlansByOrgIdAndLessonPlanId(
        currentUser.organisation.id,
        lessonPlan._id
      );
      setLessonPlans(temp);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Lesson Plan Deleted",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  /*
  fetch lessonPlan nodes, pre-load verify token
  */

  useEffect(() => {
    //if path is personal lessonPlan, set query string user  to user id
    // if (location.pathname === "/lessonPlan") {
    // queryString.user = currentUser.id;
    // }

    // if (props.startFetchData) {
    onLoad();
    // }
  }, [queryString]);

  async function onLoad() {
    try {
      let data = await LessonPlansService.getLessonPlansByOrgId(
        currentUser.organisation.id,
        queryString
      );
      setLessonPlans(data.lessons);
      setHasMore(data.hasMore);
      setPage(data.page);
      setIsDataLoading(false);
    } catch (err) {
      setErrorLoadingPage(true);
    }
    //hide loader
    setShowLoader(false);
  }

  // handle load more lessons
  const handleLoadMoreLessons = async () => {
    try {
      //update query page
      queryString.page = page;
      //get data
      let data = await LessonPlansService.getLessonPlansByOrgId(
        currentUser.organisation.id,
        queryString
      );
      //update lessons in grid
      let temp = [...lessonPlans];
      temp = temp.concat(data.lessons);
      setLessonPlans(temp);
      setHasMore(data.hasMore);
      setPage(data.page);
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error downloading lessons",
      });
    }
  };

  //grid view for lessons
  const gridView = () => {
    return (
      <Grid container spacing={3}>
        {lessonPlans.map((lessonPlan, index) => {
          return (
            <Grid item xs={12} sm={6} md={3} key={"lessonPlan" + index}>
              <Card className={classes.cardRoot}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      {lessonPlan.teacher.firstName[0]}
                    </Avatar>
                  }
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        setSelectedLessonPlanIndex(index);
                        setLessonPlan(lessonPlan);
                        if (lessonPlan.teacher._id === currentUser.id) {
                          setOpType("edit");

                          setIsAddEditLessonPlanDialogOpen(true);
                        } else {
                          setIsViewLessonPlanDialogOpen(true);
                        }
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  }
                  title={(() => {
                    return (
                      lessonPlan.teacher.firstName +
                      " " +
                      lessonPlan.teacher.lastName
                    ).length > 16
                      ? (
                          lessonPlan.teacher.firstName +
                          " " +
                          lessonPlan.teacher.lastName
                        ).slice(0, 16)
                      : lessonPlan.teacher.firstName +
                          " " +
                          lessonPlan.teacher.lastName;
                  })()}
                  subheader={moment(lessonPlan.dateCreated).format(
                    "DD/MM/YYYY"
                  )}
                />
                <CardContent style={{ paddingBottom: 0 }}>
                  <Typography
                    className={classes.cardTitle}
                    color="primary"
                    gutterBottom
                  >
                    {"Grade " +
                      lessonPlan.grade +
                      ", " +
                      lessonPlan.domainArea.displayName}
                  </Typography>
                  <Typography
                    className={classes.cardTitle}
                    color="primary"
                    gutterBottom
                  >
                    {lessonPlan.learningCycle &&
                      "Learning Cycle " + lessonPlan.learningCycle}
                  </Typography>
                  <Typography variant="subtitle2" component="p">
                    {lessonPlan.title > 200
                      ? lessonPlan.title.slice(0, 197) + "..."
                      : lessonPlan.title.slice(0, 197)}
                  </Typography>
                  <div style={{ marginTop: 8 }}>
                    <p style={{ marginBottom: 0, marginTop: 0 }}>
                      <Typography variant="caption" component="p">
                        {lessonPlan.overview > 400
                          ? lessonPlan.overview.slice(0, 397) + "..."
                          : lessonPlan.overview.slice(0, 397)}
                      </Typography>
                    </p>
                  </div>
                  {/* tags */}
                  <div style={{ marginTop: 8 }}>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      style={{ display: "inline" }}
                    >
                      {"Tags "}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="p"
                      style={{ display: "inline" }}
                    >
                      {lessonPlan.tags && lessonPlan.tags.length > 0
                        ? lessonPlan.tags.join(", ")
                        : "---"}
                    </Typography>
                  </div>
                  {/* skills */}
                  <div style={{ marginTop: 8 }}>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      style={{ display: "inline" }}
                    >
                      {"Skills "}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="p"
                      style={{ display: "inline" }}
                    >
                      {lessonPlan.skills && lessonPlan.skills.length > 0
                        ? lessonPlan.skills.join(", ")
                        : "---"}
                    </Typography>
                  </div>
                  {/* processes */}
                  <div style={{ marginTop: 8 }}>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      style={{ display: "inline" }}
                    >
                      {"Processes "}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="p"
                      style={{ display: "inline" }}
                    >
                      {lessonPlan.processes && lessonPlan.processes.length > 0
                        ? lessonPlan.processes.join(", ")
                        : "---"}
                    </Typography>
                  </div>
                  {/* watermarks */}
                  <div style={{ marginTop: 8 }}>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      style={{ display: "inline" }}
                    >
                      {"Watermarks "}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="p"
                      style={{ display: "inline" }}
                    >
                      {lessonPlan.watermarks && lessonPlan.watermarks.length > 0
                        ? lessonPlan.watermarks.join(", ")
                        : "---"}
                    </Typography>
                  </div>
                  {/* attributes */}
                  <div style={{ marginTop: 8 }}>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      style={{ display: "inline" }}
                    >
                      {"School Attributes "}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="p"
                      style={{ display: "inline" }}
                    >
                      {lessonPlan.schoolAttributes &&
                      lessonPlan.schoolAttributes.length > 0
                        ? lessonPlan.schoolAttributes.join(", ")
                        : "---"}
                    </Typography>
                  </div>
                </CardContent>
                <CardActions style={{ paddingTop: 0 }}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "right" }}
                    >
                      {currentUser.id === lessonPlan.teacher._id &&
                        location.pathname === "/lessonPlans" && (
                          <Tooltip title="Delete Lesson Plan">
                            <IconButton
                              aria-label="delete-lesson-plam"
                              onClick={() => {
                                setOpType("edit");
                                setSelectedLessonPlanIndex(index);
                                setLessonPlan(lessonPlan);
                                handleOpenAlertDialog();
                              }}
                              // onClick={handleOpenAlertDialog}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                      {currentUser.id === lessonPlan.teacher._id &&
                        location.pathname === "/timetable" && (
                          <Tooltip title="Use Lesson for Learning Experience">
                            <IconButton
                              aria-label="addToLearningExperience"
                              onClick={() => {
                                setLessonPlan(lessonPlan);
                                setAddLessonToLE(true);
                              }}
                            >
                              <AddToPhotosIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          );
        })}

        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!hasMore}
            onClick={handleLoadMoreLessons}
            style={{
              marginBottom: "32px",
            }}
          >
            {hasMore ? "Load More" : "No more  results"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const lessonsContainer = (
    <Fragment>
      <AlertDialog
        alertDialogOptions={alertDialogOptions}
        handleClose={handleCloseAlertDialog}
        handleConfirm={handleProceedDeleteLessonPlan}
      />
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />
      <ViewLessonPlanDialog
        lessonPlan={lessonPlan}
        setLessonPlan={setLessonPlan}
        setLessonPlanId={setLessonPlanId}
        isViewLessonPlanDialogOpen={isViewLessonPlanDialogOpen}
        setIsViewLessonPlanDialogOpen={setIsViewLessonPlanDialogOpen}
      />
      <AddEditLessonPlansDialog
        lessonPlan={lessonPlan}
        setLessonPlan={setLessonPlan}
        // isNewLessonPlan={isNewLessonPlan}
        // cellEventData={cellEventData}
        lessonPlans={lessonPlans}
        setLessonPlans={setLessonPlans}
        selectedLessonPlanIndex={selectedLessonPlanIndex}
        // selectedRowIndex={selectedRowIndex}
        opType={opType}
        setOpType={setOpType}
        isAddEditLessonPlanDialogOpen={isAddEditLessonPlanDialogOpen}
        handleCloseAddEditLessonPlanDialog={handleCloseLessonPlanAddEditDialog}
        // setHasNewLessonPlanInLE={setHasNewLessonPlanInLE}
        toastOptions={toastOptions}
        setToastOptions={setToastOptions}
        handleCloseToast={handleCloseToast}
      />
      <div className={classes.root}>
        {/* <AddEditLessonPlansDialog
          toastOptions={toastOptions}
          setToastOptions={setToastOptions}
          handleCloseToast={handleCloseToast}
          opType={opType}
          setOpType={setOpType}
          lessonPlan={lessonPlan}
          setLessonPlan={setLessonPlan}
          selectedLessonPlanIndex={selectedLessonPlanIndex}
          lessonPlans={lessonPlans}
          setLessonPlans={setLessonPlans}
          isAddEditLessonPlanDialogOpen={isAddEditLessonPlanDialogOpen}
          handleCloseLessonPlanAddEditDialog={
            handleCloseLessonPlanAddEditDialog
          }
        /> */}
        <div className={classes.fab}>
          <Fab
            color="primary"
            aria-label="add"
            size="small"
            className={classes.fabItem}
          >
            <AddIcon
              onClick={() => {
                setOpType("add");
                //clear dialog
                setLessonPlan(Factory.createLessonPlan());
                setIsAddEditLessonPlanDialogOpen(true);
              }}
            />
          </Fab>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Accordion className={classes.accordionFilter}>
              <AccordionSummary
                expandIcon={<FilterListIcon color="primary" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {/* <Typography variant="body1" gutterBottom>
                Filter
              </Typography> */}
              </AccordionSummary>
              <AccordionDetails>
                <LessonPlansFilter
                  queryString={queryString}
                  setQueryString={setQueryString}
                  toastOptions={toastOptions}
                  setToastOptions={setToastOptions}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        {/* loading icon */}
        <Grid
          item
          xs={12}
          style={{
            display: showLoader ? "block" : "none",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress />
          </div>
        </Grid>
        {/* no lessonPlans */}
        <Grid
          item
          xs={12}
          style={{
            display: lessonPlans.length == 0 && !showLoader ? "block" : "none",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "block" }}>
              <HourglassEmptyIcon style={{ fontSize: "40px" }} />
            </div>
            <div style={{ display: "block" }}>
              <Typography variant="h6" className={classes.textCenter}>
                There are no lessonPlans.
              </Typography>
            </div>
          </div>
        </Grid>
        {/* lessonPlan container grid  view */}
        {gridView()}
      </div>
    </Fragment>
  );

  if (errorLoadingPage) {
    return <ErrorLoadingPage />;
  } else {
    return lessonsContainer;
  }
}

export default LessonPlans;
