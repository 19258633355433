/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const LessonPlansService = {
  getLessonPlansByOrgId,
  getLessonPlanByOrgIdAndLessonPlanId,
  addLessonPlansByOrgId,
  updateLessonPlansByOrgIdAndLessonPlanId,
  deleteLessonPlansByOrgIdAndLessonPlanId,
};

function getLessonPlansByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_lessons_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return { lessons: data.lessons, page: data.page, hasMore: data.hasMore };
    });
}

function getLessonPlanByOrgIdAndLessonPlanId(orgId, lessonId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_lessons_by_orgId_and_lessonId;

  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
      lessonId: lessonId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.lesson;
    });
}

function addLessonPlansByOrgId(orgId, lessonPlan) {
  const requestOptions = RequestHelper.getPostRequestOptions(lessonPlan);

  var api_url = config.api_lessons_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  // + RequestHelper.formatQueryString(options);
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.lesson;
    });
}

function updateLessonPlansByOrgIdAndLessonPlanId(orgId, lessonId, data) {
  const requestOptions = RequestHelper.getPutRequestOptions(data);

  var api_url = config.api_lessons_by_orgId_and_lessonId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    lessonId: lessonId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.lesson;
    });
}

function deleteLessonPlansByOrgIdAndLessonPlanId(orgId, lessonId) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_lessons_by_orgId_and_lessonId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    lessonId: lessonId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
