import React, { useState } from "react";
import { useAppContext } from "../../../libs/contextLib";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { UserService } from "../../../services/user";
import Toast from "../../helperComponents/toast";
import SearchAdmins from "../../helperComponents/searchAdmins";
import { Factory } from "../../../helpers/factories";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  contentWrapper: {
    margin: "40px 16px",
  },
  formRoot: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  formControl: {
    margin: theme.spacing(2),
    width: "90%",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
}));

function ResetAdminPasswords(props) {
  const classes = useStyles();
  const { currentUser } = useAppContext();

  const [user, setUser] = useState(Factory.createUser());
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 6000,
  });

  //validate form simple
  function validateForm() {
    return (
      user &&
      user._id &&
      newPassword.length >= 8 &&
      confirmNewPassword.length >= 8 &&
      newPassword === confirmNewPassword
    );
  }

  //close toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  //submit
  async function handleSubmit(event) {
    event.preventDefault(); //prevent browser default submit
    let data = {
      userId: user._id,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
    };

    setToastOptions({
      ...toastOptions,
      open: true,
      message: "Saving...",
    });

    UserService.resetUserPasswordByOrgIdAndUserId(
      user.organisation._id,
      user._id,
      data
    ).then(
      (result) => {
        setToastOptions({
          ...toastOptions,
          open: true,
          message: "Password saved",
        });
      },
      (error) => {
        setToastOptions({
          ...toastOptions,
          open: true,
          message: error.message,
        });
      }
    );
  }

  return (
    <div className={classes.root}>
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />
      <Grid
        container
        spacing={3}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {/* <Grid item xs={12}>
          <Typography variant="h5">Reset Password</Typography>
        </Grid> */}
        <Grid item xs={12} sm={8} style={{ display: "flex" }}>
          <Paper className={classes.paper}>
            <form className={classes.formRoot} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h5">Reset User Password</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <SearchAdmins
                    user={user}
                    setUser={setUser}
                    role={""}
                    style={{ display: "flex" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="newPassword">New Password</InputLabel>
                    <Input
                      id="newPassword"
                      aria-describedby="my-helper-text"
                      type="password"
                      autoComplete="new-password"
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <FormHelperText id="my-helper-text">
                      New password at least 8 characters long.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="confirmNewPassword">
                      Confirm New Password
                    </InputLabel>
                    <Input
                      id="confirmNewPassword"
                      type="password"
                      autoComplete="new-password"
                      aria-describedby="my-helper-text"
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                    <FormHelperText id="my-helper-text">
                      Please confirm new password.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={!validateForm()}
                    onClick={handleSubmit}
                  >
                    Save Password
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ResetAdminPasswords;
