import React, { Fragment, useEffect } from "react";
import moment from "moment";
import { useAppContext } from "../../../libs/contextLib";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationService } from "../../../services/notifications";
import Skeleton from "@material-ui/lab/Skeleton";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "32px",
  },
  roundedBadge: {
    borderRadius: "4px",
    backgroundColor: "#089ce5",
    color: "white",
    display: "inline-block",
    padding: "4px 8px",
    minHeight: "26px",
    maxHeight: "26px",
    textAlign: "center",
    fontSize: "13px",
  },
  roundedBadgeLoadMore: {
    borderRadius: "4px",
    backgroundColor: "#ccc",
    color: "white",
    display: "inline-block",
    padding: "4px 8px",
    minHeight: "26px",
    maxHeight: "26px",
    textAlign: "center",
    fontSize: "13px",
  },
  notification: {},

  body: {
    display: "block",
    textAlign: "justify",
    marginBottom: "20px",
    fontSize: "13px",
  },
  title: {
    display: "block",
    fontWeight: "bold",
    fontSize: "13px",
  },
  avatarBackground: {
    //color: theme.palette.getContrastText(deepOrange[500]),
    // backgroundColor: theme.palette.primary.main, //deepOrange[500],
  },
}));

function Notification(props) {
  const classes = useStyles();

  const { currentUser } = useAppContext();

  const [notifications, setNotifications] = React.useState([]);
  const [groupedNotifications, setGroupedNotifications] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(true);
  const [errorRetrieving, setErrorRetrieving] = React.useState(false);

  const [hasMore, setHasMore] = React.useState(false);
  const [page, setPage] = React.useState(0);

  //query string
  const [queryString, setQueryString] = React.useState({});

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    groupNotifications();
  }, [notifications]);

  useEffect(() => {}, [groupedNotifications]);

  async function onLoad() {
    try {
      let data = await NotificationService.getNotificationsByOrgId(
        currentUser.organisation.id,
        queryString
      );
      setNotifications(data.notifications);
      setIsLoading(false);
      setHasMore(data.hasMore);
      setPage(data.page);
    } catch (err) {
      setIsLoading(false);
      setErrorRetrieving(true);
    }
  }

  // handle load more notifications
  const handleLoadMoreNotifications = async () => {
    //update query page
    queryString["page"] = page;
    try {
      let data = await NotificationService.getNotificationsByOrgId(
        currentUser.organisation.id,
        queryString
      );
      let temp = [...notifications];
      temp = temp.concat(data.notifications);
      setNotifications(temp);
      setHasMore(data.hasMore);
      setPage(data.page);
    } catch (err) {
      setIsLoading(false);
      setErrorRetrieving(true);
    }
  };

  const groupNotifications = () => {
    let temp = [...notifications];
    let tempGroupedNotification = { Today: [], Yesterday: [], "Past Week": [] };
    temp.forEach((item) => {
      if (
        moment(item.dateCreated).format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
      ) {
        tempGroupedNotification["Today"].push(item);
      } else if (
        moment(item.dateCreated).format("YYYY-MM-DD") ===
        moment().subtract(1, "day").format("YYYY-MM-DD")
      ) {
        tempGroupedNotification["Yesterday"].push(item);
      } else {
        tempGroupedNotification["Past Week"].push(item);
      }
    });
    //push grouped notifications into an array for ordering
    let temp2 = [];
    if (tempGroupedNotification["Today"]) {
      temp2.push({ period: "Today", data: tempGroupedNotification["Today"] });
    }
    if (tempGroupedNotification["Yesterday"]) {
      temp2.push({
        period: "Yesterday",
        data: tempGroupedNotification["Yesterday"],
      });
    }
    if (tempGroupedNotification["Past Week"]) {
      temp2.push({
        period: "Past Week",
        data: tempGroupedNotification["Past Week"],
      });
    }

    setGroupedNotifications([...temp2]);
  };

  return (
    <div className={classes.root}>
      {/* top row */}
      <Grid
        container
        spacing={3}
        style={{
          borderRadius: "10px",
          backgroundColor: "#eaeff1",
        }}
      >
        <Grid item xs={12} sm={12}>
          <span className={classes.roundedBadge}>Notifications</span>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            overflow: "scroll",
            paddingTop: "0",
            minHeight: "250px",
            maxHeight: "250px",
          }}
        >
          {isLoading ? (
            <div
              style={{
                padding: "0px 12px",
                minHeight: "60px",
                maxHeight: "60px",
                width: "100%",
              }}
            >
              <Skeleton variant="rect" width="100%" height="100%" />
            </div>
          ) : errorRetrieving ? (
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                minHeight: "60px",
                maxHeight: "60px",
              }}
            >
              <span>Error retrieving notifications</span>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                overflow: "scroll",
                paddingTop: "0",
                minHeight: "250px",
                maxHeight: "250px",
              }}
            >
              <List style={{ backgroundColor: "none" }}>
                {notifications.length > 0 ? (
                  groupedNotifications.map((item, index) => {
                    return item["data"].length > 0 ? (
                      <div style={{ marginBottom: "8px" }}>
                        <span className={classes.title}>{item.period}</span>
                        {item["data"].map((note, noteIndex) => (
                          <Fragment>
                            <ListItem
                              style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  src={note.from.picURL}
                                  alt={note.from.firstName}
                                  className={classes.avatarBackground}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                disableTypography
                                primary={
                                  <Typography
                                    variant="caption"
                                    style={{ color: "#000" }}
                                  >
                                    {note.message}
                                  </Typography>
                                }
                              />
                            </ListItem>
                            <Divider
                              variant="inset"
                              component="li"
                              style={{ backgroundColor: "#ccc" }}
                            />
                          </Fragment>
                        ))}
                      </div>
                    ) : null;
                  })
                ) : (
                  <ListItem>You don't have any notifications.</ListItem>
                )}
              </List>
              {hasMore ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    size="small"
                    className={classes.margin}
                    onClick={handleLoadMoreNotifications}
                  >
                    Load More
                  </Button>
                </div>
              ) : null}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sm={12} style={{ paddingBottom: "2px" }}></Grid>
      </Grid>
    </div>
  );
}

export default Notification;
