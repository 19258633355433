/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const GroupRoadmapService = {
  addGroupRoadmapByOrgId,
  getGroupRoadmapByOrgId,
  updateGroupRoadmapById,
  deleteGroupRoadmapById,
};

function addGroupRoadmapByOrgId(orgId, groupRoadmap) {
  const requestOptions = RequestHelper.getPostRequestOptions(groupRoadmap);

  var api_url = config.api_groupRoadmap_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.groupRoadmap;
    });
}

function getGroupRoadmapByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_groupRoadmap_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return {
        groupRoadmaps: data.groupRoadmaps,
        page: data.page,
        hasMore: data.hasMore,
      };
    });
}

function updateGroupRoadmapById(orgId, groupRoadmapId, groupRoadmap) {
  const requestOptions = RequestHelper.getPutRequestOptions(groupRoadmap);

  var api_url = config.api_groupRoadmap_by_orgId_and_groupRoadmapId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    groupRoadmapId: groupRoadmapId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.groupRoadmap;
    });
}

// delete group roadmap

function deleteGroupRoadmapById(orgId, groupRoadmapId) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_groupRoadmap_by_orgId_and_groupRoadmapId;

  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    groupRoadmapId: groupRoadmapId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data._id;
    });
}
