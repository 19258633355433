import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

import blue from "@material-ui/core/colors/blue";
import gray from "@material-ui/core/colors/blue";

let theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    //notworking nbelow
    mutedGray: {
      main: gray[400],
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
