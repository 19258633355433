import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import UserMenu from "./userMenu";
import Roles from "../../../helpers/roles";

const useStyles = makeStyles((theme) => ({
  dataGridRoot: {
    width: "100%",
    display: "flex",
    //padding: theme.spacing(4),
    // marginBottom: "20px",
  },
  dataGrid: {
    flexGrow: 1,
    // height: "600px",
    // minHeight: "400px",
    backgroundColor: "white !important",
  },
  whiteBackground: {
    backgroundColor: "white !important",
  },
  moreVertIcon: {
    cursor: "pointer",
    // color: //theme.palette.mutedGray.main,
  },
}));

function UsersDataGrid({
  setUser,
  user,
  users,
  setOpType,
  setSelectedRowIndex,
  setIsDialogOpen,
  isDataLoading,
  handleOpenDialog,
  ...props
}) {
  const classes = useStyles();

  // const [anchorEl, setAnchorEl] = React.useState({});

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    // { field: "firstName", headerName: "First Name", width: 120 },
    // { field: "lastName", headerName: "Last Name", width: 130 },
    {
      field: "firstName",
      headerName: "Name",
      width: 250,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", marginRight: "10px" }}>
              <Avatar
                src={params.row.user.picURL}
                alt={params.row.user.firstName}
                className={classes.avatarBackground}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2">
                {params.row.user.firstName + " " + params.row.user.lastName}
              </Typography>
              <Typography
                variant="caption"
                style={{ textTransform: "capitalize" }}
              >
                {Roles[params.row.user.role].displayName}
                {params.row.user.role === "student" &&
                  `, Grade ${params.row.user.grade}`}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderCell: (params) => {
        return <Typography variant="body2">{params.row.user.email}</Typography>;
      },
    },
    {
      field: "yearJoined",
      headerName: "Year Joined",
      width: 130,
      renderCell: (params) => {
        return (
          <Typography variant="body2">{params.row.user.yearJoined}</Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      renderCell: (params) => {
        return (
          <Typography variant="body2">
            {params.row.user.accountStatus}
          </Typography>
        );
      },
    },
    {
      field: "",
      headerName: "",
      width: 60,
      renderCell: (params) => {
        return (
          <UserMenu
            user={user}
            setUser={setUser}
            users={users}
            setOpType={setOpType}
            rowIndex={params.row.id}
            setIsDialogOpen={setIsDialogOpen}
          />
          // <div style={{ display: "flex" }}>
          //   <Tooltip title="More">
          //     <IconButton
          //       aria-label="edit"
          //       onClick={(e) => handleClick(params.row.id, e)}
          //       id={params.row.id + "-button"}
          //     >
          //       <MoreVertIcon className={classes.moreVertIcon} />
          //     </IconButton>
          //   </Tooltip>
          //   {/* <Button
          //     aria-controls="simple-menu"
          //     aria-haspopup="true"
          //     onClick={(e) => handleClick(params.row.id, e)}
          //     id={params.row.id + "-button"}
          //   >
          //     Open Menu
          //   </Button> */}
          //   <Menu
          //     id={params.row.id + "-menu"}
          //     anchorEl={anchorEl && anchorEl[params.row.id]}
          //     keepMounted
          //     open={Boolean(anchorEl && anchorEl[params.row.id])}
          //     onClose={handleClose}
          //   >
          //     <MenuItem onClick={handleClose}>Hello</MenuItem>
          //     <MenuItem onClick={handleClose}>Ok</MenuItem>
          //   </Menu>
          // </div>
        );
      },
    },
    // ...(currentUser.role == "schoolAdmin"
    //   ? [
    //       {
    //         field: "",
    //         headerName: "",
    //         disableClickEventBubbling: true,
    //         renderCell: (params) => {
    //           const onClick = () => {
    //             setUser(params.row);
    //             handleOpenDialog();
    //             //return alert(JSON.stringify(thisRow, null, 4));
    //           };

    //           return (
    //             <div>
    //               <Tooltip title="Edit">
    //                 <IconButton aria-label="edit" onClick={onClick}>
    //                   <EditIcon />
    //                 </IconButton>
    //               </Tooltip>
    //               <Tooltip title="Delete">
    //                 <IconButton aria-label="delete" onClick={onClick}>
    //                   <DeleteIcon />
    //                 </IconButton>
    //               </Tooltip>
    //             </div>
    //           );
    //         },
    //       },
    //     ]
    //   : []),
  ];

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleClick = (index, event) => {
  //   console.log(index);
  //   setAnchorEl({ [index]: event.currentTarget });
  // };

  return (
    <div className={classes.dataGridRoot}>
      <div className={classes.dataGrid}>
        <DataGrid
          className={classes.whiteBackground}
          rows={users}
          columns={columns}
          pageSize={10}
          pagination
          //rowCount={props.rowCount}
          //paginationMode="server"
          //onPageChange={handleDataGridPageChange}
          autoHeight={true}
          loading={isDataLoading}
        />
      </div>
    </div>
  );
}

export default UsersDataGrid;
