import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// const classes = {
//   root: {
//     display: "flex",
//     height: "100%",
//     alignItems: "center",
//     justifyContent: "center",
//   },
// };

export const Toast = {
  toastOptions,
  toastComponent,
  handleCloseToast,
};

function toastOptions() {
  return { open: false, message: "", duration: 12000 };
}

//toast
function handleCloseToast(setToastOptions) {
  return (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };
}

// const handleCloseToast = (e, reason) => {
//   if (reason === "clickaway") {
//     return;
//   }
//   setToastOptions({ ...toastOptions, open: false });
// };

function toastComponent(props) {
  return (
    <Snackbar
      //anchorOrigin={(state.vertical, state.horizontal)}
      open={props.toastOptions.open}
      //   onClose={handleClose}
      message={props.toastOptions.message}
      autoHideDuration={props.toastOptions.duration}
      onClose={props.handleCloseToast}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={props.handleCloseToast}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
      //   key={state.vertical + state.horizontal}
    />
  );
}

export default Toast;
