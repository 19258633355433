import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import SelectDomainArea from "../../helperComponents/selectDomainArea";

import { Factory } from "../../../helpers/factories";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterElements: {
    margin: theme.spacing(2),
    width: "90%",
  },
}));

export default function DomainRoadmapFilter({
  toastOptions,
  setToastOptions,
  queryString,
  setQueryString,
  ...props
}) {
  const classes = useStyles();

  //current user from app context
  const { currentUser } = useAppContext();
  const location = useLocation();

  //grade
  const [grade, setGrade] = React.useState("");

  //year
  const [year, setYear] = React.useState("");

  //populating year dropdwon
  const currentYear = moment().year();
  const yearArray = Array.from(
    Array(currentYear - 2020),
    (_, i) => currentYear - i
  );

  const [domainArea, setDomainArea] = React.useState(
    Factory.createDomainArea()
  );

  //pre-load domain/araes
  useEffect(() => {
    //set initial query string
    setQueryString({
      domainArea: domainArea._id ? domainArea._id : "",
      grade: grade != "All" ? grade : "",
      year: year,
    });
  }, [domainArea, grade, year]);

  //handle  grade change
  const handleGradeChange = (e) => {
    setGrade(e.target.value);
  };

  //handle  year change
  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  //handle assessment Type  change
  //   const handleTypeChange = (e) => {
  //     setAssessmentType(e.target.value);
  //     if (e.target.value == "all") {
  //       setQueryString({
  //         ...queryString,
  //         type: "",
  //       });
  //     } else {
  //       setQueryString({
  //         ...queryString,
  //         type: e.target.value,
  //       });
  //     }
  //   };

  return (
    <Grid container justify="space-between" spacing={3}>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ backgroundColor: "white", borderRadius: "5px" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SelectDomainArea
              domainArea={domainArea}
              setDomainArea={setDomainArea}
              showAllOption={true}
              orgId={currentUser.organisation.id}
              type={""}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%" }}
            >
              <InputLabel id="type-description">Grade</InputLabel>
              <Select
                labelId="grade"
                id="grade"
                name="grade"
                value={grade}
                onChange={handleGradeChange}
                label="Grade"
              >
                <MenuItem value={"0"}>PP</MenuItem>
                <MenuItem value={"1"}>1</MenuItem>
                <MenuItem value={"2"}>2</MenuItem>
                <MenuItem value={"3"}>3</MenuItem>
                <MenuItem value={"4"}>4</MenuItem>
                <MenuItem value={"5"}>5</MenuItem>
                <MenuItem value={"6"}>6</MenuItem>
                <MenuItem value={"7"}>7</MenuItem>
                <MenuItem value={"8"}>8</MenuItem>
                <MenuItem value={"9"}>9</MenuItem>
                <MenuItem value={"10"}>10</MenuItem>
                <MenuItem value={"11"}>11</MenuItem>
                <MenuItem value={"12"}>12</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%" }}
            >
              <InputLabel id="type-description">Year</InputLabel>
              <Select
                labelId="year"
                id="year"
                name="year"
                value={year}
                onChange={handleYearChange}
                label="Year"
              >
                {yearArray.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
