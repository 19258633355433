/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";

import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const RoadmapService = {
  getRoadmapNodesByOrgId,
  getRoadmapNodesAndRoadmapChildNodesByOrgId,
  getRoadmapChildNodeByOrgIdAndRoadmapNodeId,
  addRoadmapNodeByOrgId,
  addRoadmapChildNodeByOrgIdAndRoadmapNodeId,
  updateRoadmapNodeByOrgIdAndRoadmapNodeId,
  updateRoadmapNodeByOrgIdAndRoadmapNodeIdStatus,
  updateRoadmapNodeByOrgIdAndRoadmapNodeIdCanMarkAsCompleted,
  updateRoadmapChildNodeByOrgIdAndRoadmapNodeIdAndRoadmapChildNodeId,
  deleteRoadmapNodeByOrgIdAndRoadmapNodeId,
  deleteRoadmapChildNodeByOrgIdAndRoadmapNodeIdAndRoadmapChildNodeId,
};

function addRoadmapNodeByOrgId(orgId, roadmapNode) {
  const requestOptions = RequestHelper.getPostRequestOptions(roadmapNode);

  var api_url = config.api_roadmapNodes_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.roadmapNode;
    });
}

function addRoadmapChildNodeByOrgIdAndRoadmapNodeId(
  orgId,
  roadmapNodeId,
  roadmapChildNode
) {
  const requestOptions = RequestHelper.getPostRequestOptions(roadmapChildNode);

  var api_url = config.api_roadmapChildNodes_by_orgId_roadmapNodeId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    roadmapNodeId: roadmapNodeId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.roadmapChildNode;
    });
}

function getRoadmapNodesByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_roadmapNodes_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.roadmapNodes;
    });
}

function getRoadmapNodesAndRoadmapChildNodesByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_roadmapNodes_and_roadmapChildNodes_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.roadmapNodes;
    });
}

function getRoadmapChildNodeByOrgIdAndRoadmapNodeId(orgId, roadmapNodeId) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_roadmapChildNodes_by_orgId_roadmapNodeId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    roadmapNodeId: roadmapNodeId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.roadmapChildNodes;
    });
}

function updateRoadmapNodeByOrgIdAndRoadmapNodeId(
  orgId,
  roadmapNodeId,
  roadmapNode
) {
  const requestOptions = RequestHelper.getPutRequestOptions(roadmapNode);

  var api_url = config.api_roadmapNode_by_orgId_and_roadmapNodeId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    roadmapNodeId: roadmapNodeId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.roadmapNode;
    });
}

function updateRoadmapNodeByOrgIdAndRoadmapNodeIdStatus(
  orgId,
  roadmapNodeId,
  roadmapNode
) {
  const requestOptions = RequestHelper.getPutRequestOptions(roadmapNode);

  var api_url = config.api_roadmapNode_by_orgId_and_roadmapNodeId_status;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    roadmapNodeId: roadmapNodeId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.roadmapNode;
    });
}

function updateRoadmapNodeByOrgIdAndRoadmapNodeIdCanMarkAsCompleted(
  orgId,
  roadmapNodeId,
  roadmapNode
) {
  const requestOptions = RequestHelper.getPutRequestOptions(roadmapNode);

  var api_url =
    config.api_roadmapNode_by_orgId_and_roadmapNodeId_canMarkAsCompleted;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    roadmapNodeId: roadmapNodeId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.roadmapNode;
    });
}

//update child node

function updateRoadmapChildNodeByOrgIdAndRoadmapNodeIdAndRoadmapChildNodeId(
  orgId,
  roadmapNodeId,
  roadmapChildNodeId,
  roadmapChildNode
) {
  const requestOptions = RequestHelper.getPutRequestOptions(roadmapChildNode);

  var api_url =
    config.api_roadmapNode_by_orgId_and_roadmapNodeId_and_roadmapChildNodeId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    roadmapNodeId: roadmapNodeId,
    roadmapChildNodeId: roadmapChildNodeId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.roadmapChildNode;
    });
}

// delete roadmap parent node; all child nodes will also be deleted

function deleteRoadmapNodeByOrgIdAndRoadmapNodeId(orgId, roadmapNodeId) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_roadmapNode_by_orgId_and_roadmapNodeId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    roadmapNodeId: roadmapNodeId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data._id;
    });
}

// delete roadmap child node

function deleteRoadmapChildNodeByOrgIdAndRoadmapNodeIdAndRoadmapChildNodeId(
  orgId,
  roadmapNodeId,
  roadmapChildNodeId
) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url =
    config.api_roadmapNode_by_orgId_and_roadmapNodeId_and_roadmapChildNodeId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    roadmapNodeId: roadmapNodeId,
    roadmapChildNodeId: roadmapChildNodeId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data._id;
    });
}
