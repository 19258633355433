/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const SchoolRoadmapService = {
  addSchoolRoadmapByOrgId,
  getSchoolRoadmapByOrgId,
  updateSchoolRoadmapById,
  deleteSchoolRoadmapById,
};

function addSchoolRoadmapByOrgId(orgId, schoolRoadmap) {
  const requestOptions = RequestHelper.getPostRequestOptions(schoolRoadmap);

  var api_url = config.api_schoolRoadmap_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.schoolRoadmap;
    });
}

function getSchoolRoadmapByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_schoolRoadmap_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return {
        schoolRoadmaps: data.schoolRoadmaps,
        page: data.page,
        hasMore: data.hasMore,
      };
    });
}

function updateSchoolRoadmapById(orgId, schoolRoadmapId, schoolRoadmap) {
  const requestOptions = RequestHelper.getPutRequestOptions(schoolRoadmap);

  var api_url = config.api_schoolRoadmap_by_orgId_and_schoolRoadmapId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    schoolRoadmapId: schoolRoadmapId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.schoolRoadmap;
    });
}

// delete school roadmap ; all child nodes will also be deleted

function deleteSchoolRoadmapById(orgId, schoolRoadmapId) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_schoolRoadmap_by_orgId_and_schoolRoadmapId;

  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    schoolRoadmapId: schoolRoadmapId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data._id;
    });
}
