import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import SearchUserAdvanced from "../../helperComponents/searchUserAdvanced";

import { Factory } from "../../../helpers/factories";

import { PortraitService } from "../../../services/portrait";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  input: {
    display: "none",
  },
}));

function AddEditPortraitsDialog({
  toastOptions,
  setToastOptions,
  handleCloseToast,
  opType,
  setOpType,
  portrait,
  setPortrait,
  selectedRowIndex,
  portraits,
  setPortraits,
  isAddEditPortraitDialogOpen,
  handleCloseAddEditPortraitDialog,
  ...props
}) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  //to hold form values,
  // we could use portrait prop directly but slows down render
  const [dialogForm, setDialogForm] = React.useState(Factory.createPortrait());

  const [portraitDocument, setPortraitDocument] = React.useState(null);
  const [isFilePicked, setIsFilePicked] = React.useState(false);
  const [fileName, setFileName] = React.useState("");

  //for search user component; must init otherwise get uncontrolled component error
  const [user, setUser] = React.useState({
    _id: "",
    role: "",
    firstName: "",
    lastName: "",
  });

  const [userQueryOptions, setUserQueryOptions] = React.useState({});

  //for year ddl
  const currentYear = moment().year();
  const yearArray = Array.from(
    Array(currentYear - 2018),
    (_, i) => currentYear - i
  );

  //for editing - loads current roadmap node selected
  useEffect(() => {
    setDialogForm(portrait);
    setUser(portrait.student);
  }, [portrait]);

  //check if enable save button
  function isFormValid() {
    return dialogForm.portraitYear && dialogForm.portraitGrade;
  }

  function handleInputChange(e) {
    setDialogForm({ ...dialogForm, [e.target.name]: e.target.value });
  }

  const handleSetPortraitDocument = (event) => {
    const size = (event.target.files[0].size / 1024 / 1024).toFixed(2);
    if (size > 2) {
      alert("File must not bigger than 2 MB");
      return;
    }
    setIsFilePicked(true);
    setFileName(event.target.files[0].name);
    setPortraitDocument(event.target.files[0]);
  };

  async function handleSavePortrait() {
    //upload form
    const formData = new FormData();
    formData.append("student", user._id);
    formData.append("portraitYear", dialogForm.portraitYear);
    formData.append("portraitGrade", dialogForm.portraitGrade);
    formData.append("organisation", currentUser.organisation.id);
    formData.append("file", portraitDocument);
    formData.append("createdBy", currentUser.id);

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      //check save or edit

      if (opType === "add") {
        const savedPortrait = await PortraitService.addPortrait(formData);
        let temp = [...portraits];
        temp.unshift(savedPortrait);
        setPortraits(temp);

        setIsFilePicked(false);
        setFileName(null);
        setPortraitDocument(null);

        handleCloseAddEditPortraitDialog();
      } else {
        const updatedPortrait = await PortraitService.updatePortraitById(
          portrait._id,
          formData
        );

        //update roadmap node for dispaly
        setPortrait({ ...updatedPortrait });

        //update roadmapnode in roadmapnodes
        let temp = [...portraits];
        temp[selectedRowIndex] = updatedPortrait;
        setPortraits(temp);

        setIsFilePicked(false);
        setFileName(null);
        setPortraitDocument(null);

        handleCloseAddEditPortraitDialog();
      }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Portrait Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  }

  return (
    <Fragment>
      <Dialog
        open={isAddEditPortraitDialogOpen}
        onClose={handleCloseAddEditPortraitDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {opType === "edit" ? "Edit Portrait" : "Add Portrait"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <SearchUserAdvanced
                user={user}
                setUser={setUser}
                userQueryOptions={userQueryOptions}
                setUserQueryOptions={setUserQueryOptions}
                style={{ display: "flex" }}
                isDisabled={opType === "edit" ? true : false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <InputLabel id="type-description">Portrait Year</InputLabel>
                <Select
                  labelId="portraitYear"
                  id="portraitYear"
                  name="portraitYear"
                  value={dialogForm.portraitYear}
                  onChange={handleInputChange}
                  label="Portrait Year"
                >
                  {yearArray.map((year) => (
                    <MenuItem value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <TextField
                  variant="outlined"
                  id="portraitGrade"
                  name="portraitGrade"
                  label="Portrait Grade"
                  type="text"
                  value={dialogForm.portraitGrade}
                  onChange={handleInputChange}
                  fullWidth
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <input
                accept=".pdf"
                onChange={(e) => {
                  handleSetPortraitDocument(e);
                }}
                onClick={(e) => {
                  setIsFilePicked(false);
                  setFileName("No file selected");
                  setPortraitDocument(null);
                  e.target.value = null;
                }}
                className={classes.input}
                id="contained-button-file"
                type="file"
                name="portraitDocument"
                enctype="multipart/form-data"
                val={portraitDocument}
              />
              <label htmlFor="contained-button-file">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                >
                  Upload Portrait (.pdf)
                </Button>
              </label>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <div style={{ fontStyle: "italic" }}>
                  If a document was previously uploaded, it will be replaced by
                  the newer one.
                </div>
                <div>File: {fileName}</div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddEditPortraitDialog} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={() => {
              handleSavePortrait(false);
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AddEditPortraitsDialog;
