/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const OrganisationService = {
  getOrganisations,
  addOrganisationWithSeedUser,
};

function getOrganisations(options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_organisation;
  api_url =
    RequestHelper.replaceParams(api_url, {}) +
    RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.organisations;
    });
}

function addOrganisationWithSeedUser(formData) {
  const requestOptions = RequestHelper.getPostRequestOptions(formData);

  var api_url = config.api_organisation;

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.announcement;
    });
}

// function updateAnnouncementByOrgIdAndAnnouncementId(
//   orgId,
//   announcementId,
//   announcement
// ) {
//   const requestOptions = RequestHelper.getPutRequestOptions(announcement);

//   var api_url = config.api_announcements_by_orgId_and_announcementId;
//   api_url = RequestHelper.replaceParams(api_url, {
//     orgId: orgId,
//     announcementId: announcementId,
//   });
//   //return Promise.reject(new Error());
//   return fetch(api_url, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       return data.announcement;
//     });
// }

// function deleteAnnouncementsByOrgIdAndAnnouncementId(orgId, announcementId) {
//   const requestOptions = RequestHelper.getDeleteRequestOptions();

//   var api_url = config.api_announcements_by_orgId_and_announcementId;
//   api_url = RequestHelper.replaceParams(api_url, {
//     orgId: orgId,
//     announcementId: announcementId,
//   });

//   //return Promise.reject(new Error());
//   return fetch(api_url, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       return data.announcements;
//     });
// }
