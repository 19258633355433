import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Factory } from "../../../helpers/factories";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { constants } from "../../../helpers/constants";
import { Button } from "@material-ui/core";
import Toast from "../../helperComponents/toast";

import { OrganisationService } from "../../../services/organisation";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  formControl: {
    margin: theme.spacing(1),
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    // padding: "0px 24px",
    // padding: "0px px",
    minWidth: 120,
    width: "100%",
  },
  formField: {
    width: "95%",
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
  },
}));

function AddEditOrganisation({ opType, ...props }) {
  const classes = useStyles();
  // const { currentUser } = useAppContext();

  let countries = constants.countries;

  //organisation data form
  const [dialogForm, setDialogForm] = React.useState(
    Factory.createOrganisation
  );

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 6000,
  });

  //check if enable save button
  function isFormValid() {
    return (
      dialogForm.name &&
      dialogForm.prefix &&
      dialogForm.yearJoined &&
      dialogForm.type &&
      dialogForm.contact &&
      dialogForm.contact.email &&
      dialogForm.user &&
      dialogForm.user.username &&
      dialogForm.user.password
    );
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  // useEffect

  //input change
  function handleInputChange(e) {
    switch (e.target.name) {
      case "name":
        setDialogForm({
          ...dialogForm,
          name: e.target.value,
        });
        break;
      case "type":
        setDialogForm({
          ...dialogForm,
          type: e.target.value,
        });
        break;
      case "yearJoined":
        setDialogForm({
          ...dialogForm,
          yearJoined: e.target.value,
        });
        break;
      case "prefix":
        setDialogForm({
          ...dialogForm,
          prefix: e.target.value,
        });
        break;
      case "picURL":
        setDialogForm({
          ...dialogForm,
          picURL: e.target.value,
        });
        break;

      case "addressLine1":
        setDialogForm({
          ...dialogForm,
          ["address"]: { ...dialogForm.address, line1: e.target.value },
        });
        break;
      case "addressSuburb":
        setDialogForm({
          ...dialogForm,
          ["address"]: { ...dialogForm.address, suburb: e.target.value },
        });
        break;
      case "addressCity":
        setDialogForm({
          ...dialogForm,
          ["address"]: { ...dialogForm.address, city: e.target.value },
        });
        break;
      case "addressState":
        setDialogForm({
          ...dialogForm,
          ["address"]: { ...dialogForm.address, state: e.target.value },
        });
        break;
      case "addressCountry":
        setDialogForm({
          ...dialogForm,
          ["address"]: { ...dialogForm.address, country: e.target.value },
        });
        break;
      case "contactName":
        setDialogForm({
          ...dialogForm,
          ["contact"]: {
            ...dialogForm.contact,
            name: e.target.value,
          },
        });
        break;
      case "contactEmail":
        setDialogForm({
          ...dialogForm,
          ["contact"]: {
            ...dialogForm.contact,
            email: e.target.value,
          },
        });
        break;
      case "contactPhone":
        setDialogForm({
          ...dialogForm,
          ["contact"]: {
            ...dialogForm.contact,
            phone: e.target.value,
          },
        });
        break;
      case "username":
        setDialogForm({
          ...dialogForm,
          ["user"]: {
            ...dialogForm.user,
            username: e.target.value,
          },
        });
        break;
      case "password":
        setDialogForm({
          ...dialogForm,
          ["user"]: {
            ...dialogForm.user,
            password: e.target.value,
          },
        });
        break;
      default:
        setDialogForm({ ...dialogForm, [e.target.name]: e.target.value });
    }
  }

  //save organisation
  const handleSaveOrganisation = async () => {
    // save form data, using extra object because setState on dialogForm doesn't update immediately
    let data = {
      organisation: {
        name: dialogForm.name,
        yearJoined: dialogForm.yearJoined,
        type: dialogForm.type,
        prefix: dialogForm.prefix,
        picURL: dialogForm.picURL,

        // organisation: dialogForm.organisation,
        address: {
          line1: dialogForm.address ? dialogForm.address.line1 : "N/A",
          suburb: dialogForm.address ? dialogForm.address.suburb : "N/A",
          city: dialogForm.address ? dialogForm.address.city : "N/A",
          state: dialogForm.address ? dialogForm.address.state : "N/A",
          country: dialogForm.address ? dialogForm.address.country : "N/A",
        },
        contact: {
          name: dialogForm.contact ? dialogForm.contact.name : "N/A",
          email: dialogForm.contact ? dialogForm.contact.email : "N/A",
          phone: dialogForm.contact ? dialogForm.contact.phone : "N/A",
        },
      },

      user: dialogForm.user
        ? {
            username: dialogForm.prefix + "_" + dialogForm.user.username,
            password: dialogForm.user.password,
            email: dialogForm.contact ? dialogForm.contact.email : "N/A",
          }
        : {},
    };
    //add password field if optype is add new user
    // if (opType == "add") {
    //   data["password"] = dialogForm.password;
    // } else {
    //   data["_id"] = user._id;
    // }
    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving...",
      });

      await OrganisationService.addOrganisationWithSeedUser(data);

      //check save or edit
      // if (opType == "add") {
      //   const savedOrganisation = await OrganisationService.addOrganisationWithSeedUser(
      //     data
      //   );
      //   setOpType("edit");
      //   setUser(savedUser);
      // } else {
      //   // const updatedUser = await UserService.updateUserByOrgIdAndUserId(
      //   //   currentUser.organisation.id,
      //   //   user._id,
      //   //   data
      //   // );
      //   // setUser(updatedUser);
      //   //edit user in update table if selected from table
      //   // let temp = [...announcements];
      //   // temp[selectedRowIndex] = updatedAnnouncement;
      //   // temp[selectedRowIndex] = updatedAnnouncement;
      //   // setAnnouncements(temp);
      // }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Organisation Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  };

  return (
    <Fragment>
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Add Organisation</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {/* Organisation Details */}
            <Grid container spacing={3} justiry="space-evenly">
              <Grid item xs={12}>
                <br />
                <Typography
                  className={classes.formFieldBlockTitle}
                  variant="subtitle2"
                >
                  Organisation Details
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Organisation Name"
                    type="text"
                    value={dialogForm ? dialogForm.name : ""}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    autoFocus
                    margin="dense"
                    id="prefix"
                    name="prefix"
                    label="Prefix"
                    type="text"
                    value={dialogForm ? dialogForm.prefix : ""}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl required className={classes.formControl}>
                  <InputLabel id="type-select-label">Type</InputLabel>
                  <Select
                    labelId="type-select-label"
                    name="type"
                    value={dialogForm.type}
                    onChange={handleInputChange}
                    className={classes.formField}
                  >
                    <MenuItem value={"School"}>School</MenuItem>
                    <MenuItem value={"Institution"}>Institution</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="yearJoined"
                    name="yearJoined"
                    label="Year Joined"
                    type="text"
                    value={dialogForm ? dialogForm.yearJoined : ""}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="picURL"
                    name="picURL"
                    label="School Logo URL"
                    type="text"
                    value={dialogForm ? dialogForm.picURL : ""}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </FormControl>
              </Grid>
            </Grid>
            {/* Contact */}
            <Grid container spacing={3} justiry="space-evenly">
              <Grid item xs={12}>
                <br />
                <Typography
                  className={classes.formFieldBlockTitle}
                  variant="subtitle2"
                >
                  Contact
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} justify="space-evenly">
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="contactName"
                    name="contactName"
                    label="Contact Name"
                    onChange={handleInputChange}
                    value={
                      dialogForm &&
                      dialogForm.contact &&
                      dialogForm.contact.name
                    }
                    className={classes.formField}
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    id="contactEmail"
                    name="contactEmail"
                    label="E-mail"
                    onChange={handleInputChange}
                    value={
                      dialogForm &&
                      dialogForm.contact &&
                      dialogForm.contact.email
                    }
                    size="small"
                    className={classes.formField}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="contactPhone"
                    name="contactPhone"
                    label="Contact Phone"
                    onChange={handleInputChange}
                    value={
                      dialogForm &&
                      dialogForm.contact &&
                      dialogForm.contact.phone
                    }
                    className={classes.formField}
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            {/* Address */}
            <Grid container spacing={3} justiry="space-evenly">
              <Grid item xs={12}>
                <br />
                <Typography
                  className={classes.formFieldBlockTitle}
                  variant="subtitle2"
                >
                  Address
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} justify="space-evenly">
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="line1"
                    name="addressLine1"
                    label="Line1"
                    onChange={handleInputChange}
                    value={
                      dialogForm &&
                      dialogForm.address &&
                      dialogForm.address.line1
                    }
                    className={classes.formField}
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="suburb"
                    name="addressSuburb"
                    label="Suburb / Village"
                    onChange={handleInputChange}
                    value={
                      dialogForm &&
                      dialogForm.address &&
                      dialogForm.address.suburb
                    }
                    size="small"
                    className={classes.formField}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="city"
                    name="addressCity"
                    label="Town / City / Gewog"
                    size="small"
                    onChange={handleInputChange}
                    value={
                      dialogForm &&
                      dialogForm.address &&
                      dialogForm.address.city
                    }
                    className={classes.formField}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="state"
                    name="addressState"
                    label="State / Dzongkhag"
                    size="small"
                    onChange={handleInputChange}
                    value={
                      dialogForm &&
                      dialogForm.address &&
                      dialogForm.address.state
                    }
                    className={classes.formField}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} justify="space-evenly">
              <Grid item xs={12} sm={6}>
                <FormControl required className={classes.formControl}>
                  <InputLabel id="country-select-label">Country</InputLabel>
                  <Select
                    labelId="country-select-label"
                    name="addressCountry"
                    value={
                      dialogForm &&
                      dialogForm.address &&
                      dialogForm.address.country
                    }
                    onChange={handleInputChange}
                    className={classes.formField}
                  >
                    {countries.map((country) => (
                      <MenuItem value={country.name}>{country.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
            {/* Seed Admin */}
            <Grid container spacing={3} justiry="space-evenly">
              <Grid item xs={12}>
                <br />
                <Typography
                  className={classes.formFieldBlockTitle}
                  variant="subtitle2"
                >
                  Seed Admin
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} justiry="space-evenly">
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    id="username"
                    name="username"
                    label="User Name"
                    size="small"
                    onChange={handleInputChange}
                    value={dialogForm && dialogForm.username}
                    className={classes.formField}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    id="password"
                    name="password"
                    label="Password"
                    size="small"
                    type="password"
                    autoComplete="new-password"
                    onChange={handleInputChange}
                    value={dialogForm && dialogForm.password}
                    className={classes.formField}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} justiry="space-evenly">
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  disabled={!isFormValid()}
                  size="large"
                  onClick={handleSaveOrganisation}
                  color="primary"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default AddEditOrganisation;
