import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import SearchTag from "../../helperComponents/searchTags";
import SelectDomainArea from "../../helperComponents/selectDomainArea";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment";

import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { LessonPlansService } from "../../../services/lessonPlans";
import { DomainRoadmapService } from "../../../services/domainRoadmap";

import { Factory } from "../../../helpers/factories";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterElements: {
    marginTop: "8px",
    marginBottom: "8px",
  },
}));

function AddEditLessonPlanDialogInTimetable({
  lessonPlan,
  setLessonPlan,
  // isNewLessonPlan,
  // cellEventData,
  lessonPlans,
  setLessonPlans,
  selectedLessonPlanIndex,
  // selectedRowIndex,
  opType,
  setOpType,
  isAddEditLessonPlanDialogOpen,
  handleCloseAddEditLessonPlanDialog,
  // setHasNewLessonPlanInLE,
  toastOptions,
  setToastOptions,
  handleCloseToast,
  ...props
}) {
  const { currentUser } = useAppContext();

  const classes = useStyles();

  //temp lessonPlan state to prevent lag
  const [tempLessonPlan, setTempLessonPlan] = React.useState(lessonPlan);

  //for search domain component
  const [domainArea, setDomainArea] = React.useState(null);

  //for search tag component
  const [tags, setTags] = React.useState([]);

  const [skills, setSkills] = React.useState([]);

  const [processes, setProcesses] = React.useState([]);
  const [watermarks, setWatermarks] = React.useState([]);
  const [schoolAttributes, setSchoolAttributes] = React.useState([]);

  //load lesson plan if edit
  useEffect(() => {
    if (opType === "edit") {
      setTempLessonPlan({
        ...lessonPlan,
        domainArea: null,
        teacher: null,
      });
      setDomainArea(lessonPlan.domainArea);
      setTags(lessonPlan.tags);
      setSkills(lessonPlan.skills);
      setProcesses(lessonPlan.processes);
      setWatermarks(lessonPlan.watermarks);
      setSchoolAttributes(lessonPlan.schoolAttributes);
      // setUser(lessonPlan.teacher);
    } else {
      setTempLessonPlan(Factory.createLessonPlan());
      setDomainArea(null);
      setTags([]);
      setSkills([]);
      setProcesses([]);
      setWatermarks([]);
      setSchoolAttributes([]);

      // setUser(null);
    }
  }, [opType]);

  //get spw list for learning cycle, year, grade and domain if learning cycle changes
  useEffect(() => {
    //get list of spw
    if (tempLessonPlan.learningCycle && tempLessonPlan.grade && domainArea) {
      getSPWList();
    }
  }, [tempLessonPlan.learningCycle, tempLessonPlan.grade, domainArea]);

  function isFormValid() {
    return (
      domainArea &&
      domainArea._id &&
      tags.length > 0 &&
      skills.length > 0 &&
      processes.length > 0 &&
      watermarks.length > 0 &&
      schoolAttributes.length > 0 &&
      tempLessonPlan.title &&
      tempLessonPlan.overview &&
      tempLessonPlan.details &&
      tempLessonPlan.grade &&
      tempLessonPlan.learningCycle
    );
  }

  function handleInputChange(e) {
    let temp = { ...tempLessonPlan };
    temp[e.target.name] = e.target.value;
    setTempLessonPlan(temp);
  }

  function handleAttributesChange(e) {
    setSchoolAttributes(e.target.value);
  }

  const getSPWList = async () => {
    try {
      //filter
      let filterString = {
        year: moment().year(),
        grade: tempLessonPlan.grade,
        domainArea: domainArea && domainArea._id,
        learningCycle: tempLessonPlan.learningCycle,
      };
      //get data
      let data =
        await DomainRoadmapService.getSPWDomainRoadmapByOrgIdLearningCycleYearGradeDomain(
          currentUser.organisation.id,
          filterString
        );
      //auto-populate spw fields accoding to learning cycle
      if (data && data.data.length > 0) {
        let skillsList = data.data[0].skills.map((item) => item.skills).flat(1);
        skillsList = [...new Set(skillsList)];
        setSkills(skillsList);

        let pList = data.data[0].processes
          .map((item) => item.processes)
          .flat(1);
        pList = [...new Set(pList)];
        setProcesses(pList);

        let wList = data.data[0].watermarks
          .map((item) => item.watermarks)
          .flat(1);
        wList = [...new Set(wList)];
        setWatermarks(wList);
      } else if (data.data.length == 0) {
        setSkills([]);
        setWatermarks([]);
        setProcesses([]);
      }
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error downloading SPW from domain roadmap",
      });
    }
  };

  async function handleSaveLessonPlan() {
    // saving message
    setToastOptions({
      ...toastOptions,
      open: true,
      message: "Saving",
    });

    try {
      //prepare data
      var data = {
        organisation: currentUser.organisation.id,
        title: tempLessonPlan.title.trim(),
        overview: tempLessonPlan.overview.replace(/↵/g, "\n").trim(),
        details: tempLessonPlan.details.replace(/↵/g, "\n").trim(),
        tags: tags,
        linksToResources: tempLessonPlan.linksToResources
          ? tempLessonPlan.linksToResources.replace(/↵/g, "\n")
          : "",
        domainArea: domainArea ? domainArea._id : tempLessonPlan.domainArea._id,
        grade: tempLessonPlan.grade,
        teacher: currentUser.id,
        skills: skills,
        processes: processes,
        watermarks: watermarks,
        schoolAttributes: schoolAttributes,
        learningCycle: tempLessonPlan.learningCycle,
      };

      //check whether new lesson plan added or lesson plan being updated

      if (opType === "add") {
        const savedLessonPlan = await LessonPlansService.addLessonPlansByOrgId(
          currentUser.organisation.id,
          data
        );
        setLessonPlan({ ...lessonPlan, _id: savedLessonPlan._id });

        //update lessons
        let temp = [...lessonPlans];
        temp.unshift(savedLessonPlan);
        setLessonPlans(temp);
      } else {
        //update lessonPlan
        const updatedLessonPlan =
          await LessonPlansService.updateLessonPlansByOrgIdAndLessonPlanId(
            currentUser.organisation.id,
            lessonPlan._id,
            data
          );
        //update lesson s
        let temp = [...lessonPlans];
        temp[selectedLessonPlanIndex] = updatedLessonPlan;
        setLessonPlans(temp);
      }

      //close dialog
      setDomainArea(null);
      setTempLessonPlan(Factory.createLessonPlan());
      setTags([]);
      setSkills([]);
      setProcesses([]);
      setWatermarks([]);
      setSchoolAttributes([]);

      handleCloseAddEditLessonPlanDialog();

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Lesson Plan Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error saving lesson plan",
      });
    }
  }

  //const { title } = dialogPropsMain;
  // setDialogProps(row);
  return (
    <Fragment>
      <Dialog
        open={isAddEditLessonPlanDialogOpen}
        onClose={handleCloseAddEditLessonPlanDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {opType === "edit" ? "Edit Lesson Plan" : "Add Lesson Plan"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <SelectDomainArea
                domainArea={domainArea}
                setDomainArea={setDomainArea}
                showAllOption={false}
                orgId={currentUser.organisation.id}
                style={{ display: "flex" }}
                type={""}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <InputLabel id="type-description">Grade</InputLabel>
                <Select
                  labelId="grade"
                  id="grade"
                  name="grade"
                  value={tempLessonPlan.grade}
                  onChange={handleInputChange}
                  label="Grade"
                >
                  <MenuItem value={"0"}>PP</MenuItem>
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                  <MenuItem value={"3"}>3</MenuItem>
                  <MenuItem value={"4"}>4</MenuItem>
                  <MenuItem value={"5"}>5</MenuItem>
                  <MenuItem value={"6"}>6</MenuItem>
                  <MenuItem value={"7"}>7</MenuItem>
                  <MenuItem value={"8"}>8</MenuItem>
                  <MenuItem value={"9"}>9</MenuItem>
                  <MenuItem value={"10"}>10</MenuItem>
                  <MenuItem value={"11"}>11</MenuItem>
                  <MenuItem value={"12"}>12</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <TextField
                  id="learningCycle"
                  name="learningCycle"
                  label="Learning Cycle"
                  type="text"
                  value={tempLessonPlan.learningCycle}
                  placeholder="Eg. 1"
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Accordion
                style={{
                  background: "transparent",
                  marginLeft: "16px",
                  marginRight: "16px",
                }}
              >
                <AccordionSummary
                  expandIcon={<InfoIcon color={"primary"} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ background: "#eaeff1" }}
                >
                  <Typography variant="body2">
                    List of Skills, Processes, etc.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ background: "#eaeff1" }}>
                  <p>
                    <Typography variant="body2">
                      This is not an exhaustive list so please feel free to add
                      your own if it's missing from the list. Also, some terms
                      apply across categories.
                    </Typography>
                    <br />
                    <Typography variant="body2">Skills</Typography>
                    <Typography variant="caption">
                      analysis, articulation, assessment, coding, communication,
                      comprehension, creativity, decision making, discipline,
                      exploration, handwriting, inquiry, logical thinking,
                      metacognition, navigation, problem solving, team work,
                      time management,
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">Processes</Typography>
                    <Typography variant="caption">
                      assessment, coding, communication, community engagement,
                      comprehension, creativity, design, exploration,
                      handwriting, inquiry, metacognition, outdoor activities,
                      peer mentoring, problem solving, reflectiond
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">Watermarks</Typography>
                    <Typography variant="caption">
                      articulation, awareness, choice, compassion, creativity,
                      discipline, leadership, resilience, responsibility,
                      rigour, time management
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">Concept</Typography>
                    <Typography variant="caption">
                      accuracy, change, construction, design, diversity,
                      environment, global awareness, heritage and culture,
                      nature, perspective, symmetry, patterns, space, time,
                      transformation
                    </Typography>
                    <br /> <br />
                    <Typography variant="body2">
                      Actualizing Potential
                    </Typography>
                    <Typography variant="caption">
                      citizenship, community learning, leadership, mentorship,
                    </Typography>
                  </p>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <SearchTag tags={skills} setTags={setSkills} label={"Skills"} />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <SearchTag
                  tags={processes}
                  setTags={setProcesses}
                  label={"Processes"}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <SearchTag
                  tags={watermarks}
                  setTags={setWatermarks}
                  label={"Watermarks"}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "90%" }}
              >
                <InputLabel id="type-description">School Attributes</InputLabel>
                <Select
                  labelId="schoolAttributes"
                  id="schoolAttributes"
                  name="schoolAttributes"
                  value={schoolAttributes}
                  // value={tempLessonPlan.schoolAttributes}
                  onChange={handleAttributesChange}
                  label="School Attributes"
                  multiple
                >
                  <MenuItem value={"individualism"}>Individualism</MenuItem>
                  <MenuItem value={"mastery"}>Mastery</MenuItem>
                  <MenuItem value={"purpose"}>Purpose</MenuItem>
                  <MenuItem value={"leadership"}>Leadership</MenuItem>
                  <MenuItem value={"teachers as learners"}>
                    Teachers as Learners
                  </MenuItem>
                  <MenuItem value={"students as teachers"}>
                    Students as Teachers
                  </MenuItem>
                  <MenuItem value={"community"}>Community</MenuItem>
                  <MenuItem value={"risk-tasking"}>Risk-Tasking</MenuItem>
                  <MenuItem value={"joy"}>Joy</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ margin: "16px" }}>
              <TextField
                margin="dense"
                id="title"
                name="title"
                label="Title"
                type="text"
                value={tempLessonPlan.title}
                placeholder="Eg. Plastic-free is not easy"
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "16px" }}>
              <SearchTag
                tags={tags}
                setTags={setTags}
                label={"Content Tags - Eg. calculus, grammar"}
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "16px" }}>
              <TextField
                multiline
                margin="dense"
                id="overview"
                name="overview"
                label="Overview"
                type="text"
                rows={4}
                value={tempLessonPlan.overview}
                onChange={handleInputChange}
                variant="outlined"
                placeholder="Eg. This lesson raises awareness of how much unnecessary plastic is used in packaging and asks students to look at alternatives."
                fullWidth
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "16px" }}>
              <TextField
                multiline
                margin="dense"
                id="details"
                name="details"
                label="Details"
                type="text"
                rows={6}
                placeholder="Eg. This lesson is based on photographs taken in a local supermarket during a recent campaign which challenged consumers to shop without buying anything in plastic packaging during a week. The photos were shared on social media with the hashtag accompanying the campaign to be used as a springboard for discussion and project work with students. As well as offering a great context for reviewing the language of food, it also offers a chance to expand on this topic and look at the language used to talk about issues surrounding plastic waste."
                value={tempLessonPlan.details}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "16px" }}>
              <TextField
                multiline
                margin="dense"
                id="linksToResources"
                name="linksToResources"
                label="Links To Resources"
                placeholder="The world's plastic pollution crisis explained | https://www.nationalgeographic.com/environment/article/plastic-pollution"
                type="text"
                rows={4}
                value={tempLessonPlan.linksToResources}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
              />
              <Typography variant="caption" display="block" gutterBottom>
                Resources should have a description, followed by a | and then
                the URL of the resource. Multiple resources can be entered in
                new lines.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDomainArea(null);
              setTempLessonPlan(Factory.createLessonPlan());
              setTags([]);
              setSkills([]);
              setProcesses([]);
              setWatermarks([]);
              setSchoolAttributes([]);
              handleCloseAddEditLessonPlanDialog();
            }}
            color="primary"
          >
            Close
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={handleSaveLessonPlan}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AddEditLessonPlanDialogInTimetable;
