import React from "react";
//import Announcement from "./pages/announcement";
import RouteAuthHOC from "../helperComponents/routeAuthHOC";
//import Wangbarma from "./tempSchool/wangbarma";
import { useAppContext } from "../../libs/contextLib";
//import Dechencholing from "./tempSchool/dechencholing";
import TransitionHome from "./tempSchool/transitionHome";
import AssessmentHome from "./tempSchool/assessmentHome";
import Landing from "./landing/landing";

function Dashboard() {
  const { currentUser } = useAppContext();
  let Comp = "";
  //tra and bb org
  if (
    currentUser.organisation.id === "5fdc48c1097684456369ec97" ||
    currentUser.organisation.id === "5feffd68de4fa5340fb1aee1"
  ) {
    //Comp = RouteAuthHOC(TransitionHome);
    Comp = RouteAuthHOC(Landing);
  } else if (currentUser.organisation.id === "60dc38546620d09b81dc3013") {
    Comp = RouteAuthHOC(TransitionHome);
  } else if (currentUser.organisation.id === "60e3d30935fc4e262ab960fb") {
    Comp = RouteAuthHOC(AssessmentHome);
  } else {
    Comp = RouteAuthHOC(Landing);
  }

  return <Comp />;
}

export default Dashboard;
