/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const OpenAIService = {
  getSummary,
};

function getSummary(orgId, options = {}) {
  const requestOptions = RequestHelper.getPostRequestOptions(options);

  var api_url = config.api_openai_summary;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
