import React from "react";

import { useAppContext } from "../../../libs/contextLib";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { AppraisalService } from "../../../services/appraisal";
import generatePDF from "./generatePDF";

import { Factory } from "../../../helpers/factories";

import FormControl from "@material-ui/core/FormControl";

import { Utils } from "../../../helpers/utils";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
import Tooltip from "@material-ui/core/Tooltip";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";

import Toast from "../../helperComponents/toast";
import Slide from "@material-ui/core/Slide";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  formImage: {
    margin: "16px",
    padding: "20px",
    height: "200px",
    objectFit: "contain",
    border: "1px solid #eaeff2",
  },
  formReports: {
    marginBottom: "16px",
  },
  paper: {
    padding: "20px",
    marginBottom: "40px",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  ["@media print"]: {
    boxShadow: "none",
    noPrint: {
      display: "none",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/*
 * Head of School Appraisal Dialog - he or she is able to view collated appraisals
 */

export default function AppraisalDialogHoS({
  activeAppraisal,
  setActiveAppraisal,
  isDialogOpenHoS,
  handleCloseDialogHoS,
  opType,
  setOpType,
}) {
  const classes = useStyles();

  const { currentUser } = useAppContext();

  //query string
  const [queryString, setQueryString] = React.useState({
    user: activeAppraisal.user || "",
    learningCycle: activeAppraisal.learningCycle,
    year: activeAppraisal.year,
  });

  //appraisal state - there is an activeAppraisal state but this one is to deal with rendering issues
  const [appraisal, setAppraisal] = React.useState(Factory.createAppraisal());

  const [appraisals, setAppraisals] = React.useState(null);

  //data is loading
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  //student
  const [user, setUser] = React.useState(null);

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 2000,
  });

  /*
   * Helpers
   */

  //usestate for updatind mentee id in appraisal
  // React.useEffect(() => {
  //   if (user != null) {
  //     //add mentee,mentor, appraisal type, appraisal year to appraisal state
  //     setAppraisal({
  //       ...appraisal,
  //       createdBy: user,
  //     });
  //   }
  // }, [user]);

  React.useEffect(() => {
    if (activeAppraisal.user) {
      setQueryString({
        user: activeAppraisal.user._id,
        learningCycle: activeAppraisal.learningCycle,
        year: activeAppraisal.year,
      });
    }

    // if (opType === "edit") {
    //   setAppraisal(activeAppraisal);
    //   setUser(activeAppraisal.user);
    // } else if (opType === "add") {
    //   setAppraisal(Factory.createAppraisal);
    // }
  }, [activeAppraisal]);

  React.useEffect(() => {
    onLoad();
  }, [queryString]);

  async function onLoad() {
    try {
      let temp = await AppraisalService.getCollatedAppraisalsByOrgId(
        currentUser.organisation.id,
        queryString
      );
      temp = sortAppraisals(temp);
      setAppraisals(temp);

      setIsDataLoading(false);
    } catch (err) {
      setErrorLoadingPage(true);
    }
  }

  /*
   * Helper
   */

  //sort appraisal so self is first, followed by area, domains, and others in alphabetical order
  function sortAppraisals(listApps) {
    let self = {};
    let domains = {};
    let areas = {};
    let rest = {};
    let temp = {};
    listApps.forEach((app) => {
      switch (app.type) {
        case "self":
          self = app;
          break;
        case "domain":
          domains[app.domainArea.name] = app;
          break;
        case "area":
          areas[app.domainArea.name] = app;
          break;
        default:
          rest[app.type] = app;
      }
    });
    //sort areas and domains
    domains = Utils.sortObject(domains);
    areas = Utils.sortObject(areas);
    rest = Utils.sortObject(rest);
    //store sorted in temp
    temp["self"] = self;
    temp["domains"] = domains;
    temp["areas"] = areas;
    temp["rest"] = rest;

    //
    return temp;
  }

  //form is valid for saving

  /*
   * Handlers
   */

  /*
   * Add/Update Report
   */

  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  // print page
  const printDoc = () => {
    window.print();
  };

  //return component

  return (
    <Dialog
      fullScreen
      open={isDialogOpenHoS}
      onClose={handleCloseDialogHoS}
      TransitionComponent={Transition}
    >
      {/* toast for messages */}
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />

      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialogHoS}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Review Appraisal
          </Typography>
        </Toolbar>
      </AppBar>

      {/* student/appraisal details */}
      <div
        style={{
          height: "100vh",
          backgroundColor: "#eaeff1",
        }}
      >
        <Grid
          container
          spacing={3}
          style={{
            justifyContent: "center",
            backgroundColor: "#eaeff1",
          }}
        >
          <Grid item xs={8} sm={10}>
            <Paper
              className={classes.paper}
              style={{
                marginTop: "40px",
                paddingTop: "40px",
                paddingBottom: "60px",
                minHeight: "400px",
              }}
            >
              <div className={"printableDiv"}>
                <Grid
                  container
                  spacing={3}
                  style={{ justifyContent: "center" }}
                >
                  <Grid item xs={10} style={{ display: "flex" }}>
                    <div>
                      <Typography variant="h6" gutterBottom>
                        {(activeAppraisal &&
                          activeAppraisal.user &&
                          activeAppraisal.user.firstName +
                            " " +
                            activeAppraisal.user.lastName) ||
                          ""}
                      </Typography>
                    </div>
                    <div className={classes.noPrint}>
                      {/* <Tooltip title="Download"> */}
                      <IconButton
                        aria-label="download"
                        color="primary"
                        onClick={printDoc}
                        // onClick={() => {
                        //   generatePDF(
                        //     activeAppraisal.user.firstName +
                        //       " " +
                        //       activeAppraisal.user.lastName,
                        //     activeAppraisal.learningCycle,
                        //     activeAppraisal.year,
                        //     appraisals
                        //   );
                        // }}
                      >
                        <PrintIcon />
                      </IconButton>
                      {/* </Tooltip> */}
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  style={{ justifyContent: "center" }}
                >
                  {/* self-appraisal */}
                  {appraisals && appraisals.self && appraisals.self.comment ? (
                    <Grid item xs={10}>
                      <Typography variant="subtitle2" gutterBottom>
                        Self-Appraisal
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {appraisals &&
                          appraisals.self &&
                          appraisals.self.comment}
                      </Typography>
                    </Grid>
                  ) : null}
                  {/* areas-appraisal */}
                  {appraisals && appraisals.areas
                    ? Object.values(appraisals.areas).map((app) => {
                        return (
                          <Grid item xs={10}>
                            <Typography variant="subtitle2" gutterBottom>
                              {app.domainArea.displayName +
                                " (" +
                                app.createdBy.firstName +
                                " " +
                                app.createdBy.lastName +
                                ")"}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {app.comment}
                            </Typography>
                          </Grid>
                        );
                      })
                    : null}
                  {/* domain-appraisal */}
                  {appraisals && appraisals.domains
                    ? Object.values(appraisals.domains).map((app) => {
                        return (
                          <Grid item xs={10}>
                            <Typography variant="subtitle2" gutterBottom>
                              {app.domainArea.displayName +
                                " (" +
                                app.createdBy.firstName +
                                " " +
                                app.createdBy.lastName +
                                ")"}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {app.comment}
                            </Typography>
                          </Grid>
                        );
                      })
                    : null}
                  {/* rest-appraisal */}
                  {appraisals && appraisals.rest
                    ? Object.values(appraisals.rest).map((app) => {
                        return (
                          <Grid item xs={10}>
                            <Typography variant="subtitle2" gutterBottom>
                              {app.type[0].toUpperCase() +
                                app.type.substr(1) +
                                " (" +
                                app.createdBy.firstName +
                                " " +
                                app.createdBy.lastName +
                                ")"}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {app.comment}
                            </Typography>
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
              </div>
            </Paper>

            {/* save close */}

            <Grid container spacing={3} className={classes.noPrint}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "40px",
                }}
              >
                <div style={{ padding: "10px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleCloseDialogHoS}
                  >
                    Close
                  </Button>
                </div>
              </Grid>
            </Grid>

            {/* end save close */}
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}
