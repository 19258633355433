import React, { useEffect } from "react";
import SearchUser from "../../helperComponents/searchUser";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  filterElements: {
    margin: theme.spacing(2),
    width: "90%",
  },
}));

export default function AbsenteesFilter({
  user,
  setUser,
  role,
  opType,
  setOpType,
  setIsDialogOpen,
  queryString,
  setQueryString,
  ...props
}) {
  const classes = useStyles();

  //dates
  const [selectedFromDate, setSelectedFromDate] = React.useState(
    moment().subtract(30, "days")
  );
  const [selectedToDate, setSelectedToDate] = React.useState(moment());

  //query string
  useEffect(() => {
    //open dialog if user selected
    setQueryString({
      ...queryString,
      student: user._id ? user._id : "",
    });
  }, [user]);

  //handle From date change
  const handleFromDateChange = (date) => {
    if (date === "Invalid Date" || date === null) {
    } else {
      setSelectedFromDate(date);
      setQueryString({
        ...queryString,
        from: moment(date).startOf("day"),
      });
    }
  };

  //handle To date change
  const handleToDateChange = (date) => {
    if (date === "Invalid Date" || date === null) {
    } else {
      setSelectedToDate(date);
      setQueryString({
        ...queryString,
        to: moment(date).endOf("day"),
      });
    }
  };

  //return comp
  return (
    <Grid container justify="space-between" spacing={3}>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SearchUser
              user={user}
              setUser={setUser}
              role={"student"}
              style={{ display: "flex" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={3} justify="space-between">
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    className={classes.filterElements}
                    margin="normal"
                    id="date-picker-dialog-from"
                    label="From"
                    format="dd/MM/yyyy"
                    value={selectedFromDate}
                    onChange={handleFromDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    margin="normal"
                    className={classes.filterElements}
                    id="date-picker-dialog-t0"
                    label="To"
                    format="dd/MM/yyyy"
                    value={selectedToDate}
                    onChange={handleToDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
