import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";
export const HelperService = {
  getPublicGoogleDriveImage,
  fetchGdriveImage,
};

function getPublicGoogleDriveImage(imageURL) {
  const requestOptions = RequestHelper.getPostRequestOptions({
    imageURL: imageURL,
  });

  var api_url = config.api_helper_download_gdrive_image;
  return fetch(api_url, requestOptions)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      return url;
    })
    .catch((err) => {
      // console.log(err);
      // return "./src/assets/images/placeHolderImage.png";
      return Promise.reject(new Error("Error downloading image"));
    });
}

async function fetchGdriveImage(imageURL) {
  const init = async () => {
    await window.gapi.client.init({
      apiKey: config.dgi_google_drive_api_key,
    });
    return 1;
  };

  const getFile = async () => {
    const fileId = imageURL.slice(imageURL.indexOf("id=") + 3, imageURL.length);

    const response = await window.gapi.client.request({
      path:
        "https://www.googleapis.com/drive/v2/files/" + fileId + "?alt=media",
    });

    const charArray = new Array(response.body.length);
    for (let i = 0; i < response.body.length; i++) {
      charArray[i] = response.body.charCodeAt(i);
    }

    const typedArray = new Uint8Array(charArray);

    const blob = new Blob([typedArray], {
      type: response.headers["Content-Type"],
    });

    const url = URL.createObjectURL(blob);
    return url;
  };

  let result = "";
  try {
    await new Promise((resolve, reject) => {
      window.gapi.load("client", resolve);
    });
    await init();
    await new Promise((resolve, reject) => {
      let url = getFile();
      resolve(url);
    }).then((url) => {
      result = url;
    });
  } catch (error) {
    throw Error(`Error initializing gapi client: ${error}`);
  }
  return result;
}
