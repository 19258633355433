import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppContext } from "../../../libs/contextLib";

import { DataGrid } from "@material-ui/data-grid";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import { makeStyles } from "@material-ui/core/styles";
import AssessmentSummariesFilter from "./assessmentSummariesFilter";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Button } from "@material-ui/core";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import Chip from "@material-ui/core/Chip";
import LabelIcon from "@material-ui/icons/Label";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Fab from "@material-ui/core/Fab";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ListIcon from "@material-ui/icons/List";
import Paper from "@material-ui/core/Paper";
import AlertDialog from "../../helperComponents/alertDialog";

import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import AssessmentSummariesDialog from "./assessmentSummariesDialog";
import { Factory } from "../../../helpers/factories";
import { AssessmentSummaryService } from "../../../services/assessmentSummary";

import moment from "moment";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { Fragment } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import Toast from "../../helperComponents/toast";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  //   avatar: {
  //     backgroundColor: "#009be5",
  //   },
  contentWrapper: {
    margin: "40px 16px",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
  accordionFilter: {
    marginBottom: "5px",
    border: 0,
    // borderRadius: "8px",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  cardRoot: {
    minWidth: 190,
  },

  cardTitle: {
    fontSize: 14,
    fontWeight: "bold",
  },
  cardPos: {
    marginBottom: 12,
  },
  progress: {
    width: "100%",
    backgroundColor: "#e9ecef",
    borderRadius: ".25rem",
    height: "0.6rem",
    lineHeight: 0,
    fontSize: ".75rem",
    overflow: "hidden",
    marginBottom: "16px",
  },
  progressBarSelf: {
    height: "100%",
    // backgroundColor: "#007bff",
    backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  progressBarOther: {
    height: "100%",
    backgroundColor: "#5bc0de",
    // backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  fab: {
    margin: "0px",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
  },
  fabItem: {
    margin: "4px",
  },
  listCard: {
    boxShadow: "none",
    background: "transparent",
  },
  dataGrid: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  roundedBadge: {
    borderRadius: "4px",
    backgroundColor: "#89b17c",
    color: "white",
    display: "inline-block",
    padding: "4px 8px",
    minHeight: "26px",
    maxHeight: "200px",
    textAlign: "left",
    fontSize: "13px",
  },
}));

function AssessmentSummaries({ ...props }) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  const location = useLocation();
  //   const history = useHistory();

  const [showLoader, setShowLoader] = React.useState(true);

  const [user, setUser] = React.useState(null);

  const [assessmentSummary, setAssessmentSummary] = React.useState(
    Factory.createAssessmentSummary()
  );

  const [selectedAssessmentSummaryIndex, setSelectedAssessmentSummaryIndex] =
    React.useState("");

  const [assessmentSummaries, setAssessmentSummaries] = React.useState([]);

  //data is loading
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  //for getting more lessons
  const [page, setPage] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(false);

  //query string
  const [queryString, setQueryString] = React.useState({});

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 2000,
  });

  const [opType, setOpType] = React.useState("add");

  const [
    isAddEditAssessmentSummaryDialogOpen,
    setIsAddEditAssessmentSummaryDialogOpen,
  ] = React.useState(false);

  //alert dialog
  const [alertDialogOptions, setAlertDialogOptions] = React.useState({
    open: false,
    title: "",
    message: "Delete this roadmap?",
    cancelMessage: "Cancel",
    confirmMessage: "OK",
  });

  //close dialog
  function handleCloseAddEditAssessmentSummaryDialog() {
    setOpType("");
    setIsAddEditAssessmentSummaryDialogOpen(false);
  }

  //delete roadmap
  function handleOpenAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: true });
  }

  function handleCloseAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
  }

  function handleConfirmDeleteLessonPlan() {
    handleOpenAlertDialog();
  }

  async function handleProceedDeleteAssessmentSummary() {
    // setAlertDialogOptions({ ...alertDialogOptions, open: false });
    // let temp = [...assessmentSummaries];
    // temp.splice(selectedAssessmentSummaryIndex, 1);
    // try {
    //   await AssessmentSummaryService.deleteAssessmentSummaryById(
    //     currentUser.organisation.id,
    //     assessmentSummary._id
    //   );
    //   setAssessmentSummaries(temp);
    //   setToastOptions({
    //     ...toastOptions,
    //     open: true,
    //     message: "Assessment Summary Deleted",
    //   });
    // } catch (err) {
    //   setToastOptions({
    //     ...toastOptions,
    //     open: true,
    //     message: err.message,
    //   });
    // }
  }

  // function handleConfirmDeleteAppraisal() {
  //   handleOpenAlertDialog();
  // }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  /*
  fetch assessmentSummary nodes, pre-load verify token
  */

  useEffect(() => {
    onLoad();
    // }
  }, [queryString]);

  async function onLoad() {
    try {
      let data = await AssessmentSummaryService.getAssessmentSummariesByOrgId(
        currentUser.organisation.id,
        queryString
      );
      setAssessmentSummaries(data.assessmentSummaries);
      setHasMore(data.hasMore);
      setPage(data.page);
      setIsDataLoading(false);
    } catch (err) {
      setErrorLoadingPage(true);
    }
    //hide loader
    setShowLoader(false);
  }

  // handle load more roadmaps
  const handleLoadMoreAssessmentSummaries = async () => {
    try {
      //update query page
      queryString.page = page;
      //get data
      let data = await AssessmentSummaryService.getAssessmentSummariesByOrgId(
        currentUser.organisation.id,
        queryString
      );
      //update lessons in grid
      let temp = [...assessmentSummaries];
      temp = temp.concat(data.assessmentSummaries);
      setAssessmentSummaries(temp);
      setHasMore(data.hasMore);
      setPage(data.page);
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error downloading assessment summaries",
      });
    }
  };

  //grid view for lessons
  const gridView = () => {
    return (
      <Grid
        container
        spacing={3}
        style={{ marginTop: "32px", padding: "24px" }}
      >
        {assessmentSummaries.map((assessmentSummary, index) => {
          return (
            <Grid
              item
              xs={12}
              sm={12}
              key={"assessmentSummary" + index}
              style={{
                borderRadius: "10px",
                backgroundColor: "#fff",
                marginBottom: "32px",
                padding: "32px",
              }}
            >
              <div>
                <div className={classes.roundedBadge}>
                  {assessmentSummary.student.firstName +
                    " " +
                    assessmentSummary.student.lastName}
                  {", "}
                  {assessmentSummary.domainArea.displayName}
                </div>
                <div style={{ float: "right" }}>
                  Created By:{" "}
                  {assessmentSummary.teacher.firstName +
                    " " +
                    assessmentSummary.teacher.lastName}
                  <br />
                  Date Created:
                  {moment(assessmentSummary.dateCreated).format(
                    "DD-MMM-YYYY  "
                  )}
                </div>
              </div>
              <br />
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Asssessment Dates:{" "}
                  {moment(assessmentSummary.assessmentFrom).format(
                    "DD-MMM-YYYY"
                  )}
                  {" to "}
                  {moment(assessmentSummary.assessmentTo).format("DD-MMM-YYYY")}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  Summary
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography variant="body2" gutterBottom>
                    {assessmentSummary.summary
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={index + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </div>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Remarks about Summary
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ fontStyle: "italic" }}
                  >
                    {assessmentSummary.remarks
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={"subarea" + index + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </div>
              </div>
            </Grid>
          );
        })}

        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!hasMore}
            onClick={handleLoadMoreAssessmentSummaries}
            style={{
              marginBottom: "32px",
            }}
          >
            {hasMore ? "Load More" : "No more  results"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const assessmentSummariesContainer = (
    <Fragment>
      <AlertDialog
        alertDialogOptions={alertDialogOptions}
        handleClose={handleCloseAlertDialog}
        handleConfirm={handleProceedDeleteAssessmentSummary}
      />
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />

      <AssessmentSummariesDialog
        assessmentSummary={assessmentSummary}
        setAssessmentSummary={setAssessmentSummary}
        assessmentSummaries={assessmentSummaries}
        setAssessmentSummaries={setAssessmentSummaries}
        selectedAssessmentSummaryIndex={selectedAssessmentSummaryIndex}
        opType={opType}
        setOpType={setOpType}
        isAddEditAssessmentSummaryDialogOpen={
          isAddEditAssessmentSummaryDialogOpen
        }
        handleCloseAddEditAssessmentSummaryDialog={
          handleCloseAddEditAssessmentSummaryDialog
        }
        toastOptions={toastOptions}
        setToastOptions={setToastOptions}
        handleCloseToast={handleCloseToast}
      />
      <div className={classes.root}>
        {["domainHead", "teacher", "areaCoordinator"].includes(
          currentUser.role
        ) && (
          <div className={classes.fab}>
            <Fab
              color="primary"
              aria-label="add"
              size="small"
              className={classes.fabItem}
            >
              <AddIcon
                onClick={() => {
                  setOpType("add");
                  //clear dialog
                  setAssessmentSummary(Factory.createAssessmentSummary());
                  setIsAddEditAssessmentSummaryDialogOpen(true);
                }}
              />
            </Fab>
          </div>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AssessmentSummariesFilter
              queryString={queryString}
              setQueryString={setQueryString}
              toastOptions={toastOptions}
              setToastOptions={setToastOptions}
            />
          </Grid>
        </Grid>
        {/* loading icon */}
        <Grid
          item
          xs={12}
          style={{
            display: showLoader ? "block" : "none",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress />
          </div>
        </Grid>
        {/* no assessmentSummaries */}
        <Grid
          item
          xs={12}
          style={{
            display:
              assessmentSummaries.length == 0 && !showLoader ? "block" : "none",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "block" }}>
              <HourglassEmptyIcon style={{ fontSize: "40px" }} />
            </div>
            <div style={{ display: "block" }}>
              <Typography variant="h6" className={classes.textCenter}>
                There are no assessment summaries to show.
              </Typography>
            </div>
          </div>
        </Grid>
        {/* assessmentSummary container grid  view */}
        {gridView()}
      </div>
    </Fragment>
  );

  if (errorLoadingPage) {
    return <ErrorLoadingPage />;
  } else {
    return assessmentSummariesContainer;
  }
}

export default AssessmentSummaries;
