import React, { Fragment, useEffect } from "react";
import moment from "moment";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import AddLearningExperienceDialog from "./addLearningExperienceDialog";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import { Factory } from "../../../helpers/factories";
import { Button } from "@material-ui/core";
import LearningExperienceBadge from "./learningExperienceBadge";

import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";

import LeEditTimePeriodsCodTodDialog from "./leEditTimePeriodsCodTodDialog";
import UploadTimeTableDialog from "./uploadTimeTableDialog";
import SelectWeekUploadFileDialog from "./selectWeekUploadFileDialog";
import { TimeTableService } from "../../../services/timeTable";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import TimeTableEditNameDialog from "./timeTableEditNameDialog";
import { LearningExperienceService } from "../../../services/learningExperiences";
import { Utils } from "../../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    maxHeight: "600px",
    background: "white",
  },
  tableCellBorder: {
    border: "1px solid rgb(222 219 219)",
  },
  tableCellSlots: {
    width: "15%",
    border: "1px solid rgb(222 219 219)",
  },
  tableCellSlotsInput: {
    fontSize: "0.875rem",
    // color: "red !important",
  },
  tableCellDays: {
    width: "15%",
    cursor: "pointer",
    padding: "8px",
    border: "1px solid rgb(222 219 219)",
  },

  tableCellChip: {
    marginBottom: "5px",
  },
  tableCellAddLearningExperience: { float: "right" },
  leTextField: {
    padding: "8px",
  },

  datePicker: {
    marginTop: "0px",
  },
  input: {
    display: "none",
  },
  button: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <h1>{children}</h1>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

export default function EditTimeTable({
  activeTimeTable,
  setActiveTimeTable,
  timeTables,
  setTimeTables,
  selectedRowIndex,
  toastOptions,
  setToastOptions,
  handleCloseToast,
  ...props
}) {
  const classes = useStyles();

  //current user
  const { currentUser } = useAppContext();

  //learning experiences being loaded
  const [areLesBeingLoaded, setAreLesBeingLoaded] = React.useState(true);

  //op type - add or edit
  const [opType, setOpType] = React.useState("add");

  //check if timetable has been edited for automatic saving
  const [hasTimeTableChanged, setHasTimeTableChanged] = React.useState(false);

  //learning experience
  const [activeLearnExp, setActiveLearnExp] = React.useState(
    Factory.createLearningExperience()
  );

  //learning experiences for timetable
  const [learningExperiences, setLearningExperiences] = React.useState(null);

  const [timePeriods, setTimePeriods] = React.useState(
    activeTimeTable.timePeriods.join()
  );

  const [weekUploadFile, setWeekUploadFile] = React.useState(0);
  const [isWeekSetUploadFile, setIsWeekSetUploadFile] = React.useState(false);
  const [
    isSelectWeekUploadFileDialogOpen,
    setIsSelectWeekUploadFileDialogOpen,
  ] = React.useState(false);

  const [cod, setCod] = React.useState(activeTimeTable.cod.join());

  const [tods, setTods] = React.useState(activeTimeTable.tods.join());

  const [
    isLeEditTmePeriodsCodTodDialogOpen,
    setIsLeEditTimePeriodsCodTodDialogOpen,
  ] = React.useState(false);

  //for handline timetable name change
  const [isTimeTableEditTitleDialogOpen, setIsTimeTableEditTitleDialogOpen] =
    React.useState(false);

  //for handling upload timetable file
  const [isUploadTimeTableDialogOpen, setIsUploadTimeTableDialogOpen] =
    React.useState(false);

  const [uploadOutputText, setUploadOutputText] = React.useState(
    "Processing. Please wait ..."
  );

  //cell event and cell data
  const [cellEvent, setCellEvent] = React.useState(null);
  const [cellEventData, setCellEventData] = React.useState(null);

  //dialog add / edit event
  const [
    isAddLearningExperienceDialogOpen,
    setIsAddLearningExperienceDialogOpen,
  ] = React.useState(false);

  //prepare stucture for displaying timetable on grid
  const noWeeksSlots = Array.from(Array(activeTimeTable.noWeeks).keys());
  const slotsArray = Array.from(Array(activeTimeTable.noSlotsPerDay).keys());
  const noDays = [0, 1, 2, 3, 4, 5, 6];
  const timeTableDisplayStructure = {
    weeks: noWeeksSlots.map((week) => {
      return {
        slots: slotsArray.map((slot, slotIndex) => {
          return {
            name: slot + 1,
            timePeriod: "",
            days: noDays.map((day) => {
              return { les: [] };
            }),
          };
        }),
      };
    }),
  };

  const [localTimeTable, setLocalTimeTable] = React.useState(
    timeTableDisplayStructure
  );

  //slots  - using a separate state for lagging reasons
  const [slotsState, setSlotsState] = React.useState();

  //tab
  const [value, setValue] = React.useState(
    (() => {
      // activeTimeTable.noWeeks
      let tabValues = Array.from(Array(activeTimeTable.noWeeks).keys());
      let startDate = moment(activeTimeTable.startDate);
      let dateNow = moment();

      let diff = dateNow.diff(startDate, "days");
      let index = Math.floor(diff / 7);
      if (tabValues.includes(index)) {
        return index;
      } else {
        return tabValues[tabValues.length - 1];
      }
    })()
  );

  //can edit localTimeTable
  const canEdit = ["schoolAdmin", "domainHead"].includes(currentUser.role)
    ? true
    : false;

  //counter for showing saving toast; we only show it every 5 saves
  const [saveCount, setSaveCount] = React.useState(0);

  const [selectedTimeTableFile, setSelectedTimeTableFile] = React.useState();
  const [isFilePicked, setIsFilePicked] = React.useState(false);
  const [isFileProcessed, setIsFileProcessed] = React.useState(false);
  const [fileExt, setFileExt] = React.useState("");
  const [reloadLEs, setReLoadLEs] = React.useState(0); //counter to reload les when timetable file uploaded

  /*
   * helper functions
   */

  /*
   * handlers
   */

  //retrieve learning experiences
  useEffect(async () => {
    try {
      setLearningExperiences(
        await LearningExperienceService.getLearningExperiencesByOrgIdAndTimeTableId(
          currentUser.organisation.id,
          activeTimeTable._id
        )
      );

      setAreLesBeingLoaded(false);
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error saving timetable",
      });
    }
  }, [activeTimeTable, reloadLEs]);

  //load les for display
  useEffect(() => {
    //insert le into local timetable for display
    let tempTable = timeTableDisplayStructure;
    if (learningExperiences) {
      learningExperiences.forEach((le, index) => {
        tempTable["weeks"][le.week]["slots"][le.slot]["days"][le.day][
          "les"
        ].push(le);
      });
    }
    //update local timetable to show les
    setLocalTimeTable({ ...tempTable });
  }, [learningExperiences]);

  //if timetable has changed update timetable
  useEffect(() => {
    if (hasTimeTableChanged) {
      saveTimeTable();
      setHasTimeTableChanged(false);
    }
  }, [hasTimeTableChanged]);

  //check when file is ready for upload and begin upload process
  useEffect(() => {
    if (isFilePicked) {
      uploadFile();
    }
  }, [isFilePicked]);

  //upload file week has been set
  useEffect(() => {
    if (isWeekSetUploadFile) {
      uploadFileProceed();
    }
  }, [isWeekSetUploadFile]);

  //view/edit learning experience
  const handleViewEditLearningExperience = (event, eventData) => {
    setActiveLearnExp(
      localTimeTable.weeks[eventData.weekIndex].slots[eventData.slotIndex].days[
        eventData.dayIndex
      ].les[eventData.leIndex]
    );
    setOpType("edit");
    setCellEvent(event);
    setCellEventData(eventData);
    setIsAddLearningExperienceDialogOpen(true);
  };

  //handles opening dialog for new learning experience
  const handleAddLearningExperience = (event, eventData) => {
    //to make sure only clicking outside chip fires this
    if (event.target.localName === "tc") {
      setOpType("add");
      setCellEvent(event);
      setCellEventData(eventData);
      // setActiveLearnExp(Factory.createLearningExperience());
      setIsAddLearningExperienceDialogOpen(true);
    }
  };

  const handleDeleteLearningExperience = async (event, eventData) => {
    try {
      let learningExperience =
        localTimeTable["weeks"][eventData.weekIndex]["slots"][
          eventData.slotIndex
        ]["days"][eventData.dayIndex]["les"][eventData.leIndex];

      await LearningExperienceService.deleteLearningExperienceByLearningExperienceId(
        currentUser.organisation.id,
        learningExperience.timeTable,
        learningExperience._id
      );

      //update les in timetable
      let temp = [...learningExperiences];
      let leIndexCount = 0;
      temp.forEach((le, index) => {
        if (
          le.week == eventData.weekIndex &&
          le.day == eventData.dayIndex &&
          le.slot == eventData.slotIndex
        ) {
          if (leIndexCount == eventData.leIndex) {
            temp.splice(index, 1);
          }
          leIndexCount += 1;
        }
      });
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Learning Experience Deleted",
      });
      setLearningExperiences([...temp]);
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error deleting learning experience / event",
      });
    }
  };

  //handle learnExp name change
  const handleTimePeriodsCodTodChangeDone = () => {
    setHasTimeTableChanged(true);
  };

  //handle slot name and time periods change
  const handleTimePeriodsCodTodChange = () => {
    setIsLeEditTimePeriodsCodTodDialogOpen(true);
  };

  //handle timetable name change
  const handleTimeTableTitleChange = (event, newValue) => {
    setIsTimeTableEditTitleDialogOpen(true);
  };

  //handle From date change
  const handleFromDateChange = (date) => {
    if (date === "Invalid Date" || date === null) {
    } else {
      setActiveTimeTable({
        ...activeTimeTable,
        startDate: moment(date).utc(),
        endDate: moment(date).utc().add(20, "days"),
      });
      setHasTimeTableChanged(true);
      // setActiveTimeTable(localTimeTable);
    }
  };

  //change timetable tab
  const handleChangeWeekTab = (event, newValue) => {
    setValue(newValue);
  };

  //handle close add learning experience event dialog
  const handleCloseAddLearningExperienceDialog = () => {
    // setActiveTimeTable(localTimeTable);
    setOpType("add");
    setIsAddLearningExperienceDialogOpen(false);
  };

  const handleUploadTimeTableFile = (event) => {
    const lastDot = event.target.files[0].name.lastIndexOf(".");
    const extension = event.target.files[0].name.substring(lastDot + 1);
    if (["xml", "txt", "csv"].includes(extension)) {
      setFileExt(extension);
      setSelectedTimeTableFile(event.target.files[0]);
      setIsFilePicked(true);
    } else {
      setToastOptions({
        ...toastOptions,
        open: true,
        duration: 2000,
        message: "File must be .xml or .txt",
      });
    }
  };

  const handleCloseUploadTimeTableDialog = () => {
    setIsUploadTimeTableDialogOpen(false);
  };

  const uploadFile = () => {
    setIsSelectWeekUploadFileDialogOpen(true);
  };

  const uploadFileProceed = async () => {
    setIsFilePicked(false);
    setSelectedTimeTableFile(null);
    //upload form
    const formData = new FormData();
    formData.append("timeTableFile", selectedTimeTableFile);

    setIsUploadTimeTableDialogOpen(true);

    try {
      const result = await TimeTableService.uploadTimeTableFile(
        currentUser.organisation.id,
        activeTimeTable._id,
        formData,
        { fileExt: fileExt, week: weekUploadFile }
      );
      setUploadOutputText(result.message);
      setReLoadLEs(reloadLEs + 1);

      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Timetable uploaded. Please reload this page.",
      });
    } catch (err) {
      setUploadOutputText("Error\n\n" + err.message);
    }

    setIsFileProcessed(true);
  };

  //save timetable
  const saveTimeTable = async () => {
    try {
      let data = {
        title: activeTimeTable.title,
        startDate: activeTimeTable.startDate,
        endDate: activeTimeTable.endDate,
        noSlotsPerDay: activeTimeTable.noSlotsPerDay,
        timePeriods: activeTimeTable.timePeriods,
        cod: activeTimeTable.cod,
        tods: activeTimeTable.tods,
        // weeks: activeTimeTable.weeks, //3 weeks
      };

      //show saving toast only every ten saves
      if (saveCount % 10 == 0) {
        setToastOptions({
          ...toastOptions,
          open: true,
          message: "Saving",
        });
      }

      //save

      const savedTimeTable =
        await TimeTableService.updateTimeTableByOrgIdAndTimeTableId(
          currentUser.organisation.id,
          activeTimeTable._id,
          data
        );
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error saving timetable",
      });
    }
  };

  //timetable container
  const WeeklyTimetable = ({ index, ...rest }) => {
    // if (localTimeTable.weeks != null) {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellSlots}>Slots</TableCell>
              <TableCell className={classes.tableCellBorder}>
                {moment(activeTimeTable.startDate)
                  .add(index * 7 + 0, "days")
                  .format("ddd, MMM-DD")}
              </TableCell>
              <TableCell className={classes.tableCellBorder}>
                {moment(activeTimeTable.startDate)
                  .add(index * 7 + 1, "days")
                  .format("ddd, MMM-DD")}
              </TableCell>
              <TableCell className={classes.tableCellBorder}>
                {moment(activeTimeTable.startDate)
                  .add(index * 7 + 2, "days")
                  .format("ddd, MMM-DD")}
              </TableCell>
              <TableCell className={classes.tableCellBorder}>
                {moment(activeTimeTable.startDate)
                  .add(index * 7 + 3, "days")
                  .format("ddd, MMM-DD")}
              </TableCell>
              <TableCell className={classes.tableCellBorder}>
                {moment(activeTimeTable.startDate)
                  .add(index * 7 + 4, "days")
                  .format("ddd, MMM-DD")}
              </TableCell>
              <TableCell className={classes.tableCellBorder}>
                {moment(activeTimeTable.startDate)
                  .add(index * 7 + 5, "days")
                  .format("ddd, MMM-DD")}
              </TableCell>
              <TableCell className={classes.tableCellBorder}>
                {moment(activeTimeTable.startDate)
                  .add(index * 7 + 6, "days")
                  .format("ddd, MMM-DD")}
              </TableCell>
              {/* <TableCell className={classes.tableCellOptions}></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {localTimeTable.weeks[index].slots.map((slot, slotIndex) => (
              <TableRow key={index + "" + slotIndex}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCellSlots}
                  style={{ verticalAlign: "top" }}
                >
                  <Typography variant="body1" gutterBottom>
                    {activeTimeTable.timePeriods[slotIndex]
                      ? activeTimeTable.timePeriods[slotIndex]
                      : slotIndex}
                  </Typography>
                </TableCell>
                {slot.days.map((day, dayIndex) => (
                  <TableCell
                    key={index + "" + slotIndex + dayIndex}
                    component="tc"
                    scope="row"
                    className={classes.tableCellDays}
                    style={{ verticalAlign: "top" }}
                    onClick={(event) =>
                      canEdit
                        ? handleAddLearningExperience(
                            event,
                            (() => {
                              return {
                                type: "cell",
                                weekIndex: index,
                                slotIndex: slotIndex,
                                dayIndex: dayIndex,
                              };
                            })()
                          )
                        : null
                    }
                  >
                    {day.les.map((learnExp, leIndex) => (
                      <LearningExperienceBadge
                        learnExp={learnExp}
                        handleViewEditLearningExperience={
                          handleViewEditLearningExperience
                        }
                        handleDeleteLearningExperience={
                          handleDeleteLearningExperience
                        }
                        weekIndex={index}
                        slotIndex={slotIndex}
                        dayIndex={dayIndex}
                        leIndex={leIndex}
                      />
                      // <Chip
                      //   key={dayIndex + leIndex}
                      //   className={classes.tableCellChip}
                      //   style={{
                      //     backgroundColor: Utils.getColorForGrade(
                      //       learnExp.grade
                      //     ),
                      //     fontSize: "0.7rem",
                      //   }}
                      //   size="small"
                      //   label={(() => {
                      //     let temp = "";
                      //     if (learnExp.type === "other") {
                      //       temp = temp + learnExp.title;
                      //     } else {
                      //       // to keep chips short
                      //       // temp = temp + learnExp.domainArea.shortName;
                      //     }
                      //     if (learnExp.teacher && learnExp.title) {
                      //       temp =
                      //         temp +
                      //         ", " +
                      //         learnExp.teacher.firstName.substring(0, 3) +
                      //         learnExp.teacher.lastName.substring(0, 3);
                      //     } else if (learnExp.teacher) {
                      //       temp =
                      //         temp +
                      //         learnExp.teacher.firstName.substring(0, 3) +
                      //         learnExp.teacher.lastName.substring(0, 3);
                      //     }
                      //     if (!learnExp.isForAllGrades) {
                      //       temp = temp + ", G" + learnExp.grade;
                      //     }
                      //     if (
                      //       !learnExp.isForAllGrades &&
                      //       learnExp.group != ""
                      //     ) {
                      //       temp = temp + ", " + learnExp.group;
                      //     }

                      //     return temp;
                      //   })()}
                      //   clickable={canEdit}
                      //   color="primary"
                      //   onClick={(event) =>
                      //     handleViewEditLearningExperience(
                      //       event,
                      //       (() => {
                      //         return {
                      //           type: "chip",
                      //           weekIndex: index,
                      //           slotIndex: slotIndex,
                      //           dayIndex: dayIndex,
                      //           leIndex: leIndex,
                      //         };
                      //       })()
                      //     )
                      //   }
                      //   onDelete={(event) =>
                      //     handleDeleteLearningExperience(
                      //       event,
                      //       (() => {
                      //         return {
                      //           type: "chip",
                      //           weekIndex: index,
                      //           slotIndex: slotIndex,
                      //           dayIndex: dayIndex,
                      //           leIndex: leIndex,
                      //         };
                      //       })()
                      //     )
                      //   }
                      // />
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
    // }
  };

  return (
    <div>
      <Dialog
        onClose={null}
        aria-labelledby="simple-dialog-title"
        open={areLesBeingLoaded}
      >
        <CircularProgress />
      </Dialog>
      <SelectWeekUploadFileDialog
        noWeeks={activeTimeTable.noWeeks}
        weekUploadFile={weekUploadFile}
        setWeekUploadFile={setWeekUploadFile}
        isSelectWeekUploadFileDialogOpen={isSelectWeekUploadFileDialogOpen}
        setIsSelectWeekUploadFileDialogOpen={
          setIsSelectWeekUploadFileDialogOpen
        }
        setIsWeekSetUploadFile={setIsWeekSetUploadFile}
      />

      <UploadTimeTableDialog
        uploadOutputText={uploadOutputText}
        setUploadOutputText={setUploadOutputText}
        isFileProcessed={isFileProcessed}
        toastOptions={toastOptions}
        setToastOptions={setToastOptions}
        handleCloseToast={handleCloseToast}
        activeTimeTable={activeTimeTable}
        isUploadTimeTableDialogOpen={isUploadTimeTableDialogOpen}
        handleCloseUploadTimeTableDialog={handleCloseUploadTimeTableDialog}
      />
      <LeEditTimePeriodsCodTodDialog
        noSlotsPerDay={activeTimeTable.noSlotsPerDay}
        activeTimeTable={activeTimeTable}
        setActiveTimeTable={setActiveTimeTable}
        isLeEditTimePeriodsCodTodDialogOpen={isLeEditTmePeriodsCodTodDialogOpen}
        setIsLeEditTimePeriodsCodTodDialogOpen={
          setIsLeEditTimePeriodsCodTodDialogOpen
        }
        handleTimePeriodsCodTodChangeDone={handleTimePeriodsCodTodChangeDone}
      />
      <TimeTableEditNameDialog
        activeTimeTable={activeTimeTable}
        setActiveTimeTable={setActiveTimeTable}
        isTimeTableEditTitleDialogOpen={isTimeTableEditTitleDialogOpen}
        setIsTimeTableEditTitleDialogOpen={setIsTimeTableEditTitleDialogOpen}
        setHasTimeTableHasChanged={setHasTimeTableChanged}
      />
      <AddLearningExperienceDialog
        activeTimeTable={activeTimeTable}
        opType={opType}
        setOpType={setOpType}
        cellEvent={cellEvent}
        cellEventData={cellEventData}
        activeLearnExp={activeLearnExp}
        setActiveLearnExp={setActiveLearnExp}
        learningExperiences={learningExperiences}
        setLearningExperiences={setLearningExperiences}
        isAddLearningExperienceDialogOpen={isAddLearningExperienceDialogOpen}
        handleCloseAddLearningExperienceDialog={
          handleCloseAddLearningExperienceDialog
        }
        l
        toastOptions={toastOptions}
        setToastOptions={setToastOptions}
      />
      <Grid container spacing={6}>
        {canEdit ? (
          <Fragment>
            <Grid item xs={12} sm={3}>
              <TextField
                id="standard-basic"
                InputProps={{
                  readOnly: false,
                }}
                value={activeTimeTable.title}
                label="Calendar Title"
                onClick={handleTimeTableTitleChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  className={classes.datePicker}
                  margin="normal"
                  id="date-picker-dialog-from"
                  label={"Week Start Date"}
                  //format="dd/MM/yyyy"
                  value={moment(activeTimeTable.startDate)}
                  readOnly={false}
                  onChange={handleFromDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="slot-names-periods"
                InputProps={{
                  readOnly: false,
                }}
                value={activeTimeTable.timePeriods.join()}
                label="Names / Time Periods"
                // onChange={handleTimeTableTitleChange}
                onClick={handleTimePeriodsCodTodChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div>
                <input
                  accept=".txt, .xml"
                  onChange={(e) => {
                    handleUploadTimeTableFile(e);
                  }}
                  onClick={(e) => {
                    setIsFilePicked(false);
                    setIsWeekSetUploadFile(false);
                    e.target.value = null;
                  }}
                  className={classes.input}
                  id="contained-button-file"
                  type="file"
                  name="timeTableFile"
                  enctype="multipart/form-data"
                  val={selectedTimeTableFile}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.button}
                  >
                    Upload Timetable (.xml or .txt)
                  </Button>
                </label>
              </div>
            </Grid>
          </Fragment>
        ) : (
          <div>
            <Typography variant="h6" gutterBottom>
              {localTimeTable.title}, {localTimeTable.startDate}
            </Typography>
          </div>
        )}
      </Grid>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChangeWeekTab}
          aria-label="simple tabs example"
        >
          {Array.from(Array(activeTimeTable.noWeeks).keys()).map((index) => {
            return (
              <Tab
                label={(() => {
                  let a = moment(activeTimeTable.startDate)
                    .add(index * 7, "days")
                    .format("MMM-DD");
                  let b = moment(activeTimeTable.startDate)
                    .add(index * 7 + 5, "days")
                    .format("MMM-DD");
                  return "Week " + (index + 1) + "( " + a + " - " + b + " )";
                })()}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {Array.from(Array(activeTimeTable.noWeeks).keys()).map((index) => {
        return (
          <TabPanel value={value} index={index}>
            <WeeklyTimetable index={index} />
          </TabPanel>
        );
      })}
    </div>
  );
}
