import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { UserService } from "../../../services/user";
import { useAppContext } from "../../../libs/contextLib";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import Loader from "../../helperComponents/loader";
import { Button } from "@material-ui/core";
import Toast from "../../helperComponents/toast";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  contentWrapper: {
    margin: "40px 16px",
  },
  showProfileForm: {
    display: "block",
  },
  hideProfileForm: {
    display: "none",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
}));

function MyDetails(props) {
  const classes = useStyles();
  const { currentUser } = useAppContext();
  const [userRetrieved, setUserRetrieved] = React.useState(false);
  const [retrievedUser, setRetrievedUser] = React.useState({});
  const [showForm, setShowForm] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorLoadingProfile, setErrorLoadingProfile] = React.useState(false);
  //const [lastName, setLastName] = React.useState("");
  // const [Year, setFirstName] = React.useState("");
  // const [lastName, setLastName] = React.useState("");

  //to hold form values,
  // we could use announcement prop directly but slows down render
  // const [retrievedUser, setRetrievedUser] = React.useState({});

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 2000,
  });

  //pre-load verify token, startFecthData set in RouteAuthHOC
  useEffect(() => {
    if (props.startFetchData) {
      onLoad();
    }
  }, [props.startFetchData]);

  async function onLoad() {
    setIsLoading(true);
    setErrorLoadingProfile(false);

    try {
      setRetrievedUser(
        await UserService.getUserByOrgIdAndUserId(
          currentUser.id,
          currentUser.organisation.id
        )
      );
      setShowForm(true);
      setIsLoading(false);
      setShowLoader(false);

      // console.log("user:");
      // console.log(retrievedUser);
      setUserRetrieved(true);
      //setFirstName(retrievedUser.firstName);
    } catch (err) {
      setErrorLoadingProfile(true);
    }
  }

  //for updating form from table
  // useEffect(() => {
  //   setRetrievedUser(retrievedUser);
  // }, [retrievedUser]);

  //input change
  function handleInputChange(e) {
    switch (e.target.name) {
      case "alias":
        setRetrievedUser({
          ...retrievedUser,
          alias: e.target.value,
        });
        break;
      case "email":
        setRetrievedUser({
          ...retrievedUser,
          email: e.target.value,
        });
        break;
      case "addressLine1":
        setRetrievedUser({
          ...retrievedUser,
          ["address"]: { ...retrievedUser.address, line1: e.target.value },
        });
        break;
      case "addressSuburb":
        setRetrievedUser({
          ...retrievedUser,
          ["address"]: { ...retrievedUser.address, suburb: e.target.value },
        });
        break;
      case "addressCity":
        setRetrievedUser({
          ...retrievedUser,
          ["address"]: { ...retrievedUser.address, city: e.target.value },
        });
        break;
      case "addressState":
        setRetrievedUser({
          ...retrievedUser,
          ["address"]: { ...retrievedUser.address, state: e.target.value },
        });
        break;
      case "addressCountry":
        setRetrievedUser({
          ...retrievedUser,
          ["address"]: { ...retrievedUser.address, country: e.target.value },
        });
        break;
      case "guardianName":
        setRetrievedUser({
          ...retrievedUser,
          ["guardianEmergencyContact"]: {
            ...retrievedUser.guardianEmergencyContact,
            name: e.target.value,
          },
        });
        break;
      case "guardianEmail":
        setRetrievedUser({
          ...retrievedUser,
          ["guardianEmergencyContact"]: {
            ...retrievedUser.guardianEmergencyContact,
            email: e.target.value,
          },
        });
        break;
      case "guardianPhone":
        setRetrievedUser({
          ...retrievedUser,
          ["guardianEmergencyContact"]: {
            ...retrievedUser.guardianEmergencyContact,
            phone: e.target.value,
          },
        });
        break;
      default:
        setRetrievedUser({ ...retrievedUser, [e.target.name]: e.target.value });
    }
  }

  //check if enable save button
  function isFormValid() {
    return (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        retrievedUser.email
      ) &&
      (retrievedUser.guardianEmergencyContact.email === "" ||
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          retrievedUser.guardianEmergencyContact.email
        )) &&
      (retrievedUser.guardianEmergencyContact.phone === "" ||
        /^\d{6,}$/.test(retrievedUser.guardianEmergencyContact.phone))
    );
  }

  // update user profile
  const handleSaveUser = async () => {
    //save form data, using extra object because setState on retrievedUser doesn't update immediately
    let data = {
      alias: retrievedUser.alias,
      email: retrievedUser.email,
      organisation: currentUser.organisation.id, //add org id required
      // organisation: retrievedUser.organisation,
      address: {
        line1: retrievedUser.address ? retrievedUser.address.line1 : "N/A",
        suburb: retrievedUser.address ? retrievedUser.address.suburb : "N/A",
        city: retrievedUser.address ? retrievedUser.address.city : "N/A",
        state: retrievedUser.address ? retrievedUser.address.state : "N/A",
        country: retrievedUser.address ? retrievedUser.address.country : "N/A",
      },
      guardianEmergencyContact: {
        name: retrievedUser.guardianEmergencyContact
          ? retrievedUser.guardianEmergencyContact.name
          : "N/A",
        email: retrievedUser.guardianEmergencyContact
          ? retrievedUser.guardianEmergencyContact.email
          : "N/A",
        phone: retrievedUser.guardianEmergencyContact
          ? retrievedUser.guardianEmergencyContact.phone
          : "N/A",
      },
    };

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving...",
      });

      const updatedUser = await UserService.updateUserProfileByOrgIdAndUserId(
        currentUser.organisation.id,
        currentUser.id,
        data
      );

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Profile Updated",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  };

  function userRetrievedComponent() {
    return (
      <div className={classes.root}>
        <Toast toastOptions={toastOptions} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">My Details</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <Loader visible={showLoader ? "true" : "false"} />
              <form
                className={
                  showForm ? classes.showProfileForm : classes.hideProfileForm
                }
                noValidate
                autoComplete="off"
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      variant="outlined"
                      id="firstName"
                      label="First Name"
                      value={retrievedUser.firstName}
                      className={classes.textField}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      variant="outlined"
                      id="Last Name"
                      label="Last Name"
                      value={retrievedUser.lastName}
                      disabled={true}
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      id="alias"
                      name="alias"
                      label="Alias"
                      variant="outlined"
                      value={retrievedUser.alias}
                      onChange={handleInputChange}
                      className={classes.textField}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      id="email"
                      name="email"
                      label="E-mail"
                      variant="outlined"
                      onChange={handleInputChange}
                      value={retrievedUser.email}
                      className={classes.textField}
                    />
                  </Grid>
                  {currentUser.role === "student" && (
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        variant="outlined"
                        id="grade"
                        label="Grade"
                        value={retrievedUser.grade}
                        className={classes.textField}
                        disabled={true}
                      />
                    </Grid>
                  )}
                  {currentUser.role === "student" && (
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        variant="outlined"
                        id="groupSection"
                        label="Group / Section"
                        value={retrievedUser.groupSection}
                        className={classes.textField}
                        disabled={true}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Year Joined"
                      variant="outlined"
                      value={retrievedUser.yearJoined}
                      disabled={true}
                      className={classes.textField}
                    />
                  </Grid>
                  {currentUser.role === "student" && (
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        variant="outlined"
                        id="nationalStudentCode"
                        label="National Student Code"
                        value={retrievedUser.nationalStudentCode}
                        className={classes.textField}
                        disabled={true}
                      />
                    </Grid>
                  )}
                  {[
                    "teacher",
                    "areaCoordinator",
                    "domainHead",
                    "headOfSchool",
                  ].includes(currentUser.role) && (
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        variant="outlined"
                        id="nationalTeacherCode"
                        label="National Teacher Code"
                        value={retrievedUser.nationalTeacherCode}
                        className={classes.textField}
                        disabled={true}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.textFieldBlockTitle}
                      variant="h6"
                    >
                      Address
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      variant="outlined"
                      name="addressLine1"
                      id="grade"
                      label="Line1"
                      onChange={handleInputChange}
                      value={retrievedUser.address.line1}
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      variant="outlined"
                      name="addressSuburb"
                      id="outlined-required"
                      label="Suburb / Village"
                      onChange={handleInputChange}
                      value={retrievedUser.address.suburb}
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      variant="outlined"
                      name="addressCity"
                      id="outlined-required"
                      label="Town / City / Gewog"
                      onChange={handleInputChange}
                      value={retrievedUser.address.city}
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      variant="outlined"
                      name="addressState"
                      id="outlined-required"
                      label="State / Dzongkhag"
                      onChange={handleInputChange}
                      value={retrievedUser.address.state}
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      variant="outlined"
                      name="addressCountry"
                      id="outlined-required"
                      label="Country"
                      onChange={handleInputChange}
                      value={retrievedUser.address.country}
                      className={classes.textField}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      className={classes.textFieldBlockTitle}
                    >
                      {currentUser.role === "student"
                        ? "Guardian"
                        : "Emergency Contact"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      variant="outlined"
                      name="guardianName"
                      id="guardianName"
                      label="name"
                      onChange={handleInputChange}
                      value={retrievedUser.guardianEmergencyContact.name}
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      variant="outlined"
                      name="guardianEmail"
                      id="guardianEmail"
                      label="E-Mail"
                      onChange={handleInputChange}
                      value={retrievedUser.guardianEmergencyContact.email}
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      variant="outlined"
                      name="guardianPhone"
                      id="guardianPhone"
                      label="Phone"
                      onChange={handleInputChange}
                      value={retrievedUser.guardianEmergencyContact.phone}
                      className={classes.textField}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginTop: "16px" }}>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body2">
                      Personal email is required and must be formatted properly.
                      Guardian/Emergency contact email and phone number must be
                      properly formatted or left blank.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!isFormValid()}
                      onClick={handleSaveUser}
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  if (userRetrieved) {
    return userRetrievedComponent();
  } else {
    return errorLoadingProfile && <ErrorLoadingPage />;
  }

  // if (userRetrieved) {
  //   console.log("here");
  //   return userRetrievedComponent();
  // } else {
  //   return <ErrorLoadingPage />;
  // }

  // return (
  //   <div>
  //     <Paper className={classes.paper}>
  //       <div className={classes.contentWrapper} userRetrieved={userRetrieved}>
  //         {{ userRetrieved } && userRetrieveComponent()}
  //       </div>
  //     </Paper>
  //   </div>
  // );
}

export default MyDetails;
