import React, { useEffect } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";

import moment from "moment";

import * as dfd from "danfojs";

const data = [
  {
    subject: "Math",
    A: 120,
    B: 110,
    fullMark: 150,
  },
  {
    subject: "Chinese",
    A: 98,
    B: 130,
    fullMark: 150,
  },
  {
    subject: "Dzpngka",
    A: 98,
    B: 130,
    fullMark: 150,
  },
  // {
  //   subject: "Aesthetics",
  //   A: 98,
  //   B: 130,
  //   fullMark: 150,
  // },
  // {
  //   subject: "English",
  //   A: 86,
  //   B: 130,
  //   fullMark: 150,
  // },
  // {
  //   subject: "Geography",
  //   A: 99,
  //   B: 100,
  //   fullMark: 150,
  // },
  // {
  //   subject: "Physics",
  //   A: 85,
  //   B: 90,
  //   fullMark: 150,
  // },
  // {
  //   subject: "History",
  //   A: 65,
  //   B: 85,
  //   fullMark: 150,
  // },
];

export default function RadarProgressChart({
  dataForCharts,
  setChartDomainArea,
  clearIndividualGoalLineChart,
  setClearIndividualGoalLineChart,
}) {
  const [dataForRadar, setDataForRadar] = React.useState([]);

  useEffect(() => {
    function prepData() {
      let df = new dfd.DataFrame(dataForCharts);
      let grp = df.groupby(["domainArea", "domainAreaShortName"]);
      grp = grp.col(["progressAverage"]).mean();
      setDataForRadar(dfd.toJSON(grp));
      // grp.print();
    }

    if (dataForCharts.length > 0) {
      prepData();
    }
  }, [dataForCharts]);

  return (
    // <div width="100%" height="300" style={{ display: "flex" }}>
    //   <p>hello</p>
    <ResponsiveContainer height={225}>
      {/* <div> */}
      <RadarChart
        style={{ display: "inline" }}
        cx="50%"
        cy="50%"
        outerRadius="80%"
        data={dataForRadar}
        onClick={(data) => {
          // setChartDomainArea(data.value)
          // console.log(data);
        }}
      >
        <PolarGrid />
        <PolarAngleAxis
          dataKey="domainArea"
          onClick={(data) => {
            setChartDomainArea(data.value);
            setClearIndividualGoalLineChart(true);
          }}
        />
        <PolarRadiusAxis
          domain={[0, 100]}
          // onClick={(data) => {
          //   // setChartDomainArea()
          //   console.log(data);
          // }}
        />
        <Radar
          name="Mike"
          dataKey="progressAverage_mean"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.6}
          dot={true}
          // onClick={(data) => {
          //   console.log(data);
          // }}
          isAnimationActive={true}
        />
      </RadarChart>
      {/* </div> */}
    </ResponsiveContainer>
    // </div>
  );
}
