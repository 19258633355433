import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { AnnouncementService } from "../../../services/announcement";
// import Toast from "../../helperComponents/toast";
// import Toast from "../../helperComponents/toastComonent";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function AnnouncementDialog({
  toastOptions,
  setToastOptions,
  handleCloseToast,
  formData,
  opType,
  setOpType,
  announcement,
  setAnnouncement,
  announcements,
  setAnnouncements,
  selectedRowIndex,
  setSelectedRowIndex,
  isDialogOpen,
  handleCloseDialog,
  ...props
}) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  //to hold form values,
  // we could use announcement prop directly but slows down render
  const [dialogForm, setDialogForm] = React.useState({});

  //for updating announcements from table
  useEffect(() => {
    setDialogForm(announcement);
  }, [announcement]);

  //check if enable save button
  function isFormValid() {
    return (
      dialogForm.title &&
      dialogForm.title.length > 0 &&
      dialogForm.body.length > 0
    );
  }

  function handleInputChange(e) {
    setDialogForm({ ...dialogForm, [e.target.name]: e.target.value });
  }

  async function handleSaveAnnouncement() {
    //save form data, using extra object because setState on dialogForm doesn't update immediately for oraganisation and createdBy
    let data = {
      title: dialogForm.title,
      body: dialogForm.body.replace(/↵/g, "\n"),
      status: dialogForm.status,
      organisation: currentUser.organisation.id,
      createdBy: currentUser.id,
    };

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      //check save or edit

      if (opType === "add") {
        const savedAnnouncement =
          await AnnouncementService.addAnnouncementsByOrgId(
            currentUser.organisation.id,
            data
          );
        //add announcement to announcements to update table
        let temp = [...announcements];
        temp.push(savedAnnouncement);
        setAnnouncement(savedAnnouncement);
        setAnnouncements(temp);
        setSelectedRowIndex(temp.length - 1);
        setOpType("edit");
      } else {
        const updatedAnnouncement =
          await AnnouncementService.updateAnnouncementByOrgIdAndAnnouncementId(
            currentUser.organisation.id,
            announcement._id,
            data
          );
        //edit announcement in announcements to update table
        let temp = [...announcements];
        temp[selectedRowIndex] = updatedAnnouncement;
        setAnnouncements(temp);
      }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Announcement Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error saving announcement",
      });
    }
  }

  return (
    <Fragment>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {opType === "edit" ? "Edit Assessment" : "Add Assessment"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                name="title"
                label="Title"
                type="text"
                value={dialogForm.title}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                margin="dense"
                id="body"
                name="body"
                label="Body"
                type="text"
                value={dialogForm.body}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  defaultValue={dialogForm.status}
                  value={dialogForm.status}
                  onChange={handleInputChange}
                >
                  <MenuItem value="published">Published</MenuItem>
                  <MenuItem value="unpublished">Unpublished</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={handleSaveAnnouncement}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AnnouncementDialog;
