import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Switch from "@material-ui/core/Switch";
import { Button, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import SearchTag from "../../helperComponents/searchTags";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import { Factory } from "../../../helpers/factories";

import { RoadmapService } from "../../../services/roadmap";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function AddEditRoadmapChildNodeDialog({
  opType,
  setOpType,
  roadmapChildNodeType,
  roadmapChildNodes,
  setRoadmapChildNodes,
  roadmapNode,
  selectedRoadmapNodeIndex,
  roadmapNodes,
  setRoadmapNodes,
  roadmapChildNode,
  setRoadmapChildNode,
  selectedChildNodeIndex,
  isAddEditRoadmapChidlNodeDialogOpen,
  handleCloseAddEditRoadmapChildNodeDialog,
  toastOptions,
  setToastOptions,
  handleCloseToast,
  ...props
}) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  //to hold form values,
  // we could use roadmapNode prop directly but slows down render
  const [dialogForm, setDialogForm] = React.useState(
    Factory.createRoadmapChildNode()
  );

  //load values into dialog form if childnoe not empty; for edit
  useEffect(() => {
    setDialogForm({ ...roadmapChildNode });
  }, [roadmapChildNode]);

  //indicators of success for updating
  const [indicatorsOfSuccess, setIndicatorsOfSuccess] = React.useState([]);

  useEffect(() => {
    let temp = [];
    if (roadmapNode.indicatorsOfSuccess) {
      roadmapNode.indicatorsOfSuccess.forEach((item) => {
        temp.push({ ...item });
      });
      setIndicatorsOfSuccess(temp);
    }
  }, [roadmapNode]);

  const [description, setDescription] = React.useState("");
  const [tags, setTags] = React.useState([]);

  //save as assessment switch
  const [saveAsAssessment, setSaveAsAseessment] = React.useState(false);

  //check if enable save button
  function isFormValid() {
    //console.log("dialog form", dialogForm);
    // progress must be greater than equal to last progress
    let progressValid = true;
    if (roadmapNode.user._id === currentUser.id) {
      progressValid = dialogForm.progress >= roadmapNode.progressSelf;
    } else {
      progressValid = dialogForm.progress >= roadmapNode.progressOther;
    }
    return (
      // progressValid &&
      // dialogForm.progress >= 0 &&
      dialogForm.comment &&
      (!dialogForm.saveAsAssessment || (tags.length > 0 && description !== ""))
    );
  }

  function handleInputChange(e, isSlider = false, index, value) {
    //console.log("is slider", isSlider);
    // if (
    //   isSlider &&
    //   roadmapNode.indicatorsOfSuccess[index]["indicatorProgress"] <= value
    // ) {
    //   let temp = [...indicatorsOfSuccess];
    //   temp[index]["indicatorProgress"] = value;
    //   setIndicatorsOfSuccess(temp);
    // } else {
    switch (e.target.name) {
      case "saveAsAssessment":
        setSaveAsAseessment(e.target.checked);
        setDialogForm({ ...dialogForm, [e.target.name]: e.target.checked });
        break;
      default:
        setDialogForm({ ...dialogForm, [e.target.name]: e.target.value });
    }
    // }

    // if (e.target.name === "saveAsAssessment") {
    //   setSaveAsAseessment(e.target.checked);
    //   setDialogForm({ ...dialogForm, [e.target.name]: e.target.checked });
    // } else {
    //   setDialogForm({ ...dialogForm, [e.target.name]: e.target.value });
    // }
  }

  async function handleSaveRoadmapChildNode() {
    //save form data,
    let data = {
      parent: roadmapNode._id,
      comment: dialogForm.comment,
      commenter: currentUser.id,
      commentType: roadmapNode.user._id === currentUser.id ? "self" : "other",
      // progress: dialogForm.progress, //old model
      indicatorsOfSuccess:
        roadmapChildNodeType === "indicatorUpdate" ? indicatorsOfSuccess : null,
      tags: saveAsAssessment ? tags : null,
      saveAsAssessment: saveAsAssessment,
    };

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      //check save or edit
      if (opType === "add") {
        const savedRoadmapChildNode =
          await RoadmapService.addRoadmapChildNodeByOrgIdAndRoadmapNodeId(
            currentUser.organisation.id,
            roadmapNode._id,
            data
          );
        let temp = [...roadmapChildNodes];
        temp.push(savedRoadmapChildNode);
        setRoadmapChildNodes(temp);

        //update parent roadmap node in roadmapnodes
        let temp2 = [...roadmapNodes];
        if (data.commentType === "self") {
          temp2[selectedRoadmapNodeIndex].progressSelf = data.progress;
        } else {
          temp2[selectedRoadmapNodeIndex].progressOther = data.progress;
        }
        setRoadmapNodes([...temp2]);

        handleCloseAddEditRoadmapChildNodeDialog();

        // setOpType("edit");
      } else {
        const updatedRoadmapChildNode =
          await RoadmapService.updateRoadmapChildNodeByOrgIdAndRoadmapNodeIdAndRoadmapChildNodeId(
            currentUser.organisation.id,
            roadmapNode._id,
            roadmapChildNode._id,
            data
          );

        //edit node is display
        let temp = [...roadmapChildNodes];
        temp[selectedChildNodeIndex] = updatedRoadmapChildNode;
        setRoadmapChildNodes([...temp]);

        //update parent roadmap node in roadmapnodes status only if  last node
        let temp2 = [...roadmapNodes];
        let sorted = roadmapChildNodes.filter((node) => {
          return node.commentType === data.commentType;
        });

        if (sorted.at(-1)._id === roadmapChildNode._id) {
          if (data.commentType === "self") {
            temp2[selectedRoadmapNodeIndex].progressSelf = data.progress;
          } else {
            temp2[selectedRoadmapNodeIndex].progressOther = data.progress;
          }
          setRoadmapNodes([...temp2]);
        }

        handleCloseAddEditRoadmapChildNodeDialog();
      }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Roadmap child node saved.",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  }

  return (
    <Fragment>
      <Dialog
        open={isAddEditRoadmapChidlNodeDialogOpen}
        onClose={handleCloseAddEditRoadmapChildNodeDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">
          {opType === "edit"
            ? "Edit Reflection/Comment"
            : "Add Reflection/Comment"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                multiline
                margin="dense"
                id="comment"
                name="comment"
                label="Reflection / Comment"
                type="text"
                value={dialogForm.comment}
                onChange={handleInputChange}
                variant="outlined"
                rows={6}
                fullWidth
              />
            </Grid>
            {roadmapChildNodeType === "indicatorUpdate" &&
              indicatorsOfSuccess && (
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      justifyContent: "space-between ",
                      fontWeight: "bold",
                    }}
                  >
                    <div style={{ flexBasis: "60%" }}>Indicator</div>
                    <div style={{ flexBasis: "30%" }}>Progress</div>
                  </div>
                  {indicatorsOfSuccess.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "10px",
                          justifyContent: "space-between ",
                        }}
                        key={index + "ind"}
                      >
                        <div style={{ flexBasis: "60%" }}>{item.indicator}</div>
                        <div style={{ flexBasis: "30%" }}>
                          <Slider
                            name="indicatorProgress"
                            defaultValue={0}
                            disabled={opType === "edit" ? true : false}
                            onChange={(e, value) => {
                              // handleInputChange(e, true, index, value);
                              if (
                                roadmapNode.indicatorsOfSuccess[index][
                                  "indicatorProgress"
                                ] <= value
                              ) {
                                let temp = [...indicatorsOfSuccess];
                                temp[index]["indicatorProgress"] = value;
                                setIndicatorsOfSuccess(temp);
                              }
                            }}
                            value={item.indicatorProgress}
                            // getAriaValueText={valuetext}
                            aria-labelledby="discrete-slider-always"
                            step={1}
                            min={0}
                            max={100}
                            valueLabelDisplay="auto"
                          />
                        </div>
                      </div>
                    );
                  })}
                </Grid>
              )}
            {/* <Grid item xs={12}>
              <Typography variant="subtitle2">Progress %</Typography>
              <div>
                <Slider
                  name="progress"
                  defaultValue={0}
                  onChange={(event, value) => {
                    console.log(event);
                    setDialogForm({ ...dialogForm, progress: value });
                  }}
                  value={dialogForm.progress}
                  // getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider-always"
                  step={1}
                  min={0}
                  max={100}
                  valueLabelDisplay="auto"
                />
              </div>
            </Grid> */}
            {currentUser.role !== "student" &&
            currentUser.id != roadmapNode.user._id ? (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={saveAsAssessment}
                      onChange={handleInputChange}
                      name="saveAsAssessment"
                      color="primary"
                    />
                  }
                  label="Save as Assessment"
                />
              </Grid>
            ) : null}
            {saveAsAssessment ? (
              <Fragment>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ width: "90%" }}
                  >
                    <InputLabel id="type-description">Description</InputLabel>
                    <Select
                      labelId="type-label"
                      id="type-select"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      label="Description"
                    >
                      <MenuItem value={"mainly positive"}>
                        Mainly Positive
                      </MenuItem>
                      <MenuItem value={"mainly negative"}>
                        Mainly Negative
                      </MenuItem>
                      <MenuItem value={"mixed"}>Mixed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <SearchTag tags={tags} setTags={setTags} />
                </Grid>
              </Fragment>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseAddEditRoadmapChildNodeDialog}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={handleSaveRoadmapChildNode}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AddEditRoadmapChildNodeDialog;
