/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import Cookies from "universal-cookie";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse, handleResponse2 } from "../helpers/handleResponse";

export const Auth = {
  currentSession,
  login,
  logout,
  resetPassword,
};

function login(username, password) {
  const requestOptions = RequestHelper.getPostRequestOptions({
    username,
    password,
  });

  return fetch(config.api_login, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details
      localStorage.setItem("currentUser", JSON.stringify(user));
      //currentUserSubject.next(user);
      return user;
    });
}

/*
 * Checks if current session is valid by trying to send bhubac cookie
 * Cookie sent by browser automatically
 */
function currentSession() {
  console.log("auth.jsx - check if session valid");
  //check if localstorage contains current user
  if (!localStorage.getItem("currentUser")) {
    console.log("auth.jsx - User not found in currenet session");
    return Promise.reject("User not found in currenet session");
  }

  console.log("auth.jsx - current user in localstorage. verify token call api");

  const requestOptions = RequestHelper.getPostRequestOptions();
  const request = new Request(config.api_verify_token, requestOptions);

  return fetch(request)
    .then(handleResponse2)
    .then((result1) => {
      if (result1["refreshToken"]) {
        console.log("auth.jsx - egetting refresh token");
        return refreshToken();
      } else {
        return "NO NEED TO REFRESH TOKEN";
      }
    })
    .then((result2) => {
      console.log("auth.jsx -  CURRENT SESSION RESULT 2", result2);
      return JSON.parse(localStorage.getItem("currentUser"));
    });
}

// function currentSession() {
//   //check if localstorage contains current user
//   if (!localStorage.getItem("currentUser")) {
//     return Promise.reject();
//   }

//   const requestOptions = RequestHelper.getPostRequestOptions();
//   const request = new Request(config.api_verify_token, requestOptions);

//   return fetch(request)
//     .then(handleResponse)
//     .then((result) => {
//       //console.log("dfdf:" + JSON.parse(localStorage.getItem("currentUser")));
//       return JSON.parse(localStorage.getItem("currentUser"));
//     });
// }

/*
 * refresh token; token in cookie
 */
function refreshToken() {
  console.log("auth.jsx - refreshToken(): trying to refresh toke");
  let user = JSON.parse(localStorage.getItem("currentUser"));
  const requestOptions = RequestHelper.getPostRequestOptions(user);
  return fetch(config.api_refresh_token, requestOptions)
    .then((response) => {
      if (!response.ok) {
        console.log("auth.jsx - refreshToken():  token could not be refreshed");
        return Promise.reject(
          new Error("Token could not be freshend", response.status)
        );
      }
      return response.json();
    })
    .then((result) => {
      console.log("auth.jsx - refreshToken():  saving new access token");
      //set new access token in localstorage
      let temp = JSON.parse(localStorage.getItem("currentUser"));
      temp["auth-token"] = result["data"]["auth-token"];
      localStorage.setItem("currentUser", JSON.stringify(temp));
      return "TOKEN REFRESHED SUCCESSFULLY"; //just return new access token and refresh token should be set
    })
    .catch((error) => {
      console.log("auth.jsx - token not refreshed", error);
      return Promise.reject(new Error("Token could not be freshend"));
    });
}
// function refreshToken() {
//   const requestOptions = RequestHelper.getPostRequestOptions();

//   return fetch(config.api_refresh_token, requestOptions)
//     .then(handleResponse)
//     .then((result) => {
//       return null; //just return new access token and refresh token should be set
//     });
// }

function resetPassword(body) {
  const requestOptions = RequestHelper.getPostRequestOptions(body);

  var api_url = config.api_reset_password;
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.message;
    });
}

function logout() {
  const cookie = new Cookies();
  localStorage.removeItem("currentUser");
  cookie.remove(config.bb_jwt_cookie_name);
}
