import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import SearchUser from "../../helperComponents/searchUser";
import { MenteeService } from "../../../services/mentee";

import { Factory } from "../../../helpers/factories";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterElements: {
    margin: theme.spacing(2),
    width: "90%",
  },
}));

export default function GroupRoadmapFilter({
  user,
  setUser,
  mentor,
  setMentor,
  toastOptions,
  setToastOptions,
  queryString,
  setQueryString,
  ...props
}) {
  const classes = useStyles();

  //current user from app context
  const { currentUser } = useAppContext();
  const location = useLocation();

  //year
  const [year, setYear] = React.useState("");

  //populating year dropdwon
  const currentYear = moment().year();
  const yearArray = Array.from(
    Array(currentYear - 2020),
    (_, i) => currentYear - i
  );

  //set query string year to current year on load
  useEffect(() => {
    //set initial query string
    setYear(currentYear);
    getGroupInformation();
  }, []);

  // get group information i.e. mentor
  async function getGroupInformation() {
    //make sure user is a mentee or a mentor
    try {
      let mentorMentee;
      if (currentUser.role === "student") {
        mentorMentee = await MenteeService.getMentorsByOrgIdAndUserId(
          currentUser.organisation.id,
          currentUser.id
        );
      }

      if (currentUser.role === "student" && mentorMentee.length == 0) {
        // saving message
        setToastOptions({
          ...toastOptions,
          open: true,
          message:
            "You have no group roadmaps. You must belong to a mentor-metee group.",
        });
      } else if (currentUser.role === "student" && mentorMentee.length > 0) {
        setUser(mentorMentee[0].mentor);
        setMentor(mentorMentee[0].mentor);
      } else {
        setUser({ ...currentUser, _id: currentUser.id });
      }
    } catch (err) {
      // saving message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "There was an error getting group information",
      });
    }
  }

  //query
  useEffect(() => {
    //set initial query string
    setQueryString({
      mentor: user && user._id,
      year: year,
    });
  }, [user, year]);

  //handle  year change
  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  return ["areaCoordinator", "headOfSchool", "domainHead", "teacher"].includes(
    currentUser.role
  ) ? (
    <Grid container justify="space-between" spacing={3}>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ backgroundColor: "white", borderRadius: "5px" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SearchUser
              user={user}
              setUser={setUser}
              //   role={"teacher, areaCoordinator, domainHead,headOfSchool"}
              style={{ display: "flex" }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%" }}
            >
              <InputLabel id="type-description">Year</InputLabel>
              <Select
                labelId="year"
                id="year"
                name="year"
                value={year}
                onChange={handleYearChange}
                label="Year"
              >
                {yearArray.map((year) => (
                  <MenuItem value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
}
