import React from "react";
import { Switch, Route } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";

import RouteAuthHOC from "../helperComponents/routeAuthHOC";
import AnnouncementSchoolAdmin from "./pagesSchoolAdmin/announcementSchoolAdmin";
import MyDetails from "./pages/myDetails";
import Announcement from "./pages/announcement";
import Assessment from "./pagesTeacher/assessments";
import AssessmentSummaries from "./assessmentSummary/assessmentSummaries";
import AssessmentsSnapshot from "./pagesTeacher/assessmentsSnapshot";
import FitnessTest from "./fitnessTest/fitnessTest";
import BBChatbot from "./bbChatbot";

import Users from "./orgDirectory/users";
import Absentees from "./absentees/absentees";

import ResetUserPassword from "./orgDirectory/resetUserPassword";
import BulkUpdateStudent from "./orgDirectory/bulkUpdateStudent";

import Report from "./pagesReports/reports";
import Appraisals from "./appraisals/appraisals";
import LessonPlans from "./lessonPlans/lessonPlans";

import RoadmapMain from "./roadmaps/roadmapMain";
import DomainRoadmap from "./roadmapDomains/domainRoadmap";
import FiveAreasRoadmap from "./roadmapFiveAreas/fiveAreasRoadmap";
import GroupRoadmap from "./roadmapGroups/groupRoadmap";
import SchoolRoadmap from "./roadmapsSchool/schoolRoadmap";

import MenteesPersonal from "./mentorMentees/menteesPersonal";
import MenteesAll from "./mentorMentees/menteesAll";

import StudentProfile from "./learner/studentProfile";
import TeacherProfile from "./learner/teacherProfile";
import RoadmapTracker from "./learner/roadmapTracker/roadmapTracker";

import Dashboard from "./dashboard";

import TimeTableCurrent from "./timetable/timetableCurrent";
import ManageTimeTable from "./timetable/manageTimeTable";

import AddEditOrganisation from "./organisation/addEditOrganisation";
import Organisations from "./organisation/organisations";
import Affiliations from "./organisation/affiliations";
import Portraits from "./portraits/portraits";
import ResetAdminPasswords from "./organisation/resetAdminPasswords";

import LeResources from "./leResources/leResources";

import ErrorPageNotFound from "../errorPages/errorPageNotFound";
import ErrorPageComingSoon from "../errorPages/errorPageComingSoon";
import ResetPassword from "./settings/resetPassword";
import ReviewMain from "./review/reviewMain";

function RoutesDashboard() {
  const { currentUser } = useAppContext();

  return (
    <Switch>
      {/* <Route exact path="/profile" component={Profile} /> */}
      <Route exact path="/myDetails" component={RouteAuthHOC(MyDetails)} />
      <Route exact path="/bbChatbot" component={RouteAuthHOC(BBChatbot)} />
      {/* <Route
        exact
        path="/roadmap/:roadmapNodeId"
        component={RouteAuthHOC(ViewRoadmapDetails)}
      /> */}
      <Route exact path="/roadmap" component={RouteAuthHOC(RoadmapMain)} />
      <Route exact path="/roadmaps" component={RouteAuthHOC(RoadmapMain)} />
      <Route
        exact
        path="/domainRoadmap"
        component={RouteAuthHOC(DomainRoadmap)}
      />
      <Route
        exact
        path="/fiveAreasRoadmap"
        component={RouteAuthHOC(FiveAreasRoadmap)}
      />
      <Route
        exact
        path="/groupRoadmap"
        component={RouteAuthHOC(GroupRoadmap)}
      />
      <Route
        exact
        path="/schoolRoadmap"
        component={RouteAuthHOC(SchoolRoadmap)}
      />
      <Route exact path="/appraisals" component={RouteAuthHOC(Appraisals)} />
      <Route
        exact
        path="/resetPassword"
        component={RouteAuthHOC(ResetPassword)}
      />
      <Route exact path="/users" component={RouteAuthHOC(Users)} />
      <Route exact path="/absentees" component={RouteAuthHOC(Absentees)} />
      <Route
        exact
        path="/resetUserPassword"
        component={RouteAuthHOC(ResetUserPassword)}
      />
      <Route
        exact
        path="/bulkUpdateStudent"
        component={RouteAuthHOC(BulkUpdateStudent)}
      />
      <Route exact path="/lessonPlans" component={RouteAuthHOC(LessonPlans)} />
      <Route
        exact
        path="/studentProfile"
        component={RouteAuthHOC(StudentProfile)}
      />
      <Route
        exact
        path="/teacherProfile"
        component={RouteAuthHOC(TeacherProfile)}
      />
      <Route
        exact
        path="/roadmapTracker"
        component={RouteAuthHOC(RoadmapTracker)}
      />
      <Route exact path="/mentees" component={RouteAuthHOC(MenteesPersonal)} />
      <Route exact path="/mentormentees" component={RouteAuthHOC(MenteesAll)} />
      <Route exact path="/assessments" component={RouteAuthHOC(Assessment)} />
      <Route exact path="/fitnessTest" component={RouteAuthHOC(FitnessTest)} />
      <Route exact path="/reviewMain" component={RouteAuthHOC(ReviewMain)} />
      <Route
        exact
        path="/assessmentSummaries"
        component={RouteAuthHOC(AssessmentSummaries)}
      />
      <Route exact path="/reports" component={RouteAuthHOC(Report)} />

      <Route
        exact
        path="/assessmentsSnapshot"
        component={(() => {
          if (currentUser.organisation.id === "5fdc48c1097684456369ec97") {
            return AssessmentsSnapshot;
          } else {
            return ErrorPageComingSoon;
          }
        })()}
        // component={AssessmentsSnapshot}
      />
      <Route
        exact
        path="/timetable"
        component={RouteAuthHOC(TimeTableCurrent)}
      />

      <Route
        exact
        path="/manageTimeTable"
        component={RouteAuthHOC(ManageTimeTable)}
      />

      {/* <Route path="/announcements" component={Announcement} /> */}
      <Route
        path="/announcements"
        component={(() => {
          if (currentUser.role == "schoolAdmin") {
            return RouteAuthHOC(AnnouncementSchoolAdmin);
          } else {
            return RouteAuthHOC(Announcement);
          }
        })()}
      />

      {/* Organisation */}
      <Route
        exact
        path="/addEditOrganisation"
        component={RouteAuthHOC(AddEditOrganisation)}
      />
      <Route
        exact
        path="/organisations"
        component={RouteAuthHOC(Organisations)}
      />

      <Route
        exact
        path="/affiliations"
        component={RouteAuthHOC(Affiliations)}
      />
      <Route exact path="/portraits" component={RouteAuthHOC(Portraits)} />
      <Route
        exact
        path="/resetAdminPasswords"
        component={RouteAuthHOC(ResetAdminPasswords)}
      />
      {/* LE Resources */}
      <Route
        exact
        path="/learningResources"
        component={RouteAuthHOC(LeResources)}
      />

      <Route exact path="/" component={Dashboard} />
      <Route component={ErrorPageNotFound} />
    </Switch>
  );
}

export default React.memo(RoutesDashboard);
