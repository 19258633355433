import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  centerText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "70vh",
  },
}));

export default function ErrorPageNotFound() {
  const classes = useStyles();

  return (
    <div className={classes.centerText}>
      <div>
        <Typography variant="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="h6" gutterBottom>
          Page not found
        </Typography>
      </div>
    </div>
  );
}
