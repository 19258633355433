import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const UserService = {
  getUserByOrgIdAndUserId,
  getUserByOrgId,
  getUserAdmins,
  addUserByOrgId,
  uploadCSVFile,
  bulkUpdateStudentGradeSection,
  updateUserByOrgIdAndUserId,
  updateUserProfileByOrgIdAndUserId,
  resetUserPasswordByOrgIdAndUserId,
};

function getUserByOrgIdAndUserId(userId, orgId) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_user_by_orgId_and_userId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    userId: userId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.user;
    });
}

function getUserByOrgId(orgId, options = {}) {
  // console.log("GETTING USER BY ORG ID SERVICE")
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_user_by_orgId;

  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.users;
    });
}

/*
 * For BBAdmin, get admins for orgs
 */
function getUserAdmins(options = {}) {
  // console.log("GETTING USER BY ORG ID SERVICE")
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_user_admins;

  api_url =
    RequestHelper.replaceParams(api_url, {}) +
    RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.users;
    });
}

function addUserByOrgId(orgId, user) {
  const requestOptions = RequestHelper.getPostRequestOptions(user);

  var api_url = config.api_user_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.user;
    });
}

function uploadCSVFile(orgId, csvFile) {
  const requestOptions = RequestHelper.getPostRequestOptionsFileUpload(csvFile);

  var api_url = config.api_user_by_orgId_upload_csv;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function updateUserByOrgIdAndUserId(orgId, userId, user) {
  const requestOptions = RequestHelper.getPutRequestOptions(user);
  var api_url = config.api_user_by_orgId_and_userId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    userId: userId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.user;
    });
}

function bulkUpdateStudentGradeSection(orgId, data) {
  const requestOptions = RequestHelper.getPutRequestOptions(data);
  var api_url = config.api_user_by_orgId_bulk_update_student_grade_section;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    students: data.students,
    grade: data.grade,
    section: data.section,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.message;
    });
}

function updateUserProfileByOrgIdAndUserId(orgId, userId, user) {
  const requestOptions = RequestHelper.getPutRequestOptions(user);
  var api_url = config.api_user_profile_by_orgId_and_userId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    userId: userId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.user;
    });
}

function resetUserPasswordByOrgIdAndUserId(orgId, userId, data) {
  const requestOptions = RequestHelper.getPutRequestOptions(data);
  var api_url = config.api_reset_user_password_by_orgId_and_userId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    userId: userId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.message;
    });
}
