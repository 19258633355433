import { React, useEffect } from "react";
import { HelperService } from "../../../services/helper";

import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import { Fragment } from "react";
import moment from "moment";

import fontJomo from "../../../assets/fonts/jomolhari.ttf";
import fontUchen from "../../../assets/fonts/DDC_Uchen.ttf";

//endless image
import endlessKnot from "../../../assets/images/endlessKnot.png";

function TimeTablePDF({
  startDate,
  timePeriods,
  timetable,
  value,
  user,
  domainArea,
  grade,
}) {
  //les view

  const generateLeView = (slot, slotIndex, day, dayIndex, lastRow) => {
    //previous le to prevent duplicates from priting
    let prevLe = null;
    return (
      <View
        style={(() => {
          //if last row
          if (lastRow) {
            let a = styles.dayBorderTopLeftRightBottom;
            let b = styles.dayColor;
            //for background color
            if (dayIndex % 2 == 0) {
              a = {
                ...a,
                ...b,
              };
            }
            return a;
          }
          //if not last row check
          if (slot.days.length == dayIndex + 1) {
            let a = styles.dayBorderTopLeftRight;
            let b = styles.dayColor;
            //for background color
            if (dayIndex % 2 == 0) {
              a = {
                ...a,
                ...b,
              };
            }
            return a;
          } else {
            let a = styles.dayBorderTopLeft;
            let b = styles.dayColor;
            //for background color
            if (dayIndex % 2 == 0) {
              a = {
                ...a,
                ...b,
              };
            }
            return a;
          }
        })()}
      >
        {day.les.map((learnExp, leIndex) => {
          //check whether to show le
          function showLE() {
            let a =
              user != null && user._id !== "" && learnExp.teacher
                ? user._id == learnExp.teacher._id
                  ? true
                  : false
                : true;
            let b =
              domainArea != null && domainArea._id !== ""
                ? learnExp.domainArea !== null &&
                  domainArea._id == learnExp.domainArea._id
                  ? true
                  : false
                : true;

            let c =
              grade !== "All" ? (grade == learnExp.grade ? true : false) : true;

            let d = learnExp.isForAllGrades ? true : false;

            return (a && b && c) || d;
          }
          //check whether previous le is same; to prevent duplicates like g1Aes78, Sports; ls9 also duplicates but must be shown
          function checkPrevious() {
            if (
              learnExp.domainArea &&
              learnExp.domainArea.name === "lifeScience"
            ) {
              return true;
            }
            if (prevLe == null) return true;
            return prevLe.type === "le" &&
              learnExp.type === "le" &&
              prevLe.group === learnExp.group
              ? false
              : true;
          }
          //
          if (showLE() && checkPrevious()) {
            prevLe = learnExp;
            return (
              <View
                style={{
                  border: "1px solid #b3adad",
                  color: "black ",
                  padding: "2px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inline-block",
                  margin: "2px 2px",
                  borderRadius: "4px",
                  minWidth: "50px",
                }}
              >
                <Text style={styles.leText}>
                  {(() => {
                    let temp = "";
                    if (learnExp.type === "le") {
                      temp = temp + learnExp.group;
                    } else {
                      temp = temp + learnExp.title;
                    }
                    if (learnExp.teacher) {
                      temp =
                        temp +
                        "\n" +
                        learnExp.teacher.firstName.substring(0, 3) +
                        learnExp.teacher.lastName.substring(0, 3);
                    }

                    if (learnExp.location) {
                      temp = temp + ", " + learnExp.location.toUpperCase();
                    }
                    return temp;
                  })()}
                  {/* {learnExp.type === "le" ? learnExp.group : learnExp.title} */}
                </Text>
              </View>
            );
          }
        })}
      </View>
    );
  };
  // Create Document Component
  const MyDocument = () => (
    <Document>
      {/* timetable */}
      <Page size="A4" orientation="portrait" style={styles.body}>
        {/* //title */}
        <View style={styles.titleRow}>
          <Text style={styles.titleText}>
            {grade != "All" ? "Year " + grade + " Timetable" : "Timtable"}
          </Text>
        </View>
        {/* header */}
        <View style={styles.mainRow}>
          <View style={styles.date}>
            <Text style={styles.dateText}>Slots</Text>
          </View>
          <View style={styles.date}>
            <Text style={styles.dateText}>
              {moment(startDate)
                .add(value * 7 + 0, "days")
                .format("ddd, MMM-DD")}
            </Text>
          </View>
          <View style={styles.date}>
            <Text style={styles.dateText}>
              {moment(startDate)
                .add(value * 7 + 1, "days")
                .format("ddd, MMM-DD")}
            </Text>
          </View>
          <View style={styles.date}>
            <Text style={styles.dateText}>
              {moment(startDate)
                .add(value * 7 + 2, "days")
                .format("ddd, MMM-DD")}
            </Text>
          </View>
          <View style={styles.date}>
            <Text style={styles.dateText}>
              {moment(startDate)
                .add(value * 7 + 3, "days")
                .format("ddd, MMM-DD")}
            </Text>
          </View>
          <View style={styles.date}>
            <Text style={styles.dateText}>
              {moment(startDate)
                .add(value * 7 + 4, "days")
                .format("ddd, MMM-DD")}
            </Text>
          </View>
          <View style={styles.date}>
            <Text style={styles.dateText}>
              {moment(startDate)
                .add(value * 7 + 5, "days")
                .format("ddd, MMM-DD")}
            </Text>
          </View>
          <View style={styles.dateRightBorder}>
            <Text style={styles.dateText}>
              {moment(startDate)
                .add(value * 7 + 6, "days")
                .format("ddd, MMM-DD")}
            </Text>
          </View>
        </View>
        {timetable.weeks[value].slots.map((slot, slotIndex) => (
          <>
            <View style={styles.mainRow}>
              <View
                style={(() =>
                  timetable.weeks[value].slots.length == slotIndex + 1
                    ? styles.slotBorderTopLeftBottom
                    : styles.slotBorderTopLeft)()}
              >
                <Text style={styles.timePeriodsText}>
                  {timePeriods[slotIndex] ? timePeriods[slotIndex] : slotIndex}
                </Text>
              </View>
              {slot.days.map((day, dayIndex) =>
                generateLeView(
                  slot,
                  slotIndex,
                  day,
                  dayIndex,
                  timetable.weeks[value].slots.length == slotIndex + 1
                )
              )}
            </View>
          </>
        ))}
      </Page>
    </Document>
  );

  return <MyDocument />;
}

// Register font
Font.register({
  family: "jomo",
  src: fontJomo,
});

Font.register({
  family: "ddcuchen",
  src: fontUchen,
});

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

//styles

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  titleRow: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "4px",
  },
  mainRow: {
    display: "flex",
    flexDirection: "row",
  },
  date: {
    flexGrow: 1,
    flexBasis: 0,
    borderStyle: "solid",
    borderTop: "1",
    borderLeft: "1",
    borderRight: "0",
    borderBottom: "0",
    borderColor: "#dedbdb",
    backgroundColor: "rgb(245, 242, 224)",
    padding: "4",
  },
  dateRightBorder: {
    flexGrow: 1,
    flexBasis: 0,
    borderStyle: "solid",
    borderTop: "1",
    borderLeft: "1",
    borderRight: "1",
    borderBottom: "0",
    borderColor: "#dedbdb",
    backgroundColor: "rgb(245, 242, 224)",
    padding: "4",
  },
  slotBorderTopLeft: {
    flexGrow: 1,
    flexBasis: 0,
    borderStyle: "solid",
    borderTop: "1",
    borderLeft: "1",
    borderRight: "0",
    borderBottom: "0",
    borderColor: "#dedbdb",
    padding: "4",
  },
  slotBorderTopLeftBottom: {
    flexGrow: 1,
    flexBasis: 0,
    borderStyle: "solid",
    borderTop: "1",
    borderLeft: "1",
    borderRight: "1",
    borderBottom: "1",
    borderColor: "#dedbdb",
    padding: "4",
  },
  dayColor: {
    backgroundColor: "#ececec",
  },
  dayBorderTopLeft: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    flexBasis: 0,
    borderStyle: "solid",
    borderTop: "1",
    borderLeft: "1",
    borderRight: "0",
    borderBottom: "0",
    borderColor: "#dedbdb",
    padding: "4",
  },
  dayBorderTopLeftRight: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    flexBasis: 0,
    borderStyle: "solid",
    borderTop: "1",
    borderLeft: "1",
    borderRight: "1",
    borderBottom: "0",
    borderColor: "#dedbdb",
    padding: "4",
  },
  dayBorderTopLeftRightBottom: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    flexBasis: 0,
    borderStyle: "solid",
    borderTop: "1",
    borderLeft: "0",
    borderRight: "1",
    borderBottom: "1",
    borderColor: "#dedbdb",
    padding: "4",
  },
  titleText: {
    fontSize: "10",
  },
  leText: {
    fontSize: "7",
  },
  timePeriodsText: {
    fontSize: "6",
  },
  dateText: {
    fontSize: "8",
    fontWeight: "bold",
  },
});

export default TimeTablePDF;
