import { React, useEffect } from "react";
import { HelperService } from "../../../services/helper";

import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import { Fragment } from "react";

import fontJomo from "../../../assets/fonts/jomolhari.ttf";
import fontUchen from "../../../assets/fonts/DDC_Uchen.ttf";

//endless image
import endlessKnot from "../../../assets/images/endlessKnot.png";

function ReportPDF({
  report,
  childReports,
  hasOnlyLifeScience,
  imageDownloadOption,
}) {
  // regex to check if text other than english
  var english = /^[A-Za-z0-9\s\&:,"’\(\).!;\-\n']*$/;

  //state for student image and reflection image
  // const [studentImage, setStudentImage] = React.useState("");
  // const [reflectionImage, setReflectionImage] = React.useState("");

  useEffect(() => {}, [report]);

  async function downloadImageHelper(imageName) {
    let imgURL = "";
    try {
      switch (imageName) {
        case "student":
          imgURL = report.studentImageURL;
          break;
        case "reflection":
          imgURL = report.studentReflectionImageURL;
          break;
        case "orgLogo":
          imgURL = report.organisation.picURL;
          break;
        default:
          break;
      }
      let imgBlob = null;
      if (imageName === "orgLogo") {
        imgBlob = await HelperService.getPublicGoogleDriveImage(imgURL);
      } else if (imageDownloadOption === "frontend") {
        imgBlob = await HelperService.fetchGdriveImage(imgURL);
      } else {
        imgBlob = await HelperService.getPublicGoogleDriveImage(imgURL);
      }

      return imgBlob;
    } catch (err) {
      return Promise.reject(new Error("Error downloading image"));
    }
  }

  // Create Document Component
  const MyDocument = () => (
    <Document>
      {/* student image page */}
      <Page size="A4" orientation="portrait" style={styles.body}>
        <View style={styles.pageBordlerless}>
          <View style={styles.menteeImageSectionTop}>
            <View style={styles.menteeImageSectionTopChildLeft}>
              <Text style={styles.titleMentee}>
                {report.mentee.lastName.toLowerCase() === "n/a"
                  ? report.mentee.firstName
                  : report.mentee.firstName + " " + report.mentee.lastName}
              </Text>
            </View>
            <View style={styles.menteeImageSectionTopChildRight}>
              <Text style={styles.titleReportType}>
                {report.reportType + " Report "}
              </Text>
              <Text style={styles.titleReportYear}>{report.reportYear}</Text>
            </View>
          </View>
          <View style={styles.menteeImageSectionMiddle}>
            <View
              style={{
                width: "2%",
                backgroundImage: `url(${endlessKnot})`,
                backgroundRepeat: "repeat",
                backgroundColor: "#c1882f",
                height: "100%",
              }}
            ></View>
            <View
              style={{
                width: "90%",
                height: "100%",
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                marginLeft: "15px",
              }}
            >
              <Image
                style={styles.imageMentee}
                src={downloadImageHelper("student")}
              />
            </View>
          </View>
          <View style={styles.menteeImageSectionBottom}>
            <Text style={styles.titleCoverPageOrg}>
              {report.organisation.name}
            </Text>
          </View>
        </View>
      </Page>
      {/* student reflection image page */}
      <Page size="A4" orientation="portrait" style={styles.bodyDomain}>
        <Text style={styles.header}>{report.organisation.name}</Text>
        <View style={styles.viewBorderTop} fixed></View>
        <Text style={[styles.subtitle, { fontSize: 22, color: "#000" }]}>
          Student Reflection{" "}
        </Text>
        <View style={styles.menteeReflectionSectionBottom}>
          <Image
            style={styles.imageReflection}
            src={downloadImageHelper("reflection")}
          />
        </View>
        <View style={styles.viewBorderBottom} fixed></View>
        <Text style={styles.footer}>
          {report.reportType + " Report " + report.reportYear}
        </Text>
      </Page>
      {/* 
      five areas 
      */}
      <Page size="A4" orientation="portrait" style={styles.bodyDomain}>
        <Text style={styles.header} fixed>
          {report.organisation.name}
        </Text>
        <View style={styles.viewBorderTop} fixed></View>
        <Text
          style={[
            styles.subtitle,
            { fontSize: 24, color: "#000", marginTop: "-5px" },
          ]}
        >
          Five Areas of Development
        </Text>
        {Object.keys(childReports.fiveAreasReports).map((key, index) => {
          return (
            <Fragment>
              <Text style={styles.subtitle}>
                {(() => {
                  return key
                    .toLowerCase()
                    .split(" ")
                    .map(
                      (word) => word.charAt(0).toUpperCase() + word.substring(1)
                    )
                    .join(" ");
                })()}
              </Text>
              <Text
                style={(() => {
                  return english.test(
                    childReports.fiveAreasReports[key].comment
                  )
                    ? styles.text
                    : styles.textDzongkha;
                })()}
              >
                {childReports.fiveAreasReports[key].comment}
              </Text>
            </Fragment>
          );
        })}
        <View style={styles.viewBorderBottom} fixed></View>
        <Text style={styles.footer} fixed>
          {report.reportType + " Report " + report.reportYear}
        </Text>
      </Page>
      {/* 
      domains 
      */}
      <Page size="A4" orientation="portrait" style={styles.bodyDomain}>
        <Text style={styles.header} fixed>
          {report.organisation.name}
        </Text>
        <View style={styles.viewBorderTop} fixed></View>
        <Text
          style={[
            styles.subtitle,
            { fontSize: 24, color: "#000", marginTop: "-5px" },
          ]}
        >
          Domain Reports
        </Text>
        {Object.keys(childReports.domainReports).map((key, index) => {
          //check if individual life science report
          if (key === "Life Science" && !hasOnlyLifeScience > 0) {
            return (
              <Fragment>
                <Text style={styles.subtitle}>Life Science</Text>
                {Object.keys(childReports.domainReports[key]).map(
                  (subdomain, sindex) => {
                    return (
                      <Fragment>
                        <Text style={styles.subsubtitle}>
                          {(() => {
                            return childReports.domainReports[key][
                              subdomain
                            ].domainArea.displayName
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.substring(1)
                              )
                              .join(" ");
                          })()}
                        </Text>
                        <Text style={styles.text}>
                          {childReports.domainReports[key][subdomain].comment}
                        </Text>
                      </Fragment>
                    );
                  }
                )}
              </Fragment>
            );
          }
          //no individual life science report
          return (
            <Fragment>
              <Text
                style={(() => {
                  return key === "Dzongkha"
                    ? styles.textDzongkhaSubtitle
                    : styles.subtitle;
                })()}
              >
                {(() => {
                  return childReports.domainReports[key].domainArea.displayName
                    .toLowerCase()
                    .split(" ")
                    .map(
                      (word) => word.charAt(0).toUpperCase() + word.substring(1)
                    )
                    .join(" ");
                })()}
              </Text>
              <Text
                style={(() => {
                  return key === "Dzongkha" ? styles.textDzongkha : styles.text;
                })()}
              >
                {childReports.domainReports[key].comment}
              </Text>
            </Fragment>
          );
        })}
        <View style={styles.viewBorderBottom} fixed></View>
        <Text style={styles.footer} fixed>
          {report.reportType + " Report " + report.reportYear}
        </Text>
      </Page>
      {/* lifescience */}
      {/* {Object.keys(childReports.lifeScienceReports).map((key, index) => {
        return (
          <Page size="A4" orientation="portrait" style={styles.body} key={key}>
            <View style={styles.pageBorder}>
              {(() => {
                return index === 0 ? (
                  <Text style={styles.subtitle}>Life Science</Text>
                ) : null;
              })()}
              <Text style={styles.header}>{report.organisation.name}</Text>
              <Text style={styles.subsubtitle}>
                {(() => {
                  return childReports.lifeScienceReports[
                    key
                  ].domainArea.displayName
                    .toLowerCase()
                    .split(" ")
                    .map(
                      (word) => word.charAt(0).toUpperCase() + word.substring(1)
                    )
                    .join(" ");
                })()}
              </Text>
              <Text style={styles.text}>
                {childReports.lifeScienceReports[key].comment}
              </Text>
              <Text style={styles.footer}>
                {report.reportType + " Report " + report.reportYear}
              </Text>
            </View>
          </Page>
        );
      })} */}

      {/* mentor report */}
      <Page size="A4" orientation="portrait" style={styles.bodyDomain}>
        <Text style={styles.header} fixed>
          {report.organisation.name}
        </Text>
        <View style={styles.viewBorderTop} fixed></View>
        <Text style={[styles.subtitle, { fontSize: 24, color: "#000" }]}>
          From The Mentor
        </Text>
        <Text
          style={(() => {
            return english.test(childReports.mentorReport.comment)
              ? styles.text
              : styles.textDzongkha;
          })()}
        >
          {childReports.mentorReport.comment}
        </Text>
        <Text style={styles.footer}>
          {report.reportType + " Report " + report.reportYear}
        </Text>
        <View style={styles.viewBorderBottom} fixed></View>
        <Text style={styles.footer} fixed>
          {report.reportType + " Report " + report.reportYear}
        </Text>
      </Page>
      {/* last page */}
      <Page size="A4" orientation="portrait" style={styles.body}>
        <View style={styles.titleSection}>
          <View style={styles.titleSectionOrgLogo}>
            <Image
              style={styles.imageOrgLogo}
              src={downloadImageHelper("orgLogo")}
            />
          </View>
          <View style={styles.titleSectionChild}>
            <Text style={styles.titleContact}>{report.organisation.name}</Text>
            <Text style={styles.titleContact}>Bhutan Baccalaureate</Text>
            {/* <Text style={styles.titleContact}>Pangbisa</Text>
            <Text style={styles.titleContactURL}>www.dgi.edu.bt</Text> */}
            {/* <Text style={styles.titleOrganisation}>
              {report.organisation.address.country}
            </Text> */}
          </View>
        </View>
      </Page>
    </Document>
  );

  return <MyDocument />;
}

// Register font
Font.register({
  family: "jomo",
  src: fontJomo,
});

Font.register({
  family: "ddcuchen",
  src: fontUchen,
});

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

//styles

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  bodyDomain: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 50,
    position: "relative",
  },

  pageBorder: {
    borderTop: "1 solid #c9cccd",
    borderBottom: "1 solid #c9cccd  ",
    // borderTop: "1 solid #000",
    // borderBottom: "1 solid #000",
    height: "100%",
    paddingLeft: 15,
    paddingRight: 15,
  },
  viewBorderTop: {
    // borderTop: "1 solid #c9cccd",
    backgroundColor: "#c9cccd",
    // borderTop: "1 solid #000",
    // borderBottom: "1 solid #000",
    height: "1px",
    marginBottom: "15px",
  },
  viewBorderBottom: {
    // borderTop: "1 solid #c9cccd",
    backgroundColor: "#c9cccd",
    // borderTop: "1 solid #000",
    // borderBottom: "1 solid #000",
    height: "1px",
    marginTop: "15px",
    position: "absolute",
    width: "99%",
    bottom: 50,
    left: 50,
    right: 0,
  },

  pageBordlerless: {
    height: "100%",
    display: "flex",
    // justifyContent: "center",
    paddingLeft: 15,
    paddingRight: 15,
  },

  menteeImageSectionTop: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 15,
  },
  menteeImageSectionTopChildLeft: {
    width: "70%",
  },
  menteeImageSectionTopChildRight: {
    width: "30%",
    display: "flex",
    justifyContent: "flexEnd",
  },
  menteeImageSectionMiddle: {
    display: "flex",
    flexDirection: "row",
    height: "80%",
    alignItems: "center",
    marginTop: "15px",
    marginBottom: "15px",
  },
  menteeImageSectionBottom: {
    display: "flex",
    alignItems: "center",
  },
  menteeReflectionSectionBottom: {
    height: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  menteeReflectionPage: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },

  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  titleSectionOrgLogo: {
    display: "block",
    width: "100%",
    alignItems: "center",
  },
  titleSectionChild: {
    display: "block",
    height: "100",
    width: "400",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
    color: "#9F2525",
    padding: 15,
  },
  titleMentee: {
    fontSize: 24,
    // textAlign: "center",
    fontFamily: "Oswald",
    color: "#9F2525",
    // color: "black"
  },
  titleCoverPageOrg: {
    fontSize: 18,
    // textAlign: "center",
    fontFamily: "Oswald",
    color: "black", //"#c1882f",
  },
  titleReportType: {
    fontSize: 18,
    fontFamily: "Oswald",
    // color: "#9F2525",
    color: "black",
    display: "block",
    textAlign: "right",
  },
  titleReportYear: {
    fontSize: 16,
    // textAlign: "center",
    fontFamily: "Oswald",
    color: "#000",
    textAlign: "right",
  },
  titleOrganisation: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
    color: "#9F2525",
    padding: 15,
  },
  titleContact: {
    fontSize: 16,
    textAlign: "center",
    fontFamily: "Oswald",
    color: "#9F2525",
  },
  titleContactURL: {
    marginTop: "6px",
    fontSize: "12",
    textAlign: "center",
    color: "#0000FF",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    paddingTop: 12,
    paddingBottom: 12,
    fontFamily: "Oswald",
    color: "#c1882f",
  },
  subsubtitle: {
    fontSize: 16,
    paddingTop: 12,
    paddingBottom: 12,
    fontFamily: "Oswald",
    color: "#000",
  },
  text: {
    // margin: 12,
    fontSize: 14,
    textAlign: "justify",
    // fontFamily: "Times-Roman",
  },
  textDzongkha: {
    // margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "jomo",
    // fontFamily: "ddcuchen",
  },
  textDzongkhaSubtitle: {
    // margin: 12,
    fontSize: 24,
    textAlign: "justify",
    fontFamily: "jomo",
    color: "#c1882f",
    paddingTop: 12,
    paddingBottom: 12,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },

  imageOrgLogo: {
    height: "auto",
    width: "25%",
    marginVertical: 15,
    marginHorizontal: 100,
  },
  imageMentee: {
    width: "100%",
    height: "auto",
    // margin: 12,
  },
  imageReflection: {
    width: "100%",
    height: "auto",
    // margin: 12,
  },
  header: {
    fontSize: 10,
    marginBottom: 2,
    textAlign: "right",
    fontStyle: "italic",
    color: "#9F2525",
  },
  // header: {
  //   position: "absolute",
  //   fontSize: 10,
  //   top: -15,
  //   left: 0,
  //   right: 0,
  //   textAlign: "right",
  //   fontStyle: "italic",
  //   color: "#9F2525",
  // },
  // footer: {
  //   position: "absolute",
  //   fontSize: 10,
  //   bottom: -20,
  //   left: 0,
  //   right: 0,
  //   textAlign: "right",
  //   fontStyle: "italic",
  //   color: "#c1882f",
  // },
  footer: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 50,
    textAlign: "right",
    fontStyle: "italic",
    color: "#c1882f",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default ReportPDF;
