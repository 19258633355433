import * as dfd from "danfojs";

export default function prepareDataForRoadmapPieChart(tempRoadmapNodes) {
  let dfRoadMapPie = new dfd.DataFrame(tempRoadmapNodes);
  // dfRoadMapPie.print();

  let grpGoalPie = dfRoadMapPie.groupby(["progressSelf"]);
  let sub_df = dfRoadMapPie.iloc({
    rows: dfRoadMapPie["progressSelf"].eq(100),
  });
  // dfRoadMapPie.print();
  // sub_df.print();

  let data = [
    { name: "completed", value: sub_df.shape[0] },
    { name: "incomplete", value: dfRoadMapPie.shape[0] - sub_df.shape[0] },
  ];

  return { data: data, total: dfRoadMapPie.shape[0] };

  // setRoadmapPieData(data);
  // setTotalRoadmapGoals(dfRoadMapPie.shape[0]);

  // let summedGoalPie = grpGoalPie.col(["progressSelf"]).count();
  // summedGoalPie.print();
  // console.log(dfRoadMapPie[dfRoadMapPie["progressSelf"] == 100]);
}
