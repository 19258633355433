import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAppContext } from "../../libs/contextLib";
import { DomainAreaService } from "../../services/domainAreas";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { Factory } from "../../helpers/factories";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: "90%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SelectDomainArea({
  domainArea,
  setDomainArea,
  showAllOption,
  orgId,
  type,
  ...props
}) {
  const classes = useStyles();

  const { currentUser } = useAppContext();

  //autcomplete loading
  // const [loading, setLoading] = useState(true);

  // currently selected domain
  const [selectedDomainAreaId, setSelectedDomainAreaId] = useState("");

  //list of domain areas
  const [domainAreas, setDomainAreas] = React.useState([]);

  //pre-load domain/araes
  useEffect(() => {
    if (domainArea) {
      setSelectedDomainAreaId(domainArea._id);
    }
    //set initial query string
    // props.setQueryString({
    //   student: "",
    //   domainArea: "",
    //   type: "",
    //   from: "",
    //   to: "",
    // });

    (async () => {
      try {
        const data = await loadDomainAreas();
        setDomainAreas(data);
      } catch (err) {
        props.setToastOptions({
          ...props.toastOptions,
          open: true,
          message: "Error loading domains. Try refreshing the page.",
        });
      }
    })();
  }, []);

  async function loadDomainAreas() {
    //query options
    let queryOptions = {
      type: type,
    };
    try {
      const data = await DomainAreaService.getDomainAreasByOrgId(
        currentUser.organisation.id,
        queryOptions
      );
      return new Promise((resolve) => {
        resolve(data);
      });
    } catch (err) {
      return new Promise((reject) => {
        reject(err);
      });
    }
  }

  //handle domain / area change
  const handleDomainAreaChange = (e) => {
    // setSelectedDomainArea(e.target.value);
    if (e.target.value === "all") {
      setDomainArea(Factory.createDomainArea());
      setSelectedDomainAreaId("all");
    } else {
      let { domainAreaObj } = e.currentTarget.dataset;
      domainAreaObj = JSON.parse(domainAreaObj);
      setDomainArea(domainAreaObj);
      setSelectedDomainAreaId(domainAreaObj._id);
    }
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="domain-area-label">Domain / Area</InputLabel>
      <Select
        labelId="domain-area-label"
        id="demo-simple-select-outlined"
        value={selectedDomainAreaId}
        onChange={handleDomainAreaChange}
        label="Domain / Area"
      >
        {domainAreas.length
          ? (() => {
              let b = [];
              if (showAllOption) {
                b.push(
                  <MenuItem value="all" key="all">
                    All
                  </MenuItem>
                );
              }

              let c = b.concat(
                domainAreas.map((domainArea, index) => {
                  return (
                    <MenuItem
                      value={domainArea._id}
                      key={domainArea.name}
                      data-domain-area-obj={JSON.stringify(domainArea)}
                    >
                      {domainArea.displayName}
                    </MenuItem>
                  );
                })
              );
              return c;
            })()
          : (() => {
              return (
                <MenuItem value={""} key={"error"}>
                  {"Error Loading Domain / Areas"}
                </MenuItem>
              );
            })()}
      </Select>
    </FormControl>
    // <FormControl className={classes.formControl} variant="outlined">
    //   <InputLabel id="demo-simple-select-label">Domain/Area</InputLabel>
    //   <Select
    //     labelId="demo-simple-select-label"
    //     id="demo-simple-select"
    //     value={selectedDomainArea}
    //     onChange={handleDomainAreaChange}
    //   >
    //     {domainAreas.length
    //       ? (() => {
    //           let b = [];
    //           b.push(
    //             <MenuItem value="all" key="all">
    //               All
    //             </MenuItem>
    //           );
    //           let c = b.concat(
    //             domainAreas.map((domainArea, index) => {
    //               return (
    //                 <MenuItem value={domainArea._id} key={domainArea.name}>
    //                   {domainArea.displayName}
    //                 </MenuItem>
    //               );
    //             })
    //           );
    //           return c;
    //         })()
    //       : (() => {
    //           return (
    //             <MenuItem value={""} key={"error"}>
    //               {"Error Loading Domain / Areas"}
    //             </MenuItem>
    //           );
    //         })()}
    //   </Select>
    // </FormControl>
  );
}
