import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { useAppContext } from "../../../libs/contextLib";

import { DataGrid } from "@material-ui/data-grid";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import { makeStyles } from "@material-ui/core/styles";
import RoadmapsFilter from "./roadmapFilter";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FilterListIcon from "@material-ui/icons/FilterList";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Fab from "@material-ui/core/Fab";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ListIcon from "@material-ui/icons/List";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import AddEditRoadmapNodeDialog from "./addEditRoadmapNodeDialog";

//NEW Code

import AddEditRoadmapNodeDialogForStudent from "./addEditRoadmapNodeDialogForStudent";

import { Factory } from "../../../helpers/factories";
import { RoadmapService } from "../../../services/roadmap";
import moment from "moment";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ViewRoadmapDetails from "./viewRoadmapDetails";
import { Fragment } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import Toast from "../../helperComponents/toast";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  //   avatar: {
  //     backgroundColor: "#009be5",
  //   },
  contentWrapper: {
    margin: "40px 16px",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
  accordionFilter: {
    marginBottom: "5px",
    border: 0,
    // borderRadius: "8px",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  cardRoot: {
    minWidth: 190,
  },

  cardTitle: {
    fontSize: 14,
    fontWeight: "bold",
  },
  cardPos: {
    marginBottom: 12,
  },
  progress: {
    width: "100%",
    backgroundColor: "#e9ecef",
    borderRadius: ".25rem",
    height: "0.6rem",
    lineHeight: 0,
    fontSize: ".75rem",
    overflow: "hidden",
    marginBottom: "16px",
  },
  progressBarSelf: {
    height: "100%",
    // backgroundColor: "#007bff",
    backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  progressBarOther: {
    height: "100%",
    backgroundColor: "#5bc0de",
    // backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  fab: {
    margin: "0px",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
  },
  fabItem: {
    margin: "4px",
  },
  listCard: {
    boxShadow: "none",
    background: "transparent",
  },
  dataGrid: {
    flexGrow: 1,
    backgroundColor: "white",
  },

  cardWithTeacher: {
    border: "2px solid #39c0e6 !important",
  },

  cardInactive: {
    border: "1px solid #c9521a", // Light orange border for inactive cards
  },
  cardExpire: {
    border: "1px solid #c9521a", // Red border for expiring (warning) cards
  },
}));

function RoadmapMain(props) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  const location = useLocation();

  const { _id } = location.state || {};

  const [showLoader, setShowLoader] = React.useState(true);

  const [viewAsList, setViewAsList] = React.useState(false);

  const [roadmapChildNodes, setRoadmapChildNodes] = React.useState([]);
  //console.log("roadmapChildNodes", roadmapChildNodes);
  const [expireGoal, setExpireGoal] = useState([]);
  const [inactiveRoadmaps, setInactiveRoadmaps] = useState([]);

  //const test datammnn
  const [roadmapNode, setRoadmapNode] = React.useState(
    Factory.createRoadmapNode()
  );
  //console.log("roadmap", roadmapNode);
  const [selectedRoadmapNodeIndex, setSelectedRoadmapNodeIndex] =
    React.useState("");

  const [roadmapNodes, setRoadmapNodes] = React.useState([]);
  //onsole.log("roadmapNodes", roadmapNodes);

  const [hasChildNodes, setHasChildNodes] = React.useState(false);

  //data is loading
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  const [queryString, setQueryString] = React.useState({});

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 2000,
  });

  const [opType, setOpType] = React.useState("add");

  const [isAddEditRoadmapNodeDialogOpen, setIsAddEditRoadmapNodeDialogOpen] =
    React.useState(false);

  //new code for student adding dialog
  const [
    isAddEditRoadmapNodeDialogOpenForStudent,
    setIsAddEditRoadmapNodeDialogOpenForStudent,
  ] = React.useState(false);

  // change view
  const changeView = () => {
    setViewAsList(!viewAsList);
  };

  const [rows, setRows] = React.useState([]);

  //list view columns for data grid
  const columns = [
    // { field: "id", headerName: "ID", width: 70 },
    { field: "_id", headerName: "_id", width: 120, hide: "true" },
    {
      field: "user",
      headerName: "Name",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", marginRight: "10px" }}>
              <Avatar aria-label="recipe" className={classes.avatar}>
                {params.row.user.firstName[0]}
              </Avatar>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2">
                {params.row.user.firstName + " " + params.row.user.lastName}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      field: "domainAreaRole",
      headerName: "Details",
      width: 120,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal", fontWeight: "bold" }}>
            <Typography
              className={classes.cardTitle}
              color="primary"
              gutterBottom
            >
              {params.row.domainAreaRole.domainArea.displayName}
            </Typography>
            <Typography
              className={classes.cardTitle}
              color="primary"
              gutterBottom
            >
              {"(" + params.row.domainAreaRole.role + ")"}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal" }}>
            <span>
              {moment(params.row.date.dateCreated).format("DD/MM/YYYY") + " - "}
            </span>
            <br />
            <span>
              {moment(params.row.date.completionDate).format("DD/MM/YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      field: "goal",
      headerName: "Goal",
      width: 350,
      renderCell: (params) => {
        return (
          <div style={{ lineHeight: "normal", whiteSpace: "pre-wrap" }}>
            {params.row.goal}
          </div>
        );
      },
    },
    {
      field: "daysLeft",
      headerName: "Days Left",
      width: 100,
      renderCell: (params) => {
        return (
          <Typography
            className={classes.cardTitle}
            color={params.row.daysLeft <= 5 ? "secondary" : "primary"}
            gutterBottom
          >
            {params.row.daysLeft}
          </Typography>
        );
      },
    },
    {
      field: "progress",
      headerName: "Progress",
      width: 150,
      renderCell: (params) => {
        if (params.row.indicatorsOfSuccess) {
          return null;
        } else {
          return (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                lineHeight: "normal",
                width: "100%",
              }}
            >
              <div style={{ width: "100%", display: "block" }}>
                <p
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ float: "left" }}>Self</span>
                  <span style={{ float: "right" }}>
                    {params.row.progress.progressSelf + "%"}
                  </span>
                </p>
                <div className={classes.progress}>
                  <div
                    className={classes.progressBarSelf}
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width: `${params.row.progress.progressSelf}%`,
                    }}
                  ></div>
                </div>
              </div>
              <div style={{ width: "100%", display: "block" }}>
                <p
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ float: "left" }}>Other</span>
                  <span style={{ float: "right" }}>
                    {params.row.progress.progressOther + "%"}
                  </span>
                </p>
                <div className={classes.progress}>
                  <div
                    className={classes.progressBarOther}
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width: `${params.row.progress.progressOther}%`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          );
        }
      },
    },
    {
      field: "",
      headerName: "",
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = () => {
          setSelectedRoadmapNodeIndex(params.row.id);
          setRoadmapNode(params.row);
          setIsViewRoadmapDetailsDialogOpen(true);
        };
        return (
          <div>
            <Tooltip title="Details">
              <IconButton
                aria-label="edit"
                onClick={() => {
                  return onClick();
                }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  //close roadmap node dialog
  function handleCloseRoadmapNodeAddEditDialog() {
    setIsAddEditRoadmapNodeDialogOpen(false);
  }

  //new code////
  function handleCloseRoadmapNodeAddEditDialogForStudent() {
    setIsAddEditRoadmapNodeDialogOpenForStudent(false);
  }

  //roadmap details dialog
  const [isViewRoadmapDetailsDialogOpen, setIsViewRoadmapDetailsDialogOpen] =
    React.useState(false);
  const handleCloseViewRoadmapDetailsDialog = () => {
    setIsViewRoadmapDetailsDialogOpen(false);
  };

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  /*
  fetch roadmap nodes, pre-load verify token
  */

  useEffect(() => {
    //if path is personal roadmap, set query string user  to user id
    if (location.pathname === "/roadmap") {
      queryString.user = currentUser.id;
    }

    if (props.startFetchData) {
      onLoad();
    }
  }, [props.startFetchData, queryString]);
  async function onLoad() {
    try {
      // Fetch roadmap nodes by organisation ID and query string
      const roadmapNodesData = await RoadmapService.getRoadmapNodesByOrgId(
        currentUser.organisation.id,
        queryString
      );

      setRoadmapNodes(roadmapNodesData);

      if (roadmapNodesData.length > 0) {
        // Fetch child nodes for each roadmap node
        const roadmapChildNodesData = await Promise.all(
          roadmapNodesData.map(async (roadmapNode) => {
            const childNodes =
              await RoadmapService.getRoadmapChildNodeByOrgIdAndRoadmapNodeId(
                currentUser.organisation.id,
                roadmapNode._id
              );

            return {
              roadmapNodeId: roadmapNode._id,
              goal: roadmapNode.goal,
              childNodes: childNodes || [],
            };
          })
        );

        setRoadmapChildNodes(roadmapChildNodesData);
      }

      setIsDataLoading(false);
    } catch (err) {
      setErrorLoadingPage(true);
    } finally {
      // Hide loader after everything is done
      setShowLoader(false);
    }
  }
  const [roadmapStatuses, setRoadmapStatuses] = useState([]);

  const checkRoadmapStatuses = () => {
    const now = moment();
    const thresholdDate = now.subtract(7, "days"); // Adjusted to 7 days for inactivity check

    const updatedStatuses = roadmapNodes.map((node) => {
      const childNodes =
        roadmapChildNodes.find(
          (childNode) => childNode.roadmapNodeId === node._id
        )?.childNodes || [];

      // Check if all indicators of success are fully completed
      const isMainNodeFullyCompleted = node.indicatorsOfSuccess?.every(
        (indicator) => indicator.indicatorProgress === 100
      );

      // Skip status update for fully completed roadmaps
      if (isMainNodeFullyCompleted) {
        return {
          ...node,
          status: "normal",
        };
      }

      let status = "normal";

      // Check for roadmap expiration
      let daysLeft = moment(node.completionDate).diff(moment(), "days");

      if (daysLeft <= 0) {
        // Roadmap is expired
        status = "Expired";
      } else if (daysLeft <= 5) {
        // Roadmap is expiring soon (less than 6 days)
        status = "Expiring";
      }

      const allLastUpdatedDates = childNodes.map((childNode) =>
        moment(childNode.lastUpdated)
      );

      if (allLastUpdatedDates.length > 0) {
        const mostRecentUpdateDate = allLastUpdatedDates.reduce(
          (mostRecent, date) => (date.isAfter(mostRecent) ? date : mostRecent),
          moment("2024-01-01")
        );

        const isRoadmapInactive = mostRecentUpdateDate.isBefore(thresholdDate);

        if (isRoadmapInactive) {
          status = status === "normal" ? "Inactive" : status;
        }
      }

      return {
        ...node,
        status,
      };
    });

    // Sort roadmaps by status, with "Expired" first, then "Expiring Soon", then "Inactive", and "normal" last
    const sortedStatuses = updatedStatuses.sort((a, b) => {
      const statusOrder = { Expired: 1, Expiring: 2, Inactive: 3, normal: 4 };
      return statusOrder[a.status] - statusOrder[b.status];
    });

    setRoadmapStatuses(sortedStatuses);
  };

  useEffect(() => {
    checkRoadmapStatuses();
  }, [roadmapNodes, roadmapChildNodes]);

  // async function onLoad() {
  //   try {
  //     setRoadmapNodes(
  //       await RoadmapService.getRoadmapNodesByOrgId(
  //         currentUser.organisation.id,
  //         queryString
  //       )
  //     );

  //     setIsDataLoading(false);
  //   } catch (err) {
  //     setErrorLoadingPage(true);
  //   }
  //   //hide loader
  //   setShowLoader(false);
  // }

  // fetching user roadmap for particular student
  //i added
  // useEffect(() => {
  //   async function fetchRoadmap() {
  //     try {
  //       const roadmapData = await RoadmapService.addRoadmapNodeByOrgId(
  //         (queryString = menteeId)
  //       );
  //       setRoadmapNodes(roadmapData);
  //     } catch (error) {
  //       console.error("Error fetchng roadmap data");
  //     }
  //   }
  //   fetchRoadmap();
  // }, [menteeId]);

  useEffect(() => {
    //for data grid
    let temp = [];
    roadmapNodes.forEach((roadmapNode, index) => {
      roadmapNode["id"] = index;
      roadmapNode["progress"] = {
        progressSelf: roadmapNode.progressSelf,
        progressOther: roadmapNode.progressOther,
      };
      roadmapNode["domainAreaRole"] = {
        domainArea: roadmapNode.domainArea,
        role: roadmapNode.role,
      };
      roadmapNode["date"] = {
        dateCreated: roadmapNode.dateCreated,
        completionDate: roadmapNode.completionDate,
      };
      roadmapNode["daysLeft"] = (() => {
        let daysRem = moment(roadmapNode.completionDate).diff(moment(), "days");
        return (daysRem = daysRem < 0 ? 0 : daysRem);
      })();
      temp.push(roadmapNode);
    });
    setRows([...temp]);
  }, [roadmapNodes]);

  const listView = () => {
    return rows.length > 0 ? (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <DataGrid
            className={classes.dataGrid}
            rows={rows}
            rowHeight={150}
            columns={columns}
            pageSize={20}
            autoHeight={true}
            loading={isDataLoading}
            {...props}
          />
        </Grid>
      </Grid>
    ) : null;
  };

  const gridView = () => {
    return (
      <Grid container spacing={3}>
        {roadmapStatuses.map((roadmapNode, index) => {
          // Check if the roadmapNode has a valid creator
          const hasCreator =
            roadmapNode.createdBy &&
            typeof roadmapNode.createdBy === "object" &&
            roadmapNode.createdBy.firstName &&
            roadmapNode.createdBy.firstName.trim() !== "";
          // console.log(`RoadmapNode ${index}`, roadmapNode);
          // console.log(`Has creator: ${hasCreator}`);

          // Define a class name based on whether there is a creator or not
          const cardClassName = `${classes.cardRoot} ${
            roadmapNode.status === "Inactive"
              ? classes.cardInactive
              : roadmapNode.status === "Expired"
              ? classes.cardExpire
              : roadmapNode.status === "Expiring"
              ? classes.cardExpire
              : hasCreator
              ? classes.cardWithTeacher
              : ""
          }`;

          return (
            <Grid item xs={12} sm={6} md={3} key={"roadmapNode" + index}>
              <Card className={cardClassName}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                      {roadmapNode.user.firstName[0]}
                    </Avatar>
                  }
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        setSelectedRoadmapNodeIndex(index);
                        setRoadmapNode(roadmapNode);
                        setIsViewRoadmapDetailsDialogOpen(true);
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  }
                  title={(() => {
                    const fullName =
                      roadmapNode.user.firstName +
                      " " +
                      roadmapNode.user.lastName;
                    return fullName.length > 16
                      ? fullName.slice(0, 16)
                      : fullName;
                  })()}
                  subheader={
                    <div>
                      {moment(roadmapNode.dateCreated).format("DD/MM/YYYY")}
                      {hasCreator && (
                        <>
                          <br />
                          Created by: {roadmapNode.createdBy.firstName}{" "}
                          {roadmapNode.createdBy.lastName}
                        </>
                      )}
                    </div>
                  }
                />

                <CardContent style={{ paddingBottom: 0 }}>
                  <Typography
                    className={classes.cardTitle}
                    color="primary"
                    gutterBottom
                  >
                    {roadmapNode.domainArea.displayName +
                      " (" +
                      roadmapNode.role +
                      ") "}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {roadmapNode.goal.length > 200
                      ? roadmapNode.goal.slice(0, 197) + "..."
                      : roadmapNode.goal.slice(0, 197)}
                  </Typography>
                  <div style={{ marginTop: 15 }}>
                    <p style={{ marginBottom: 0, marginTop: 0 }}>
                      <span style={{ fontWeight: "bold" }}>
                        Completion Date
                      </span>
                      <span style={{ float: "right" }}>
                        {moment(roadmapNode.completionDate).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </p>
                    <p style={{ marginBottom: 0, marginTop: 0 }}>
                      <span style={{ fontWeight: "bold" }}>Days Left</span>
                      <span style={{ float: "right" }}>
                        {(() => {
                          let daysRem = moment(roadmapNode.completionDate).diff(
                            moment(),
                            "days"
                          );
                          daysRem = daysRem < 0 ? 0 : daysRem;
                          if (daysRem <= 5) {
                            return (
                              <span style={{ color: "red" }}>{daysRem}</span>
                            );
                          } else {
                            return <span>{daysRem}</span>;
                          }
                        })()}
                        {/* {moment(roadmapNode.completionDate).diff(
                          moment(),
                          "days"
                        )} */}
                      </span>

                      {/* show the status */}
                      {roadmapNode.status !== "normal" && (
                        <p style={{ marginBottom: 0, marginTop: 0 }}>
                          <span style={{ fontWeight: "bold" }}>Status</span>
                          <span
                            style={{
                              float: "right",
                              color:
                                roadmapNode.status === "Inactive"
                                  ? "#c40a0a"
                                  : roadmapNode.status === "Expired"
                                  ? "#c40a0a"
                                  : roadmapNode.status === "Expiring"
                                  ? "#c40a0a"
                                  : "inherit",
                            }}
                          >
                            {roadmapNode.status}
                          </span>
                        </p>
                      )}
                    </p>
                  </div>
                </CardContent>
                <CardActions style={{ paddingTop: 0 }}>
                  <Grid container spacing={3}>
                    {roadmapNode.indicatorsOfSuccess ? (
                      <Grid item xs={12}>
                        <div style={{ margin: "8px" }}>
                          <p
                            style={{ fontWeight: "bold", marginBottom: "5px" }}
                          >
                            Progress Average
                            <span style={{ float: "right" }}>
                              {Math.round(
                                roadmapNode.indicatorsOfSuccess.reduce(
                                  (result, item) =>
                                    result + item.indicatorProgress,
                                  0
                                ) / roadmapNode.indicatorsOfSuccess.length
                              )}
                              %
                            </span>
                          </p>

                          <div className={classes.progress}>
                            <div
                              className={classes.progressBarSelf}
                              role="progressbar"
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: `${
                                  roadmapNode.indicatorsOfSuccess.reduce(
                                    (result, item) => {
                                      return result + item.indicatorProgress;
                                    },
                                    0
                                  ) / roadmapNode.indicatorsOfSuccess.length
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <div style={{ margin: "8px" }}>
                          <p
                            style={{ fontWeight: "bold", marginBottom: "5px" }}
                          >
                            Progress Self
                            <span style={{ float: "right" }}>
                              {roadmapNode.progressSelf + "%"}
                            </span>
                          </p>
                          <div className={classes.progress}>
                            <div
                              className={classes.progressBarSelf}
                              role="progressbar"
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: `${roadmapNode.progressSelf}%` }}
                            ></div>
                          </div>
                        </div>
                        <div style={{ margin: "8px" }}>
                          <p
                            style={{ fontWeight: "bold", marginBottom: "5px" }}
                          >
                            Progress Reviewers
                            <span style={{ float: "right" }}>
                              {roadmapNode.progressOther + "%"}
                            </span>
                          </p>
                          <div className={classes.progress}>
                            <div
                              className={classes.progressBarOther}
                              role="progressbar"
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: `${roadmapNode.progressOther}%` }}
                            ></div>
                          </div>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const roadmapNodesContainer = (
    <Fragment>
      {/* Your existing components */}

      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />
      <ViewRoadmapDetails
        opTypeRoadmapNode={opType}
        setOpTypeRoadmapNode={setOpType}
        roadmapNode={roadmapNode}
        setRoadmapNode={setRoadmapNode}
        roadmapNodes={roadmapNodes}
        setHasChildNodes={setHasChildNodes}
        setRoadmapNodes={setRoadmapNodes}
        selectedRoadmapNodeIndex={selectedRoadmapNodeIndex}
        setSelectedRoadmapNodeIndex={setSelectedRoadmapNodeIndex}
        isViewRoadmapDetailsDialogOpen={isViewRoadmapDetailsDialogOpen}
        handleCloseViewRoadmapDetailsDialog={
          handleCloseViewRoadmapDetailsDialog
        }
        setIsAddEditRoadmapNodeDialogOpen={setIsAddEditRoadmapNodeDialogOpen}
        handleCloseRoadmapNodeAddEditDialog={
          handleCloseRoadmapNodeAddEditDialog
        }
        toastOptions={toastOptions}
        setToastOptions={setToastOptions}
        handleCloseToast={handleCloseToast}
        // new code
        setIsAddEditRoadmapNodeDialogOpenForStudent={
          setIsAddEditRoadmapNodeDialogOpenForStudent
        }
        isAddEditRoadmapNodeDialogOpenForStudent={
          isAddEditRoadmapNodeDialogOpenForStudent
        }
        handleCloseRoadmapNodeAddEditDialogForStudent={
          handleCloseRoadmapNodeAddEditDialogForStudent
        }
      />

      <div className={classes.root}>
        <AddEditRoadmapNodeDialog
          toastOptions={toastOptions}
          setToastOptions={setToastOptions}
          handleCloseToast={handleCloseToast}
          opType={opType}
          setOpType={setOpType}
          roadmapNode={roadmapNode}
          setRoadmapNode={setRoadmapNode}
          hasChildNodes={hasChildNodes}
          selectedRoadmapNodeIndex={selectedRoadmapNodeIndex}
          roadmapNodes={roadmapNodes}
          setRoadmapNodes={setRoadmapNodes}
          isAddEditRoadmapNodeDialogOpen={isAddEditRoadmapNodeDialogOpen}
          handleCloseRoadmapNodeAddEditDialog={
            handleCloseRoadmapNodeAddEditDialog
          }
          //new code
          isAddEditRoadmapNodeDialogOpenForStudent={
            isAddEditRoadmapNodeDialogOpenForStudent
          }
          handleCloseRoadmapNodeAddEditDialogForStudent={
            handleCloseRoadmapNodeAddEditDialogForStudent
          }
        />
        {/* new code roadmap can be created for the student */}
        <AddEditRoadmapNodeDialogForStudent
          toastOptions={toastOptions}
          setToastOptions={setToastOptions}
          handleCloseToast={handleCloseToast}
          opType={opType}
          setOpType={setOpType}
          roadmapNode={roadmapNode}
          setRoadmapNode={setRoadmapNode}
          hasChildNodes={hasChildNodes}
          selectedRoadmapNodeIndex={selectedRoadmapNodeIndex}
          roadmapNodes={roadmapNodes}
          setRoadmapNodes={setRoadmapNodes}
          isAddEditRoadmapNodeDialogOpenForStudent={
            isAddEditRoadmapNodeDialogOpenForStudent
          }
          handleCloseRoadmapNodeAddEditDialogForStudent={
            handleCloseRoadmapNodeAddEditDialogForStudent
          }
        />
        {/* new code */}

        <div className={classes.fab}>
          {location.pathname === "/roadmaps" ? (
            <Fab
              color="primary"
              aria-label="add"
              size="small"
              className={classes.fabItem}
            >
              <Tooltip title="Create Learner Roadmap">
                <AddIcon
                  onClick={() => {
                    //clear dialog
                    setRoadmapNode(Factory.createRoadmapNode());
                    setOpType("add");
                    setHasChildNodes(false);
                    setIsAddEditRoadmapNodeDialogOpenForStudent(true);
                  }}
                />
              </Tooltip>
            </Fab>
          ) : null}

          {location.pathname === "/roadmap" ? (
            <Fab
              color="primary"
              aria-label="add"
              size="small"
              className={classes.fabItem}
            >
              <AddIcon
                onClick={() => {
                  //clear dialog
                  setRoadmapNode(Factory.createRoadmapNode());
                  setOpType("add");
                  setHasChildNodes(false);
                  setIsAddEditRoadmapNodeDialogOpen(true);
                }}
              />
            </Fab>
          ) : null}
          <Fab
            color="primary"
            aria-label="add"
            size="small"
            lassName={classes.fabItem}
          >
            {viewAsList ? (
              <ViewComfyIcon
                onClick={() => {
                  changeView();
                }}
              />
            ) : (
              <ListIcon
                onClick={() => {
                  changeView();
                }}
              />
            )}
          </Fab>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Accordion className={classes.accordionFilter}>
              <AccordionSummary
                expandIcon={<FilterListIcon color="primary" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {/* <Typography variant="body1" gutterBottom>
                Filter
              </Typography> */}
              </AccordionSummary>
              <AccordionDetails>
                <RoadmapsFilter
                  queryString={queryString}
                  setQueryString={setQueryString}
                  toastOptions={toastOptions}
                  setToastOptions={setToastOptions}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        {/* loading icon */}
        <Grid
          item
          xs={12}
          style={{
            display: showLoader ? "block" : "none",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress />
          </div>
        </Grid>
        {/* no roadmaps */}
        <Grid
          item
          xs={12}
          style={{
            display: roadmapNodes.length == 0 && !showLoader ? "block" : "none",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "block" }}>
              <HourglassEmptyIcon style={{ fontSize: "40px" }} />
            </div>
            <div style={{ display: "block" }}>
              <Typography variant="h6" className={classes.textCenter}>
                There are no roadmaps.
              </Typography>
            </div>
          </div>
        </Grid>
        {/* roadmap container grid  view */}
        {viewAsList ? listView() : gridView()}
      </div>
    </Fragment>
  );

  if (errorLoadingPage) {
    return <ErrorLoadingPage />;
  } else {
    return roadmapNodesContainer;
  }
}

export default RoadmapMain;
