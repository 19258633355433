import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import TableChartIcon from "@material-ui/icons/TableChart";
import MapIcon from "@material-ui/icons/Map";
import BusinessIcon from "@material-ui/icons/Business";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AssessmentIcon from "@material-ui/icons/Assessment";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import PhoneIcon from "@material-ui/icons/Phone";
import ExploreIcon from "@material-ui/icons/Explore";
import ChatIcon from "@material-ui/icons/Chat";
import RateReviewIcon from "@material-ui/icons/RateReview";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import ExtensionIcon from "@material-ui/icons/Extension";
import FaceIcon from "@material-ui/icons/Face";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LinkIcon from "@material-ui/icons/Link";
import SettingsIcon from "@material-ui/icons/Settings";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import GestureIcon from "@material-ui/icons/Gesture";
import GroupIcon from "@material-ui/icons/Group";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import NotesIcon from "@material-ui/icons/Notes";
import SchoolIcon from "@material-ui/icons/School";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

import BorderColorIcon from "@material-ui/icons/BorderColor";

import { useAppContext } from "../../libs/contextLib";

const categories = [
  {
    id: "Me",
    roles: [
      "teacher",
      "student",
      "areaCoordinator",
      "domainHead",
      "headOfSchool",
      "schoolAdmin",
      "bbAdmin",
      "staff",
      "headOfAdmin",
    ],
    children: [
      {
        id: "Timetable",
        link: "/timetable",
        icon: <TableChartIcon />,
        roles: [
          "teacher",
          "student",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
          "schoolAdmin",
          "staff",
          "headOfAdmin",
        ],
      },
      {
        id: "My Roadmap",
        link: "/roadmap",
        icon: <MapIcon />,
        roles: [
          "teacher",
          "student",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
          "staff",
          "headOfAdmin",
        ],
      },
      {
        id: "My Roadmap Tracker",
        link: "/roadmapTracker",
        icon: <MapIcon />,
        roles: ["student", "staff"],
      },
      {
        id: "My Portraits",
        link: "/portraits",
        icon: <AccountBoxIcon />,
        roles: ["student"],
      },

      // {
      //   id: "My Assessments",
      //   link: "/assessments",
      //   icon: <RateReviewIcon />,
      //   roles: ["student"],
      // },
      {
        id: "My Mentees",
        link: "/mentees",
        icon: <PeopleIcon />,
        roles: ["teacher", "areaCoordinator", "domainHead", "headOfSchool"],
      },
      {
        id: "Lesson Plans",
        link: "/lessonPlans",
        icon: <MenuBookIcon />,
        roles: ["teacher", "areaCoordinator", "domainHead", "headOfSchool"],
      },
      {
        id: "Appraisals",
        link: "/appraisals",
        icon: <MapIcon />,
        roles: [
          "teacher",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
          "staff",
          "headOfAdmin",
        ],
      },

      // {
      //   id: "Reports",
      //   link: "/reports",
      //   icon: <AssignmentIcon />,
      //   roles: ["student"],
      // },
      // {
      //   id: "Portrait",
      //   link: "/portrait",
      //   icon: <AssignmentIndIcon />,
      //   roles: ["student"],
      // },

      {
        id: "Account",
        icon: <AccountCircleIcon />,
        roles: [
          "student",
          "teacher",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
          "schoolAdmin",
          "staff",
          "headOfAdmin",
        ],
        children: [
          {
            id: "My Details",
            link: "/myDetails",
            icon: <NotesIcon />,
            // active: true,
            roles: [
              "teacher",
              "student",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
              "schoolAdmin",
              "bbAdmin",
              "staff",
              "headOfAdmin",
            ],
          },
          {
            id: "Reset Password",
            link: "/resetPassword",
            icon: <LockOpenIcon />,
            roles: [
              "student",
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
              "schoolAdmin",
              "staff",
              "headOfAdmin",
            ],
          },
        ],
      },
    ],
  },

  {
    id: "School",
    roles: [
      "student",
      "teacher",
      "researcher",
      "areaCoordinator",
      "domainHead",
      "headOfSchool",
      "schoolAdmin",
      "staff",
      "headOfAdmin",
    ],
    children: [
      //new code
      {
        id: "Review",
        link: "/reviewMain",
        icon: <BorderColorIcon />,
        roles: [
          "teacher",
          "student",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
          "schoolAdmin",
          "staff",
          "headOfAdmin",
        ],
      },

      {
        id: "Announcements",
        link: "/announcements",
        icon: <VolumeUpIcon />,
        roles: [
          "student",
          "teacher",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
          "schoolAdmin",
          "staff",
          "headOfAdmin",
        ],
      },
      {
        id: "School Timetable",
        link: "/manageTimeTable",
        icon: <TableChartIcon />,
        roles: ["schoolAdmin"],
      },
      {
        id: "Absentees",
        icon: <ListAltIcon />,
        roles: [
          "teacher",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
          "staff",
          "headOfAdmin",
          "schoolAdmin",
        ],
        link: "/absentees",
      },
      {
        id: "Directory",
        icon: <PeopleIcon />,
        roles: [
          "teacher",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
          "staff",
          "headOfAdmin",
        ],
        link: "/users",
      },
      {
        id: "Users",
        link: "/users",
        icon: <PeopleIcon />,
        roles: ["schoolAdmin"],
        children: [
          {
            id: "Mangage Users",
            link: "/users",
            icon: <GroupAddIcon />,
            roles: ["schoolAdmin"],
          },
          {
            id: "Bulk Update Students",
            link: "/bulkUpdateStudent",
            icon: <PeopleIcon />,
            roles: ["schoolAdmin"],
          },
          {
            id: "Reset User Password",
            link: "/resetUserPassword",
            icon: <LockOpenIcon />,
            roles: ["schoolAdmin"],
          },
        ],
      },
      {
        id: "Mentor Mentees",
        link: "/mentormentees",
        icon: <SupervisedUserCircleIcon />,
        roles: [
          "schoolAdmin",
          "teacher",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
          "staff",
          "headOfAdmin",
        ],
      },
      {
        id: "Learner Profile",
        // link: "/learnerProfile",
        icon: <GestureIcon />,
        roles: [
          "schoolAdmin",
          "teacher",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
        ],
        children: [
          {
            id: "Student Profile",
            link: "/studentProfile",
            icon: <FaceIcon />,
            roles: [
              "schoolAdmin",
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
            ],
          },
          {
            id: "Teacher Profile",
            link: "/teacherProfile",
            icon: <PermIdentityIcon />,
            roles: [
              "schoolAdmin",
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
            ],
          },
          {
            id: "Roadmap Tracker (Beta)",
            link: "/roadmapTracker",
            icon: <PermIdentityIcon />,
            roles: [
              "schoolAdmin",
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
            ],
          },
        ],
      },
      {
        id: "Roadmaps",
        icon: <MapIcon />,
        roles: [
          "schoolAdmin",
          "teacher",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
          "headOfAdmin",
          "student",
        ],
        children: [
          {
            id: "Learner Roadmaps",
            link: "/roadmaps",
            icon: <GroupIcon />,
            roles: [
              "schoolAdmin",
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
              "headOfAdmin",
            ],
          },
          {
            id: "Group Roadmaps",
            link: "/groupRoadmap",
            icon: <GroupWorkIcon />,
            roles: [
              "schoolAdmin",
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
              "headOfAdmin",
              "student",
            ],
          },
          {
            id: "Domain Roadmaps",
            link: "/domainRoadmap",
            icon: <MenuBookIcon />,
            roles: [
              "schoolAdmin",
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
              "headOfAdmin",
              "student",
            ],
          },
          {
            id: "Five Areas Roadmaps",
            link: "/fiveAreasRoadmap",
            icon: <ExtensionIcon />,
            roles: [
              "schoolAdmin",
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
              "headOfAdmin",
              "student",
            ],
          },

          {
            id: "School Roadmap",
            link: "/schoolRoadmap",
            icon: <BusinessIcon />,
            roles: [
              "student",
              "schoolAdmin",
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
            ],
          },
        ],
      },
      //studnet assemsent
      {
        id: "Assessments & Reports",
        icon: <AssessmentIcon />,
        roles: [
          "schoolAdmin",
          "teacher",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
        ],
        children: [
          // {
          //   id: "Assesments Visualizer",
          //   link: "/assessmentsSnapshot",
          //   icon: <BubbleChartIcon />,
          //   roles: [
          //     "schoolAdmin",
          //     "teacher",
          //     "areaCoordinator",
          //     "domainHead",
          //     "headOfSchool",
          //   ],
          // },
          {
            id: "Assessment Summaries",
            link: "/assessmentSummaries",
            icon: <TimelapseIcon />,
            roles: [
              "schoolAdmin",
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
            ],
          },
          {
            id: "Assessments",
            link: "/assessments",
            icon: <RateReviewIcon />,
            roles: [
              "schoolAdmin",
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
            ],
          },
          {
            id: "Portraits",
            link: "/portraits",
            icon: <AccountBoxIcon />,
            roles: [
              "schoolAdmin",
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
            ],
          },
          {
            id: "Fitness Test",
            link: "/fitnessTest",
            icon: <DirectionsRunIcon />,
            roles: ["teacher", "areaCoordinator", "domainHead", "headOfSchool"],
          },
          {
            id: "Reports - View & Edit",
            link: "/reports",
            icon: <AssignmentIcon />,
            roles: [
              "schoolAdmin",
              "teacher",
              "areaCoordinator",
              "domainHead",
              "headOfSchool",
            ],
          },
        ],
      },
      {
        id: "BB chatbot",
        link: "/bbChatbot",
        icon: <ChatIcon />,
        roles: [
          "teacher",
          "student",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
          "schoolAdmin",
          "staff",
          "headOfAdmin",
        ],
      },
      {
        id: "Emergency",
        link: "/emergency",
        icon: <PhoneIcon />,
        roles: [
          "teacher",
          "student",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
          "schoolAdmin",
          "staff",
          "headOfAdmin",
        ],
      },
    ],
  },

  //// added bbo under the school BbO
  {
    id: "BBO",
    roles: [
      "student",
      "teacher",
      "researcher",
      "areaCoordinator",
      "domainHead",
      "headOfSchool",
      "schoolAdmin",
      "staff",
      "headOfAdmin",
    ],
    children: [
      {
        id: "Learning Resources",
        link: "/learningResources",
        icon: <VideoLabelIcon />,
        roles: [
          "teacher",
          "student",
          "areaCoordinator",
          "domainHead",
          "headOfSchool",
          "schoolAdmin",
          "staff",
          "headOfAdmin",
        ],
      },
    ],
  },

  {
    id: "Manage Organisations",
    roles: ["bbAdmin"],
    children: [
      {
        id: "Affliations",
        link: "/affiliations",
        icon: <LinkIcon />,
        roles: ["bbAdmin"],
      },
      {
        id: "Add Organisation",
        link: "/addEditOrganisation",
        icon: <BusinessIcon />,
        roles: ["bbAdmin"],
      },
      {
        id: "View Organisations",
        icon: <BusinessIcon />,
        roles: ["bbAdmin"],
        link: "/organisations",
      },
      {
        id: "Reset Admin Passwords",
        icon: <LockOpenIcon />,
        roles: ["bbAdmin"],
        link: "/resetAdminPasswords",
      },
    ],
  },
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover,&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  organisationLogo: {
    width: "25%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  organisationTitle: {
    //fontSize: 18,
    textAlign: "center",
  },
  itemCategory: {
    backgroundColor: "#19212c", //"#232f3e",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: "#4fc3f7",
  },
  itemPrimary: {
    fontSize: "inherit",
  },
  itemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  link: {
    color: "rgba(255, 255, 255, 0.7)",
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});

function Navigator(props) {
  const { currentUser } = useAppContext();
  const { classes, ...other } = props;

  const [openState, setOpenState] = React.useState({
    e: false,
  });

  //for active links
  const location = useLocation();

  // const handleClick = () => {
  //   setOpen(!open);
  // };
  const handleClick = (id) => {
    setOpenState({ ...openState, [id]: !openState[id] });
  };

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          className={clsx(classes.firebase, classes.item, classes.itemCategory)}
        >
          <img
            alt="Organisation Logo"
            className={clsx(classes.organisationLogo)}
            src={currentUser.organisation.picURL}
          />
        </ListItem>
        <ListItem
          className={clsx(
            classes.item,
            classes.itemCategory,
            classes.organisationTitle
          )}
        >
          {/* <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon> */}
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            <Link className={classes.link} to={"/"}>
              {currentUser.organisation.name}
            </Link>
          </ListItemText>
        </ListItem>
        {categories.map(
          ({ id, children, roles }) =>
            roles.includes(currentUser.role) && (
              <React.Fragment key={id}>
                <ListItem className={classes.categoryHeader}>
                  <ListItemText
                    classes={{
                      primary: classes.categoryHeaderPrimary,
                    }}
                  >
                    {id}
                  </ListItemText>
                </ListItem>
                {children.map(
                  ({
                    id: childId,
                    link: childLink,
                    icon,
                    active,
                    roles,
                    children: grandChildren,
                  }) => {
                    return !grandChildren
                      ? roles.includes(currentUser.role) && (
                          <Link
                            className={classes.link}
                            to={childLink}
                            key={childId}
                          >
                            <ListItem
                              key={childId}
                              button
                              className={clsx(
                                classes.item,
                                childLink === location.pathname &&
                                  classes.itemActiveItem
                              )}
                            >
                              <ListItemIcon className={classes.itemIcon}>
                                {icon}
                              </ListItemIcon>
                              <ListItemText
                                classes={{
                                  primary: classes.itemPrimary,
                                }}
                                primary={childId}
                              ></ListItemText>
                            </ListItem>
                          </Link>
                        )
                      : roles.includes(currentUser.role) && (
                          <Fragment>
                            <ListItem
                              key={childId}
                              button
                              className={clsx(
                                classes.item,
                                childLink === location.pathname &&
                                  classes.itemActiveItem
                              )}
                              onClick={() => handleClick(childId)}
                            >
                              <ListItemIcon className={classes.itemIcon}>
                                {icon}
                              </ListItemIcon>
                              <ListItemText
                                classes={{
                                  primary: classes.itemPrimary,
                                }}
                                primary={childId}
                              ></ListItemText>
                              {openState[childId] ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItem>
                            <Collapse
                              in={openState[childId]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {grandChildren.map(
                                  ({
                                    id: childId,
                                    link: childLink,
                                    icon,
                                    active,
                                    roles,
                                  }) => {
                                    return (
                                      roles.includes(currentUser.role) && (
                                        <Link
                                          className={classes.link}
                                          to={childLink}
                                          key={childId}
                                        >
                                          <ListItem
                                            key={childId}
                                            button
                                            className={clsx(
                                              classes.item,
                                              classes.nested,
                                              childLink === location.pathname &&
                                                classes.itemActiveItem
                                            )}
                                          >
                                            <ListItemIcon
                                              className={classes.itemIcon}
                                            >
                                              {icon}
                                            </ListItemIcon>
                                            <ListItemText
                                              classes={{
                                                primary: classes.itemPrimary,
                                              }}
                                              primary={childId}
                                            ></ListItemText>
                                          </ListItem>
                                        </Link>
                                      )
                                    );
                                  }
                                )}
                              </List>
                            </Collapse>
                          </Fragment>
                        );
                  }
                )}
                <Divider className={classes.divider} />
              </React.Fragment>
            )
        )}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);
