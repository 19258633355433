import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAppContext } from "../../../libs/contextLib";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import LessonPlans from "../lessonPlans/lessonPlans";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    position: "relative",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddLessonPlanToLEDialog({
  isAddLessonPlanToLEDialogOpen,
  handleCloseAddLessonPlanToLEDialog,
  activeLearningExperience,
  handleLessonPlanAddedToLE,
  ...props
}) {
  const classes = useStyles();

  const { currentUser } = useAppContext();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {}, []);

  return (
    <Dialog
      fullScreen
      // fullWidth={fullWidth}
      // maxWidth={maxWidth}
      open={isAddLessonPlanToLEDialogOpen}
      onClose={handleCloseAddLessonPlanToLEDialog}
      TransitionComponent={Transition}
      aria-labelledby="max-width-dialog-title"
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseAddLessonPlanToLEDialog}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent
        style={{
          background: "#eaeff1",
        }}
      >
        <Grid
          container
          spacing={3}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item xs={10}>
            <LessonPlans
              activeLearningExperience={activeLearningExperience}
              handleLessonPlanAddedToLE={handleLessonPlanAddedToLE}
              handleCloseAddLessonPlanToLEDialog={
                handleCloseAddLessonPlanToLEDialog
              }
              {...props}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {/* <DialogActions>
        <Button
          onClick={handleCloseAddLessonPlanToLEDialog}
          variant="outlined"
          color="primary"
        >
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

export default AddLessonPlanToLEDialog;
