import { React, useEffect } from "react";
import { HelperService } from "../../../services/helper";

import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import { Fragment } from "react";

import fontJomo from "../../../assets/fonts/jomolhari.ttf";
import fontUchen from "../../../assets/fonts/DDC_Uchen.ttf";
import { AppContext } from "../../../libs/contextLib";

function AppraisalPDF({ name, learningCycle, year, appraisals }) {
  // Create Document Component
  const MyDocument = () => (
    <Document>
      {/* student image page */}
      <Page size="A4" orientation="portrait" style={styles.body}>
        <Text>Appraisal</Text>
        <Text>{name + ", Learning Cycle " + learningCycle + ", " + year}</Text>
        {/* 
      five areas 
      */}
        {Object.values(appraisals.areas).map((app, index) => {
          return (
            <Fragment>
              <Text style={styles.subtitle}>
                {app.type === "domain" || app.type === "area"
                  ? app.domainArea.displayName +
                    " (" +
                    app.createdBy.firstName +
                    " " +
                    app.createdBy.lastName +
                    ")"
                  : app.type +
                    " (" +
                    app.createdBy.firstName +
                    " " +
                    app.createdBy.lastName +
                    ")"}
              </Text>
              <Text style={styles.text}>{app.comment}</Text>
            </Fragment>
          );
        })}
        {/* 
      domains 
      */}
        {Object.values(appraisals.domains).map((app, index) => {
          return (
            <Fragment>
              <Text style={styles.subtitle}>
                {app.type === "domain" || app.type === "area"
                  ? app.domainArea.displayName +
                    " (" +
                    app.createdBy.firstName +
                    " " +
                    app.createdBy.lastName +
                    ")"
                  : app.type +
                    " (" +
                    app.createdBy.firstName +
                    " " +
                    app.createdBy.lastName +
                    ")"}
              </Text>
              <Text style={styles.text}>{app.comment}</Text>
            </Fragment>
          );
        })}
        {/* 
      domains 
      */}
        {Object.values(appraisals.rest).map((app, index) => {
          return (
            <Fragment>
              <Text style={styles.subtitle}>
                {app.type[0].toUpperCase() +
                  app.type.substr(1) +
                  " (" +
                  app.createdBy.firstName +
                  " " +
                  app.createdBy.lastName +
                  ")"}
              </Text>
              <Text style={styles.text}>{app.comment}</Text>
            </Fragment>
          );
        })}
      </Page>
    </Document>
  );

  return <MyDocument />;
}

// Register font
Font.register({
  family: "jomo",
  src: fontJomo,
});

Font.register({
  family: "ddcuchen",
  src: fontUchen,
});

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

//styles

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  bodyDomain: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 50,
    position: "relative",
  },

  pageBorder: {
    borderTop: "1 solid #c9cccd",
    borderBottom: "1 solid #c9cccd  ",
    // borderTop: "1 solid #000",
    // borderBottom: "1 solid #000",
    height: "100%",
    paddingLeft: 15,
    paddingRight: 15,
  },
  viewBorderTop: {
    // borderTop: "1 solid #c9cccd",
    backgroundColor: "#c9cccd",
    // borderTop: "1 solid #000",
    // borderBottom: "1 solid #000",
    height: "1px",
    marginBottom: "15px",
  },
  viewBorderBottom: {
    // borderTop: "1 solid #c9cccd",
    backgroundColor: "#c9cccd",
    // borderTop: "1 solid #000",
    // borderBottom: "1 solid #000",
    height: "1px",
    marginTop: "15px",
    position: "absolute",
    width: "99%",
    bottom: 50,
    left: 50,
    right: 0,
  },

  pageBordlerless: {
    height: "100%",
    display: "flex",
    // justifyContent: "center",
    paddingLeft: 15,
    paddingRight: 15,
  },

  menteeImageSectionTop: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 15,
  },
  menteeImageSectionTopChildLeft: {
    width: "70%",
  },
  menteeImageSectionTopChildRight: {
    width: "30%",
    display: "flex",
    justifyContent: "flexEnd",
  },
  menteeImageSectionMiddle: {
    display: "flex",
    flexDirection: "row",
    height: "80%",
    alignItems: "center",
    marginTop: "15px",
    marginBottom: "15px",
  },
  menteeImageSectionBottom: {
    display: "flex",
    alignItems: "center",
  },
  menteeReflectionSectionBottom: {
    height: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  menteeReflectionPage: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },

  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  titleSectionOrgLogo: {
    display: "block",
    width: "100%",
    alignItems: "center",
  },
  titleSectionChild: {
    display: "block",
    height: "100",
    width: "400",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
    color: "#9F2525",
    padding: 15,
  },
  titleMentee: {
    fontSize: 24,
    // textAlign: "center",
    fontFamily: "Oswald",
    color: "#9F2525",
    // color: "black"
  },
  titleCoverPageOrg: {
    fontSize: 18,
    // textAlign: "center",
    fontFamily: "Oswald",
    color: "black", //"#c1882f",
  },
  titleReportType: {
    fontSize: 18,
    fontFamily: "Oswald",
    // color: "#9F2525",
    color: "black",
    display: "block",
    textAlign: "right",
  },
  titleReportYear: {
    fontSize: 16,
    // textAlign: "center",
    fontFamily: "Oswald",
    color: "#000",
    textAlign: "right",
  },
  titleOrganisation: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
    color: "#9F2525",
    padding: 15,
  },
  titleContact: {
    fontSize: 16,
    textAlign: "center",
    fontFamily: "Oswald",
    color: "#9F2525",
  },
  titleContactURL: {
    marginTop: "6px",
    fontSize: "12",
    textAlign: "center",
    color: "#0000FF",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 14,
    paddingTop: 12,
    paddingBottom: 12,
    fontFamily: "Oswald",
    color: "#c1882f",
  },
  subsubtitle: {
    fontSize: 16,
    paddingTop: 12,
    paddingBottom: 12,
    fontFamily: "Oswald",
    color: "#000",
  },
  text: {
    // margin: 12,
    fontSize: 12,
    textAlign: "justify",
    // fontFamily: "Times-Roman",
  },
  textDzongkha: {
    // margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "jomo",
    // fontFamily: "ddcuchen",
  },
  textDzongkhaSubtitle: {
    // margin: 12,
    fontSize: 24,
    textAlign: "justify",
    fontFamily: "jomo",
    color: "#c1882f",
    paddingTop: 12,
    paddingBottom: 12,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },

  imageOrgLogo: {
    height: "auto",
    width: "25%",
    marginVertical: 15,
    marginHorizontal: 100,
  },
  imageMentee: {
    width: "100%",
    height: "auto",
    // margin: 12,
  },
  imageReflection: {
    width: "100%",
    height: "auto",
    // margin: 12,
  },
  header: {
    fontSize: 10,
    marginBottom: 2,
    textAlign: "right",
    fontStyle: "italic",
    color: "#9F2525",
  },
  // header: {
  //   position: "absolute",
  //   fontSize: 10,
  //   top: -15,
  //   left: 0,
  //   right: 0,
  //   textAlign: "right",
  //   fontStyle: "italic",
  //   color: "#9F2525",
  // },
  // footer: {
  //   position: "absolute",
  //   fontSize: 10,
  //   bottom: -20,
  //   left: 0,
  //   right: 0,
  //   textAlign: "right",
  //   fontStyle: "italic",
  //   color: "#c1882f",
  // },
  footer: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 50,
    textAlign: "right",
    fontStyle: "italic",
    color: "#c1882f",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default AppraisalPDF;
