/*
 * prepare assessment data for pie chart
 */

import * as dfd from "danfojs";

export default function prepareAssessmentDataForPieChart(tempAss) {
  let dfAssPie = new dfd.DataFrame(tempAss);
  // dfAssPie.print();
  dfAssPie = dfAssPie.replace("mainly negative", "needs work", {
    columns: ["description"],
  });
  // dfAssPie.print();
  let grpAssPie = dfAssPie.groupby(["description"]);

  let summedAssPie = grpAssPie.col(["description"]).count();
  summedAssPie = summedAssPie.dropNa({ axis: 1 });

  //check that frame not empty after dropping undefined values
  if (summedAssPie.shape[0] == 0) {
    //   setAssessmentScore(null);
    return { isEmpty: true };
  }

  summedAssPie.rename(
    { description: "name", description_count: "value" },
    { inplace: true }
  );

  return {
    isEmpty: false,
    total: summedAssPie.sum({ axis: 0 }).iat(1, 1),
    data: dfd.toJSON(summedAssPie),
  };

  // setTotalAssessments(summedAssPie.sum({ axis: 0 }).iat(1, 1));
  // setAssessmentPieChartData(dfd.toJSON(summedAssPie));
}
