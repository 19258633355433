import React, { useEffect } from "react";
import SearchUser from "../../helperComponents/searchUser";
import SearchUserAdvanced from "../../helperComponents/searchUserAdvanced";

import Grid from "@material-ui/core/Grid";

export default function MentorMenteeFilter({
  user,
  setUser,
  setIsDialogOpen,
  setQueryString,
  ...props
}) {
  useEffect(() => {
    //open dialog if user selected
    if (user._id !== "") {
      setQueryString({ userId: user._id });
    }
  }, [user]);

  const [userQueryOptions, setUserQueryOptions] = React.useState({});

  //return comp
  return (
    <Grid container justify="space-between" spacing={3}>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {/* <SearchUser
              user={user}
              setUser={setUser}
              role={""}
              style={{ display: "flex" }}
            /> */}
            <SearchUserAdvanced
              user={user}
              setUser={setUser}
              userQueryOptions={userQueryOptions}
              setUserQueryOptions={setUserQueryOptions}
              style={{ display: "flex" }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
