import React, { useEffect } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import { MenteeService } from "../../../services/mentee";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
// import StudentDialog from "./studentsDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dataGridRoot: {
    height: 400,
    width: "100%",
  },
  dataGrid: {
    backgroundColor: "white",
  },
  avatarBackground: {
    //color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: theme.palette.primary.main, //deepOrange[500],
  },
}));

export default function MenteesPersonal(props) {
  const history = useHistory();
  const classes = useStyles();
  const { currentUser } = useAppContext();
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [mentees, setMentees] = React.useState([]);
  //console.log("mentees", mentees);
  const [menteesLoaded, setMenteesLoaded] = React.useState(false);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  // handleMenteeClick() tto navigate new link
  //added new feature to nagivate to learner profile
  const handleMenteeClick = (_id) => {
    console.log("mentee roadmap", _id);
    history.push({
      pathname: "/roadmaptracker",
      //pathname: "/roadmap",
      state: { _id: _id },
    });
  };

  let rows = [];

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "menteeName",
      headerName: "Mentee",
      width: 300,
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", marginRight: "10px" }}>
              <Avatar
                src={params.row.menteePicURL}
                alt={params.row.menteeName}
                className={classes.avatarBackground}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* adding link to name */}
              <Typography
                variant="body2"
                onClick={() => handleMenteeClick(params.row._id)}
              >
                {params.row.menteeName}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      field: "mentorName",
      headerName: "Mentor Name",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", marginRight: "10px" }}>
              <Avatar
                src={params.row.mentorPicURL}
                alt={params.row.mentorName}
                className={classes.avatarBackground}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2">{params.row.mentorName}</Typography>
            </div>
          </div>
        );
      },
    },
    { field: "grade", headerName: "Mentee Grade", width: 130 },
    { field: "yearJoined", headerName: "Mentee Year Joined", width: 130 },
  ];

  //pre-load verify token
  useEffect(() => {
    if (props.startFetchData) {
      getMenteesByOrgIdAndUserId();
    }

    async function getMenteesByOrgIdAndUserId() {
      try {
        //checke whether to get a user's mentee or all mentees of an organisation
        if (
          ["teacher", "domainHead", "areaCoordinator", "headOfSchool"].includes(
            currentUser.role
          )
        ) {
          setMentees(
            await MenteeService.getMenteesByOrgIdAndUserId(
              currentUser.organisation.id,
              currentUser.id
            )
          );
        } else {
          setMentees(
            await MenteeService.getMenteesByOrgId(currentUser.organisation.id)
          );
        }

        setMenteesLoaded(true);
        setIsDataLoading(false);
      } catch (err) {
        setErrorLoadingPage(true);
      }
    }
  }, [props.startFetchData]);

  function loadRows(mentorMentees) {
    let temp = [];
    mentorMentees.forEach((mentorMentee, index) => {
      temp.push({
        _id: mentorMentee.mentee._id,
        id: index,
        mentorName: `${mentorMentee.mentor.firstName} ${mentorMentee.mentor.lastName} `,
        menteeName: `${mentorMentee.mentee.firstName} ${mentorMentee.mentee.lastName} `,
        menteePicURL: mentorMentee.mentee.picURL,
        mentorPicURL: mentorMentee.mentor.picURL,
        email: mentorMentee.mentee.email,
        grade: mentorMentee.mentee.grade,
        yearJoined: mentorMentee.mentee.yearJoined,
        status: mentorMentee.mentee.status,
        address: mentorMentee.mentee.address,
        guardianEmergencyContact: mentorMentee.mentee.guardianEmergencyContact,
      });
    });
    return temp;
  }

  function MenteeDataGrid(props) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.dataGridRoot}>
          <DataGrid
            className={classes.dataGrid}
            rows={rows}
            columns={columns}
            autoHeight={true}
            pageSize={10}
            checkboxSelection
            {...props}
          />
        </Grid>
      </Grid>
    );
  }

  function menteeComponent(props) {
    // return (
    //   <Fragment>
    //     <StudentDialog isDialogOpen={isDialogOpen} formData={formData} />
    //     {AnnouncementDataGrid({ loading: isDataLoading })}
    //   </Fragment>
    // );
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.textCenter}>
              Mentees
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {/* <StudentDialog
              isDialogOpen={isDialogOpen}
              dialogFormTitle={dialogFormTitle}
              formData={formData}
              handleCloseDialog={handleCloseDialog}
            /> */}
          </Grid>
          {MenteeDataGrid({ loading: isDataLoading })}
        </Grid>
      </div>
    );
  }

  if (menteesLoaded) {
    rows = loadRows(mentees);
    return menteeComponent({ loading: isDataLoading });
  } else if (isDataLoading & !errorLoadingPage) {
    return menteeComponent({ loading: isDataLoading });
  } else {
    return <ErrorLoadingPage />;
  }
}
