import React, { Fragment, useEffect } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import SelectDomainArea from "../../helperComponents/selectDomainArea";
import SearchUser from "../../helperComponents/searchUser";
import { Factory } from "../../../helpers/factories";
import { LearningExperienceService } from "../../../services/learningExperiences";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: "16px",
  },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });

export default function AddLearningExperienceDialog({
  activeTimeTable,
  opType,
  setOpType,
  cellEvent,
  cellEventData,
  activeLearnExp,
  setActiveLearnExp,
  learningExperiences,
  setLearningExperiences,
  isAddLearningExperienceDialogOpen,
  handleCloseAddLearningExperienceDialog,
  toastOptions,
  setToastOptions,
  ...props
}) {
  const classes = useStyles();

  const { currentUser } = useAppContext();

  //save learning exp
  // const [saveLearningExp, setSaveLearningExp] = React.useState(false);

  //teacher
  const [user, setUser] = React.useState(null);

  //domain
  const [domainArea, setDomainArea] = React.useState(
    Factory.createDomainArea()
  );

  //learning experience
  const [learningExperience, setLearningExperience] = React.useState(
    Factory.createLearningExperience()
  );

  //load active learning experience if edit
  useEffect(() => {
    if (opType === "edit") {
      setLearningExperience({
        ...activeLearnExp,
        domainArea: null,
        teacher: null,
      });
      setDomainArea(activeLearnExp.domainArea);
      setUser(activeLearnExp.teacher);
    } else {
      setDomainArea(null);
      setUser(null);
      setLearningExperience(Factory.createLearningExperience());
    }
  }, [opType]);

  //check if enable save button
  function isFormValid() {
    return (
      (learningExperience.type != "other" || learningExperience.title) &&
      (learningExperience.type != "le" ||
        (user && user._id && domainArea && domainArea._id)) &&
      (learningExperience.isForAllGrades || learningExperience.grade != "")
    );
  }

  //handle changes

  const handleLEInputChange = (e) => {
    switch (e.target.name) {
      case "type":
        setLearningExperience({ ...learningExperience, type: e.target.value });
        if (e.target.value === "other") {
          setDomainArea(Factory.createDomainArea());
        }
        break;
      case "isForAllGrades":
        setLearningExperience({
          ...learningExperience,
          grade: "",
          isForAllGrades: e.target.value,
        });
        break;
      case "title":
        setLearningExperience({ ...learningExperience, title: e.target.value });
        break;
      case "grade":
        setLearningExperience({ ...learningExperience, grade: e.target.value });
        break;
      case "group":
        setLearningExperience({ ...learningExperience, group: e.target.value });
        break;
      case "location":
        setLearningExperience({
          ...learningExperience,
          location: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  //close learning experience dialog
  const handleClose = () => {
    handleCloseAddLearningExperienceDialog();
  };

  //save learning experience
  const handleSaveLearningExperiencw = async (addNewAfterSave) => {
    try {
      //add user if not null
      let data = { ...learningExperience };
      data["title"] = data["type"] === "other" ? data["title"] : null;
      data["teacher"] = user && user._id ? user._id : null;
      data["domainArea"] = domainArea && domainArea._id ? domainArea._id : null;
      data["organisation"] =
        data.organisation && data.organisation._id
          ? data.organisation._id
          : null;
      data["grade"] = data["isForAllGrades"] ? null : data["grade"];
      data["week"] = cellEventData.weekIndex;
      data["day"] = cellEventData.dayIndex;
      data["slot"] = cellEventData.slotIndex;
      data["timeTable"] = activeTimeTable._id;
      data["lesson"] = null;

      if (opType === "add") {
        const savedLearningExperience =
          await LearningExperienceService.addLearningExperience(
            currentUser.organisation.id,
            data.timeTable,
            data
          );

        let tempLEs = [...learningExperiences];
        tempLEs.push(savedLearningExperience);

        setLearningExperiences(tempLEs);
      } else {
        const updatedLearningExperience =
          await LearningExperienceService.updateLearningExperienceByOrgIdAndTimeTableIdAndLearningExperienceId(
            currentUser.organisation.id,
            data.timeTable,
            activeLearnExp._id,
            data
          );

        //update les in timetable
        let temp = [...learningExperiences];
        let leIndexCount = 0;
        temp.forEach((le, index) => {
          if (
            le.week == cellEventData.weekIndex &&
            le.day == cellEventData.dayIndex &&
            le.slot == cellEventData.slotIndex
          ) {
            if (leIndexCount == cellEventData.leIndex) {
              temp[index] = updatedLearningExperience;
            }
            leIndexCount += 1;
          }
        });
        setLearningExperiences([...temp]);
      }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Learning Experience / Event saved",
      });

      handleCloseAddLearningExperienceDialog();
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error saving learning experience / event",
      });
    }
  };

  return (
    <Dialog
      open={isAddLearningExperienceDialogOpen}
      fullWidth={true}
      maxWidth={"md"}
      //fullScreen
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      //   TransitionComponent={Transition}
    >
      <DialogTitle id="form-dialog-title">
        Add Learning Experience / Event
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText>Add a new event</DialogContentText> */}
        {/* Add Event */}
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%", margin: "16px" }}
            >
              <InputLabel id="type-type">Type</InputLabel>
              <Select
                labelId="type"
                id="type"
                name="type"
                value={learningExperience.type}
                onChange={handleLEInputChange}
                label="Type"
              >
                <MenuItem value={"le"}>Learning Experience</MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%", margin: "16px" }}
            >
              <InputLabel id="type-isForAllGrades">All Grades</InputLabel>
              <Select
                labelId="isForAllGrades"
                id="isForAllGrades"
                name="isForAllGrades"
                value={learningExperience.isForAllGrades}
                onChange={handleLEInputChange}
                label="All Grades"
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              disabled={learningExperience.isForAllGrades}
              style={{ width: "90%", margin: "16px" }}
            >
              <InputLabel id="type-description">Grade</InputLabel>
              <Select
                labelId="grade"
                id="grade"
                name="grade"
                default="1"
                value={learningExperience.grade}
                onChange={handleLEInputChange}
                label="Grade"
              >
                <MenuItem value={"0"}>PP</MenuItem>
                <MenuItem value={"1"}>1</MenuItem>
                <MenuItem value={"2"}>2</MenuItem>
                <MenuItem value={"3"}>3</MenuItem>
                <MenuItem value={"4"}>4</MenuItem>
                <MenuItem value={"5"}>5</MenuItem>
                <MenuItem value={"6"}>6</MenuItem>
                <MenuItem value={"7"}>7</MenuItem>
                <MenuItem value={"8"}>8</MenuItem>
                <MenuItem value={"9"}>9</MenuItem>
                <MenuItem value={"10"}>10</MenuItem>
                <MenuItem value={"11"}>11</MenuItem>
                <MenuItem value={"12"}>12</MenuItem>
                <MenuItem value={"All"}>All</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <SearchUser
              user={user}
              setUser={setUser}
              role={""}
              style={{ display: "flex" }}
            />
          </Grid>
          {learningExperience.type === "le" ? (
            <Grid item xs={6}>
              <SelectDomainArea
                domainArea={domainArea}
                setDomainArea={setDomainArea}
                showAllOption={false}
                orgId={currentUser.organisation.id}
                type={""}
                style={{ display: "flex" }}
              />
            </Grid>
          ) : null}
        </Grid>
        {learningExperience.type === "le" ? (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                style={{ width: "90%", margin: "16px" }}
                className={classes.textField}
                margin="dense"
                id="group"
                name="group"
                label="Group/Section"
                value={learningExperience.group}
                onChange={handleLEInputChange}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                style={{ width: "90%", margin: "16px" }}
                className={classes.textField}
                margin="dense"
                id="location"
                name="location"
                label="Location"
                value={learningExperience.location}
                onChange={handleLEInputChange}
                type="text"
                fullWidth
              />
            </Grid>
          </Grid>
        ) : null}
        {learningExperience.type === "other" ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                style={{ width: "90%", margin: "16px" }}
                className={classes.textField}
                margin="dense"
                id="title"
                name="title"
                label="Title"
                placeholder="Example: Ngondro"
                value={learningExperience.title}
                onChange={handleLEInputChange}
                type="text"
                fullWidth
              />
            </Grid>
          </Grid>
        ) : null}
        {/* <Grid item xs={4}>
            <SelectDomainArea
              domainArea={domainArea}
              setDomainArea={setDomainArea}
              showAllOption={false}
              orgId={currentUser.organisation.id}
              style={{ display: "flex" }}
            />
          </Grid> */}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isFormValid()}
          onClick={() => {
            handleSaveLearningExperiencw(false);
          }}
          color="primary"
        >
          Save and Close
        </Button>

        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {/* <Button onClick={handleClose} color="primary">
          Delete
        </Button> */}
      </DialogActions>
    </Dialog>
  );
}
