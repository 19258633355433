const Roles = {
  superAdmin: {
    name: "superAdmin",
    displayName: "Super Admin",
  },
  bbAdmin: { name: "bbAdmin", displayName: "BB Admin" },
  schoolAdmin: { name: "schoolAdmin", displayName: "School Admin" },
  teacher: { name: "teacher", displayName: "Teacher" },
  researcher: { name: "researcher", displayName: "Researcher" },
  student: { name: "student", displayName: "Student" },
  areaCoordinator: { name: "areaCoordinator", displayName: "Area Coordinator" },
  domainHead: { name: "domainHead", displayName: "Domain Head" },
  staff: { name: "staff", displayName: "Staff" },
  headOfSchool: { name: "headOfSchool", displayName: "Head of School" },
  headOfAdmin: { name: "headOfAdmin", displayName: "Head of Administration" },
};

export default Roles;
