import React from "react";

import moment from "moment";

import { useAppContext } from "../../../libs/contextLib";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import SearchUserAdvanced from "../../helperComponents/searchUserAdvanced";

// import SearchUser from "../../helperComponents/searchUser";
import { AppraisalService } from "../../../services/appraisal";
import SelectDomainArea from "../../helperComponents/selectDomainArea";

import { Factory } from "../../../helpers/factories";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { Utils } from "../../../helpers/utils";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";

import Toast from "../../helperComponents/toast";
import Slide from "@material-ui/core/Slide";

import { makeStyles } from "@material-ui/core/styles";

//placeholder image

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  formImage: {
    margin: "16px",
    padding: "20px",
    height: "200px",
    objectFit: "contain",
    border: "1px solid #eaeff2",
  },
  formReports: {
    marginBottom: "16px",
  },
  paper: {
    padding: "20px",
    marginBottom: "40px",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AppraisalDialog({
  activeAppraisal,
  setActiveAppraisal,
  appraisals,
  setAppraisals,
  isDialogOpen,
  handleCloseDialog,
  selectedRowIndex,
  setSelectedRowIndex,
  opType,
  setOpType,
  ...props
}) {
  const classes = useStyles();

  const { currentUser } = useAppContext();

  //appraisal state - there is an activeAppraisal state but this one is to deal with rendering issues
  const [appraisal, setAppraisal] = React.useState(Factory.createAppraisal());

  //chosen domain area state for individual domain appraisals
  const [domainArea, setDomainArea] = React.useState(null);

  //student
  const [user, setUser] = React.useState(null);

  const [userQueryOptions, setUserQueryOptions] = React.useState({});

  //appraisal states
  const [yearRange, setYearRange] = React.useState(
    Utils.getYearRange(
      parseInt(moment().subtract(10, "years").format("YYYY")),
      parseInt(moment().format("YYYY"))
    )
  );

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 2000,
  });

  /*
   * Helpers
   */

  //usestate for updatind mentee id in appraisal
  // React.useEffect(() => {
  //   if (user != null) {
  //     //add mentee,mentor, appraisal type, appraisal year to appraisal state
  //     setAppraisal({
  //       ...appraisal,
  //       createdBy: user,
  //     });
  //   }
  // }, [user]);

  React.useEffect(() => {
    setAppraisal(activeAppraisal);
    if (opType === "edit") {
      // setAppraisal(activeAppraisal);
      setUser(activeAppraisal.user);
      setDomainArea(activeAppraisal.domainArea);
    }
    //  else if (opType === "add") {
    //   setAppraisal(Factory.createAppraisal);
    // }
  }, [activeAppraisal]);

  React.useEffect(() => {
    setAppraisal({ ...appraisal, domianAea: domainArea });
  }, [domainArea]);

  //form is valid for saving

  const isFormValid = () => {
    return (
      appraisal &&
      appraisal.learningCycle &&
      appraisal.year &&
      appraisal.comment &&
      appraisal.type &&
      (appraisal.type === "self" ||
        appraisal.type === "response" ||
        (user && user._id)) &&
      (!(appraisal.type === "domain" || appraisal.type === "area") ||
        (domainArea && domainArea._id))
    );
  };

  /*
   * Handlers
   */

  /*
   * Add/Update Report
   */

  const handleSaveAppraisal = async (addNew = false) => {
    //prepare to save
    let temp = { ...appraisal };

    //check whether it's a update or a new addition
    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      //check save or edit
      if (opType === "add") {
        appraisal.type === "self" || appraisal.type === "response"
          ? (temp["user"] = currentUser.id)
          : (temp["user"] = user._id);
        temp["createdBy"] = currentUser.id;
        temp["organisation"] = currentUser.organisation.id;
        if (appraisal.type === "area" || appraisal.type === "domain") {
          temp["domainArea"] = domainArea._id;
        } else {
          //delete domain area if in temp
          delete temp.domainArea;
        }

        const savedAppraisal = await AppraisalService.addAppraisalsByOrgId(
          currentUser.organisation.id,
          temp
        );

        //add appraisal to appraisals to update table

        setActiveAppraisal(savedAppraisal);
        let updatedAppraisalList = [...appraisals];
        updatedAppraisalList.unshift(savedAppraisal);
        setAppraisals(updatedAppraisalList);
        setSelectedRowIndex(0);
      } else {
        //edit appraisal in appraisals to update table
        appraisal.type === "self" || appraisal.type === "response"
          ? (temp["user"] = currentUser.id)
          : (temp["user"] = user._id);
        temp["createdBy"] = currentUser.id;
        temp["organisation"] = currentUser.organisation.id;
        if (appraisal.type === "area" || appraisal.type === "domain") {
          temp["domainArea"] = domainArea;
        } else {
          //delete domain area if in temp
          delete temp.domainArea;
        }
        const updatedAppraisal =
          await AppraisalService.updateAppraisalsByOrgIdAndAppraisalId(
            currentUser.organisation.id,
            activeAppraisal._id,
            temp
          );
        let updatedAppraisalList = [...appraisals];
        updatedAppraisal["id"] = selectedRowIndex;
        updatedAppraisalList[selectedRowIndex] = updatedAppraisal;
        setAppraisals(updatedAppraisalList);
      }

      if (addNew) {
        setOpType("add");
      } else {
        handleCloseDialog();
      }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Appraisal Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  };

  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  //return component

  return (
    <Dialog
      fullScreen
      open={isDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      {/* toast for messages */}
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />

      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {opType === "add" ? "Add Appraisal" : "Edit Appraisal"}
          </Typography>
        </Toolbar>
      </AppBar>

      {/* student/appraisal details */}
      <div
        style={{
          height: "100vh",
          backgroundColor: "#eaeff1",
        }}
      >
        <Grid
          container
          spacing={3}
          style={{
            justifyContent: "center",
            backgroundColor: "#eaeff1",
          }}
        >
          <Grid item xs={8} sm={10}>
            <Paper className={classes.paper} style={{ marginTop: "20px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Appraisal
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    style={{ width: "90%" }}
                    disabled={
                      appraisal.createdBy &&
                      appraisal.createdBy._id != currentUser.id
                    }
                  >
                    <InputLabel id="type-select-label">Type</InputLabel>
                    <Select
                      labelId="type-select-label"
                      id="type-select-appraisal-type"
                      size="small"
                      value={appraisal.type}
                      onChange={(e) => {
                        setAppraisal({ ...appraisal, type: e.target.value });
                      }}
                      label="Type"
                    >
                      <MenuItem value={"area"}>Area</MenuItem>
                      <MenuItem value={"domain"}>Domain </MenuItem>
                      <MenuItem value={"general"}>General</MenuItem>
                      <MenuItem value={"response"}>Response</MenuItem>
                      <MenuItem value={"self"}>Self </MenuItem>
                      <MenuItem value={"other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    style={{ width: "90%" }}
                    disabled={
                      appraisal.createdBy &&
                      appraisal.createdBy._id != currentUser.id
                    }
                  >
                    <InputLabel id="learningCycle-select-label">
                      Learning Cycle
                    </InputLabel>
                    <Select
                      labelId="learningCycle-select-label"
                      id="learningCycle-select-type"
                      size="small"
                      value={appraisal.learningCycle}
                      onChange={(e) => {
                        setAppraisal({
                          ...appraisal,
                          learningCycle: e.target.value,
                        });
                      }}
                      label="Learning Cycle"
                    >
                      <MenuItem value={"1"}>1</MenuItem>
                      <MenuItem value={"2"}>2 </MenuItem>
                      <MenuItem value={"3"}>3</MenuItem>
                      <MenuItem value={"4"}>4 </MenuItem>
                      <MenuItem value={"5"}>5</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    style={{ width: "90%" }}
                    disabled={
                      appraisal.createdBy &&
                      appraisal.createdBy._id != currentUser.id
                    }
                  >
                    <InputLabel id="reportYear-select-label">Year</InputLabel>
                    <Select
                      labelId="reportYear-select-label"
                      id="reportYear-select-appraisal"
                      size="small"
                      value={appraisal.year}
                      onChange={(e) => {
                        setAppraisal({
                          ...appraisal,
                          year: e.target.value,
                        });
                      }}
                      label="Year"
                    >
                      {yearRange.map((year) => {
                        return (
                          <MenuItem value={year} key={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: (() => {
                      return !appraisal.type ||
                        appraisal.type === "self" ||
                        appraisal.type === "response"
                        ? "none"
                        : "block";
                    })(),
                  }}
                >
                  <SearchUserAdvanced
                    user={user}
                    setUser={setUser}
                    userQueryOptions={userQueryOptions}
                    setUserQueryOptions={setUserQueryOptions}
                    style={{ display: "flex" }}
                  />
                  {/* <SearchUser
                    user={activeAppraisal.user}
                    setUser={setUser}
                    //   role={"student"}
                    style={{ display: "flex" }}
                    isDisabled={
                      appraisal.createdBy &&
                      appraisal.createdBy._id != currentUser.id
                    }
                  /> */}
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: (() => {
                      return appraisal.type === "domain" ||
                        appraisal.type === "area"
                        ? "block"
                        : "none";
                    })(),
                  }}
                >
                  <SelectDomainArea
                    domainArea={activeAppraisal.domainArea}
                    setDomainArea={setDomainArea}
                    showAllOption={false}
                    orgId={currentUser.organisation.id}
                    style={{ display: "flex" }}
                    type={""}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      multiline
                      className={classes.formReports}
                      rows={10}
                      margin="dense"
                      id="mentorReport"
                      name="mentorReport"
                      label="Enter appraisal"
                      type="text"
                      variant="filled"
                      // value={childAppraisals.mentorReport.comment || ""}
                      value={appraisal.comment}
                      onChange={(e) => {
                        setAppraisal({
                          ...appraisal,
                          comment: e.target.value,
                        });
                      }}
                      disabled={
                        appraisal.createdBy &&
                        appraisal.createdBy._id != currentUser.id
                      }
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
            {/* save close */}
            {opType === "add" ||
            (appraisal.createdBy &&
              appraisal.createdBy._id === currentUser.id) ? (
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "40px",
                  }}
                >
                  <div style={{ padding: "10px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleCloseDialog}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={!isFormValid()}
                      onClick={() => {
                        handleSaveAppraisal(false);
                      }}
                    >
                      Save and Close
                    </Button>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={!isFormValid()}
                      onClick={() => {
                        handleSaveAppraisal(true);
                      }}
                    >
                      Save and Add New
                    </Button>
                  </div>
                </Grid>
              </Grid>
            ) : null}
            {/* end save close */}
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
}
