import React, { useEffect, Fragment } from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { et } from "date-fns/locale";

function SelectWeekUploadFileDialog({
  noWeeks,
  weekUploadFile,
  setWeekUploadFile,
  isSelectWeekUploadFileDialogOpen,
  setIsSelectWeekUploadFileDialogOpen,
  setIsWeekSetUploadFile,
  ...props
}) {
  const [timeTableTitle, setTimeTableTitle] = React.useState("");

  const handleInputChange = (e) => {
    if (e.target.value <= noWeeks - 1 && e.target.value >= 0) {
      setWeekUploadFile(e.target.value);
    }
  };

  function handleCloseDialog() {
    setIsWeekSetUploadFile(true);
    setIsSelectWeekUploadFileDialogOpen(false);
  }

  function handleCancelDialog() {
    setIsSelectWeekUploadFileDialogOpen(false);
  }

  return (
    <Fragment>
      <Dialog
        open={isSelectWeekUploadFileDialogOpen}
        onClose={handleCancelDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Select Week Between 0 to {noWeeks - 1}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="selectWeek"
                name="selectWeek"
                label="Week starts from 0"
                type="number"
                max
                value={weekUploadFile}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseDialog} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default SelectWeekUploadFileDialog;
