/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const DomainRoadmapService = {
  addDomainRoadmapByOrgId,
  getDomainRoadmapByOrgId,
  getSPWDomainRoadmapByOrgIdLearningCycleYearGradeDomain,
  updateDomainRoadmapById,
  deleteDomainRoadmapById,
};

function addDomainRoadmapByOrgId(orgId, domainRoadmap) {
  const requestOptions = RequestHelper.getPostRequestOptions(domainRoadmap);

  var api_url = config.api_domainRoadmap_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.domainRoadmap;
    });
}

function getDomainRoadmapByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_domainRoadmap_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return {
        domainRoadmaps: data.domainRoadmaps,
        page: data.page,
        hasMore: data.hasMore,
      };
    });
}

//used for lesson plans by returning a list of SPW for a domain,year,grade arranged according to learning cycle
function getSPWDomainRoadmapByOrgIdLearningCycleYearGradeDomain(
  orgId,
  options = {}
) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url =
    config.api_spw_domainRoadmap_by_orgId_and_learningCycle_year_grade_domain;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return {
        data: data.spw,
      };
    });
}

function updateDomainRoadmapById(orgId, domainRoadmapId, domainRoadmap) {
  const requestOptions = RequestHelper.getPutRequestOptions(domainRoadmap);

  var api_url = config.api_domainRoadmap_by_orgId_and_domainRoadmapId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    domainRoadmapId: domainRoadmapId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.domainRoadmap;
    });
}

// delete school roadmap ; all child nodes will also be deleted

function deleteDomainRoadmapById(orgId, domainRoadmapId) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_domainRoadmap_by_orgId_and_domainRoadmapId;

  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    domainRoadmapId: domainRoadmapId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data._id;
    });
}
