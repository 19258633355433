import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppContext } from "../../../libs/contextLib";

import { DataGrid } from "@material-ui/data-grid";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import { makeStyles } from "@material-ui/core/styles";
import DomainRoadmapFilter from "./domainRoadmapFilter";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Button } from "@material-ui/core";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import Chip from "@material-ui/core/Chip";
import LabelIcon from "@material-ui/icons/Label";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Fab from "@material-ui/core/Fab";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ListIcon from "@material-ui/icons/List";
import Paper from "@material-ui/core/Paper";
import AlertDialog from "../../helperComponents/alertDialog";

import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import AddEditDomainRoadmap from "./addEditDomainRoadmapDialog";
import { Factory } from "../../../helpers/factories";
import { DomainRoadmapService } from "../../../services/domainRoadmap";

import moment from "moment";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { Fragment } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import Toast from "../../helperComponents/toast";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  //   avatar: {
  //     backgroundColor: "#009be5",
  //   },
  contentWrapper: {
    margin: "40px 16px",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
  accordionFilter: {
    marginBottom: "5px",
    border: 0,
    // borderRadius: "8px",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  cardRoot: {
    minWidth: 190,
  },

  cardTitle: {
    fontSize: 14,
    fontWeight: "bold",
  },
  cardPos: {
    marginBottom: 12,
  },
  progress: {
    width: "100%",
    backgroundColor: "#e9ecef",
    borderRadius: ".25rem",
    height: "0.6rem",
    lineHeight: 0,
    fontSize: ".75rem",
    overflow: "hidden",
    marginBottom: "16px",
  },
  progressBarSelf: {
    height: "100%",
    // backgroundColor: "#007bff",
    backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  progressBarOther: {
    height: "100%",
    backgroundColor: "#5bc0de",
    // backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  fab: {
    margin: "0px",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
  },
  fabItem: {
    margin: "4px",
  },
  listCard: {
    boxShadow: "none",
    background: "transparent",
  },
  dataGrid: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  roundedBadge: {
    borderRadius: "4px",
    backgroundColor: "#89b17c",
    color: "white",
    display: "inline-block",
    padding: "4px 8px",
    minHeight: "26px",
    maxHeight: "200px",
    textAlign: "left",
    fontSize: "13px",
  },
  textDzongkha: {
    // margin: 12,
    fontSize: 16,
    textAlign: "justify",
    fontFamily: "jomo",
    // fontFamily: "ddcuchen",
  },
}));

function DomainRoadmap({ ...props }) {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  const location = useLocation();
  //   const history = useHistory();

  // regex to check if text other than english
  var english = /^[A-Za-z0-9\s\&:,\(\).!;\-\n']*$/;

  const [showLoader, setShowLoader] = React.useState(true);

  //const test datammnn
  const [domainRoadmap, setDomainRoadmap] = React.useState(
    Factory.createDomainRoadmap()
  );

  const [selectedDomainRoadmapIndex, setSelectedDomainRoadmapIndex] =
    React.useState("");

  const [domainRoadmaps, setDomainRoadmaps] = React.useState([]);

  //data is loading
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  //for getting more lessons
  const [page, setPage] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(false);

  //query string
  const [queryString, setQueryString] = React.useState({});

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 2000,
  });

  const [opType, setOpType] = React.useState("add");

  const [
    isAddEditDomainRoadmapDialogOpen,
    setIsAddEditDomainRoadmapDialogOpen,
  ] = React.useState(false);

  //alert dialog
  const [alertDialogOptions, setAlertDialogOptions] = React.useState({
    open: false,
    title: "",
    message: "Delete this roadmap?",
    cancelMessage: "Cancel",
    confirmMessage: "OK",
  });

  //close dialog
  function handleCloseAddEditDomainRoadmapDialog() {
    setOpType("add");
    setIsAddEditDomainRoadmapDialogOpen(false);
  }

  //delete roadmap
  function handleOpenAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: true });
  }

  function handleCloseAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
  }

  function handleConfirmDeleteLessonPlan() {
    handleOpenAlertDialog();
  }

  async function handleProceedDeleteDomainRoadmap() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
    let temp = [...domainRoadmaps];
    temp.splice(selectedDomainRoadmapIndex, 1);
    try {
      await DomainRoadmapService.deleteDomainRoadmapById(
        currentUser.organisation.id,
        domainRoadmap._id
      );
      setDomainRoadmaps(temp);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Domain Roadmap Deleted",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  }

  function handleConfirmDeleteAppraisal() {
    handleOpenAlertDialog();
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  /*
  fetch domainRoadmap nodes, pre-load verify token
  */

  useEffect(() => {
    onLoad();
    // }
  }, [queryString]);

  async function onLoad() {
    try {
      let data = await DomainRoadmapService.getDomainRoadmapByOrgId(
        currentUser.organisation.id,
        queryString
      );
      setDomainRoadmaps(data.domainRoadmaps);
      setHasMore(data.hasMore);
      setPage(data.page);
      setIsDataLoading(false);
    } catch (err) {
      setErrorLoadingPage(true);
    }
    //hide loader
    setShowLoader(false);
  }

  // handle load more roadmaps
  const handleLoadMoreDomainRoadmaps = async () => {
    try {
      //update query page
      queryString.page = page;
      //get data
      let data = await DomainRoadmapService.getDomainRoadmapByOrgId(
        currentUser.organisation.id,
        queryString
      );
      //update lessons in grid
      let temp = [...domainRoadmaps];
      temp = temp.concat(data.domainRoadmaps);
      setDomainRoadmaps(temp);
      setHasMore(data.hasMore);
      setPage(data.page);
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error downloading domain roadmaps",
      });
    }
  };

  //grid view for lessons
  const gridView = () => {
    return (
      <Grid
        container
        spacing={3}
        style={{ marginTop: "32px", padding: "24px" }}
      >
        {domainRoadmaps.map((domainRoadmap, index) => {
          return (
            <Grid
              item
              xs={12}
              sm={12}
              key={"domainRoadmap" + index}
              style={{
                borderRadius: "10px",
                backgroundColor: "#fff",
                marginBottom: "32px",
                padding: "32px",
              }}
            >
              <div>
                <div className={classes.roundedBadge}>
                  Learning Cycle {domainRoadmap.learningCycle}, Grade{" "}
                  {domainRoadmap.grade}, {domainRoadmap.domainArea.displayName},{" "}
                  {moment(domainRoadmap.startDate).format("DD-MMM-YYYY")}
                </div>
                <div style={{ float: "right" }}>
                  Duration in weeks: {domainRoadmap.duration}
                  <br />
                  Created By:{" "}
                  {domainRoadmap.createdBy.firstName +
                    " " +
                    domainRoadmap.createdBy.lastName}
                </div>
              </div>
              <br />
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Focus Area / Concepts
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={(() => {
                      return english.test(domainRoadmap.focusArea)
                        ? null
                        : classes.textDzongkha;
                    })()}
                  >
                    {domainRoadmap.focusArea
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={index + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </div>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Subarea / Sub-concepts
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={(() => {
                      return english.test(domainRoadmap.focusArea)
                        ? null
                        : classes.textDzongkha;
                    })()}
                  >
                    {domainRoadmap.subAreas
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={"subarea" + index + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </div>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Skills, Processes and Watermarks
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography variant="body2" gutterBottom>
                    <span
                      style={{ fontStyle: "italic" }}
                      className={(() => {
                        return english.test(domainRoadmap.skills.join(", "))
                          ? null
                          : classes.textDzongkha;
                      })()}
                    >
                      Skills: {domainRoadmap.skills.join(", ")}
                    </span>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <span
                      style={{ fontStyle: "italic" }}
                      className={(() => {
                        return english.test(domainRoadmap.processes.join(","))
                          ? null
                          : classes.textDzongkha;
                      })()}
                    >
                      Processes: {domainRoadmap.processes.join(", ")}
                    </span>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <span
                      style={{ fontStyle: "italic" }}
                      className={(() => {
                        return english.test(domainRoadmap.watermarks.join(","))
                          ? null
                          : classes.textDzongkha;
                      })()}
                    >
                      Watermarks: {domainRoadmap.watermarks.join(", ")}
                    </span>
                  </Typography>
                </div>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Action Plan
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={(() => {
                      return english.test(domainRoadmap.actionPlan)
                        ? null
                        : classes.textDzongkha;
                    })()}
                  >
                    {domainRoadmap.actionPlan
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={"ap" + index + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </div>
              </div>
              <div style={{ marginBottom: "12px" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Indicators of Success
                </Typography>
                <div style={{ marginLeft: "12px" }}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={(() => {
                      return english.test(domainRoadmap.indOfSuccess)
                        ? null
                        : classes.textDzongkha;
                    })()}
                  >
                    {domainRoadmap.indOfSuccess
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={"ind" + index + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Typography>
                </div>
              </div>
              {[
                "teacher",
                "headOfSchool",
                "schoolAdmin",
                "domainHead",
                "areaCoordinator",
              ].includes(currentUser.role) && (
                <div style={{ marginBottom: "12px" }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Comments
                  </Typography>
                  <div style={{ marginLeft: "12px" }}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={(() => {
                        return english.test(domainRoadmap.actionPlan)
                          ? null
                          : classes.textDzongkha;
                      })()}
                    >
                      {domainRoadmap.comments
                        .split("\n")
                        .map(function (item, bodyIndex) {
                          return (
                            <span
                              key={"ind" + index + bodyIndex}
                              style={{ wordBreak: "break-word" }}
                            >
                              {item}
                              <br />
                            </span>
                          );
                        })}
                    </Typography>
                  </div>
                </div>
              )}
              {["teacher", "domainHead", "areaCoordinator"].includes(
                currentUser.role
              ) && (
                <div style={{ float: "right" }}>
                  <Tooltip title="Edit">
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        setOpType("edit");
                        setSelectedDomainRoadmapIndex(index);
                        setDomainRoadmap(domainRoadmap);
                        setIsAddEditDomainRoadmapDialogOpen(true);
                      }}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => {
                        setSelectedDomainRoadmapIndex(index);
                        setDomainRoadmap(domainRoadmap);
                        handleOpenAlertDialog();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </Grid>
          );
        })}

        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!hasMore}
            onClick={handleLoadMoreDomainRoadmaps}
            style={{
              marginBottom: "32px",
            }}
          >
            {hasMore ? "Load More" : "No more  results"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const domainRoadmapsContainer = (
    <Fragment>
      <AlertDialog
        alertDialogOptions={alertDialogOptions}
        handleClose={handleCloseAlertDialog}
        handleConfirm={handleProceedDeleteDomainRoadmap}
      />
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />

      <AddEditDomainRoadmap
        domainRoadmap={domainRoadmap}
        setDomainRoadmap={setDomainRoadmap}
        domainRoadmaps={domainRoadmaps}
        setDomainRoadmaps={setDomainRoadmaps}
        selectedDomainRoadmapIndex={selectedDomainRoadmapIndex}
        opType={opType}
        setOpType={setOpType}
        isAddEditDomainRoadmapDialogOpen={isAddEditDomainRoadmapDialogOpen}
        handleCloseAddEditDomainRoadmapDialog={
          handleCloseAddEditDomainRoadmapDialog
        }
        toastOptions={toastOptions}
        setToastOptions={setToastOptions}
        handleCloseToast={handleCloseToast}
      />
      <div className={classes.root}>
        {["domainHead", "teacher", "areaCoordinator"].includes(
          currentUser.role
        ) && (
          <div className={classes.fab}>
            <Fab
              color="primary"
              aria-label="add"
              size="small"
              className={classes.fabItem}
            >
              <AddIcon
                onClick={() => {
                  setOpType("add");
                  //clear dialog
                  setDomainRoadmap(Factory.createDomainRoadmap());
                  setIsAddEditDomainRoadmapDialogOpen(true);
                }}
              />
            </Fab>
          </div>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DomainRoadmapFilter
              queryString={queryString}
              setQueryString={setQueryString}
              toastOptions={toastOptions}
              setToastOptions={setToastOptions}
            />
          </Grid>
        </Grid>
        {/* loading icon */}
        <Grid
          item
          xs={12}
          style={{
            display: showLoader ? "block" : "none",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress />
          </div>
        </Grid>
        {/* no domainRoadmaps */}
        <Grid
          item
          xs={12}
          style={{
            display:
              domainRoadmaps.length == 0 && !showLoader ? "block" : "none",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "block" }}>
              <HourglassEmptyIcon style={{ fontSize: "40px" }} />
            </div>
            <div style={{ display: "block" }}>
              <Typography variant="h6" className={classes.textCenter}>
                There are no roadmaps. Make sure year, grade and domain are
                selected.
              </Typography>
            </div>
          </div>
        </Grid>
        {/* domainRoadmap container grid  view */}
        {gridView()}
      </div>
    </Fragment>
  );

  if (errorLoadingPage) {
    return <ErrorLoadingPage />;
  } else {
    return domainRoadmapsContainer;
  }
}

export default DomainRoadmap;
