import React, { Fragment, useEffect } from "react";
import { AnnouncementService } from "../../../services/announcement";
import Grid from "@material-ui/core/Grid";
import { useAppContext } from "../../../libs/contextLib";
import { Utils } from "../../../helpers/utils";
import Moment from "react-moment";
//import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "32px",
  },
  roundedBadge: {
    borderRadius: "4px",
    backgroundColor: "#89b17c",
    color: "white",
    display: "inline-block",
    padding: "4px 8px",
    minHeight: "26px",
    maxHeight: "26px",
    textAlign: "center",
    fontSize: "13px",
  },
  announcement: {},
  title: {
    display: "block",
    fontWeight: "bold",
    fontSize: "13px",
  },
  body: {
    display: "block",
    textAlign: "justify",
    fontSize: "13px",
    wordBreak: "break-word",
  },
  line: {
    height: "1px",
    padding: "0px 4px",
    backgroundColor: "#cbd2d4",
    marginBottom: "20px",
    marginTop: "32px",
  },
  date: {
    display: "block",
    fontSize: "11px",
    marginBottom: "26px",
  },
}));

function Announcement(props) {
  const classes = useStyles();

  const { currentUser } = useAppContext();
  const [announcements, setAnnouncements] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorRetrieving, setErrorRetrieving] = React.useState(false);

  //pre-load verify token
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      setAnnouncements(
        await AnnouncementService.getAnnouncementsByOrgId(
          currentUser.organisation.id
        )
      );
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrorRetrieving(true);
      // console.log(err);
    }
  }

  return (
    <div className={classes.root}>
      {/* top row */}
      <Grid
        container
        spacing={3}
        style={{
          borderRadius: "10px",
          backgroundColor: "#f1f5ee",
        }}
      >
        <Grid item xs={12} sm={12}>
          <span className={classes.roundedBadge}>Announcements</span>
        </Grid>
        {isLoading ? (
          <div
            style={{
              padding: "12px",
              height: "250px",
              width: "100%",
            }}
          >
            <Skeleton variant="rect" width="100%" height="100%" />
          </div>
        ) : errorRetrieving ? (
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              overflow: "scroll",
              paddingTop: "0",
              minHeight: "250px",
              maxHeight: "250px",
            }}
          >
            <span>Error retrieving announcements</span>
          </Grid>
        ) : announcements.length > 0 ? (
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              overflow: "scroll",
              paddingTop: "0",
              minHeight: "250px",
              maxHeight: "250px",
            }}
          >
            {announcements.map((announcement, annIndex) => (
              <Fragment key={annIndex}>
                <div className={classes.announcement} key={annIndex + "title"}>
                  <span className={classes.title}>{announcement.title}</span>
                  <span className={classes.date}>
                    <Moment format="MMMM DD YYYY">
                      {announcement.dateCreated}
                    </Moment>
                  </span>
                  <span className={classes.body}>
                    {announcement.body
                      .split("\n")
                      .map(function (item, bodyIndex) {
                        return (
                          <span
                            key={annIndex + bodyIndex}
                            style={{ wordBreak: "break-word" }}
                          >
                            {Utils.urlify(item)}
                            <br />
                          </span>
                        );
                      })}
                  </span>
                </div>
                <div className={classes.line}></div>
              </Fragment>
            ))}
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              overflow: "scroll",
              paddingTop: "0",
              minHeight: "250px",
              maxHeight: "250px",
            }}
          >
            There are no announcements.
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          style={{ paddingBottom: "2px", textAlign: "center" }}
        ></Grid>
      </Grid>
    </div>
  );
}

export default Announcement;
