import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppContext } from "../../../libs/contextLib";

import LeResourceNotesDialog from "./leResourceNotesDialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import { makeStyles } from "@material-ui/core/styles";

import LeResourcesFilter from "./leResourcesFilter";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Button } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import Fab from "@material-ui/core/Fab";
import DeleteIcon from "@material-ui/icons/Delete";
import AlertDialog from "../../helperComponents/alertDialog";

import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import LeResourceDialog from "./leResourceDialog";
import { Factory } from "../../../helpers/factories";
import { LeResourcesService } from "../../../services/leResources";

import moment from "moment";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { Fragment } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import Toast from "../../helperComponents/toast";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  //   avatar: {
  //     backgroundColor: "#009be5",
  //   },
  contentWrapper: {
    margin: "40px 16px",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
  accordionFilter: {
    marginBottom: "5px",
    border: 0,
    // borderRadius: "8px",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  cardRoot: {
    minWidth: 190,
  },

  cardTitle: {
    fontSize: 14,
    fontWeight: "bold",
  },
  cardPos: {
    marginBottom: 12,
  },
  progress: {
    width: "100%",
    backgroundColor: "#e9ecef",
    borderRadius: ".25rem",
    height: "0.6rem",
    lineHeight: 0,
    fontSize: ".75rem",
    overflow: "hidden",
    marginBottom: "16px",
  },
  progressBarSelf: {
    height: "100%",
    // backgroundColor: "#007bff",
    backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  progressBarOther: {
    height: "100%",
    backgroundColor: "#5bc0de",
    // backgroundColor: "#5cb85c",
    transition: "width .6s ease",
  },
  fab: {
    margin: "0px",
    top: "auto",
    right: "20px",
    bottom: "20px",
    left: "auto",
    position: "fixed",
  },
  fabItem: {
    margin: "4px",
  },
  listCard: {
    boxShadow: "none",
    background: "transparent",
  },
  dataGrid: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  iframeContainer: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    paddingTop: "56.25%",
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    border: "none",
  },
  tagsContainer: {
    marginTop: "15px",
    minHeight: "20px",
  },
}));

function LeResources() {
  const { currentUser } = useAppContext();
  const classes = useStyles();

  const location = useLocation();
  //   const history = useHistory();

  const [showLoader, setShowLoader] = React.useState(true);

  //const test datammnn
  const [leResource, setLeResource] = React.useState(
    Factory.createLessonPlan()
  );
  const [leResourceId, setLeResourceId] = React.useState(null); //not really used but need it for viewLesson which is called also from current timetbale

  const [selectedLeResourceIndex, setSelectedLeResourceIndex] =
    React.useState("");

  const [leResources, setLeResources] = React.useState([]);

  const [addLessonToLE, setAddLessonToLE] = React.useState(false);

  //data is loading
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  // le resource notes dialog
  const [isLeResourceNotesDialogOpen, setIsLeResourceNotesDialogOpen] =
    React.useState(false);

  //for getting more lessons
  const [page, setPage] = React.useState(0);
  const [hasMore, setHasMore] = React.useState(false);

  //query string
  const [queryString, setQueryString] = React.useState({});

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 2000,
  });

  const [opType, setOpType] = React.useState("add");

  const [isAddEditLeResourceDialogOpen, setIsAddEditLeResourceDialogOpen] =
    React.useState(false);

  //alert dialog
  const [alertDialogOptions, setAlertDialogOptions] = React.useState({
    open: false,
    title: "",
    message: "Delete this learning resource?",
    cancelMessage: "Cancel",
    confirmMessage: "OK",
  });

  //close leResource add/edit dialog
  function handleCloseLeResourceAddEditDialog() {
    setOpType("add");
    setIsAddEditLeResourceDialogOpen(false);
  }

  //close le resource notes dialog
  function handleCloseLeResourceNotesDialog() {
    setIsLeResourceNotesDialogOpen(false);
  }

  //delete le resource
  function handleOpenAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: true });
  }

  function handleCloseAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
  }

  function handleConfirmDeleteLeResource() {
    handleOpenAlertDialog();
  }

  async function handleProceedDeleteLeResource() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
    let temp = [...leResources];
    temp.splice(selectedLeResourceIndex, 1);
    try {
      await LeResourcesService.deleteLeResourcesByOrgIdAndLeResourceId(
        currentUser.organisation.id,
        leResource._id
      );
      setLeResources(temp);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Resource Deleted",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  /*
  fetch leResource nodes, pre-load verify token
  */

  useEffect(() => {
    //if path is personal leResource, set query string user  to user id
    // if (location.pathname === "/leResource") {
    // queryString.user = currentUser.id;
    // }
    // if (props.startFetchData) {
    onLoad();
    // }
  }, [queryString]);

  async function onLoad() {
    try {
      let data = await LeResourcesService.getLeResourcesByOrgId(
        currentUser.organisation.id,
        queryString
      );
      setLeResources(data.leResources);
      setHasMore(data.hasMore);
      setPage(data.page);
      setIsDataLoading(false);
    } catch (err) {
      setErrorLoadingPage(true);
    }
    //hide loader
    setShowLoader(false);
  }

  // handle load more lessons
  const handleLoadMoreLeResources = async () => {
    try {
      //update query page
      queryString.page = page;
      //get data
      let data = await LeResourcesService.getLeResourcesByOrgId(
        currentUser.organisation.id,
        queryString
      );
      //update lessons in grid
      let temp = [...leResources];
      temp = temp.concat(data.leResources);
      setLeResources(temp);
      setHasMore(data.hasMore);
      setPage(data.page);
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error downloading learning resources",
      });
    }
  };

  //grid view for lessons
  const gridView = () => {
    return (
      <Grid container spacing={3}>
        {leResources.map((leResource, index) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={"leResource" + index}>
              <Card>
                <CardHeader
                  title={leResource.title}
                  subheader={moment(leResource.dateUploaded).format(
                    "DD/MM/YYYY"
                  )}
                />
                <CardContent>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    {leResource.organisation.name}
                  </Typography>
                </CardContent>

                <CardContent>
                  <div className={classes.iframeContainer}>
                    <iframe
                      src={leResource.link}
                      width="100%"
                      height="300"
                      allow="fullscreen"
                      pop
                      className={classes.iframe}
                    ></iframe>
                  </div>
                  <div className={classes.tagsContainer}>
                    <Chip
                      size="small"
                      label={leResource.domainArea.displayName}
                      variant="outlined"
                      color="primary"
                      style={{ margin: "3px" }}
                    />
                    {leResource.tags
                      ? leResource.tags.map((tag) => {
                          return (
                            <Chip
                              size="small"
                              key={tag}
                              label={tag}
                              variant="outlined"
                              color="primary"
                              style={{ margin: "3px" }}
                            />
                          );
                        })
                      : null}
                  </div>
                </CardContent>
                {/* <CardActions disableSpacing>
                  {currentUser.role === "schoolAdmin" &&
                    currentUser.organisation.id ===
                      leResource.organisation._id && (
                      <>
                        <Tooltip title="Delete Learning Resource">
                          <IconButton
                            aria-label="delete-learning-resource"
                            onClick={() => {
                              setLeResource(leResource);
                              setSelectedLeResourceIndex(index);
                              handleOpenAlertDialog();
                            }}
                          > */}
                <CardActions disableSpacing>
                  {currentUser &&
                    currentUser.role === "schoolAdmin" &&
                    currentUser.organisation &&
                    currentUser.organisation.id ===
                      leResource.organisation._id && (
                      <>
                        <Tooltip title="Delete Learning Resource">
                          <IconButton
                            aria-label="delete-learning-resource"
                            onClick={() => {
                              setLeResource(leResource);
                              setSelectedLeResourceIndex(index);
                              handleOpenAlertDialog();
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Learning Resource">
                          <IconButton
                            aria-label="settings"
                            onClick={() => {
                              setSelectedLeResourceIndex(index);
                              setLeResource(leResource);
                              setOpType("edit");
                              setIsAddEditLeResourceDialogOpen(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  <Tooltip title="Notes / Discussion">
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        setLeResource(leResource);
                        setIsLeResourceNotesDialogOpen(true);
                      }}
                    >
                      <DescriptionIcon />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </Card>
            </Grid>
          );
        })}

        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!hasMore}
            onClick={handleLoadMoreLeResources}
            style={{
              marginBottom: "32px",
            }}
          >
            {hasMore ? "Load More" : "No more  results"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const leResourcesContainer = (
    <Fragment>
      <AlertDialog
        alertDialogOptions={alertDialogOptions}
        handleClose={handleCloseAlertDialog}
        handleConfirm={handleProceedDeleteLeResource}
      />
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />
      <LeResourceNotesDialog
        description={leResource.description}
        isLeResourceNotesDialogOpen={isLeResourceNotesDialogOpen}
        handleCloseLeResourceNotesDialog={handleCloseLeResourceNotesDialog}
      />
      <LeResourceDialog
        leResource={leResource}
        setLeResource={setLeResource}
        leResources={leResources}
        setLeResources={setLeResources}
        selectedLeResourceIndex={selectedLeResourceIndex}
        opType={opType}
        setOpType={setOpType}
        isAddEditLeResourceDialogOpen={isAddEditLeResourceDialogOpen}
        handleCloseLeResourceAddEditDialog={handleCloseLeResourceAddEditDialog}
        toastOptions={toastOptions}
        setToastOptions={setToastOptions}
        handleCloseToast={handleCloseToast}
      />
      <div className={classes.root}>
        {currentUser.role === "schoolAdmin" && (
          <div className={classes.fab}>
            <Fab
              color="primary"
              aria-label="add"
              size="small"
              className={classes.fabItem}
            >
              <AddIcon
                onClick={() => {
                  setOpType("add");
                  //clear dialog
                  setLeResource(Factory.createLeResource());
                  setIsAddEditLeResourceDialogOpen(true);
                }}
              />
            </Fab>
          </div>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Accordion className={classes.accordionFilter}>
              <AccordionSummary
                expandIcon={<FilterListIcon color="primary" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {/* <Typography variant="body1" gutterBottom>
                Filter
              </Typography> */}
              </AccordionSummary>
              <AccordionDetails>
                <LeResourcesFilter
                  queryString={queryString}
                  setQueryString={setQueryString}
                  toastOptions={toastOptions}
                  setToastOptions={setToastOptions}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        {/* loading icon */}
        <Grid
          item
          xs={12}
          style={{
            display: showLoader ? "block" : "none",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress />
          </div>
        </Grid>
        {/* no leResources */}
        <Grid
          item
          xs={12}
          style={{
            display: leResources.length == 0 && !showLoader ? "block" : "none",
          }}
        >
          <div
            style={{
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "block" }}>
              <HourglassEmptyIcon style={{ fontSize: "40px" }} />
            </div>
            <div style={{ display: "block" }}>
              <Typography variant="h6" className={classes.textCenter}>
                There are no learning experience resources.
              </Typography>
            </div>
          </div>
        </Grid>
        {/* leResource container grid  view */}
        {gridView()}
      </div>
    </Fragment>
  );

  // if (errorLoadingPage) {
  //   return <ErrorLoadingPage />;
  // } else {
  return leResourcesContainer;
  // }
}

export default LeResources;
