import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import Skeleton from "@material-ui/lab/Skeleton";

// const data = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

export default function ChartBarTags({ data }) {
  return (
    <ResponsiveContainer width="100%" height={300}>
      {data.length > 0 ? (
        <BarChart
          width={500}
          height={250}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={10}
          layout="vertical"
        >
          <XAxis type={"number"} />
          <YAxis
            dataKey="name"
            type="category"
            style={{
              fontSize: "0.7rem",
            }}
          />
          <Tooltip />
          {/* <Legend /> */}
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="count" fill="#089ce5" background={{ fill: "#eee" }} />
        </BarChart>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton variant="rect" width="80%" height={250} />
        </div>
      )}
    </ResponsiveContainer>
  );
}
