/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const ReportService = {
  getReportsByOrgId,
  getChildReportsByOrgIdAndReportId,
  addReportByOrgId,
  addChildReportByOrgIdAndReportId,
  updateReportsByOrgIdAndReportId,
  updateChildReportByOrgIdAndReportIdAndChildReportId,
  deleteReportsByOrgIdAndReportId,
  deleteChildReportByOrgIdAndReportIdAndChildReportId,
};

function getReportsByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_reports_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.reports;
    });
}

function getChildReportsByOrgIdAndReportId(orgId, reportId) {
  const requestOptions = RequestHelper.getGetRequestOptions();
  var api_url = config.api_childReport_by_orgId_and_reportId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    reportId: reportId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.childReports;
    });
}

function addReportByOrgId(orgId, report) {
  const requestOptions = RequestHelper.getPostRequestOptions(report);

  var api_url = config.api_reports_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  // + RequestHelper.formatQueryString(options);
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.report;
    });
}

function addChildReportByOrgIdAndReportId(orgId, reportId, childReport) {
  const requestOptions = RequestHelper.getPostRequestOptions(childReport);

  var api_url = config.api_childReport_by_orgId_and_reportId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    reportId: reportId,
  });

  // + RequestHelper.formatQueryString(options);
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.childReport;
    });
}

function updateReportsByOrgIdAndReportId(orgId, reportId, data, options = {}) {
  const requestOptions = RequestHelper.getPutRequestOptions(data);

  var api_url = config.api_reports_by_orgId_and_reportId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    reportId: reportId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.report;
    });
}

function updateChildReportByOrgIdAndReportIdAndChildReportId(
  orgId,
  reportId,
  childReportId,
  data,
  options = {}
) {
  const requestOptions = RequestHelper.getPutRequestOptions(data);

  var api_url = config.api_childReport_by_orgId_and_reportId_and_childReportId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    reportId: reportId,
    childReportId: childReportId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.childReport;
    });
}

function deleteReportsByOrgIdAndReportId(orgId, reportId) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_reports_by_orgId_and_reportId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    reportId: reportId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function deleteChildReportByOrgIdAndReportIdAndChildReportId(
  orgId,
  reportId,
  childReportId
) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_childReport_by_orgId_and_reportId_and_childReportId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    reportId: reportId,
    childReportId: childReportId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
