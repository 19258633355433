import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { UserService } from "../../../services/user";
import Grid from "@material-ui/core/Grid";
import { Factory } from "../../../helpers/factories";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import UserDialog from "./userDialog";
import UsersDataGrid from "./usersDataGrid";
import UsersFilter from "./usersFilter";
import Toast from "../../helperComponents/toast";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "none",
  },
  dataGridRoot: {
    width: "100%",
  },
  dataGrid: {
    backgroundColor: "white",
  },
  button: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatarBackground: {
    //color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: theme.palette.primary.main, //deepOrange[500],
  },
}));

export default function Users(props) {
  const classes = useStyles();
  const { currentUser } = useAppContext();

  //current selected user
  const [user, setUser] = React.useState(Factory.createUser());

  //list of users
  const [users, setUsers] = React.useState([]);

  const [queryString, setQueryString] = React.useState({});

  const [isLoading, setIsLoading] = React.useState(true);

  const [selectedRowIndex, setSelectedRowIndex] = React.useState("");
  const [opType, setOpType] = React.useState("");
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const [selectedCSVFile, setSelectedCSVFile] = React.useState();
  const [isFilePicked, setIsFilePicked] = React.useState(false);

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 6000,
  });

  //pre-load verify token
  useEffect(() => {
    if (props.startFetchData) {
      onLoad();
    }
  }, [props.startFetchData, queryString]);

  //check when file is ready for upload and begin upload process
  useEffect(() => {
    if (isFilePicked) {
      uploadFile();
    }
  }, [isFilePicked]);

  async function onLoad() {
    try {
      const foundUsers = await UserService.getUserByOrgId(
        currentUser.organisation.id
      );
      setUsers(loadRows(foundUsers));
      setIsLoading(false);
    } catch (err) {
      setErrorLoadingPage(true);
    }
  }

  function loadRows(users) {
    let temp = [];
    users.forEach((user, index, anns) => {
      temp.push({
        id: index,
        user: user,
      });
    });
    return temp;
  }

  const handleUploadFile = (event) => {
    setSelectedCSVFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const uploadFile = async () => {
    //upload form
    const formData = new FormData();
    formData.append("usersCSV", selectedCSVFile);

    //toast
    setToastOptions({
      ...toastOptions,
      open: true,
      message: "Uploading...",
    });

    try {
      const a = await UserService.uploadCSVFile(
        currentUser.organisation.id,
        formData
      );

      setToastOptions({
        ...toastOptions,
        open: true,
        message: "CSV file uploaded and user accounts created",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error Uploading: " + err.message,
      });
    }

    setIsFilePicked(false);
    setSelectedCSVFile(null);
  };

  function handleCreateUser() {
    setUser(Factory.createUser());
    setOpType("add");
    setIsDialogOpen(true);
  }

  function handleCloseDialog() {
    setIsDialogOpen(false);
  }

  function handleOpenDialog() {
    setIsDialogOpen(true);
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  if (errorLoadingPage) {
    return (
      <ErrorLoadingPage message="There was a problem loading the page. Try refreshing the page." />
    );
  } else {
    return (
      <div className={classes.root}>
        <Toast
          toastOptions={toastOptions}
          handleCloseToast={handleCloseToast}
        />
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <Typography variant="h4" className={classes.textCenter}>
              Students
            </Typography>
          </Grid> */}
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {currentUser.role === "schoolAdmin" && (
              <Fragment>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleCreateUser}
                  className={classes.button}
                >
                  Add User
                </Button>
                <div>
                  <input
                    accept=".csv"
                    onChange={(e) => {
                      handleUploadFile(e);
                    }}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    name="usersCSV"
                    enctype="multipart/form-data"
                    val={selectedCSVFile}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.button}
                    >
                      Upload CSV
                    </Button>
                  </label>
                </div>
              </Fragment>
            )}
          </Grid>
          <Grid item xs={12}>
            <UserDialog
              user={user}
              setUser={setUser}
              opType={opType}
              setOpType={setOpType}
              isDialogOpen={isDialogOpen}
              handleCloseDialog={handleCloseDialog}
              toastOptions={toastOptions}
              setToastOptions={setToastOptions}
            />
            {/* {StudentDialog({
            //isDialogOpen: isDialogOpen,
            // handleCloseDialog: handleCloseDialog,
            // formData: formData,
          })} */}
          </Grid>
          <Grid itex xs={12}>
            <UsersFilter
              user={user}
              setUser={setUser}
              opType={opType}
              setOpType={setOpType}
              setIsDialogOpen={setIsDialogOpen}
            />
          </Grid>
          <Grid item xs={12}>
            <UsersDataGrid
              setUser={setUser}
              user={user}
              users={users}
              setOpType={setOpType}
              setSelectedRowIndex={selectedRowIndex}
              setIsDialogOpen={setIsDialogOpen}
              isDataLoading={isLoading}
              handleOpenDialog={handleOpenDialog}
            />
          </Grid>
          {/* {AnnouncementDataGrid({ loading: isDataLoading })} */}
        </Grid>
      </div>
    );
  }
}
