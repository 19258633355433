import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { ReportService } from "../../../services/report";
// import SearchUser from "../../helperComponents/searchUser";
import SearchUserAdvanced from "../../helperComponents/searchUserAdvanced";

import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import ReportDialog from "./reportDialog";
import AlertDialog from "../../helperComponents/alertDialog";
import Toast from "../../helperComponents/toast";

import { Factory } from "../../../helpers/factories";

import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import GetAppIcon from "@material-ui/icons/GetApp";
import generatePDF from "./generatePDF";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dataGridRoot: {
    height: "100vh",
    width: "100%",
  },
  dataGrid: {
    backgroundColor: "white",
  },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

function Report(props) {
  const classes = useStyles();
  const { currentUser } = useAppContext();

  //search user
  const [searchedUser, setSearchedUser] = React.useState(Factory.createUser);

  //query string
  const [queryString, setQueryString] = React.useState({});

  //active report
  const [activeReport, setActiveReport] = React.useState(Factory.createReport);

  //loaded reports
  const [reports, setReports] = React.useState([]);

  //current row selected
  const [selectedRowIndex, setSelectedRowIndex] = React.useState("");

  //operation type Save or Edit
  const [opType, setOpType] = React.useState("");

  //data is loading
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  //report dialog
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 6000,
  });

  //alert dialog
  const [alertDialogOptions, setAlertDialogOptions] = React.useState({
    open: false,
    title: "",
    message: "Delete this report?",
    cancelMessage: "Cancel",
    confirmMessage: "OK",
  });

  //rows for datagrid

  let rows = [];

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "_id", headerName: "_id", width: 120, hide: "true" },
    {
      field: "organisation",
      headerName: "Organisation",
      width: 120,
      hide: "true",
    },
    {
      field: "mentee",
      headerName: "Student",
      width: 200,
      renderCell: (params) => {
        return params.row.mentee.firstName + " " + params.row.mentee.lastName;
      },
    },
    {
      field: "mentor",
      headerName: "Mentor",
      width: 200,
      renderCell: (params) => {
        return params.row.mentor.firstName + " " + params.row.mentor.lastName;
      },
    },

    { field: "reportType", headerName: "Report Type", width: 130 },
    { field: "reportYear", headerName: "Report Year", width: 130 },

    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 130,
      renderCell: (params) => {
        return moment(params.row.dateCreated).format("DD-MM-yyyy");
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
      renderCell: (params) => {
        return params.row.status === "unlocked" ? (
          <LockOpenIcon color="primary" />
        ) : (
          <LockIcon color="primary" />
        );
      },
    },
    {
      field: "",
      headerName: "",
      disableClickEventBubbling: true,
      width: 170,
      renderCell: (params) => {
        const onClick = async (op, imageDownloadOption = "backend") => {
          let temp = { ...params.row };
          //make sure five area reports and domain reports defined as mongo doesn't save empty objects created by factory; rendering error otherwise
          if (!temp.fiveAreasReports) {
            temp["fiveAreasReports"] = {};
          }
          if (!temp.domainReports) {
            temp["domainReports"] = {};
          }

          //set active report
          setActiveReport(temp);
          setSelectedRowIndex(params.row.id);
          switch (op) {
            case "edit":
              handleOpenDialog();
              break;
            case "delete":
              handleConfirmDeleteReport();
              break;
            case "download":
              setToastOptions({
                ...toastOptions,
                message: "Preparing Download ...",
                open: true,
                duration: 300000,
              });
              try {
                await generatePDF(
                  currentUser.organisation.id,
                  params.row,
                  imageDownloadOption
                );
                setToastOptions({
                  ...toastOptions,
                  message: "Download Complete",
                  duration: 2000,
                  open: true,
                });
              } catch (err) {
                setToastOptions({
                  ...toastOptions,
                  message:
                    "Error Generating PDF. Possible image download failed.",
                  open: true,
                });
              }
              break;
            default:
          }
        };

        return (
          <div>
            <Tooltip title="View / Edit">
              <IconButton
                aria-label="edit"
                onClick={() => {
                  return onClick("edit");
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
            {currentUser.id === params.row.mentor._id ? (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={() => {
                    return onClick("delete");
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip title="Download">
              <IconButton
                aria-label="download"
                color="primary"
                onClick={() => {
                  return onClick("download", "backend");
                }}
              >
                <GetAppIcon />
              </IconButton>
            </Tooltip>
            {currentUser.organisation.id === "5fdc48c1097684456369ec97" ? (
              <Tooltip title="Download New">
                <IconButton
                  aria-label="download"
                  color="default"
                  onClick={() => {
                    return onClick("download", "frontend");
                  }}
                >
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            {/* <DownloadReportPDF report={params.row} /> */}
          </div>
        );
      },
    },
  ];

  //pre-load verify token
  useEffect(() => {
    if (props.startFetchData) {
      onLoad();
    }
  }, [props.startFetchData, queryString]);

  //searched user
  useEffect(() => {
    if (searchedUser._id != "") {
      setQueryString({ mentee: searchedUser._id });
    } else {
      setQueryString({});
    }
  }, [searchedUser]);

  async function onLoad() {
    try {
      setReports(
        await ReportService.getReportsByOrgId(
          currentUser.organisation.id,
          queryString
        )
      );
      setIsDataLoading(false);
    } catch (err) {
      setErrorLoadingPage(true);
    }
  }

  function loadRows() {
    // if (reports.length > 0) {
    //   console.log(reports[0].studentImageURL);
    //   fetch(reports[0].studentImageURL, { mode: "no-cors" })
    //     // .then((response) => response.json())
    //     .then((response) => response.blob())
    //     .then((images) => {
    //       // Then create a local URL for that image and print it
    //       console.log("images", images);
    //       let outside = URL.createObjectURL(images);
    //       console.log("outside", outside);
    //     });
    // }
    let temp = [];
    reports.forEach((report, index, anns) => {
      report["id"] = index;

      temp.push(report);
      //   temp.push({
      //     id: index,
      //     _id: report._id,
      //     dateCreated: moment(report.dateCreated).format("DD/MM/YYYY"),
      //     organisation: report.organisation._id,
      //     title: report.title,
      //     body: report.body,
      //     createdBy: report.createdBy,
      //     status: report.status,
      //   });
    });
    return temp;
  }

  function handleCreateReport() {
    setActiveReport(Factory.createReport);
    setOpType("add");
    setIsDialogOpen(true);
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setOpType("edit");
    setIsDialogOpen(true);
  };

  function handleOpenAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: true });
  }

  function handleCloseAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
  }

  function handleConfirmDeleteReport() {
    handleOpenAlertDialog();
  }

  async function handleProceedDeleteReport() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
    let temp = [...reports];
    temp.splice(selectedRowIndex, 1);
    try {
      await ReportService.deleteReportsByOrgIdAndReportId(
        currentUser.organisation.id,
        activeReport._id
      );
      setReports(temp);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Report Deleted",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Report not deleted. Make sure no domain/area/mentor reports",
      });
    }
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  //data grid
  function ReportDataGrid(props) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.dataGridRoot}>
          <DataGrid
            className={classes.dataGrid}
            rows={rows}
            columns={columns}
            pageSize={5}
            autoHeight={true}
            loading={isDataLoading}
            checkboxSelection={false}
            {...props}
          />
        </Grid>
      </Grid>
    );
  }

  //report
  function reportComponent(props) {
    return (
      <Fragment>
        <Toast
          toastOptions={toastOptions}
          handleCloseToast={handleCloseToast}
        />
        <AlertDialog
          alertDialogOptions={alertDialogOptions}
          handleClose={handleCloseAlertDialog}
          handleConfirm={handleProceedDeleteReport}
        />
        {/* View/Edit Report Dialog */}
        {/* <Dialog
          fullScreen
          open={isDialogOpen}
          onClose={handleCloseDialog}
          TransitionComponent={Transition}
        > */}
        {/* <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseDialog}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Sound
              </Typography>
              <Button autoFocus color="inherit" onClick={handleCloseDialog}>
                save
              </Button>
            </Toolbar>
          </AppBar> */}
        <ReportDialog
          activeReport={activeReport}
          setActiveReport={setActiveReport}
          reports={reports}
          setReports={setReports}
          isDialogOpen={isDialogOpen}
          handleCloseDialog={handleCloseDialog}
          selectedRowIndex={selectedRowIndex}
          setSelectedRowIndex={setSelectedRowIndex}
          opType={opType}
          setOpType={setOpType}
          toastOptions={toastOptions}
          setToastOptions={setToastOptions}
        />
        {/* </Dialog> */}
        {/* Report Form */}
        <div className={classes.root}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
              <Typography variant="h4" className={classes.textCenter}>
                Reports
              </Typography>
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCreateReport}
              >
                Add Report
              </Button>
            </Grid>
            <Grid container spacing={3}>
              {/* <Grid item xs={12}>
              <Typography variant="h4" className={classes.textCenter}>
                Reports
              </Typography>
            </Grid> */}
              <Grid item xs={12} sm={12}>
                {/* <SearchUser
                  user={searchedUser}
                  setUser={setSearchedUser}
                  role={""}
                  style={{ display: "flex" }}
                /> */}
                <SearchUserAdvanced
                  user={searchedUser}
                  setUser={setSearchedUser}
                  userQueryOptions={queryString}
                  setUserQueryOptions={setQueryString}
                  style={{ display: "flex" }}
                />
              </Grid>
            </Grid>
            {ReportDataGrid()}
          </Grid>
        </div>
      </Fragment>
    );
  }

  if (!errorLoadingPage) {
    rows = loadRows();
    return reportComponent();
  } else {
    return <ErrorLoadingPage />;
  }
}

export default Report;
