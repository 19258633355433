import { useState, useEffect } from "react";
import { AppContext } from "./libs/contextLib";
import Routes from '../src/Routes'
import { useHistory } from "react-router-dom";
import {Auth} from './services/auth'
// import { ThemeProvider } from '@material-ui/core/styles'
// import theme from "./theme"


function App() {
  const history = useHistory()
  //state  
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState();



  //pre-load verify token
  useEffect(() => {
    async function getCurrentSession(){
      try{
        console.log("app.jsx - call auth.currentsession()")
        const user = await Auth.currentSession()
  
        setCurrentUser(user);
        userHasAuthenticated(true);
        setIsAuthenticating(false);
       }
      catch (error) {
        console.log("app.jsx - logging out:", error)
        Auth.logout()
        setIsAuthenticating(false);
        history.push('/')
      }
    }

    getCurrentSession()
  }, []);

  return (
    !isAuthenticating && (
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, currentUser, setCurrentUser }}>
          <Routes />
      </AppContext.Provider>

    )
  );
}

export default App;
