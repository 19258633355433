/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const MenteeService = {
  addMentorMenteeByOrgIdAndUserId,
  getMenteesByOrgIdAndUserId,
  getMentorsByOrgIdAndUserId,
  getMenteesByOrgId,
  deleteMentorMenteeByOrgIdAndUserId,
};

function addMentorMenteeByOrgIdAndUserId(orgId, mentorId, mentorMentee) {
  const requestOptions = RequestHelper.getPostRequestOptions(mentorMentee);

  var api_url = config.api_mentees_by_orgId_and_userId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    userId: mentorId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.announcement;
    });
}

function getMenteesByOrgIdAndUserId(orgId, userId) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_mentees_by_orgId_and_userId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    userId: userId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.mentorMentees;
    });
}

function getMentorsByOrgIdAndUserId(orgId, userId) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_mentors_by_orgId_and_userId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    userId: userId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.mentors;
    });
}

function getMenteesByOrgId(orgId, options = {}) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_mentees_by_orgId;
  api_url =
    RequestHelper.replaceParams(api_url, {
      orgId: orgId,
    }) + RequestHelper.formatQueryString(options);

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.mentorMentees;
    });
}

function deleteMentorMenteeByOrgIdAndUserId(orgId, userId, mentorMentee) {
  const requestOptions = RequestHelper.getDeleteRequestOptions(mentorMentee);

  var api_url = config.api_mentees_by_orgId_and_userId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    userId: userId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data._id;
    });
}
