import React, { useState } from "react";
import { useAppContext } from "../../libs/contextLib";
import { UserService } from "../../services/user";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Factory } from "../../helpers/factories";
import Roles from "../../helpers/roles";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Fade from "@material-ui/core/Fade";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterElements: {
    margin: theme.spacing(2),
    width: "90%",
  },
}));

export default function SearchUserAdvanced({
  label = "Search User",
  user,
  setUser,
  userQueryOptions,
  setUserQueryOptions,
  role,
  isDisabled,
  isReadOnly,
  ...props
}) {
  const { currentUser } = useAppContext();

  const classes = useStyles();

  const queryOptions = { ...userQueryOptions };

  const [checked, setChecked] = React.useState(false);

  //autocomplete suggestions
  const [searchSettings, setSearchSettings] = useState({
    userName: "",
    suggestions: [],
  });

  //autcomplete loading
  const [loading, setLoading] = useState(true);

  const handleShowHideSearchOptions = () => {
    setChecked((prev) => !prev);
  };

  //handle key change
  const handleChange = (e) => {
    //query options
    switch (e.target.name) {
      case "name":
        queryOptions["name"] = e.target.value;
        break;
      case "grade":
        queryOptions["grade"] = e.target.value === "None" ? "" : e.target.value;
        break;
      case "groupSection":
        queryOptions["groupSection"] = e.target.value;
        break;
      case "role":
        queryOptions["role"] = e.target.value === "all" ? "" : e.target.value;
        break;
      case "yearJoined":
        queryOptions["yearJoined"] = e.target.value;
        break;
      case "nationalStudentCode":
        queryOptions["nationalStudentCode"] = e.target.value;
        break;
      case "accountStatus":
        queryOptions["accountStatus"] =
          e.target.value === "all" ? "" : e.target.value;
        break;
      default:
        break;
    }
    setUserQueryOptions(queryOptions);

    if (role) {
      queryOptions.role = role;
    }
    //call api
    (async () => {
      try {
        const filteredUsers = await UserService.getUserByOrgId(
          currentUser.organisation.id,
          queryOptions
        );

        setSearchSettings({
          ...searchSettings,
          suggestions: filteredUsers,
        });

        setLoading(false);
      } catch (err) {
        setSearchSettings({
          ...searchSettings,
          suggestions: [],
        });
      }
    })();
  };

  //return component

  return (
    <div style={{ marginBotton: "16px" }}>
      <Grid container spacing={3}>
        <Grid item xs={10} sm={6}>
          <Autocomplete
            id="asynchronous-demo"
            //size="small"
            disabled={isDisabled}
            style={{ width: "90%", margin: "16px" }}
            loading={loading}
            freeSolo
            defaultValue={() => {
              return user ? user : Factory.createUser();
            }}
            onChange={(e, v) => {
              if (v != null) {
                setUser(v);
              } else {
                setUser(Factory.createUser());
              }
            }}
            getOptionSelected={(option, value) => {
              // console.log("OPTION", option);
              // console.log("VALUE", value);
              return option.firstName === value.firstName;
            }}
            getOptionLabel={(option) => {
              return option.firstName
                ? option.firstName + " " + option.lastName
                : "";
            }}
            renderOption={(option) => {
              // console.log("opton", option);
              return (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div style={{ display: "flex", marginRight: "10px   " }}>
                    <Avatar src={option.picURL} alt={option.firstName} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="body2">
                      {option.firstName + " " + option.lastName}
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{ textTransform: "capitalize" }}
                    >
                      {option.role === "student" && `${option.username}, `}
                      {Roles[option.role].displayName}{" "}
                      {option.role === "student" && `, Grade ${option.grade}`}
                      {option.role === "student" &&
                        `, Sec ${
                          option.groupSection ? option.groupSection : ""
                        }`}
                    </Typography>
                    <Typography variant="caption">{option.email}</Typography>
                    {/* <img src={option.picUrl} alt="user image" /> */}
                  </div>
                </div>
              );
            }}
            options={searchSettings.suggestions}
            //   loading={loading}
            renderInput={(params) => (
              <TextField
                name="name"
                {...params}
                label={label}
                variant="outlined"
                InputProps={{ ...params.InputProps, readOnly: isReadOnly }}
                onChange={handleChange}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sm={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={checked}
                color={"primary"}
                onChange={handleShowHideSearchOptions}
              />
            }
            label="Search Filter"
          />
        </Grid>
      </Grid>
      <Fade in={checked}>
        <Grid
          container
          spacing={3}
          style={(() => {
            return checked
              ? { backgroundColor: "#f5f5f5", borderRadius: "10px" }
              : { display: "none" };
          })()}
        >
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%" }}
            >
              <InputLabel id="type-description">Role</InputLabel>
              <Select
                labelId="role"
                id="role"
                name="role"
                // value={grade}
                onChange={handleChange}
                label="Role"
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"student"}>Student</MenuItem>
                <MenuItem value={"teacher"}>Teacher</MenuItem>
                <MenuItem value={"domainHead"}>Domain Head</MenuItem>
                <MenuItem value={"areaCoordinator"}>Area Coordinator</MenuItem>
                <MenuItem value={"headOfSchool"}>Head of School</MenuItem>
                <MenuItem value={"staff"}>Staff</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%" }}
            >
              <InputLabel id="type-description">Grade</InputLabel>
              <Select
                labelId="grade"
                id="grade"
                name="grade"
                // value={grade}
                onChange={handleChange}
                label="Grade"
              >
                <MenuItem value={""}>None</MenuItem>
                <MenuItem value={"0"}>PP</MenuItem>
                <MenuItem value={"1"}>1</MenuItem>
                <MenuItem value={"2"}>2</MenuItem>
                <MenuItem value={"3"}>3</MenuItem>
                <MenuItem value={"4"}>4</MenuItem>
                <MenuItem value={"5"}>5</MenuItem>
                <MenuItem value={"6"}>6</MenuItem>
                <MenuItem value={"7"}>7</MenuItem>
                <MenuItem value={"8"}>8</MenuItem>
                <MenuItem value={"9"}>9</MenuItem>
                <MenuItem value={"10"}>10</MenuItem>
                <MenuItem value={"11"}>11</MenuItem>
                <MenuItem value={"12"}>12</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%" }}
            >
              <TextField
                id="groupSection"
                name="groupSection"
                label="Section/Group"
                type="text"
                //   value={tempSchoolRoadmap.focusArea}
                onChange={handleChange}
                variant="outlined"
                placeholder="Eg. 9A"
                fullWidth
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%" }}
            >
              <TextField
                id="nationalStudentCode"
                name="nationalStudentCode"
                label="Student Code"
                type="text"
                //   value={tempSchoolRoadmap.focusArea}
                onChange={handleChange}
                variant="outlined"
                placeholder=""
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%" }}
            >
              <TextField
                id="yearJoined"
                name="yearJoined"
                label="Year Joined"
                type="text"
                //   value={tempSchoolRoadmap.focusArea}
                onChange={handleChange}
                variant="outlined"
                placeholder=""
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "90%" }}
            >
              <InputLabel id="type-description">Status</InputLabel>
              <Select
                labelId="status"
                id="status"
                name="accountStatus"
                // value={grade}
                onChange={handleChange}
                label="Status"
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"active"}>Active</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Fade>
    </div>
  );
}
