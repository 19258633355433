import React, { useEffect } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { MenteeService } from "../../../services/mentee";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import Avatar from "@material-ui/core/Avatar";
import Toast from "../../helperComponents/toast";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AlertDialog from "../../helperComponents/alertDialog";
import { Factory } from "../../../helpers/factories";
import MentorMenteeDialog from "./mentorMenteeDialaog";
import MentorMenteeFilter from "./mentorMenteeFilter";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
// import StudentDialog from "./studentsDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dataGridRoot: {
    height: 400,
    width: "100%",
  },
  dataGrid: {
    backgroundColor: "white",
  },
  avatarBackground: {
    //color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: theme.palette.primary.main, //deepOrange[500],
  },
}));

export default function MenteesAll(props) {
  const classes = useStyles();
  const { currentUser } = useAppContext();

  const [user, setUser] = React.useState(Factory.createUser());

  //selected mentor mentee
  const [mentorMentee, setMentorMentee] = React.useState({});

  //current row selected
  const [selectedRowIndex, setSelectedRowIndex] = React.useState("");

  const [isDataLoading, setIsDataLoading] = React.useState(true);

  //list of mentees
  const [mentees, setMentees] = React.useState([]);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const [queryString, setQueryString] = React.useState({});

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 6000,
  });

  //alert dialog
  const [alertDialogOptions, setAlertDialogOptions] = React.useState({
    open: false,
    title: "",
    message: "Delete Mentor-Mentee?",
    cancelMessage: "Cancel",
    confirmMessage: "OK",
  });

  let rows = [];

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "mentee",
      headerName: "Mentee",
      width: 300,
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", marginRight: "10px" }}>
              <Avatar
                src={params.row.mentee.picURL}
                alt={params.row.mentee.firstName}
                className={classes.avatarBackground}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2">
                {params.row.mentee.firstName + " " + params.row.mentee.lastName}
              </Typography>
              <Typography variant="caption">
                {params.row.mentee.email}, Grade {params.row.mentee.grade}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      field: "mentor",
      headerName: "Mentor",
      width: 300,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", marginRight: "10px" }}>
              <Avatar
                src={params.row.mentor.picURL}
                alt={params.row.mentor.firstName}
                className={classes.avatarBackground}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2">
                {params.row.mentor.firstName + " " + params.row.mentor.lastName}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      field: "yearJoined",
      headerName: "Mentee Year Joined",
      width: 200,
      renderCell: (params) => {
        return (
          <Typography variant="body2">
            {params.row.mentee.yearJoined}
          </Typography>
        );
      },
    },
    ...(currentUser.role === "schoolAdmin"
      ? [
          {
            field: "",
            headerName: "",
            disableClickEventBubbling: true,

            renderCell: (params) => {
              const onClick = () => {
                setMentorMentee(params.row);
                setSelectedRowIndex(params.row.id);
                handleConfirmDeleteMentorMentee();
                //return alert(JSON.stringify(thisRow, null, 4));
              };
              return (
                <div>
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        return onClick();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              );
            },
          },
        ]
      : []),
  ];

  //pre-load verify token
  useEffect(() => {
    if (props.startFetchData) {
      getMenteesByOrgId();
    }

    async function getMenteesByOrgId() {
      try {
        setMentees(
          await MenteeService.getMenteesByOrgId(
            currentUser.organisation.id,
            queryString
          )
        );

        setIsDataLoading(false);
      } catch (err) {
        setErrorLoadingPage(true);
      }
    }
  }, [props.startFetchData, queryString]);

  function loadRows(mentorMentees) {
    let temp = [];
    mentorMentees.forEach((mentorMentee, index, anns) => {
      temp.push({
        id: index,
        mentor: mentorMentee.mentor,
        mentee: mentorMentee.mentee,
        // menteePicURL: mentorMentee.mentee.picURL,
        // mentorPicURL: mentorMentee.mentor.picURL,
        // email: mentorMentee.mentee.email,
        // grade: mentorMentee.mentee.grade,
        // yearJoined: mentorMentee.mentee.yearJoined,
        // status: mentorMentee.mentee.status,
        // address: mentorMentee.mentee.address,
        // guardianEmergencyContact: mentorMentee.mentee.guardianEmergencyContact,
      });
    });
    return temp;
  }

  function handleAddMentees() {
    //setFormData({ title: " ", body: " ", status: "unpublished" });
    setIsDialogOpen(true);
  }

  function handleCloseDialog() {
    setIsDialogOpen(false);
  }

  function handleRowClicked(rowInfo) {
    handleOpenDialog();
  }

  function handleOpenDialog() {
    setIsDialogOpen(true);
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  //alert dialgo

  function handleOpenAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: true });
  }

  function handleCloseAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
  }

  function handleConfirmDeleteMentorMentee() {
    handleOpenAlertDialog();
  }

  async function handleProceedDeleteMentorMentee() {
    //delete data
    let data = { mentee: mentorMentee.mentee._id };

    setAlertDialogOptions({ ...alertDialogOptions, open: false });
    let temp = [...mentees];
    temp.splice(selectedRowIndex, 1);
    try {
      await MenteeService.deleteMentorMenteeByOrgIdAndUserId(
        currentUser.organisation.id,
        mentorMentee.mentor._id,
        data
      );
      setMentees(temp);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Mentor-Mentee Deleted",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error deleting mentor-mentee",
      });
    }
  }

  //data grid

  function MenteeDataGrid(props) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.dataGridRoot}>
          <DataGrid
            className={classes.dataGrid}
            isDataLoading={isDataLoading}
            rows={rows}
            columns={columns}
            autoHeight={true}
            pageSize={10}
            {...props}
            onRowClick={
              currentUser.role === "schoolAdmin" ? handleRowClicked : null
            }
          />
        </Grid>
      </Grid>
    );
  }

  function menteeComponent(props) {
    // return (
    //   <Fragment>
    //     <StudentDialog isDialogOpen={isDialogOpen} formData={formData} />
    //     {AnnouncementDataGrid({ loading: isDataLoading })}
    //   </Fragment>
    // );
    return (
      <div className={classes.root}>
        <AlertDialog
          alertDialogOptions={alertDialogOptions}
          handleClose={handleCloseAlertDialog}
          handleConfirm={handleProceedDeleteMentorMentee}
        />
        <Toast
          toastOptions={toastOptions}
          handleCloseToast={handleCloseToast}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MentorMenteeDialog
              toastOptions={toastOptions}
              setToastOptions={setToastOptions}
              handleCloseToast={handleCloseToast}
              isDialogOpen={isDialogOpen}
              handleCloseDialog={handleCloseDialog}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {[
              "schoolAdmin",
              "teacher",
              "domainHead",
              "areaCoordinator",
              "headOfSchool",
            ].includes(currentUser.role) && (
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleAddMentees}
              >
                Add Mentor Mentee
              </Button>
            )}
          </Grid>
          <MentorMenteeFilter
            user={user}
            setUser={setUser}
            setIsDialogOpen={setIsDialogOpen}
            setQueryString={setQueryString}
          />
          {MenteeDataGrid({ loading: isDataLoading })}
        </Grid>
      </div>
    );
  }

  if (!errorLoadingPage) {
    rows = loadRows(mentees);
    return menteeComponent();
  } else {
    return <ErrorLoadingPage />;
  }

  // if (menteesLoaded) {
  //   rows = loadRows(mentees);
  //   return menteeComponent({ loading: isDataLoading });
  // } else if (isDataLoading & !errorLoadingPage) {
  //   return menteeComponent({ loading: isDataLoading });
  // } else {
  //   return <ErrorLoadingPage />;
  // }
}
