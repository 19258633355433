import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  contentWrapper: {
    margin: "40px 16px",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
}));

function BBChatbot(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">
            Learn about the Bhutan Baccalaureate
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <iframe
              //updated new link for "bbchatbot"
              src="https://www.chatbase.co/chatbot-iframe/c2tkVPVl3boz98vY8Bfdz"
              title="Bhutan Baccalaureate Chat Bot"
              style={{ width: "100%", height: "400px", border: 0 }}
            ></iframe>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default BBChatbot;
