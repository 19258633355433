import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },

  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    marginLeft: theme.spacing(2), // Adjust the left margin
  },
  paragraph1: {
    fontWeight: 800, // Semi-bold
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  paragraph2: {
    color: "#333333", // Dark gray
    fontWeight: 400, // Semi-bold
    fontSize: "0.8rem",
  },
  paragraph3: {
    color: "#333333", // Dark gray
    fontWeight: 700, // Semi-bold
    fontSize: "0.8rem",
  },
  examKey: {
    fontWeight: 500, // Semi-bold text
    fontSize: "0.9rem",
    color: "#6e1235",
    cursor: "pointer",
  },

  itemSeparator: {
    borderBottom: `0.5px solid #D3D3D3`, //gray color
    // Add border bottom line
    paddingBottom: theme.spacing(0.5), // Adjust space between items
    marginBottom: theme.spacing(3), // Adjust space between items
  },
}));

// Data for the link for exam net

const data1 = [
  {
    grade: "Online Review Trial Run",
    Exam_key: "5Ysas9",
  },
];

const data = [
  {
    grade: "5 & 6",
    Exam_key: "JCr5Je",
  },
  {
    grade: 7,
    Exam_key: "ErBHWB",
  },
  {
    grade: 8,
    Exam_key: "fFj6Dm",
  },
  {
    grade: 9,
    Exam_key: "KkbM6T",
  },
  {
    grade: 11,
    Exam_key: "7PAjmN",
  },
];

function ReviewMain() {
  const classes = useStyles();

  const [copied, setCopied] = React.useState(false);

  // Function to copy the Exam Key to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000); // Hide after 1 second
  };
  return (
    <div>
      <h1>Review</h1>
      <div className={classes.flexContainer}>
        <p className={classes.paragraph1}>Exam.net Link</p>
        <a
          href="https://exam.net"
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here
        </a>
      </div>

      {data1.map((item, index) => (
        <div key={index}>
          {/* Wrapping only the first paragraph and link */}

          <p className={classes.paragraph3}>
            Online Review Trial Run :{"  "}
            <Tooltip title={copied ? "Copied" : "Copy the Exam Key"}>
              <span
                className={classes.examKey}
                onClick={() => copyToClipboard(item.Exam_key)}
              >
                {item.Exam_key}
              </span>
            </Tooltip>
          </p>
          {/* soft underline for each item */}
          <div className={classes.itemSeparator} />
        </div>
      ))}

      {data.map((item, index) => (
        <div key={index}>
          {/* Wrapping only the first paragraph and link */}

          <p className={classes.paragraph2}>
            Exam Key for grade {item.grade} :{"  "}
            <Tooltip title={copied ? "Copied" : "Copy the Exam Key"}>
              <span
                className={classes.examKey}
                onClick={() => copyToClipboard(item.Exam_key)}
              >
                {item.Exam_key}
              </span>
            </Tooltip>
          </p>
          {/* soft underline for each item */}
          <div className={classes.itemSeparator} />
        </div>
      ))}
    </div>
  );
}

export default ReviewMain;
