/*
 * Check if JWT Cookie set and then verify it
 */

import config from "../helpers/config";
import { RequestHelper } from "../helpers/requestHelper";

import { handleResponse } from "../helpers/handleResponse";

export const AnnouncementService = {
  getAnnouncementsByOrgId,
  addAnnouncementsByOrgId,
  deleteAnnouncementsByOrgIdAndAnnouncementId,
  updateAnnouncementByOrgIdAndAnnouncementId,
};

function getAnnouncementsByOrgId(orgId) {
  const requestOptions = RequestHelper.getGetRequestOptions();

  var api_url = config.api_announcements_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.announcements;
    });
}

function addAnnouncementsByOrgId(orgId, announcement) {
  const requestOptions = RequestHelper.getPostRequestOptions(announcement);

  var api_url = config.api_announcements_by_orgId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.announcement;
    });
}

function updateAnnouncementByOrgIdAndAnnouncementId(
  orgId,
  announcementId,
  announcement
) {
  const requestOptions = RequestHelper.getPutRequestOptions(announcement);

  var api_url = config.api_announcements_by_orgId_and_announcementId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    announcementId: announcementId,
  });
  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.announcement;
    });
}

function deleteAnnouncementsByOrgIdAndAnnouncementId(orgId, announcementId) {
  const requestOptions = RequestHelper.getDeleteRequestOptions();

  var api_url = config.api_announcements_by_orgId_and_announcementId;
  api_url = RequestHelper.replaceParams(api_url, {
    orgId: orgId,
    announcementId: announcementId,
  });

  //return Promise.reject(new Error());
  return fetch(api_url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data._id;
    });
}
