import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import SearchUser from "../../helperComponents/searchUser";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import SearchTag from "../../helperComponents/searchTags";
import SelectDomainArea from "../../helperComponents/selectDomainArea";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Toast from "../../helperComponents/toast";
import moment from "moment";
import SearchUserAdvanced from "../../helperComponents/searchUserAdvanced";

import { AssessmentService } from "../../../services/assessment";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterElements: {
    marginTop: "8px",
    marginBottom: "8px",
  },
}));

function AssessmentDialog({
  assessment,
  setAssessment,
  assessments,
  setAssessments,
  selectedRowIndex,
  opType,
  setOpType,
  toastOptions,
  setToastOptions,
  handleCloseToast,
  handleCloseDialog,
  isDialogOpen,
  ...props
}) {
  const { currentUser } = useAppContext();

  const classes = useStyles();

  //for search user component; must init otherwise get uncontrolled component error
  const [user, setUser] = React.useState({
    _id: "",
    role: "",
    firstName: "",
    lastName: "",
  });

  const [userQueryOptions, setUserQueryOptions] = React.useState({});

  //for search domain component
  const [domainArea, setDomainArea] = React.useState({
    _id: "",
    name: "",
    displayName: "",
  });

  //for search tag component
  const [tags, setTags] = React.useState([]);

  //for assessment type
  const [type, setType] = React.useState("");

  //for assessment type
  const [comment, setComment] = React.useState("");

  //for assessment description
  const [description, setDescription] = React.useState("");

  //hide from student
  const [studentAccess, setStudentAccess] = React.useState("");

  //keep dialog open; for adding new assessments without closing dialog
  // const [addNewAfterSave, setAddNewAfterSave] = React.useState(false);

  //useEffect
  useEffect(() => {
    //if operation type is Edit, load values
    if (opType === "edit") {
      // alert("edit");
      setDomainArea(assessment.domainArea);
      setUser(assessment.student);
      setTags(assessment.tags);
      setType(assessment.type);
      setComment(assessment.comment);
      setDescription(assessment.description);
      setStudentAccess(
        assessment.studentAccess ? assessment.studentAccess : ""
      );
    } else {
      // setUser({
      //   _id: "",
      //   role: "",
      //   firstName: "",
      //   lastName: "",
      // });
      // setDomainArea({
      //   _id: "",
      //   name: "",
      //   displayName: "",
      // });
      // setTags([]);
      // setType("");
      // setComment("");
      // setDescription("");
    }
  }, [assessment]);

  function isFormValid() {
    return (
      user._id &&
      domainArea._id &&
      tags.length > 0 &&
      type &&
      description &&
      studentAccess &&
      comment.length > 0
    );
  }

  const handleChangeAssessmentType = (e) => {
    setType(e.target.value);
  };

  const handleChangeAssessmentDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleChangeAccess = (e) => {
    setStudentAccess(e.target.value);
  };

  function handleInputChange(e) {
    setComment(e.target.value);
  }

  async function handleSaveAssessment(addNewAfterSave) {
    let newAssessment = {
      type: type,
      teacher: currentUser.id,
      student: user._id,
      domainArea: domainArea._id,
      comment: comment,
      tags: tags,
      description: description,
      studentAccess: studentAccess,
    };

    try {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Saving",
      });

      //check save or edit

      if (opType === "add") {
        const savedAssessment = await AssessmentService.addAssessmentsByOrgId(
          currentUser.organisation.id,
          newAssessment
        );
        //update datagrid
        let temp = [...assessments];
        temp.forEach((item, index) => {
          item["id"] = index + 1;
        });
        temp.unshift(savedAssessment);
        temp[0]["id"] = 0;
        temp[0]["dateCreated"] = moment(temp["dateCreated"]).format(
          "DD/MM/YYYY"
        );
        setAssessments(temp);
        setAssessment(savedAssessment);
      } else {
        //edit assessment in assessments to update table
        const updatedAssessment =
          await AssessmentService.updateAssessmentsByOrgIdAndAssessmentId(
            currentUser.organisation.id,
            assessment._id,
            newAssessment
          );
        let temp = [...assessments];
        updatedAssessment["id"] = selectedRowIndex;
        updatedAssessment["dateCreated"] = moment(
          updatedAssessment["dateCreated"]
        ).format("DD/MM/YYYY");
        temp[selectedRowIndex] = updatedAssessment;
        temp[selectedRowIndex] = updatedAssessment;
        setAssessments(temp);
      }

      //add new assessment after save
      if (!addNewAfterSave) {
        handleCloseDialog();
      } else {
        setOpType("add");
        setComment("");
        setTags([]);
      }

      //show saved message
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Assessment Saved",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error saving assessment",
      });
    }
  }

  //const { title } = dialogPropsMain;
  // setDialogProps(row);
  return (
    <Fragment>
      <Toast toastOptions={toastOptions} handleCloseToast={handleCloseToast} />
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          {opType === "edit" ? "Edit Assessment" : "Add Assessment"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SearchUserAdvanced
                    label={"Select a mentee"}
                    user={user}
                    setUser={setUser}
                    userQueryOptions={userQueryOptions}
                    setUserQueryOptions={setUserQueryOptions}
                    style={{ display: "flex" }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <SelectDomainArea
                    domainArea={domainArea}
                    setDomainArea={setDomainArea}
                    showAllOption={false}
                    orgId={currentUser.organisation.id}
                    style={{ display: "flex" }}
                    type={""}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ width: "90%" }}
                  >
                    <InputLabel id="type-label">Type</InputLabel>
                    <Select
                      labelId="type-label"
                      id="type-select"
                      value={type}
                      onChange={handleChangeAssessmentType}
                      label="Type"
                    >
                      <MenuItem value={"review"}>Review</MenuItem>
                      <MenuItem value={"observation"}>Observation</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ width: "90%" }}
                  >
                    <InputLabel id="type-description">Description</InputLabel>
                    <Select
                      labelId="type-label"
                      id="type-select"
                      value={description}
                      onChange={handleChangeAssessmentDescription}
                      label="Description"
                    >
                      <MenuItem value={"mainly positive"}>
                        Mainly Positive
                      </MenuItem>
                      <MenuItem value={"mainly negative"}>
                        Mainly Negative
                      </MenuItem>
                      <MenuItem value={"mixed"}>Mixed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ width: "90%" }}
                  >
                    <InputLabel id="type-description">
                      Student Access
                    </InputLabel>
                    <Select
                      labelId="type-label"
                      id="type-select"
                      value={studentAccess}
                      onChange={handleChangeAccess}
                      label="Student Access"
                    >
                      <MenuItem value={"hide"}>Hide From Student</MenuItem>
                      <MenuItem value={"show"}>Show Student</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <SearchTag tags={tags} setTags={setTags} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                margin="dense"
                id="body"
                name="body"
                label="Assessment"
                type="text"
                value={comment}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={() => {
              handleSaveAssessment(false);
            }}
            color="primary"
          >
            Save and Close
          </Button>
          <Button
            disabled={!isFormValid()}
            onClick={() => {
              handleSaveAssessment(true);
            }}
            color="primary"
          >
            Save and Add New
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default AssessmentDialog;
