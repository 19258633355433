import React, { useState } from "react";
import { TagService } from "../../services/tag";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";

import Autocomplete from "@material-ui/lab/Autocomplete";

export default function SearchTag({ tags, setTags, label, ...rest }) {
  // const { currentUser } = useAppContext();

  //autocomplete search settings
  const [searchSettings, setSearchSettings] = useState({
    suggestions: [],
  });

  //autcomplete loading
  // const [loading, setLoading] = useState(true);

  //handle key change
  const handleChange = (e) => {
    //query options
    let queryOptions = {
      tag: e.target.value,
    };
    //call api
    (async () => {
      try {
        const filteredTags = await TagService.getTags(queryOptions);
        setSearchSettings({
          ...searchSettings,
          suggestions: filteredTags,
        });
      } catch (err) {
        setSearchSettings({
          ...searchSettings,
          suggestions: [],
        });
        // props.setToastOptions({
        //   ...props.toastOptions,
        //   open: true,
        //   message: "Error loading domains/areas. Try refreshing the page.",
        // });
      }
    })();
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      id="tags-standard"
      // inputProps={{ maxLength: 30 }}
      options={searchSettings.suggestions}
      getOptionLabel={(option) => option.tag || option} //option is important for custom input
      value={tags}
      defaultValue={[]}
      onChange={(e, v, reason) => {
        let temp = [];
        v.forEach((item) => {
          temp.push(item["tag"] || item);
        });
        if (v != null) {
          setTags(temp);
        } else {
          setTags([]);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={
            label
              ? label
              : "Select a tag or type a new one and press Enter. Please USE adjectives and AVOID phrases or sentences."
          }
          placeholder="Tags"
          inputProps={{ ...params.inputProps, maxLength: 30 }}
          onKeyDown={(e) => {
            if (e.key === "@" || e.key === "#") {
              e.preventDefault();
            }
          }}
          onChange={handleChange}
        />
      )}
      // renderInput={(params) => (
      //   <TextField
      //     {...params}
      //     variant="standard"
      //     label={
      //       label
      //         ? label
      //         : "Select a tag or type a new one and press Enter. Please USE adjectives and AVOID phrases or sentences."
      //     }
      //     placeholder="Tags"
      //     inputProps={{ ...params.inputProps, maxLength: 15 }}
      //     onChange={handleChange}
      //   />
      // )}
      //   renderInput={(params) => (
      //     <TextField {...params} variant="filled" label="freeSolo" placeholder="Favorites"  />
      //   )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.tag || option}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );

  //   return (
  //     <Autocomplete
  //       id="asynchronous-demo"
  //       //size="small"
  //       style={{ width: "100%", margin: "16px" }}
  //       value={selectedTag ? selectedTag : null}
  //       onChange={(e, v, reason) => {
  //         if (v != null) {
  //           console.log("Value", v);
  //           setSelectedTag(v);
  //           props.setTags({
  //             selectedTag,
  //           });
  //         } else {
  //           props.setTags({
  //             selectedTag,
  //           });
  //         }
  //       }}
  //       getOptionSelected={(option, value) => option.tag === value.tag}
  //       getOptionLabel={(option) => option.tag}
  //       renderOption={(option) => {
  //         console.log("Option", option);
  //         return (
  //           <div
  //             style={{
  //               display: "flex",
  //               width: "100%",
  //             }}
  //           >
  //             <div style={{ display: "flex" }}>
  //               <Typography variant="body2">{option.tag}</Typography>
  //             </div>
  //           </div>
  //         );
  //       }}
  //       options={searchSettings.suggestions}
  //       // loading={loading}
  //       renderInput={(params) => (
  //         <TextField
  //           {...params}
  //           label="Enter tags"
  //           variant="outlined"
  //           onChange={handleChange}
  //         />
  //       )}
  //     />
  //   );
}
