import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../libs/contextLib";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { AffiliationService } from "../../../services/portrait";
import { PortraitService } from "../../../services/portrait";

import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import ErrorLoadingPage from "../../errorPages/errorLoadingPage";
import AlertDialog from "../../helperComponents/alertDialog";
import Toast from "../../helperComponents/toast";
import AddEditPortraitsDialog from "./addEditPortraitsDialog";
import { Factory } from "../../../helpers/factories";
import DescriptionIcon from "@material-ui/icons/Description";
import SearchUserAdvanced from "../../helperComponents/searchUserAdvanced";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  dataGridRoot: {
    height: "100vh",
    width: "100%",
  },
  dataGrid: {
    backgroundColor: "white",
  },
}));

function Portraits(props) {
  const classes = useStyles();
  const { currentUser } = useAppContext();

  //active portraits
  const [portrait, setPortrait] = React.useState(Factory.createPortrait());

  //loaded portraits
  const [portraits, setPortraits] = React.useState([]);

  //for search user component; must init otherwise get uncontrolled component error; state set if user student to prevent generating portraits of others
  const [user, setUser] = React.useState(
    currentUser.role === "student"
      ? { _id: currentUser.id }
      : {
          _id: "",
          role: "",
          firstName: "",
          lastName: "",
        }
  );

  //search for user
  const [userQueryOptions, setUserQueryOptions] = React.useState([]);

  //state set if user student to prevent generating portraits of others
  const [queryString, setQueryString] = React.useState(
    currentUser.role === "student" ? { student: currentUser.id } : []
  );

  //current row selected
  const [selectedRowIndex, setSelectedRowIndex] = React.useState("");

  //operation type Save or Edit
  const [opType, setOpType] = React.useState("");

  //data is loading
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [errorLoadingPage, setErrorLoadingPage] = React.useState(false);

  //portrait dialog
  const [isAddEditPortraitDialogOpen, setIsAddEditPortraitDialogOpen] =
    React.useState(false);

  //toast options
  const [toastOptions, setToastOptions] = React.useState({
    open: false,
    message: "",
    duration: 6000,
  });

  //alert dialog
  const [alertDialogOptions, setAlertDialogOptions] = React.useState({
    open: false,
    title: "",
    message: "Delete portrait?",
    cancelMessage: "Cancel",
    confirmMessage: "OK",
  });

  //rows for datagrid

  let rows = [];

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "_id", headerName: "_id", width: 120, hide: "true" },
    {
      field: "student",
      headerName: "Name",
      width: 260,
      renderCell: (params) => {
        return params.row.student.firstName + " " + params.row.student.lastName;
      },
    },
    { field: "portraitGrade", headerName: "Portrait Grade", width: 160 },
    { field: "portraitYear", headerName: "Portrait Year", width: 130 },
    {
      field: "docURL",
      headerName: "Document",
      width: 120,
      renderCell: (params) => {
        return params.row.docURL ? (
          <Tooltip title="Download Portrait Document">
            <IconButton
              aria-label="download"
              onClick={() => {
                return downloadPortraitDocument(params.row.docURL);
              }}
            >
              <DescriptionIcon />
            </IconButton>
          </Tooltip>
        ) : (
          "None"
        );
      },
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 120,
      renderCell: (params) => {
        return moment(params.row.dateCreated).format("DD-MM-yyyy");
      },
    },
    {
      field: "",
      headerName: "",
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const onClick = (op) => {
          setPortrait(params.row);
          setSelectedRowIndex(params.row.id);
          op === "edit" ? handleEditPortrait() : handleConfirmDeletePortrait();
          //return alert(JSON.stringify(thisRow, null, 4));
        };
        if (
          ["teacher", "domainHead", "areaCoordinator", "headOfSchool"].includes(
            currentUser.role
          )
        ) {
          return (
            <div>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="edit"
                  onClick={() => {
                    return onClick("edit");
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    return onClick("delete");
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  ];

  //pre-load verify token
  useEffect(() => {
    if (props.startFetchData) {
      onLoad();
    }
  }, [props.startFetchData]);

  //for filtering portraits according to searched user
  useEffect(() => {
    setQueryString({ student: user._id });
  }, [user]);

  //for searching for user
  useEffect(() => {
    onLoad();
  }, [queryString]);

  async function onLoad() {
    try {
      const portraits = await PortraitService.getPortraits(queryString);
      setPortraits(portraits);
      setIsDataLoading(false);
    } catch (err) {
      setErrorLoadingPage(true);
    }
  }

  function loadRows(orgs) {
    let temp = [];
    portraits.forEach((portrait, index, orgs) => {
      portrait["id"] = index;
      temp.push(portrait);
    });
    return temp;
  }

  function handleCreatePortrait() {
    setPortrait(Factory.createPortrait());
    setOpType("add");
    setIsAddEditPortraitDialogOpen(true);
  }

  function handleCloseAddEditPortraitDialog() {
    setIsAddEditPortraitDialogOpen(false);
  }

  const handleEditPortrait = () => {
    setOpType("edit");
    setIsAddEditPortraitDialogOpen(true);
  };

  function handleOpenAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: true });
  }

  function handleCloseAlertDialog() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
  }

  const downloadPortraitDocument = async (docURL) => {
    setToastOptions({
      ...toastOptions,
      open: true,
      message: "Downloading...",
    });

    try {
      const url = await PortraitService.downloadPortrait({
        docURL: docURL,
      });
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "File Downloaded",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Error dowloading file",
      });
    }
  };

  function handleConfirmDeletePortrait() {
    handleOpenAlertDialog();
  }

  async function handleProceedDeletePortrait() {
    setAlertDialogOptions({ ...alertDialogOptions, open: false });
    let temp = [...portraits];
    temp.splice(selectedRowIndex, 1);
    try {
      await PortraitService.deletePortraitById(
        currentUser.organisation.id,
        portrait._id
      );
      setPortraits(temp);
      setToastOptions({
        ...toastOptions,
        open: true,
        message: "Portrait Deleted",
      });
    } catch (err) {
      setToastOptions({
        ...toastOptions,
        open: true,
        message: err.message,
      });
    }
  }

  //toast
  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOptions({ ...toastOptions, open: false });
  };

  //data grid
  function PortraitDataGrid(props) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.dataGridRoot}>
          <DataGrid
            className={classes.dataGrid}
            rows={rows}
            columns={columns}
            pageSize={10}
            autoHeight={true}
            loading={isDataLoading}
            {...props}
          />
        </Grid>
      </Grid>
    );
  }

  //assessment
  function PortraitComponent(props) {
    return (
      <Fragment>
        <Toast
          toastOptions={toastOptions}
          handleCloseToast={handleCloseToast}
        />
        <AlertDialog
          alertDialogOptions={alertDialogOptions}
          handleClose={handleCloseAlertDialog}
          handleConfirm={handleProceedDeletePortrait}
        />
        <AddEditPortraitsDialog
          toastOptions={toastOptions}
          setToastOptions={setToastOptions}
          handleCloseToast={handleCloseToast}
          opType={opType}
          setOpType={setOpType}
          portrait={portrait}
          setPortrait={setPortrait}
          selectedRowIndex={selectedRowIndex}
          portraits={portraits}
          setPortraits={setPortraits}
          isAddEditPortraitDialogOpen={isAddEditPortraitDialogOpen}
          handleCloseAddEditPortraitDialog={handleCloseAddEditPortraitDialog}
        />
        <div className={classes.root}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
              <Typography variant="h4" className={classes.textCenter}>
                Portraits
              </Typography>
            </Grid> */}

            {[
              "teacher",
              "domainHead",
              "areaCoordinator",
              "headOfSchool",
            ].includes(currentUser.role) ? (
              <Grid
                item
                xs={12}
                sm={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleCreatePortrait}
                >
                  Add Portrait
                </Button>
              </Grid>
            ) : null}
            {[
              "teacher",
              "domainHead",
              "areaCoordinator",
              "headOfSchool",
            ].includes(currentUser.role) ? (
              <Grid item xs={12} sm={12}>
                <SearchUserAdvanced
                  user={user}
                  setUser={setUser}
                  userQueryOptions={userQueryOptions}
                  setUserQueryOptions={setUserQueryOptions}
                  // style={{ display: "flex" }}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={6}>
              {/* <SearchAffiliations
                portrait={portrait}
                setPortrait={setPortrait}
                queryString={queryString}
                setQueryString={setQueryString}
              /> */}
            </Grid>
            <Grid item xs={12}>
              {/* <OrganisationDialog
                isDialogOpen={isDialogOpen}
                organisation={organisation}
                setOrganisation={setOrganisation}
                organisations={organisations}
                setOrganisations={setOrganisations}
                selectedRowIndex={selectedRowIndex}
                setSelectedRowIndex={setSelectedRowIndex}
                handleCloseDialog={handleCloseDialog}
                opType={opType}
                setOpType={setOpType}
                toastOptions={toastOptions}
                setToastOptions={setToastOptions}
              /> */}
            </Grid>
            {PortraitDataGrid()}
          </Grid>
        </div>
      </Fragment>
    );
  }

  if (!errorLoadingPage) {
    rows = loadRows(portraits);
    return PortraitComponent();
  } else {
    return <ErrorLoadingPage />;
  }

  // if (organisationsLoaded) {
  //   rows = loadRows(organisations);
  //   return PortraitComponent({ loading: isDataLoading });
  // } else if (isDataLoading & !errorLoadingPage) {
  //   return PortraitComponent({ loading: isDataLoading });
  // } else {
  //   return <ErrorLoadingPage />;
  // }
}

export default Portraits;
