import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Auth } from "../../../services/auth";
import { Toast } from "../../helperComponents/toastComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    padding: theme.spacing(4),
    overflow: "hidden",
  },
  contentWrapper: {
    margin: "40px 16px",
  },
  formRoot: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  textFieldBlockTitle: {
    marginTop: theme.spacing(2),
    display: "block",
  },
}));

function ResetPassword(props) {
  const classes = useStyles();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  //toast options
  const ToastComponent = Toast.toastComponent;
  const [toastOptions, setToastOptions] = React.useState(Toast.toastOptions());
  //toast
  const handleCloseToast = Toast.handleCloseToast(setToastOptions);

  //validate form simple
  function validateForm() {
    return (
      oldPassword.length >= 8 &&
      newPassword.length >= 8 &&
      confirmNewPassword.length >= 8 &&
      newPassword == confirmNewPassword
    );
  }

  //submit
  async function handleSubmit(event) {
    event.preventDefault(); //prevent browser default submit
    let body = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
    };

    setToastOptions({
      ...toastOptions,
      open: true,
      message: "Saving...",
    });

    Auth.resetPassword(body).then(
      (result) => {
        setToastOptions({
          ...toastOptions,
          open: true,
          message: "Password saved",
        });
      },
      (error) => {
        setToastOptions({
          ...toastOptions,
          open: true,
          message: "Error saving password", //error.message,
        });
      }
    );
  }

  return (
    <div className={classes.root}>
      <ToastComponent
        toastOptions={toastOptions}
        handleCloseToast={handleCloseToast}
      />
      <Grid
        container
        spacing={3}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {/* <Grid item xs={12}>
          <Typography variant="h5">Reset Password</Typography>
        </Grid> */}
        <Grid item xs={12} sm={8} style={{ display: "flex" }}>
          <Paper className={classes.paper}>
            <form className={classes.formRoot} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h5">Reset Password</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="oldPassword">
                      Current Password
                    </InputLabel>
                    <Input
                      id="oldPassword"
                      type="password"
                      aria-describedby="my-helper-text"
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <FormHelperText id="my-helper-text">
                      Your current password.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="newPassword">New Password</InputLabel>
                    <Input
                      id="newPassword"
                      aria-describedby="my-helper-text"
                      type="password"
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <FormHelperText id="my-helper-text">
                      Your new password must be atleast 8 characters long.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="confirmNewPassword">
                      Confirm New Password
                    </InputLabel>
                    <Input
                      id="confirmNewPassword"
                      type="password"
                      aria-describedby="my-helper-text"
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                    <FormHelperText id="my-helper-text">
                      Please confirm your new password.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={!validateForm()}
                    onClick={handleSubmit}
                  >
                    Save Password
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ResetPassword;
