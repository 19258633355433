import React from "react";
import LocalHotelIcon from "@material-ui/icons/LocalHotel";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  centerText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "70vh",
    flexDirection: "column",
  },
}));

export default function ErrorLoadingPage(props) {
  const classes = useStyles();

  return (
    <div className={classes.centerText}>
      <div style={{ display: "flex" }}>
        <LocalHotelIcon style={{ fontSize: "80px" }} />
      </div>
      <div style={{ display: "flex" }}>
        <Typography
          variant="h2"
          gutterBottom
          style={{ fontFamily: "Sacramento, cursive" }}
        >
          Oops!
        </Typography>
      </div>
      <div style={{ display: "flex" }}>
        <Typography variant="h6" gutterBottom>
          {props.message ? props.message : "The page couldn't be displayed."}
        </Typography>
      </div>
    </div>
  );
}
