import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

function Toast({ toastOptions, handleCloseToast, ...rest }) {
  return (
    <Snackbar
      open={toastOptions.open}
      message={toastOptions.message}
      autoHideDuration={toastOptions.duration ? toastOptions.duration : 600}
      onClose={handleCloseToast}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseToast}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
}

export default Toast;
